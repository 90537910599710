<xpo-ltl-dialog-title>Shipment Warnings</xpo-ltl-dialog-title>
<div mat-dialog-content>
  <h3>Highlighted shipments will not be assigned unless selected</h3>
  <table mat-table matSort [dataSource]="dataSource" matSortActive="tdcWarnings" matSortDirection="asc">
    <ng-container *ngFor="let column of visibleColumns" [matColumnDef]="column.field">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column.name }}
      </th>

      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="column.field">
          <ng-container *ngSwitchCase="specialServicesField">
            <pnd-special-services
                [value]="element[column.field]"
                [specialServiceMarks]="element['specialServiceMarks']"></pnd-special-services>
          </ng-container>
          <ng-container *ngSwitchCase="consigneeField">
            <a (click)="onConsigneeClick(element[column.field][1])">
              {{ element[column.field][0] }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="warningMessagesField">
            <ng-container *ngFor="let warningMessage of element[column.field]">
              <ng-template
                  [ngTemplateOutlet]="warningMessageDisplay"
                  [ngTemplateOutletContext]="{ message: warningMessage }"></ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ element[column.field] }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleAllOnOff($event)" [checked]="isToggleAllOn"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox
            #checkboxes
            (change)="addSelectionToOverride($event, element)"
            [checked]="!element.tdcWarnings"
            [disabled]="element.disabled"></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnFields; sticky: true"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: columnFields"
        [ngClass]="{ 'tdc-error': row.disabled, 'tdc-warning': row.tdcWarnings }"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button [disabled]="selectedsToOverride.length === 0" mat-flat-button [mat-dialog-close]="selectedsToOverride">
    ASSIGN
  </button>
  <button mat-stroked-button (click)="cancel()">
    CANCEL
  </button>
</div>

<ng-template #warningMessageDisplay let-message="message">
  <div class="tdc-message">
    <div [ngClass]="{ 'error-circle': message.isError, 'tdc-circle': !message.isError }"></div>
    <span>{{ message.message }}</span>
  </div>
</ng-template>
