import { NumberToValueMap } from '@pnd-store/number-to-value-map';
import { DockActivity, Stop } from '@xpo-ltl/sdk-cityoperations';

import { DispatcherTripsGridItem } from 'app/inbound-planning/components/dispatcher-trips/models/dispatcher-trips-grid-item.model';

import { TripPlanningGridItem } from 'app/inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import { PrintGridSources } from './print-grid-sources.enum';

export class PrintDialogData {
  source: PrintGridSources;
  sicCd: string;
  trips?: TripPlanningGridItem[];
  proNbrs?: string[];
  routeInstIds?: number[];
  bolInstId?: string[];
  tripInstIds?: number[];
  dockActivities?: DockActivity[];

  dispatchTrips?: DispatcherTripsGridItem[];

  stops?: NumberToValueMap<Stop[]>;
  isFbdsDisabled?: boolean = false;
  isEbolDisabled?: boolean = true;
  isDockDisabled?: boolean = false;
  isPndDisabled?: boolean = false;
  isDsrDocDisabled?: boolean = false;
  isObmDisabled?: boolean = false;
}
