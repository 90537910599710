import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridRowErrors } from '../../models/grid-errors';

export enum ErrorLabel {
  ERRORS = 'Errors',
  ON_HOLD = 'On Hold',
}

@Component({
  selector: 'pnd-grid-errors',
  templateUrl: './grid-errors.component.html',
  styleUrls: ['./grid-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridErrorsComponent {
  private _errors$: Observable<GridRowErrors> = of({});

  @Input() errorLabel: string = ErrorLabel.ERRORS;

  @Input() set errors$(errors$) {
    this._errors$ = errors$;

    this.displayErrors$ = this.errors$.pipe(map((e) => !!_size(e)));
  }
  get errors$(): Observable<GridRowErrors> {
    return this._errors$;
  }

  displayErrors$: Observable<boolean> = of(false);

  constructor() {}
}
