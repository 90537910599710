import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConditioningService } from '@xpo-ltl/common-services';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ag-grid';
import { XpoLtlFormatValidationService, ProFormatterPipe } from '@xpo-ltl/ngx-ltl';
import { ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  CellClickedEvent,
  GridOptions,
  IAfterGuiAttachedParams,
  ICellRendererParams,
  ValueGetterParams,
  ProcessCellForExportParams,
  GridReadyEvent,
  GridApi,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community';
import { trim as _trim, toString as _toString, size as _size } from 'lodash';
import { BoardUtils } from 'shared/board-utils';
import { PndDialogService } from '../../../../../core/dialogs/pnd-dialog.service';
import { UnassignedPickupsDetailGridFieldsWidth } from '../../../components/unassigned-pickups/enums/unassigned-pickups-detail-grid-fields-width.enum';
import { UnassignedPickupsDetailGridFields } from '../../../components/unassigned-pickups/enums/unassigned-pickups-detail-grid-fields.enum';
import { UnassignedPickupsDetailGridHeaders } from '../../../components/unassigned-pickups/enums/unassigned-pickups-detail-grid-headers.enum';
import { UnassignedPickupsDetailGridTypes } from '../../../components/unassigned-pickups/enums/unassigned-pickups-detail-grid-types.enum';
import { UnassignedPickupsGridItem } from '../../../components/unassigned-pickups/models/unassigned-pickups-grid-item.model';
import { UnassignedPickupsLineItem } from '../../../components/unassigned-pickups/models/unassigned-pickups-line-item.model';
import { UnassignedPickupsRowHeightConfig } from '../../../components/unassigned-pickups/models/unassigned-pickups-row-height-config.model';
import { Note } from '../../models/note.model';
import { UnassignedPickupsService } from '../../services/unassigned-pickups.service';
import { UnassignedPickupsDetailGridData } from './../../../components/unassigned-pickups/models/unassigned-pickups-detail-grid-item.model';
import { NotesCellRendererComponent } from './../notes-cell-renderer/notes-cell-renderer.component';
import { SpecialServicesCellRendererComponent } from './../special-services-cell-renderer/special-services-cell-renderer.component';
import { VisibilityCellRendererComponent } from './../visibility-cell-renderer/visibility-cell-renderer.component';

export interface UnassignedPickupDetailGridRendererParams extends ICellRendererParams {
  detailGridType: (nodeId: string) => UnassignedPickupsDetailGridTypes;
  getDetailRowData: (pickup: UnassignedPickupsGridItem) => UnassignedPickupsDetailGridData;
  getCurrentRowHeight: () => number;
}

@Component({
  selector: 'pnd-unassigned-pickup-detail-grid-renderer',
  templateUrl: './unassigned-pickup-detail-grid-renderer.component.html',
  styleUrls: ['./unassigned-pickup-detail-grid-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnassignedPickupDetailGridRendererComponent implements ICellRendererAngularComp {
  constructor(
    protected formatValidationService: XpoLtlFormatValidationService,
    protected proFormatterPipe: ProFormatterPipe,
    protected conditioningService: ConditioningService,
    protected pndDialogService: PndDialogService,
    protected unassignedPickupsService: UnassignedPickupsService
  ) {}

  readonly remarksRowHeight = UnassignedPickupsRowHeightConfig.DETAIL_ROW_HEIGHT_REMARKS;
  detailGridTypes = UnassignedPickupsDetailGridTypes;
  detailGridType: UnassignedPickupsDetailGridTypes;

  lineItemsRowData: UnassignedPickupsLineItem[];
  notesRowData: Note[];
  parentCallNbr: string;
  gridApi: GridApi;

  getCurrentRowHeight: () => number;
  lineItemsGridOptions: GridOptions = {
    frameworkComponents: {
      specialServicesCellRenderer: SpecialServicesCellRendererComponent,
      visibilityCellRenderer: VisibilityCellRendererComponent,
      notesCellRenderer: NotesCellRendererComponent,
    },
    rowClass: 'unassignedPickupsDetailGrid__lineItems__row',
    enableCellTextSelection: false,
    headerHeight: UnassignedPickupsRowHeightConfig.HEADER_HEIGHT,
    defaultCsvExportParams: {
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const headerName: string = params.column.getColDef().headerName;

        if (headerName === UnassignedPickupsDetailGridHeaders.PRO_NBR) {
          const proValue = params.value;

          return proValue && this.formatValidationService.isValidProNumber(_toString(proValue))
            ? this.proFormatterPipe.transform(proValue, 9)
            : proValue;
        } else {
          return params.value;
        }
      },
    },
  };

  notesGridOptions: GridOptions = {
    frameworkComponents: {
      specialServicesCellRenderer: SpecialServicesCellRendererComponent,
      visibilityCellRenderer: VisibilityCellRendererComponent,
      notesCellRenderer: NotesCellRendererComponent,
    },
    rowClass: 'unassignedPickupsDetailGrid__remarks__row',
    enableCellTextSelection: false,
    headerHeight: 40,
  };

  remarksColumnDefs = [
    {
      headerName: UnassignedPickupsDetailGridHeaders.NOTES,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.NOTES,
      colId: UnassignedPickupsDetailGridFields.NOTES,
      suppressMenu: true,
      width: UnassignedPickupsDetailGridFieldsWidth.NOTES,
      lockPosition: true,
      cellClass: 'unassignedPickupsDetailGrid__remarks__row__cell',
      cellRenderer: 'notesCellRenderer',
      valueGetter: (params: ValueGetterParams) => {
        return params.data;
      },
    },
  ];

  lineItemsColumnDefs = [
    {
      headerName: UnassignedPickupsDetailGridHeaders.ROW_SELECTED,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.ROW_SELECTED,
      colId: UnassignedPickupsDetailGridFields.ROW_SELECTED,
      width: 50,
      suppressMenu: true,
      suppressNavigable: true,
      headerValueGetter: () => '',
      checkboxSelection: true,

      onCellClicked: (params) => {
        // params.node.setSelected(!params.node.isSelected());
      },
      headerCheckboxSelection: false,
      lockPosition: true,
      cellRendererSelector: (params: ICellRendererParams) => {
        return {
          component: BoardUtils.isTotalsRow(params.node) ? 'typeCountCellRenderer' : null,
          params: params,
        };
      },

      valueGetter: (params: ValueGetterParams) => {
        return params.data?.rowSelected;
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (_size(nodeA.allLeafChildren) > 0 || _size(nodeB.allLeafChildren) > 0) {
          return 0;
        } else {
          const nodeASel = nodeA.isSelected() ? 1 : 0;
          const nodeBSel = nodeB.isSelected() ? 1 : 0;
          return nodeASel - nodeBSel;
        }
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.BOL,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.BOL,
      colId: UnassignedPickupsDetailGridFields.BOL,
      width: UnassignedPickupsDetailGridFieldsWidth.BOL,
      suppressMenu: true,
      suppressNavigable: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__bolCell',
      cellRenderer: 'visibilityCellRenderer',
      cellRendererParams: (params) => {
        return {
          tooltip: params?.data?.bolInstId > 0 ? 'View BOL' : null,
          tooltipPosition: 'right',
        };
      },
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.bolInstId > 0;
      },
      onCellClicked: (event: CellClickedEvent) => {
        this.pndDialogService.showEBoLDialog(event.data.bolInstId);
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.PRO_NBR,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.PRO_NBR,
      colId: UnassignedPickupsDetailGridFields.PRO_NBR,
      width: UnassignedPickupsDetailGridFieldsWidth.PRO_NBR,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return this.conditioningService.conditionProNumber(_trim(item.proNbr), 10);
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.ZIP_CODE,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.ZIP_CODE,
      colId: UnassignedPickupsDetailGridFields.ZIP_CODE,
      width: UnassignedPickupsDetailGridFieldsWidth.ZIP_CODE,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.zip6;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.DEST_SIC,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.DEST_SIC,
      colId: UnassignedPickupsDetailGridFields.DEST_SIC,
      width: UnassignedPickupsDetailGridFieldsWidth.DEST_SIC,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.destinationTerminalSicCd;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.REMARKS,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.REMARKS,
      colId: UnassignedPickupsDetailGridFields.REMARKS,
      width: UnassignedPickupsDetailGridFieldsWidth.REMARKS,
      suppressMenu: true,

      cellStyle: () => ({
        display: 'block',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'min-width': '0',
        'line-height': `${this.getCurrentRowHeight()}px`,
      }),
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.remarks;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.WEIGHT,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.WEIGHT,
      colId: UnassignedPickupsDetailGridFields.WEIGHT,
      width: UnassignedPickupsDetailGridFieldsWidth.WEIGHT,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      cellStyle: { 'justify-content': 'flex-end' },
      type: 'numericColumn',
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.weightLbs;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.PALLETS,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.PALLETS,
      colId: UnassignedPickupsDetailGridFields.PALLETS,
      width: UnassignedPickupsDetailGridFieldsWidth.PALLETS,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      cellStyle: { 'justify-content': 'flex-end' },
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.palletsCount;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.PIECES,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.PIECES,
      colId: UnassignedPickupsDetailGridFields.PIECES,
      width: UnassignedPickupsDetailGridFieldsWidth.PIECES,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      cellStyle: { 'justify-content': 'flex-end' },
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.loosePiecesCount;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.CUBE,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.CUBE,
      colId: UnassignedPickupsDetailGridFields.CUBE,
      width: UnassignedPickupsDetailGridFieldsWidth.CUBE,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      cellStyle: { 'justify-content': 'flex-end' },
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.cubeNbr;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.MM,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.MM,
      colId: UnassignedPickupsDetailGridFields.MM,
      width: UnassignedPickupsDetailGridFieldsWidth.MM,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      cellStyle: { 'justify-content': 'flex-end' },
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item.motorMovesNbr;
      },
    },
    {
      headerName: UnassignedPickupsDetailGridHeaders.SPECIAL_SERVICES,
      headerClass: 'grid-header',
      field: UnassignedPickupsDetailGridFields.SPECIAL_SERVICES,
      colId: UnassignedPickupsDetailGridFields.SPECIAL_SERVICES,
      width: UnassignedPickupsDetailGridFieldsWidth.SPECIAL_SERVICES,
      suppressMenu: true,
      cellClass: 'unassignedPickupsDetailGrid__lineItems__row__cell',
      cellRenderer: 'specialServicesCellRenderer',
      valueGetter: (params: ValueGetterParams) => {
        const item: UnassignedPickupsLineItem = params.data;
        return item?.specialServiceSummary?.map((ss: ShipmentSpecialServiceSummary) => ss?.specialService);
      },
    },
  ];

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;

    this.updateRowSelectionFromService();
    this.unassignedPickupsService.onParentRowSelectionUpdated$.subscribe((callNbr) => {
      if (callNbr === this.parentCallNbr) {
        this.updateRowSelectionFromService();
      }
    });
  }

  updateRowSelectionFromService(): void {
    // check if parent is seleced, select all children
    this.gridApi.forEachNode((node: RowNode) => {
      const callNbr = node.data.callNbr;
      const rowIndex = node.rowIndex;
      const uniqueLineItemId = callNbr + '.' + rowIndex;
      const currentSelectedLineItems: string[] = this.unassignedPickupsService.selectedPickupLineItems;
      if (currentSelectedLineItems.includes(uniqueLineItemId)) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  }

  onRowSelected(event: RowSelectedEvent): void {
    const callNbr = event.data.callNbr;
    const rowIndex = event.node.rowIndex;
    const uniqueLineItemId = callNbr + '.' + rowIndex;
    const currentSelectedLineItems: string[] = this.unassignedPickupsService.selectedPickupLineItems;
    if (event.node.isSelected()) {
      // when row selected
      if (!currentSelectedLineItems.includes(uniqueLineItemId)) {
        // this.unassignedPickupsService.selectedPickupLineItems.push(uniqueLineItemId);
        this.unassignedPickupsService.updateSelectedPickupLineItems([...currentSelectedLineItems, uniqueLineItemId]);
      } else {
        // do nothing
      }
    } else {
      // when row unselected
      if (currentSelectedLineItems.includes(uniqueLineItemId)) {
        const newLineItems = this.unassignedPickupsService.selectedPickupLineItems.filter((item) => {
          return item !== uniqueLineItemId;
        });
        this.unassignedPickupsService.updateSelectedPickupLineItems(newLineItems);
      }
    }

    // TODO: if on event none of the children are selected, deselect the parent
  }

  refresh(params: UnassignedPickupDetailGridRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  agInit(params: UnassignedPickupDetailGridRendererParams): void {
    const data = params.getDetailRowData(params.data);
    this.parentCallNbr = data.callNbr;
    this.lineItemsRowData = data.lineItems;
    this.notesRowData = data.notes;
    this.detailGridType = params.detailGridType(params.data.pickupRequestInstId);
    this.getCurrentRowHeight = params.getCurrentRowHeight;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
