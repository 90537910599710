import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-board/ag-grid';
import { XpoDashbarModule, XpoFiltersModule } from '@xpo-ltl/ngx-board/core';
import {
  XpoAllowCharactersModule,
  XpoLtlDialogTitleModule,
  XpoLtlPipeModule,
  XpoPhoneFormatterModule,
} from '@xpo-ltl/ngx-ltl';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCheckboxModule } from '@xpo-ltl/ngx-ltl-core/checkbox';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoInlineSearchModule } from '@xpo-ltl/ngx-ltl-core/inline-search';
import { XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core/popover';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoSsiModule } from '@xpo-ltl/ngx-ltl-core/special-service-indicators';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';
import {
  XpoLtlOperationsCustomerProfileComponent,
  XpoLtlOperationsCustomerProfileModule,
} from '@xpo-ltl/ngx-ltl-cust-profile';
import { NgxLtlPickupRequestComponent, NgxLtlPickupRequestModule } from '@xpo-ltl/ngx-ltl-pickup-request';
import { XpoLtlShipmentDetailModule } from '@xpo-ltl/ngx-ltl-shipment-details';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSplitModule } from 'angular-split';
import { DragScrollModule } from 'cdk-drag-scroll';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PndKeyboardShortcutsModule } from 'shared/keyboard-shortcuts/keyboard-shortcuts.module';
import { ActivityCdPipe, ActivityTypePipe, InterfaceAcctNamePipe, PickupTypeCdPipe, StopWindowCdPipe } from '.';
import { ResizeObserverDirective } from '../../inbound-planning/shared/directives/resize-observer.directive';
import {
  ActionLinkCellRendererComponent,
  AlarmHeaderRendererComponent,
  DispatchTripExceptionsCellRendererComponent,
  GuaranteedIconComponent,
  HazmatIconComponent,
  IconHeaderRendererComponent,
  MetricBarComponent,
  NotesHeaderRendererComponent,
  RouteActionLinkCellRendererComponent,
  RouteTimelineCellRendererComponent,
  RouteTimelineHeaderRendererComponent,
  SpecialServicesCellRendererComponent,
  SpecialServicesComponent,
  StatusChipComponent,
  TotalTextCellRendererComponent,
} from './components';
import { PndAccordionGroupCellActionRendererComponent } from './components/accordion-group-cell-action-renderer/accordion-group-cell-action-renderer.component';
import { ActionsCellRendererComponent } from './components/actions-cell-renderer';
import { XpoAgGridSelectAllCheckbox } from './components/ag-grid-select-all';
import { AlertCounterComponent } from './components/alert-counter/alert-counter.component';
import { ApplyChangesModalComponent } from './components/apply-changes-modal/apply-changes-modal.component';
import { AutocompleteEditorComponent } from './components/autocomplete-editor/autocomplete-editor.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BooleanDotCellRendererComponent } from './components/boolean-dot-cell-renderer/boolean-dot-cell-renderer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarriersSelectorDialogComponent } from './components/carriers-selector-dialog/carriers-selector-dialog.component';
import { CheckmarkCellRendererComponent } from './components/checkmark-cell-render/checkmark-cell-renderer.component';
import { CheckmarkHeaderRendererComponent } from './components/checkmark-header-renderer/checkmark-header-renderer.component';
import { CircleAndTextCellRendererComponent } from './components/circle-and-text-cell-renderer/circle-and-text-cell-renderer.component';
import { CircleCellRendererComponent } from './components/circle-cell-renderer/circle-cell-renderer.component';
import { XpoGridBaseCellRendererComponent } from './components/configurable-features/inline-editing';
import {
  XpoGridAlphanumericCellEditorComponent,
  XpoGridNumericCellEditorComponent,
} from './components/configurable-features/inline-editing/cell-editors';
import { CustomAccordionGroupCellRendererComponent } from './components/custom-accordion-group-cell-renderer/custom-accordion-group-cell-renderer.component';
import { CustomerNameLinkCellRender } from './components/customer-name-link-cell-render/customer-name-link-cell-render';
import { DeliveryWindowTimeRendererComponent } from './components/delivery-window-time-renderer/delivery-window-time-renderer.component';
import { DetailGridToggleCellRendererComponent } from './components/detail-grid-toggle-cell-renderer/detail-grid-toggle-cell-renderer.component';
import { DispatchAreaSelectorComponent } from './components/dispatch-area-selector/dispatch-area-selector.component';
import { DispatchDriverCellRendererComponent } from './components/dispatch-driver-cell-renderer/dispatch-driver-cell-renderer.component';
import { DispatchExceptionTooltipCellRendererComponent } from './components/dispatch-exception-tooltip-cell-renderer';
import { DmanPanelComponent } from './components/dman-panel/dman-panel.component';
import { DriverEditorComponent } from './components/driver-editor/driver-editor.component';
import { FilteredEditorComponent } from './components/filtered-editor/filtered-editor.component';
import { GeoAreaDrawerDetailComponent } from './components/geo-area-drawer/components/geo-area-drawer-detail/geo-area-drawer-detail.component';
import { GeoAreaDrawerComponent } from './components/geo-area-drawer/geo-area-drawer.component';
import { XpoAccordionGroupCellRendererComponent } from './components/grid-accordian-cell-renderer/xpo-accordion-group-cell-renderer.component';
import { GridBottomDrawerComponent } from './components/grid-bottom-drawer/grid-bottom-drawer.component';
import { GridDetailNotesTriggerComponent } from './components/grid-detail-notes-trigger/grid-detail-notes-trigger.component';
import { GridDetailsNotesRenderComponent } from './components/grid-details-notes-render/grid-details-notes-render.component';
import { GridErrorsComponent } from './components/grid-errors/grid-errors.component';
import { GridToolbarSearchComponent } from './components/grid-toolbar/components/grid-toolbar-search/grid-toolbar-search.component';
import { GridToolbarViewManagerComponent } from './components/grid-toolbar/components/grid-toolbar-view-manager/grid-toolbar-view-manager.component';
import { GridToolbarComponent } from './components/grid-toolbar/grid-toolbar.component';
import { GroupedCountCellRendererComponent } from './components/grouped-count-cell-renderer/grouped-count-cell-renderer.component';
import { HandlingUnitsCellRendererComponent } from './components/handling-units-cell-renderer';
import { MapDispatchAreaCardComponent } from './components/map-dispatch-area/map-dispatch-area-card/map-dispatch-area-card.component';
import { MapDispatchAreaComponent } from './components/map-dispatch-area/map-dispatch-area.component';
import { CenterSicButton } from './components/map-toolbar-widget/components/center-map/center-map-button.component';
import { GeoFilterPolygonSelectionComponent } from './components/map-toolbar-widget/components/geo-filter-polygon-selection/geo-filter-polygon-selection.component';
import { PolygonSelectionComponent } from './components/map-toolbar-widget/components/polygon-selection/polygon-selection.component';
import { RecenterComponent } from './components/map-toolbar-widget/components/recenter/recenter.component';
import { SelectiveZoomComponent } from './components/map-toolbar-widget/components/selective-zoom/selective-zoom.component';
import { MapToolbarWidgetComponent } from './components/map-toolbar-widget/map-toolbar-widget.component';
import { MapWatchedDriverComponent } from './components/map-watched-driver/map-watched-driver.component';
import { MapWeatherComponent } from './components/map-weather/map-weather.component';
import { ZoomInButton } from './components/map-zoom-widget/components/zoom-in/zoom-in-button.component';
import { ZoomOutButton } from './components/map-zoom-widget/components/zoom-out/zoom-out-button.component';
import { MapZoomWidgetComponent } from './components/map-zoom-widget/map-zoom-widget.component';
import { MatTooltipCellRendererComponent } from './components/mat-tooltip-cell-renderer/mat-tooltip-cell-renderer.component';
import { MetricBarCardComponent } from './components/metric-bar/card';
import { MetricBarSkeletonCardComponent } from './components/metric-bar/skeleton-card';
import { ModifyTripDetailsActivityTypeCellRendererComponent } from './components/modify-trip-details-activity-type-cell-renderer';
import { ModifyTripDetailsCellRendererComponent } from './components/modify-trip-details-group-cell-renderer';
import { NotesCellRendererComponent } from './components/notes-cell-renderer/notes-cell-renderer.component';
import { NotesCountCellRendererComponent } from './components/notes-count-cell-renderer/notes-count-cell-renderer.component';
import { OptimizerUpdateShipmentAlertCellRenderComponent } from './components/optimizer-update-shipment-alert-cell-render/optimizer-update-shipment-alert-cell-render.component';
import { OversizeCellRendererComponent } from './components/oversize-cell-renderer/oversize-cell-renderer.component';
import { PastDueNotificationComponent } from './components/past-due-widget/past-due-notification.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PickupCallNbrCellRendererComponent } from './components/pickup-call-number-cell-renderer/pickup-call-nbr-cell-renderer.component';
import { PickupCloseSoonCellRendererComponent } from './components/pickup-close-soon-cell-renderer/pickup-close-soon-cell-renderer.component';
import { PickupCloseTimeCellRendererComponent } from './components/pickup-close-time-cell-renderer/pickup-close-time-cell-renderer.component';
import { PickupDateCellRendererComponent } from './components/pickup-date-cell-renderer/pickup-date-cell-renderer.component';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { RadioButtonSelectionCellRendererComponent } from './components/radio-button-selection-cell-renderer/radio-button-selection-cell-renderer.component';
import { RefreshNotificationWidgetComponent } from './components/refresh-notification-widget/refresh-notification-widget.component';
import { ReleaseRouteToggleCellRendererComponent } from './components/release-route-toggle-cell-renderer';
import { ReleaseRouteComponent } from './components/release-route/release-route.component';
import { XpoGridSelectCellEditorComponent } from './components/select-editor/select-editor.component';
import { SelectionSummaryComponent } from './components/selection-summary/selection-summary.component';
import { DriverCollectIconComponent } from './components/service-icons/driver-collect-icon.component';
import { LiftGateIconComponent } from './components/service-icons/lift-gate-icon.component';
import { SicMarkerCompassComponent } from './components/sic-marker-compass/sic-marker-compass.component';
import { SicZonesSelectorComponent } from './components/sic-zones-selector/sic-zones-selector.component';
import { SingleCarrierSelectorDialogComponent } from './components/single-carrier-selector-dialog/single-carrier-selector-dialog.component';
import { SplitPanelComponent } from './components/split-panel/split-panel.component';
import { StatusIndicatorCellRendererComponent } from './components/status-indicator-cell-renderer/status-indicator-cell-renderer.component';
import { StopMapComponent } from './components/stop-map/stop-map.component';
import { TdcWarningsComponent } from './components/tdc-warnings/tdc-warnings.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { TimeEditorComponent } from './components/time-editor/time-editor.component';
import { TimeFilterComponent } from './components/time-filter/time-filter.component';
import { TypeCountCellRendererComponent } from './components/type-count-cell-renderer/type-count-cell-renderer.component';
import { UnassignedPickupDetailGridRendererComponent } from './components/unassigned-pickup-detail-grid-renderer/unassigned-pickup-detail-grid-renderer.component';
import { UnmappedStopDetailComponent } from './components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.component';
import { UnmappedStopsComponent } from './components/unmapped-stops/unmapped-stops.component';
import { VisibilityCellRendererComponent } from './components/visibility-cell-renderer/visibility-cell-renderer.component';
import { ViewRefDirective } from './directives/view-ref.directive';
import { XpoTriggerMenuDirective } from './directives/xpo-trigger-menu.directive';
import { CarrierTenderGroupStatusCdPipe, CarrierTenderStatusCdPipe } from './pipes';
import { BillClassCdPipe } from './pipes/bill-class-cd.pipe';
import { DeliveryQualifierCdPipe } from './pipes/delivery-qualifier-cd.pipe';
import { DockActivityCdPipe } from './pipes/dock-activity-cd.pipe';
import { EmployeeStatusCdPipe } from './pipes/employee-status-cd.pipe';
import { EquipmentPipe } from './pipes/equipment.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HandlingUnitTypeCdPipe } from './pipes/handling-unit-type-cd.pipe';
import { NoteTypeCdPipe } from './pipes/note-type-cd.pipe';
import { PdoEquipmentStatusPipe } from './pipes/pdo-equipment-status.pipe';
import { PdoEquipmentTypePipe } from './pipes/pdo-equipment-type.pipe';
import { PrintTypeCdPipe } from './pipes/print-type-cd.pipe';
import { RemoveLeadingZerosPipe } from './pipes/remove-leading-zeros.pipe';
import { ShipperNamePipe } from './pipes/shipper-name.pipe';
import { SpecialServiceMarkPipe } from './pipes/special-service-mark.pipe';
import { StopWindowNotePipe } from './pipes/stop-window-note.pipe';
import { TrailerStatusCdPipe } from './pipes/trailer-status-cd.pipe';
import { TripStatusCdPipe } from './pipes/trip-status-cd.pipe';

const pipes = [
  DeliveryQualifierCdPipe,
  StopWindowCdPipe,
  TripStatusCdPipe,
  EquipmentPipe,
  RemoveLeadingZerosPipe,
  ActivityTypePipe,
  ActivityCdPipe,
  DockActivityCdPipe,
  FilterPipe,
  PickupTypeCdPipe,
  NoteTypeCdPipe,
  BillClassCdPipe,
  PdoEquipmentTypePipe,
  PdoEquipmentStatusPipe,
  ShipperNamePipe,
  TrailerStatusCdPipe,
  InterfaceAcctNamePipe,
  StopWindowNotePipe,
  EmployeeStatusCdPipe,
  CarrierTenderStatusCdPipe,
  CarrierTenderGroupStatusCdPipe,
  HandlingUnitTypeCdPipe,
  PrintTypeCdPipe,
];

const directives = [ViewRefDirective, XpoTriggerMenuDirective];

// ADD ALL GRID RENDERERS TO THIS ARRAY SO THEY ARE AUTOMATICALLY ADDED TO
// DECLARATIONS, EXPORTS, AND ENTRY COMPONENTS ARRAYS!
const gridComponentRenderers = [
  HandlingUnitsCellRendererComponent,
  StatusIndicatorCellRendererComponent,
  ActionLinkCellRendererComponent,
  ActionsCellRendererComponent,
  AlarmHeaderRendererComponent,
  BooleanDotCellRendererComponent,
  CheckmarkCellRendererComponent,
  CheckmarkHeaderRendererComponent,
  CircleAndTextCellRendererComponent,
  CircleCellRendererComponent,
  CustomAccordionGroupCellRendererComponent,
  CustomerNameLinkCellRender,
  DeliveryWindowTimeRendererComponent,
  DetailGridToggleCellRendererComponent,
  DispatchDriverCellRendererComponent,
  DispatchExceptionTooltipCellRendererComponent,
  DispatchTripExceptionsCellRendererComponent,
  DriverCollectIconComponent,
  FilteredEditorComponent,
  GridDetailNotesTriggerComponent,
  GridDetailsNotesRenderComponent,
  GroupedCountCellRendererComponent,
  GuaranteedIconComponent,
  HazmatIconComponent,
  IconHeaderRendererComponent,
  LiftGateIconComponent,
  ModifyTripDetailsActivityTypeCellRendererComponent,
  ModifyTripDetailsCellRendererComponent,
  NotesCellRendererComponent,
  NotesCountCellRendererComponent,
  NotesHeaderRendererComponent,
  OptimizerUpdateShipmentAlertCellRenderComponent,
  PickupCallNbrCellRendererComponent,
  PickupCloseSoonCellRendererComponent,
  PickupCloseTimeCellRendererComponent,
  PickupDateCellRendererComponent,
  OversizeCellRendererComponent,
  PndAccordionGroupCellActionRendererComponent,
  RadioButtonSelectionCellRendererComponent,
  ReleaseRouteToggleCellRendererComponent,
  RouteActionLinkCellRendererComponent,
  RouteTimelineCellRendererComponent,
  RouteTimelineHeaderRendererComponent,
  SpecialServicesCellRendererComponent,
  TimeEditorComponent,
  TotalTextCellRendererComponent,
  TypeCountCellRendererComponent,
  UnassignedPickupDetailGridRendererComponent,
  VisibilityCellRendererComponent,
  MatTooltipCellRendererComponent,
  XpoAccordionGroupCellRendererComponent,
  XpoGridSelectCellEditorComponent,
  XpoGridSelectCellEditorComponent,
  XpoGridNumericCellEditorComponent,
  XpoGridAlphanumericCellEditorComponent,
  XpoGridBaseCellRendererComponent,
  XpoAgGridSelectAllCheckbox,
];

@NgModule({
  imports: [
    AgGridModule,
    ClipboardModule,
    CommonModule,
    MatChipsModule,
    DragDropModule,
    DragScrollModule,
    ScrollingModule,
    ReactiveFormsModule,
    XpoDashbarModule,
    XpoFiltersModule,
    // XpoCellRenderersModule,
    XpoIconModule,
    XpoDialogModule,
    XpoPopoverModule,
    XpoLtlPopoverModule,
    XpoLtlShipmentDetailModule,
    XpoLtlPipeModule,
    XpoLtlDialogTitleModule,
    XpoAllowCharactersModule,
    XpoPhoneFormatterModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    XpoButtonModule,
    XpoInlineSearchModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    MatBadgeModule,
    MatRadioModule,
    PndKeyboardShortcutsModule,
    XpoTabsModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatTooltipModule,
    FormsModule,
    MatAutocompleteModule,
    AgmCoreModule,
    MatProgressSpinnerModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    NgxSkeletonLoaderModule,
    XpoSelectModule,
    XpoStatusIndicatorModule,
    XpoLtlOperationsCustomerProfileModule,
    NgxLtlPickupRequestModule,
    XpoCheckboxModule,
    PdfViewerModule,
    AngularSplitModule.forChild(),
    XpoLtlPipeModule,
    MatExpansionModule,
    XpoSsiModule,
  ],
  declarations: [
    AlertCounterComponent,
    ApplyChangesModalComponent,
    CarouselComponent,
    CenterSicButton,
    DispatchAreaSelectorComponent,
    GeoFilterPolygonSelectionComponent,
    GridBottomDrawerComponent,
    GridErrorsComponent,
    GridToolbarComponent,
    GridToolbarSearchComponent,
    GridToolbarViewManagerComponent,
    MapDispatchAreaCardComponent,
    MapDispatchAreaComponent,
    MapWatchedDriverComponent,
    MapToolbarWidgetComponent,
    MapWeatherComponent,
    MapZoomWidgetComponent,
    MetricBarCardComponent,
    MetricBarComponent,
    MetricBarSkeletonCardComponent,
    PdfViewerComponent,
    PolygonSelectionComponent,
    RecenterComponent,
    RefreshNotificationWidgetComponent,
    PastDueNotificationComponent,
    ReleaseRouteComponent,
    ResizeObserverDirective,
    SelectionSummaryComponent,
    SelectiveZoomComponent,
    SicMarkerCompassComponent,
    SicZonesSelectorComponent,
    SpecialServicesComponent,
    SplitPanelComponent,
    StatusChipComponent,
    StopMapComponent,
    TdcWarningsComponent,
    TimeFilterComponent,
    UnmappedStopDetailComponent,
    UnmappedStopsComponent,
    ZoomInButton,
    ZoomOutButton,
    TextEditorComponent,
    AutocompleteComponent,
    ProgressSpinnerComponent,
    AutocompleteEditorComponent,
    PrintButtonComponent,
    CarriersSelectorDialogComponent,
    SingleCarrierSelectorDialogComponent,
    ...pipes,
    ...directives,
    ...gridComponentRenderers,
    SpecialServiceMarkPipe,
    GeoAreaDrawerComponent,
    GeoAreaDrawerDetailComponent,
    DriverEditorComponent,
    DmanPanelComponent,
  ],
  exports: [
    AlertCounterComponent,
    CarouselComponent,
    DispatchAreaSelectorComponent,
    GridBottomDrawerComponent,
    GridErrorsComponent,
    GridToolbarComponent,
    GridToolbarSearchComponent,
    MapDispatchAreaCardComponent,
    MapDispatchAreaComponent,
    MapWatchedDriverComponent,
    MapToolbarWidgetComponent,
    MapWeatherComponent,
    MapZoomWidgetComponent,
    MetricBarCardComponent,
    MetricBarComponent,
    RefreshNotificationWidgetComponent,
    PastDueNotificationComponent,
    SelectionSummaryComponent,
    SicMarkerCompassComponent,
    SicZonesSelectorComponent,
    SpecialServicesComponent,
    SplitPanelComponent,
    StatusChipComponent,
    TimeFilterComponent,
    UnmappedStopsComponent,
    XpoLtlOperationsCustomerProfileComponent,
    NgxLtlPickupRequestComponent,
    AutocompleteComponent,
    ProgressSpinnerComponent,
    PrintButtonComponent,
    GeoAreaDrawerComponent,
    GeoAreaDrawerDetailComponent,
    DmanPanelComponent,
    ...pipes,
    ...directives,
    ...gridComponentRenderers,
  ],
  providers: [...pipes, DatePipe, DecimalPipe, GoogleMapsAPIWrapper],
})
export class InboundPlanningSharedModule {}
