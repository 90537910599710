import { Injectable, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PndStoreState } from '@pnd-store/.';
import { PndStore } from '@pnd-store/pnd-store';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import {
  OperationsCustomerProfileActionConfig,
  OperationsCustomerProfileConfig,
  XpoLtlOperationsCustomerProfileComponent,
} from '@xpo-ltl/ngx-ltl-cust-profile';
import { ConfigManagerProperties } from 'core/enums/config-manager-properties.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SplitPanelUnit } from '../../components/split-panel/split-panel.component';
import { MapSplitPanelItemsId } from '../../enums/map-split-panel-items-id.enum';
import { MapSplitPanelService } from '../map-split-panel.service';
import { SplitPanelService } from '../split-panel-service/split-panel.service';

@Injectable({
  providedIn: 'root',
})
export class OperationsCustomerProfileService {
  private currentlyOpenedAcctId: number;

  private readonly routeBalancerOpenedAcctIdSubject = new BehaviorSubject<number>(undefined);
  readonly routeBalancerOpenedAcctId$ = this.routeBalancerOpenedAcctIdSubject.asObservable();

  constructor(
    @Optional() private splitPanelService: SplitPanelService,
    @Optional() private dialog: MatDialog,
    private pndStore$: PndStore<PndStoreState.State>,
    private mapSplitPanelService: MapSplitPanelService,
    private configManagerService: ConfigManagerService,
    private featuresService: XpoLtlFeaturesService
  ) {}

  openSplitPanelProfile(customerAcctId: number) {
    const alreadyOpen =
      this.splitPanelService.rightPanelComponentConfig &&
      this.splitPanelService.rightPanelComponentConfig.componentRef === XpoLtlOperationsCustomerProfileComponent;
    const sameIdAsOpened = customerAcctId === this.currentlyOpenedAcctId;

    const cancelOrCloseFn = () => {
      this.currentlyOpenedAcctId = undefined;
      this.splitPanelService.closeRightPanel();
    };
    const config: OperationsCustomerProfileConfig = this.getBasicConfig(cancelOrCloseFn);

    this.splitPanelService.openRightPanel({
      componentRef: XpoLtlOperationsCustomerProfileComponent,
      injectionData: undefined,
      hideDefaultPanelHeader: true,
      lockSize: false,
      unit: SplitPanelUnit.Pixel,
      size: alreadyOpen ? this.splitPanelService.rightPanelComponentConfig.size : 950,
      inputs: alreadyOpen
        ? undefined // if profile is already open, we'll use updateInputs instead
        : {
            customerAcctId,
            config,
          },
      updateInputs: alreadyOpen && !sameIdAsOpened ? { customerAcctId } : undefined,
      maxSize: 1500,
      minSize: 600,
      useTransition: true,
      onClose: () => this.splitPanelService.closeRightPanel(),
      style: {
        paddingPx: 15,
      },
    });
  }

  openDialogProfile(customerAcctId: number, closeExistingDialog: boolean = true): Observable<void> {
    const dialogClosed = new Subject<void>();

    if (this.dialog && closeExistingDialog) {
      this.dialog.closeAll();
    }

    const dialogRef = this.dialog.open(XpoLtlOperationsCustomerProfileComponent, {
      width: '1290px',
      height: '98%',
      maxHeight: '1200px',
      panelClass: 'pnd-ops-cust-profile-dialog',
      hasBackdrop: false,
    });

    dialogRef
      .keydownEvents()
      .pipe(takeUntil(dialogClosed))
      .subscribe((e: KeyboardEvent) => {
        if (e.code === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          dialogRef.close();
        }
      });

    const cancelOrCloseFn = () => {
      // make sure we can close the dialog, else don't do it
      dialogRef.componentInstance.canClose$().subscribe((allowClose: boolean) => {
        if (allowClose) {
          this.currentlyOpenedAcctId = undefined;
          dialogRef.close();
        }
      });
    };
    const config: OperationsCustomerProfileConfig = this.getBasicConfig(cancelOrCloseFn);

    // Set @Inputs
    const instance = dialogRef.componentInstance;
    instance.customerAcctId = customerAcctId;
    instance.config = config;

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        dialogClosed.next();
        dialogClosed.complete();
      });

    return dialogClosed.asObservable();
  }

  openRouteBalancerProfile(customerAcctId: number) {
    this.routeBalancerOpenedAcctIdSubject.next(customerAcctId);
  }

  getRouteBalancerConfig(): OperationsCustomerProfileConfig {
    const cancelOrCloseFn = () => {
      // closeRouteBalancerProfile
      this.routeBalancerOpenedAcctIdSubject.next(undefined);
    };
    return this.getBasicConfig(cancelOrCloseFn);
  }

  getMapConfig(): OperationsCustomerProfileConfig {
    const cancelOrCloseFn = () => {
      this.mapSplitPanelService.removePanelById(MapSplitPanelItemsId.CUSTOMER_PROFILE);
    };
    return this.getBasicConfig(cancelOrCloseFn);
  }

  /**
   * Builds a basic config with a close button.
   *
   * @param cancelOrCloseFn
   */
  getBasicConfig(cancelOrCloseFn: () => void): OperationsCustomerProfileConfig {
    const headerActions: OperationsCustomerProfileActionConfig[] = [
      {
        tooltip: 'Close',
        dataTestAttr: 'opsCustProfileActionClose',
        iconName: 'close',
        callbackFn: cancelOrCloseFn,
      },
    ];

    const config: OperationsCustomerProfileConfig = {
      headerActions,
      debugMode: false,
      includeMap: true,
      forceReadOnly: false,
      historicChartsUrl: this.configManagerService.getSetting<string>(ConfigManagerProperties.lookerUrl),
      cancelCallbackFn: cancelOrCloseFn,
    };
    return config;
  }
}
