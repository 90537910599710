<div class="xpoAccordionGroup">
  <ng-container *ngIf="hasAction; else noActionTemplate">
    <a data-test="accordion-group-cell-action" (click)="onActionClick()">
      <span class="xpoAccordionGroup-value">{{ groupName }}</span>
    </a>
  </ng-container>
  <ng-template #noActionTemplate>
    <span class="xpoAccordionGroup-value">{{ groupName }}</span>
  </ng-template>

  <span class="xpoAccordionGroup-count">{{ rowCount }}</span>
  <div *ngFor="let tag of tags" class="xpoAccordionGroup-notification" [ngClass]="tag?.chipClass">
    <div class="xpoAccordionGroup-notification__text">
      <mat-icon>pan_tool</mat-icon>
      <span> {{ tag?.text }} </span>
    </div>
    <span class="xpoAccordionGroup-notification__action" (click)="onViewMode()" *ngIf="params.onViewMode">VIEW
      MORE</span>
  </div>
</div>
