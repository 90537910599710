import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GridToolbarViewItem } from '../../grid-toolbar.model';

@Component({
  selector: 'pnd-grid-toolbar-view-manager',
  templateUrl: './grid-toolbar-view-manager.component.html',
  styleUrls: ['./grid-toolbar-view-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridToolbarViewManagerComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: true }) menuTrigger: MatMenuTrigger;

  @Input() views$: Observable<GridToolbarViewItem[]> = of([]);

  @Output() viewSelected: EventEmitter<string> = new EventEmitter();

  systemDefinedViews$: Observable<GridToolbarViewItem[]>;
  userDefinedViews$: Observable<GridToolbarViewItem[]>;
  activeView$: Observable<GridToolbarViewItem>;

  private expandViewsSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  expandViews$: Observable<boolean> = this.expandViewsSubject.asObservable();

  constructor() {}

  ngOnInit() {
    this.systemDefinedViews$ = this.views$.pipe(
      map((views: GridToolbarViewItem[]) => views.filter((view) => !!view.systemDefined))
    );

    this.userDefinedViews$ = this.views$.pipe(
      map((views: GridToolbarViewItem[]) => views.filter((view) => !view.systemDefined))
    );

    this.activeView$ = this.views$.pipe(map((views: GridToolbarViewItem[]) => views.find((view) => !!view.isActive)));
  }

  handleViewSelected(viewId: string): void {
    this.viewSelected.emit(viewId);
  }

  toggleViewGroupExpanded($event: MouseEvent): void {
    $event.stopPropagation();

    this.expandViewsSubject.next(!this.expandViewsSubject.value);
  }
}
