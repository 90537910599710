import { Injectable } from '@angular/core';
import { PndStore } from '@pnd-store/pnd-store';
import {
  CityOperationsApiService,
  ListPnDTripStopsResp,
  Route,
  RouteSummary,
  Stop,
  TripDriver,
  TripSummary,
} from '@xpo-ltl/sdk-cityoperations';
import { NodeTypeCd } from '@xpo-ltl/sdk-common';
import { GeoLocationService } from 'app/inbound-planning/shared/services/geo-location.service';
import { filter as _filter, find as _find, forEach as _forEach } from 'lodash';
import { BehaviorSubject, Observable, Subject, throwError, forkJoin } from 'rxjs';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';
import { PndStoreState, TripsStoreSelectors } from '../../../../store';
import { NumberToValueMap } from '../../../../store/number-to-value-map';
import { TripRenderingService } from '../../planning-map/layers/routes-layer/services/trip-rendering.service';
import { TripRenderType } from '../enums/trip-render-type.enum';
import { TripRenderingOptions } from '../interfaces/trip-rendering-options.interface';

@Injectable({
  providedIn: 'root',
})
export class DispatcherTripsRouteRenderingService {
  map: google.maps.Map;
  overrideSelectedTripRenderingOptions: TripRenderingOptions;

  private selectedRoutes: BehaviorSubject<Route[]> = new BehaviorSubject<Route[]>([]);
  selectedRoutes$ = this.selectedRoutes.asObservable();

  private readonly stopsForSelectedRoutesSubject: BehaviorSubject<NumberToValueMap<Stop[]>> = new BehaviorSubject<
    NumberToValueMap<Stop[]>
  >({});
  readonly stopsForSelectedRoutes$ = this.stopsForSelectedRoutesSubject.asObservable();

  get selectedStops() {
    return this.stopsForSelectedRoutesSubject.value;
  }

  private pickupsForSelectedRoutes: BehaviorSubject<NumberToValueMap<Stop[]>> = new BehaviorSubject<
    NumberToValueMap<Stop[]>
  >([]);
  pickupsForSelectedRoutes$ = this.pickupsForSelectedRoutes.asObservable();

  get selectedPUStops() {
    return this.pickupsForSelectedRoutes.value;
  }
  private driversForSelectedRoutes: Subject<NumberToValueMap<TripDriver>> = new Subject<NumberToValueMap<TripDriver>>();
  driversForSelectedRoutes$ = this.driversForSelectedRoutes.asObservable();

  private isPrintEnabledSubject: Subject<boolean> = new Subject<boolean>();
  isPrintEnabled$ = this.isPrintEnabledSubject.asObservable();

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private tripRenderingService: TripRenderingService,
    private cityOperationsApiService: CityOperationsApiService,
    private geoLocationService: GeoLocationService
  ) {}

  getStops(selectedTripsIds: number[], tripSummary?: TripSummary) {
    // If there are not trips clean the map markers and routes
    if (!selectedTripsIds.length) {
      this.tripRenderingService.updateRoutes([], {}).subscribe();
      this.selectedRoutes.next([]);
      this.stopsForSelectedRoutesSubject.next([]);
      this.pickupsForSelectedRoutes.next([]);
      this.driversForSelectedRoutes.next({});
      this.isPrintEnabledSubject.next(false);
    }

    const routeStops: NumberToValueMap<Stop[]> = {};
    const tripPickups: NumberToValueMap<Stop[]> = {};
    const drivers: NumberToValueMap<TripDriver> = {};
    const routes: Route[] = [];

    // Get data from the API
    // We don't use the store here because the object is too big and it would cause performance issues
    const apiCalls: Observable<ListPnDTripStopsResp>[] = [];
    if (!tripSummary) {
      selectedTripsIds.forEach((tripInstId) => {
        const observable = this.cityOperationsApiService.listPnDTripStops({ tripInstId });
        apiCalls.push(observable);
      });
      forkJoin(apiCalls)
        .pipe(take(1))
        .subscribe((res) => {
          _forEach(res, (tripStopsResponse: ListPnDTripStopsResp) => {
            _forEach(tripStopsResponse.tripSummary.routeSummary, (summary: RouteSummary) => {
              this.structureRouteDriverStopData(
                summary,
                routeStops,
                tripPickups,
                drivers,
                routes,
                tripStopsResponse.tripSummary
              );
            });
          });
          this.updateStopsWithGeoLocationAndShowOnMap(routeStops, tripPickups, drivers, routes);
        });
    } else {
      _forEach(tripSummary.routeSummary, (summary: RouteSummary) => {
        this.structureRouteDriverStopData(summary, routeStops, tripPickups, drivers, routes, tripSummary);
      });
      this.updateStopsWithGeoLocationAndShowOnMap(routeStops, tripPickups, drivers, routes);
    }
  }

  updateStopsWithGeoLocationAndShowOnMap(routeStops, tripPickups, drivers, routes) {
    /*if any trip is selected after editing geolocation, 
      API sometimes gets old data (maybe a delay in the elastic update), 
      so we need to update these newly selected stops with new geolocation if the customer exists for them.
      if backend get new data then simply overwrite the address
    */
    const address = this.geoLocationService.address;
    if (address) {
      routeStops = this.geoLocationService.getMapForSelectedDLorPU(address, routeStops);
      tripPickups = this.geoLocationService.getMapForSelectedDLorPU(address, tripPickups);
    }

    // Emit values so markers can listen and be created accordingly
    this.selectedRoutes.next(routes);
    this.stopsForSelectedRoutesSubject.next(routeStops);
    this.pickupsForSelectedRoutes.next(tripPickups);
    this.driversForSelectedRoutes.next(drivers);
    this.isPrintEnabledSubject.next(true);

    // Display routes on the map
    this.tripRenderingService
      .updateRoutes(routes, routeStops, tripPickups)
      .pipe(take(1), withLatestFrom(this.pndStore$.select(TripsStoreSelectors.showCompletedStops)))
      .subscribe(([renderInfo, showCompletedStops]) => {
        if (this.overrideSelectedTripRenderingOptions?.type === TripRenderType.BREADCRUMBS) {
          this.tripRenderingService.showBreadcrumbs(this.overrideSelectedTripRenderingOptions.tripInstId);
          this.overrideSelectedTripRenderingOptions = undefined;
        } else {
          this.tripRenderingService.updateAllVisibility(showCompletedStops, 8);
        }
      });
  }

  structureRouteDriverStopData(
    summary: RouteSummary,
    routeStops,
    tripPickups,
    drivers,
    routes,
    tripSummary: TripSummary
  ) {
    // We get the index 2 stop because the 0 and 1 index are service centers and therefore they don't have activities
    const stops: Stop[] = _filter(summary?.stops ?? [], (stop: Stop) => {
      return stop.tripNode.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER;
    });

    const stopWithActivity: Stop = _find(stops, (stop: Stop) => !!stop?.activities?.[0]?.tripNodeActivity?.tripInstId);

    const tripInstId: number = stopWithActivity?.activities?.[0]?.tripNodeActivity?.tripInstId;
    const routeInstId: number = summary?.route?.routeInstId;

    if (routeInstId) {
      routeStops[routeInstId] = stops;
      summary.route.dsrName = tripSummary?.tripDriver?.dsrName;
      routes.push(summary.route);
    } else if (tripInstId) {
      drivers[tripInstId] = tripSummary.tripDriver;
      tripPickups[tripInstId] = stops;
    }
  }

  getStopsForRoute(tripInstId: number): Observable<NumberToValueMap<Stop[]>> {
    const routeStops: NumberToValueMap<Stop[]> = {};

    if (!tripInstId) {
      return throwError('Trip Instance Id is Required');
    }

    return this.cityOperationsApiService.listPnDTripStops({ tripInstId }).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      map((response) => {
        response?.tripSummary?.routeSummary?.forEach((summary) => {
          const stops: Stop[] = _filter(summary?.stops ?? [], (stop: Stop) => {
            return stop.tripNode.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER;
          });
          const routeInstId: number = summary?.route?.routeInstId;

          if (routeInstId) {
            routeStops[routeInstId] = stops;
          }
        });
        return routeStops;
      })
    );
  }

  setDLStopsForSelectedRoutes(selectedDLStops: NumberToValueMap<Stop[]>) {
    this.stopsForSelectedRoutesSubject.next(selectedDLStops);
  }
  setPUStopsForSelectedRoutes(selectedPUStops: NumberToValueMap<Stop[]>) {
    this.pickupsForSelectedRoutes.next(selectedPUStops);
  }
}
