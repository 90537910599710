import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatLegacyCheckbox as MatCheckbox,
  MatLegacyCheckboxChange as MatCheckboxChange,
} from '@angular/material/legacy-checkbox';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConditioningService } from '@xpo-ltl/common-services';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { CreateNewRouteResp, DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { DataValidationError } from '@xpo-ltl/sdk-common';

import moment from 'moment-timezone';
import { SpecialServicesHelper } from '../../helpers/special-services/special-services.helper';
import { OperationsCustomerProfileService } from '../../services/operations-customer-profile/operations-customer-profile.service';
import { StopWindowService } from '../../services/stop-window.service';
import { SpecialServicesService } from './../../services/special-services.service';
import { TdcWarningMessage, TdcWarningMessages } from './tdc-warnings.model';

@Component({
  selector: 'pnd-tdc-warnings',
  templateUrl: './tdc-warnings.component.html',
  styleUrls: ['./tdc-warnings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdcWarningsComponent implements OnInit {
  readonly warningMessagesField = 'warningMessages';
  readonly specialServicesField = 'specialServiceSummary';
  readonly consigneeField = 'consigneeName';

  dataSource = new MatTableDataSource();
  columnFields: string[];
  visibleColumns: { name: string; field: string }[];
  selectedsToOverride: DeliveryShipmentSearchRecord[] = [];
  isToggleAllOn: boolean = false;

  @ViewChildren('checkboxes') checkboxes: QueryList<MatCheckbox>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { warnings: CreateNewRouteResp; selecteds: DeliveryShipmentSearchRecord[] },
    public dialogRef: MatDialogRef<TdcWarningsComponent>,
    private stopWindowService: StopWindowService,
    private conditioningService: ConditioningService,
    private decimalPipe: DecimalPipe,
    private timeService: XpoLtlTimeService,
    private specialServicesService: SpecialServicesService,
    protected operationsCustomerProfileService: OperationsCustomerProfileService
  ) {
    this.visibleColumns = [
      { name: 'PRO', field: 'proNbr' },
      { name: 'Message', field: this.warningMessagesField },
      { name: 'Consignee', field: this.consigneeField },
      { name: 'Address', field: 'consigneeAddress' },
      { name: 'DLW Type', field: 'deliveryWindowType' },
      { name: 'DLW Time', field: 'deliveryWindowTime' },
      { name: 'DLW Date', field: 'deliveryWindowDate' },
      { name: 'Weight', field: 'weight' },
      { name: 'MM', field: 'motorizedPiecesCount' },
      { name: 'Special Services', field: this.specialServicesField },
      { name: 'Svc Date', field: 'estimatedDeliveryDate' },
      { name: 'Dest. SIC', field: 'destinationSicCd' },
      { name: 'Current SIC', field: 'shipmentLocationSicCd' },
      { name: 'Trailer SIC', field: 'trailerCurrSicCd' },
      { name: 'Trailer', field: 'currentTrailer' },
      { name: 'Sched. ETA', field: 'scheduleETA' },
      { name: 'Sched. Destination', field: 'scheduleDestinationSicCd' },
    ];

    this.columnFields = ['checkbox', ...this.visibleColumns.map((x) => x.field)];
  }

  ngOnInit() {
    this.setSelectedsToOverride();
    this.buildDataSourceFromDialogData();
    this.subscribeToLogg();
  }

  private subscribeToLogg(): void {
    this.dialogRef.beforeClosed().subscribe((res) => {
      if (res) {
        let message = 'User override and assign the following TDC shipments';
        this.selectedsToOverride.forEach((shipment) => {
          if (this.isTdcWarning(shipment)) {
            message = `${message} ${this.conditioningService.conditionProNumber(shipment.proNbr, 10)},`;
          }
        });
      }
    });
  }

  private setSelectedsToOverride(): void {
    this.selectedsToOverride = this.data.selecteds.filter((selected) => {
      return !this.data.warnings.tdcWarnings.some(
        (warning: DataValidationError) => `${selected[warning.fieldName]}` === warning.fieldValue
      );
    });
  }

  private buildDataSourceFromDialogData(): void {
    const tempDataSource = [];
    const addToDataSource = (shipments: DeliveryShipmentSearchRecord[]): void => {
      const sortedShipments = this.advancedSort(shipments);
      sortedShipments.forEach((shipment: DeliveryShipmentSearchRecord) => {
        const warningMessage: TdcWarningMessages = this.getMessagesFromTDCWarnings(shipment);

        tempDataSource.push({
          shipmentInstId: shipment.shipmentInstId,
          proNbr: this.conditioningService.conditionProNumber(shipment.proNbr, 10),
          consigneeName: [shipment.consignee.name1, shipment.consignee.acctInstId],
          consigneeAddress: shipment?.consignee?.addressLine1 ?? '',
          deliveryWindowType: this.stopWindowService.getStopWindowType(shipment?.stopWindow),
          deliveryWindowTime: this.stopWindowService.getStopWindowTime(shipment?.stopWindow, false),
          deliveryWindowDate: this.stopWindowService.getStopWindowDate(shipment?.stopWindow),
          weight: this.decimalPipe.transform(shipment.totalWeightLbs),
          motorizedPiecesCount: shipment.motorizedPiecesCount,
          specialServiceSummary: SpecialServicesHelper.getSpecialServicesForSummary(shipment?.specialServiceSummary),
          estimatedDeliveryDate: this.getEstimatedDeliveryDate(shipment),
          destinationSicCd: shipment.destinationSicCd,
          shipmentLocationSicCd: shipment.shipmentLocationSicCd,
          trailerCurrSicCd: shipment.trailerCurrSicCd,
          currentTrailer: shipment.currentTrailer,
          scheduleETA: this.getScheduleEta(shipment),
          scheduleDestinationSicCd: shipment.scheduleDestinationSicCd,
          tdcWarnings: this.isTdcWarning(shipment),
          warningMessages: warningMessage?.messages,
          disabled: !!warningMessage?.hasErrors,
          specialServiceMarks: this.specialServicesService.getSpecialServiceAppointmentMark(
            shipment,
            'appointmentStatusCd'
          ),
        });
      });
    };

    addToDataSource(this.data.selecteds);
    this.dataSource.data = tempDataSource;
  }

  advancedSort(shipments: DeliveryShipmentSearchRecord[]): DeliveryShipmentSearchRecord[] {
    const tdcWarnings = (shipments || [])
      .filter((shipment) => this.isTdcWarning(shipment))
      .sort(this.compareSort.bind(this));
    const nonWarnings = (shipments || [])
      .filter((shipment) => !this.isTdcWarning(shipment))
      .sort(this.compareSort.bind(this));
    return [...tdcWarnings, ...nonWarnings];
  }
  onConsigneeClick(acctInstId: number) {
    if (acctInstId) {
      this.operationsCustomerProfileService.openDialogProfile(acctInstId, false);
    }
  }
  compareSort(prev: DeliveryShipmentSearchRecord, curr: DeliveryShipmentSearchRecord) {
    if ((prev?.consignee?.name1 ?? '') === curr?.consignee?.name1) {
      return this.conditioningService.conditionProNumber(prev.proNbr, 10) <
        this.conditioningService.conditionProNumber(curr.proNbr, 10)
        ? -1
        : this.conditioningService.conditionProNumber(prev.proNbr, 10) >
          this.conditioningService.conditionProNumber(curr.proNbr, 10)
        ? 1
        : 0;
    } else {
      return prev.consignee.name1 > curr.consignee.name1 ? 1 : prev.consignee.name1 < curr.consignee.name1 ? -1 : 0;
    }
  }

  private isTdcWarning(shipment: DeliveryShipmentSearchRecord): boolean {
    const warnings: DataValidationError[] = this.data?.warnings?.tdcWarnings ?? [];
    return warnings.some((warning: DataValidationError) => `${shipment[warning.fieldName]}` === warning.fieldValue);
  }

  private getScheduleEta(shipment: DeliveryShipmentSearchRecord) {
    return this.timeService.formatDate(shipment?.scheduleETA, 'MM/DD HH:mm', shipment?.scheduleDestinationSicCd);
  }

  private getEstimatedDeliveryDate(shipment: DeliveryShipmentSearchRecord) {
    const date = shipment?.estimatedDeliveryDate;
    return date ? moment(date).format('MM/DD') : '';
  }

  addSelectionToOverride(event, element) {
    const shipment = this.data.selecteds.find(
      (ship: DeliveryShipmentSearchRecord) => ship.shipmentInstId === element.shipmentInstId
    );
    if (event.checked) {
      this.selectedsToOverride = [...this.filterSelecteds(shipment), shipment];
    } else {
      this.selectedsToOverride = this.filterSelecteds(shipment);
    }

    let itemsChecked = 0;
    this.checkboxes?.forEach((checkbox: MatCheckbox) => {
      if (checkbox.checked) {
        itemsChecked++;
      }
    });

    if (itemsChecked === 0) {
      this.isToggleAllOn = false;
    } else {
      this.isToggleAllOn = itemsChecked === this.dataSource.data.length;
    }
  }

  toggleAllOnOff(event: MatCheckboxChange) {
    this.isToggleAllOn = event.checked;

    if (!this.isToggleAllOn) {
      this.selectedsToOverride = [];
    } else {
      this.selectedsToOverride = [...this.dataSource.data.map((item) => <DeliveryShipmentSearchRecord>item)];
    }

    this.checkboxes?.forEach((checkbox: MatCheckbox) => {
      if (!checkbox.checked === this.isToggleAllOn && !checkbox.disabled) {
        checkbox.toggle();
      }
    });
  }

  private filterSelecteds(shipment: DeliveryShipmentSearchRecord) {
    return this.selectedsToOverride.filter(
      (ship: DeliveryShipmentSearchRecord) => ship.shipmentInstId !== shipment.shipmentInstId
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  private getMessagesFromTDCWarnings(shipment: DeliveryShipmentSearchRecord): TdcWarningMessages {
    if (this.isTdcWarning(shipment)) {
      const tdcWarningsErrorSignifier: string = 'E';
      const shipmentWarnings: DataValidationError[] =
        this.data?.warnings?.tdcWarnings?.filter(
          (warning: DataValidationError) => `${shipment[warning.fieldName]}` === warning.fieldValue
        ) ?? [];

      const messages: TdcWarningMessages = shipmentWarnings?.reduce(
        (tdcWarningMessages: TdcWarningMessages, shipmentWarning: DataValidationError) => {
          let hasErrors: boolean = tdcWarningMessages.hasErrors;

          const isErrorMessage: boolean = shipmentWarning.errorCd?.endsWith(tdcWarningsErrorSignifier);
          const tdcMessage: TdcWarningMessage = {
            message: shipmentWarning.message,
            isError: isErrorMessage,
          };

          tdcWarningMessages.messages.push(tdcMessage);

          if (isErrorMessage) {
            hasErrors = true;
          }

          return {
            ...tdcWarningMessages,
            hasErrors,
          };
        },
        {
          messages: [],
          hasErrors: false,
        } as TdcWarningMessages
      );

      return messages;
    }
  }
}
