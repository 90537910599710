import { Injectable } from '@angular/core';
import { PndStoreState } from '@pnd-store/.';
import { DispatcherTripsStoreActions } from '@pnd-store/dispatcher-trips-store';
import { ModifyTripDetailsActions } from '@pnd-store/modify-trip-details-store';
import { PndStore } from '@pnd-store/pnd-store';
import { TripsStoreActions } from '@pnd-store/trips-store';
import { UnassignedPickupsStoreActions, UnassignedPickupsStoreSelectors } from '@pnd-store/unassigned-pickups-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  AssignPickupsToDockDropRoutePath,
  AssignPickupsToDockDropRouteRqst,
  AssignPickupsToPnDTripPath,
  AssignPickupsToPnDTripRqst,
  CityOperationsApiService,
  ListPnDSuggestedTripsForPickupPath,
  ListPnDSuggestedTripsForPickupQuery,
  Route,
} from '@xpo-ltl/sdk-cityoperations';
import {
  AssignHookEquipmentToTripRqst,
  AssignHookEquipmentToTripPath,
} from '@xpo-ltl/sdk-cityoperations/lib/api-cityoperations';
import { DataValidationError, PickupTypeCd, TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { AssignShipmentsPanelTitle } from 'app/inbound-planning/components/assign-shipments/enums/assign-shipments-panel-title.enum';
import {
  MapSplitPanelItem,
  SplitPanelComponentType,
} from 'app/inbound-planning/components/planning-map/components/map-split-panels/map-split-panel-item';
import { size as _size, isEqual as _isEqual, keyBy as _keyBy, Dictionary as _dictionary } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subscriber } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { LayoutComponentName } from 'shared/enums/layout-component-name.enum';
import { LayoutManagerService } from 'shared/layout-manager';
import { PndZoneUtils } from 'shared/zone-utils';
import { MapSplitPanelService, UnassignedPickupsService } from '.';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../core';
import { UnassignedPickupsSummary } from '../../components/unassigned-pickups/models/unassigned-pickup-summary';
import { MapSplitPanelItemsId } from '../enums/map-split-panel-items-id.enum';
import { StoreSourcesEnum } from '../enums/store-sources.enum';
import { SpecialServicesHelper } from '../helpers/special-services/special-services.helper';
import { EventItem, UnassignedPickupIdentifier } from '../interfaces/event-item.interface';
import { AssignedStopMapMarker } from '../models';
import { SicZonesAndSatellites } from '../models/sic-zones-and-satellites.model';
import { DispatcherTripsService } from './dispatcher-trips.service';
import { ModifyTripDetailsSplitPanelService } from './modify-trip-details-split-panel/modify-trip-details-split-panel.service';
import { PlanningRoutesCacheService } from './planning-routes-cache.service';
import { TripsService } from './trips.service';

interface ColSortModel {
  colId: string;
  sort: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssignPickupsService {
  readonly unmappedPickupToBeAssignedSubject = new BehaviorSubject<EventItem<UnassignedPickupIdentifier>>(undefined);
  readonly unmappedPickupToBeAssigned$ = this.unmappedPickupToBeAssignedSubject.asObservable();
  private _sortModel: ColSortModel[];

  set sortModel(value: ColSortModel[]) {
    this._sortModel = value;
  }
  get sortModel(): ColSortModel[] {
    return this._sortModel;
  }

  get unmappedPickupToBeAssigned() {
    return this.unmappedPickupToBeAssignedSubject.value;
  }

  private readonly pickupsToBeReassignedSubject = new BehaviorSubject<EventItem<UnassignedPickupIdentifier>[]>([]);
  readonly pickupsToBeReassigned$ = this.pickupsToBeReassignedSubject.asObservable();

  private readonly isAssignPickupsPanelOpenSubject = new BehaviorSubject<boolean>(false);
  readonly isAssignedPickupsPanelOpened$ = this.isAssignPickupsPanelOpenSubject.asObservable();

  private readonly assignCompletedSubject = new BehaviorSubject<boolean>(false);
  readonly assignCompleted$ = this.assignCompletedSubject.asObservable();

  protected unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService,
    private cityOperationsService: CityOperationsApiService,
    private layoutManagerService: LayoutManagerService,
    private tripsService: TripsService,
    private mapSplitPanelService: MapSplitPanelService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private dispatchTripsService: DispatcherTripsService,
    private unassignedPickupsService: UnassignedPickupsService,
    private modifyTripDetailsSplitPanelService: ModifyTripDetailsSplitPanelService
  ) {}

  /**
   * Set the state of the panel to either open or closed
   */
  setPanelOpenState(open: boolean) {
    this.isAssignPickupsPanelOpenSubject.next(open);
  }

  /**
   * set the pickup to be reassigned from the passed Marker
   */
  reassignPickups(marker: AssignedStopMapMarker) {
    const stop = marker.stop;

    const id: UnassignedPickupIdentifier = {
      loosePiecesCount: 0,
      motorMovesNbr: 0,
      palletsCount: 0,
      weightLbs: 0,
      pickupInstId: undefined,
      tripInstId: undefined,
      shipper: stop.customer,
      specialServiceSummary: SpecialServicesHelper.getSpecialServicesForSummary(stop.specialServicesSummary),
      pickupTypeCd: undefined,
    };

    let nonPickups: boolean = false;

    stop.activities.forEach((activity) => {
      activity?.activityShipments?.forEach((activityShipment) => {
        id.loosePiecesCount += activityShipment?.loosePiecesCount;
      });
      id.motorMovesNbr += activity.tripNodeActivity.totalMmCount;
      id.palletsCount += activity.tripNodeActivity.totalPalletCount;
      id.weightLbs += activity.tripNodeActivity.totalWeightCount;
      if (!id.pickupInstId) {
        id.pickupInstId = activity.tripNodeActivity.pickupRequestInstId;
      }
      if (!id.tripInstId) {
        id.tripInstId = activity.tripNodeActivity.tripInstId;
      }
      if (activity.tripNodeActivity.activityCd !== TripNodeActivityCd.PICKUP_SHIPMENTS) {
        nonPickups = true;
      }
    });

    id.pickupTypeCd = nonPickups ? undefined : PickupTypeCd.PU;

    const source: StoreSourcesEnum = StoreSourcesEnum.REASSIGN_ACTION;

    // Note: atm we can only reassign a pickup at a time but this may change in the future
    this.pickupsToBeReassignedSubject.next([{ id, source }]);
  }

  assignPickupsToDockDrop(
    pickupRequestInstIds: number[],
    carrierId: number = null,
    successNotificationMessage: string
  ): Observable<{ succeeded: boolean; error?: Error }> {
    return new Observable((observer) => {
      combineLatest([of(this.planningRoutesCacheService.getDockDropRoute()), this.dispatchTripsService.dockRoutes$])
        .pipe(take(1))
        .subscribe(([planningSource, dispatchSource]: [Route, Route[]]) => {
          const dockRoute =
            planningSource ??
            dispatchSource.find(
              (route) => route.routePrefix.toUpperCase() === 'DOCK' && route.routeSuffix.toUpperCase() === 'DROP'
            );

          const request = { ...new AssignPickupsToDockDropRouteRqst(), pickupRequestInstIds, carrierId };
          const path = {
            ...new AssignPickupsToDockDropRoutePath(),
            routeInstId: dockRoute?.routeInstId,
          };

          this.cityOperationsService
            .assignPickupsToDockDropRoute(request, path)
            .pipe(take(1))
            .subscribe(
              () => {
                this.clearSelectionsAndRefresh(request.pickupRequestInstIds, observer, successNotificationMessage);
              },
              (error) => {
                // Any pre-assigned pickups will have been processed in the assign...
                // This happens from the map marker and is a one-time operation... if it fails we still need to remove it.
                this.pickupsToBeReassignedSubject.next([]);
                this.unmappedPickupToBeAssignedSubject.next(null);

                this.notificationMessageService
                  .openNotificationMessage(
                    NotificationMessageStatus.Error,
                    this.notificationMessageService.parseErrorMessage(error)
                  )
                  .subscribe(() => {});
                observer.next({ succeeded: false, error });
                observer.complete();
              }
            );
        });
    });
  }

  private clearSelectionsAndRefresh(
    pickupRequestInstIds: number[],
    observer: Subscriber<{ succeeded: boolean; error?: Error }>,
    successNotificationMessage: string
  ): void {
    // Remove assigned pickups to improve visual performance
    const filteredUnassignedPickupIds: { [key: number]: boolean } = this.pndStore$.selectSnapshot(
      UnassignedPickupsStoreSelectors.filteredUnassignedPickupIds
    );
    pickupRequestInstIds.forEach((pickup) => {
      delete filteredUnassignedPickupIds[pickup];
    });

    this.pndStore$.dispatch(
      new UnassignedPickupsStoreActions.SetFilteredUnassignedPickupIds({
        pickupIds: filteredUnassignedPickupIds,
      })
    );

    // Refresh all the data that may have changed after the assign
    if (this.layoutManagerService.isPanelOpen(LayoutComponentName.DISPATCHER_TRIPS)) {
      if (!this.modifyTripDetailsSplitPanelService.isPanelOpen()) {
        this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
      }
    }

    if (this.layoutManagerService.isPanelOpen(LayoutComponentName.TRIP_PLANNING)) {
      // Clear cached stops to force api call for all selected routes and refetch all the stops
      this.tripsService.keepCachedStops([]);

      if (!this.modifyTripDetailsSplitPanelService.isPanelOpen()) {
        this.pndStore$.dispatch(new TripsStoreActions.Refresh());
      }
    }

    this.clearSelectedPickups(pickupRequestInstIds);

    // clear any selections in ModifyTripsDetails and refresh the data
    this.pndStore$.dispatch(
      new ModifyTripDetailsActions.SetSelectedActivities({
        selectedActivities: [],
      })
    );

    this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());

    // Any pre-assigned pickups will have been processed in the assign...
    // This happens from the map marker and is a one-time operation...
    this.pickupsToBeReassignedSubject.next([]);
    this.unmappedPickupToBeAssignedSubject.next(null);
    this.assignCompletedSubject.next(true);

    this.notificationMessageService
      .openNotificationMessage(NotificationMessageStatus.Success, successNotificationMessage)
      .subscribe(() => {});
    observer.next({ succeeded: true });
    observer.complete();
  }

  /**
   * Execute calling API to assign pickups
   */
  assignPickups(
    request: AssignPickupsToPnDTripRqst,
    path: AssignPickupsToPnDTripPath,
    successNotificationMessage: string
  ): Observable<{ succeeded: boolean; error?: Error; warning?: DataValidationError }> {
    return new Observable((observer) => {
      this.cityOperationsService
        .assignPickupsToPnDTrip(request, path)
        .pipe(take(1))
        .subscribe(
          (response) => {
            const driverWarning = response?.warnings?.find((warning) => warning.errorCd === 'SCOP030-801W') ?? false;
            if (driverWarning) {
              observer.next({ succeeded: false, error: null, warning: driverWarning });
              observer.complete();
            } else {
              this.clearSelectionsAndRefresh(request.pickupRequestInstIds, observer, successNotificationMessage);
            }
          },
          (error) => {
            // Any pre-assigned pickups will have been processed in the assign...
            // This happens from the map marker and is a one-time operation... if it fails we still need to remove it.
            this.pickupsToBeReassignedSubject.next([]);
            this.unmappedPickupToBeAssignedSubject.next(null);
            this.assignCompletedSubject.next(false);

            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Error,
                this.notificationMessageService.parseErrorMessage(error)
              )
              .subscribe(() => {});
            observer.next({ succeeded: false, error });
            observer.complete();
          }
        );
    });
  }

  setAssignCompletedFalse() {
    // called after assign process completed
    this.assignCompletedSubject.next(false);
  }

  assignHookEquipment(
    request: AssignHookEquipmentToTripRqst,
    path: AssignHookEquipmentToTripPath,
    successNotificationMessage: string
  ): Observable<{ succeeded: boolean; error?: Error; warning?: DataValidationError }> {
    return new Observable((observer) => {
      this.cityOperationsService
        .assignHookEquipmentToTrip(request, path)
        .pipe(take(1))
        .subscribe(
          (response) => {
            const driverWarning = response?.warnings?.find((warning) => warning.errorCd === 'SCOP030-801W') ?? false;
            if (driverWarning) {
              observer.next({ succeeded: false, error: null, warning: driverWarning });
              observer.complete();
            } else {
              this.clearSelectionsAndRefresh(request.hookInstIds, observer, successNotificationMessage);
              const pickupMap: _dictionary<UnassignedPickupsSummary> = _keyBy(
                this.unassignedPickupsService?.unassignedPickups,
                (pickup: UnassignedPickupsSummary) => pickup?.pickupHeader?.pickupRequestInstId
              );
              request?.hookInstIds.forEach((id) => {
                delete pickupMap[id];
              });
              this.unassignedPickupsService.updateUnassignedPickups(Object.values(pickupMap));
              this.pndStore$.dispatch(new UnassignedPickupsStoreActions.SetLastUpdate({ lastUpdate: new Date() }));
            }
          },
          (error) => {
            this.pickupsToBeReassignedSubject.next([]);
            this.unmappedPickupToBeAssignedSubject.next(null);
            this.assignCompletedSubject.next(false);

            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Error,
                this.notificationMessageService.parseErrorMessage(error)
              )
              .pipe(take(1))
              .subscribe(() => {});
            observer.next({ succeeded: false, error });
            observer.complete();
          }
        );
    });
  }

  private clearSelectedPickups(pickupRequestInstIds: number[]): void {
    const unassignedPU: EventItem<UnassignedPickupIdentifier>[] = this.pndStore$.selectSnapshot(
      UnassignedPickupsStoreSelectors.unassignedPickupsSelected
    );
    const pickupRequestInstIdArr: number[] = [];
    unassignedPU?.forEach((item) => {
      pickupRequestInstIdArr.push(item?.id?.pickupInstId);
    });

    if (_isEqual(pickupRequestInstIds, pickupRequestInstIdArr)) {
      this.pndStore$.dispatch(
        new UnassignedPickupsStoreActions.SetSelectedUnassignedPickups({
          selectedPickups: [],
        })
      );
    }
  }

  fetchRecommendedTrips(unassignedPickupIds: number[], zoneIndicatorCd: SicZonesAndSatellites): Observable<number[]> {
    if (_size(unassignedPickupIds) === 1) {
      const path: ListPnDSuggestedTripsForPickupPath = {
        ...new ListPnDSuggestedTripsForPickupPath(),
        pickupInstId: unassignedPickupIds[0],
      };

      const query: ListPnDSuggestedTripsForPickupQuery = {
        ...new ListPnDSuggestedTripsForPickupQuery(),
        zoneIndicatorCd: PndZoneUtils.getZoneIndicatorCd(zoneIndicatorCd),
      };

      return this.cityOperationsService.listPnDSuggestedTripsForPickup(path, query).pipe(
        map((result) => result.tripInstIds),
        catchError(() => of([]))
      );
    } else {
      return of([]);
    }
  }

  toggleAssignPickupsInMapSplitPanel(): void {
    this.mapSplitPanelService.addPanelItem(
      new MapSplitPanelItem(SplitPanelComponentType.AssignPickups, {
        title: AssignShipmentsPanelTitle.ASSIGN_PICKUPS,
        id: MapSplitPanelItemsId.ASSIGN_PICKUPS,
        orderIndex: 1,
        expanded: true,
      }),
      true
    );
  }
}
