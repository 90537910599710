import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { ProFormatterPipe, Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { GlobalSearchDialogService } from '@xpo-ltl/ngx-ltl-global-search';
import {
  XpoLtlShipmentDetailsComponentConfig,
  XpoLtlShipmentDetailTabCd,
  XpoLtlShipmentDetailsService,
} from '@xpo-ltl/ngx-ltl-shipment-details';
import { StopWindowCd } from '@xpo-ltl/sdk-common';
import { StopWindow } from '@xpo-ltl/sdk-common/lib';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { forEach as _forEach } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BoardUtils } from '../../../../../shared/board-utils';
import { OversizeShipment } from '../../models/oversize-shipment.model';
import { StopWindowService } from '../../services/stop-window.service';
import { XpoColors } from '../../services/xpo-colors';

export interface OversizeCellRendererParams extends ICellRendererParams {
  value: [OversizeShipment];
  oversizeAlert: OversizeAlert;
  handlingUnitAlert: HandlingUnitAlert;
  pastDueAlert: PastDueAlert;
  data: any;
  isTotalRow: boolean;
  currentPlanDate$: () => Observable<Date>;
  isAvailableForPastDueAlert: boolean;
}

interface OversizeCellRendererData {
  alert: OversizeAlert;
  handlingUnitAlert: HandlingUnitAlert;
  pastDueAlert: PastDueAlert;
  position: TooltipPosition;
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos;
  color: ThemePalette;
}

export interface OversizeAlert {
  handlingUnits?: string;
  pros?: string[];
  isMultiplePros: boolean;
  isMultipleHandlingUnits: boolean;
}

export interface HandlingUnitAlert {
  handlingUnitsCount: number;
  isPartial: boolean;
  isSplit: boolean;
  pros?: string[];
}

export interface PastDueAlert {
  isPastDue: boolean;
  dueDate: string;
}

@Component({
  selector: 'pnd-oversize-cell-renderer',
  templateUrl: './oversize-cell-renderer.component.html',
  styleUrls: ['./oversize-cell-renderer.component.scss'],
})
export class OversizeCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  private params: OversizeCellRendererParams;
  readonly XpoColors = XpoColors;

  private readonly overSizeCellDataSubject = new BehaviorSubject<OversizeCellRendererData>(undefined);
  readonly overSizeCellData$ = this.overSizeCellDataSubject.asObservable();
  private currentPlanDate: Date;
  private unsubscriber = new Unsubscriber();
  readonly shipmentDetailsConfig: XpoLtlShipmentDetailsComponentConfig = {
    tabs: [
      XpoLtlShipmentDetailTabCd.APPOINTMENTS,
      XpoLtlShipmentDetailTabCd.DOCUMENTS,
      XpoLtlShipmentDetailTabCd.EDI,
      XpoLtlShipmentDetailTabCd.CLAIMS,
      XpoLtlShipmentDetailTabCd.DISPUTES,
      XpoLtlShipmentDetailTabCd.EXCEPTIONS,
      XpoLtlShipmentDetailTabCd.INVOICING,
      XpoLtlShipmentDetailTabCd.NOTES,
    ],
  };

  get isOrangeFlag(): boolean {
    if (
      this.overSizeCellDataSubject?.value?.pastDueAlert?.isPastDue &&
      !this.overSizeCellDataSubject?.value?.alert &&
      !this.overSizeCellDataSubject?.value?.handlingUnitAlert?.isPartial &&
      !this.overSizeCellDataSubject?.value?.handlingUnitAlert?.isSplit
    ) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private xpoLtlShipmentDetailsService: XpoLtlShipmentDetailsService,
    private proFormatterPipe: ProFormatterPipe,
    private globalSearchDialogService: GlobalSearchDialogService,
    protected stopWindowService: StopWindowService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  agInit(oversizeCellRendererParams: OversizeCellRendererParams): void {
    this.params = oversizeCellRendererParams;
    this.params.isTotalRow = BoardUtils.isTotalsRow(this.params?.node);
    if (!this.params.isTotalRow) {
      this.params.isTotalRow = this.params?.node?.group ?? false;
    }
    if (this.params && this.params.currentPlanDate$ && this.params.isAvailableForPastDueAlert) {
      this.params
        ?.currentPlanDate$()
        ?.pipe(takeUntil(this.unsubscriber.done$))
        ?.subscribe((currentPlanDate) => {
          if (!this.params?.isTotalRow) {
            this.currentPlanDate = currentPlanDate;
            this.params.pastDueAlert = this.setPastDueAlert(oversizeCellRendererParams, this.currentPlanDate);
          }
        });
    }

    if (!this.params?.isTotalRow) {
      this.params.oversizeAlert = this.setOverSizeAlert(oversizeCellRendererParams);
      this.params.handlingUnitAlert = this.setHandlingUnitAlert(oversizeCellRendererParams);
    }
    this.changeDetectorRef.markForCheck();
  }

  setPastDueAlert(params: OversizeCellRendererParams, currentPlanDate: Date): PastDueAlert {
    const currentPlanDateFormatted = moment(currentPlanDate).format('MM-DD-YYYY');
    const estimatedDeliveryDateFormatted = moment(params?.data?.estimatedDeliveryDate).format('MM-DD-YYYY');
    const deliveryWindowDate = this.getWindowsDeliveryDate(params?.data?.stopWindow);
    let pastDueAlert: PastDueAlert;
    let DueDateText = 'Service Date';

    if (params?.data?.shipmentLocationSicCd === params?.data?.destinationSicCd) {
      if (
        (params?.data?.stopWindow?.[0]?.stopWindowType === StopWindowCd.APPOINTMENT ||
          params?.data?.stopWindow?.[0]?.stopWindowType === StopWindowCd.TIME_DATE_CRITICAL) &&
        moment(deliveryWindowDate).isBefore(currentPlanDateFormatted)
      ) {
        DueDateText =
          params?.data?.stopWindow?.[0]?.stopWindowType === StopWindowCd.APPOINTMENT ? 'Appointment' : 'TDC';
        pastDueAlert = {
          isPastDue: true,
          dueDate: deliveryWindowDate + ' ' + DueDateText,
        };
      } else if (
        params?.data?.stopWindow?.[0]?.stopWindowType !== StopWindowCd.APPOINTMENT &&
        params?.data?.stopWindow?.[0]?.stopWindowType !== StopWindowCd.TIME_DATE_CRITICAL &&
        moment(estimatedDeliveryDateFormatted).isBefore(currentPlanDateFormatted)
      ) {
        pastDueAlert = {
          isPastDue: true,
          dueDate: estimatedDeliveryDateFormatted + ' ' + DueDateText,
        };
      }
    }

    if (pastDueAlert?.isPastDue) {
      params.pastDueAlert = pastDueAlert;
      this.updateOversizeData();
    }
    return params.pastDueAlert;
  }

  setHandlingUnitAlert(params: OversizeCellRendererParams): HandlingUnitAlert {
    if (params?.data?.handlingUnitPartialInd || params?.data?.handlingUnitSplitInd) {
      const handlingUnitAlert: HandlingUnitAlert = {
        handlingUnitsCount: params?.data?.totalHandlingUnitCount ?? 0,
        isPartial: params?.data?.handlingUnitPartialInd,
        isSplit: params?.data?.handlingUnitSplitInd,
        pros: [],
      };

      params.handlingUnitAlert = handlingUnitAlert;
      this.updateOversizeData();
    } else if (params?.data?.deliveryShipments?.length > 0) {
      // HACK: PCT-18763
      let handlingUnitAlert: HandlingUnitAlert;
      params?.data?.deliveryShipments?.forEach((deliveryShipment) => {
        if (deliveryShipment?.handlingUnitPartialInd || deliveryShipment?.handlingUnitSplitInd) {
          handlingUnitAlert = {
            handlingUnitsCount: params?.data?.totalHandlingUnitCount ?? 0,
            isPartial: params?.data?.handlingUnitPartialInd,
            isSplit: params?.data?.handlingUnitSplitInd,
            pros: [],
          };
        }
      });
      if (handlingUnitAlert) {
        params.handlingUnitAlert = handlingUnitAlert;
        this.updateOversizeData();
      }
    }
    return params.handlingUnitAlert;
  }

  getWindowsDeliveryDate(stopWindow: StopWindow[]): string {
    const stopWindowType: StopWindowCd = stopWindow?.[0]?.stopWindowType;
    const unformattedBeginDate = stopWindow?.[0]?.beginDate;
    const unformattedEndDate = stopWindow?.[0]?.endDate;

    const beginDate = unformattedBeginDate ? moment(unformattedBeginDate).format('MM-DD-YYYY') : undefined;
    const endDate = unformattedEndDate ? moment(unformattedEndDate).format('MM-DD-YYYY') : undefined;

    let output = '';

    if (beginDate || endDate) {
      switch (stopWindowType) {
        case StopWindowCd.APPOINTMENT:
          output = beginDate || endDate;
          break;
        case StopWindowCd.TIME_DATE_CRITICAL:
          if (beginDate && endDate) {
            if (beginDate === endDate) {
              output = beginDate;
            } else {
              output = endDate;
            }
          } else {
            output = beginDate ? beginDate : endDate;
          }
          break;
      }
    }

    return output;
  }

  setOverSizeAlert(oversizeCellRendererParams: OversizeCellRendererParams): OversizeAlert {
    if (oversizeCellRendererParams?.value?.length > 0) {
      const overSizeAlert: OversizeAlert = {
        handlingUnits: this.getHandlingUnitsString(oversizeCellRendererParams?.value),
        isMultiplePros: false,
        isMultipleHandlingUnits: oversizeCellRendererParams?.value?.length > 1,
      };
      _forEach(oversizeCellRendererParams?.value, (oversizeShipment: OversizeShipment) => {
        if (oversizeShipment.proNumber) {
          if (overSizeAlert.pros) {
            if (!overSizeAlert.pros.includes(oversizeShipment.proNumber)) {
              overSizeAlert.pros.push(oversizeShipment.proNumber);
            }
          } else {
            overSizeAlert.pros = [oversizeShipment.proNumber];
          }
        }
      });
      if (overSizeAlert?.pros?.length > 1) {
        overSizeAlert.handlingUnits = undefined; // We don't show HUs if there are multiple pros
        overSizeAlert.isMultiplePros = true;
        overSizeAlert.pros = overSizeAlert.pros.sort();
      }
      oversizeCellRendererParams.oversizeAlert = overSizeAlert;
      this.updateOversizeData();
    }
    return oversizeCellRendererParams.oversizeAlert;
  }

  openHandlingUnitsDialog(): void {
    if (this.params?.data?.proNbr) {
      this.globalSearchDialogService.showHUDialog({ proNbr: this.params?.data?.proNbr } as any);
    }
  }

  getHandlingUnitsString(overSizeUnits: OversizeShipment[]): string {
    let handlingUnitsString = '';
    _forEach(overSizeUnits, (oversizeShipment: OversizeShipment) => {
      if (handlingUnitsString === '') {
        handlingUnitsString =
          this.formatLengthWidth(oversizeShipment.length, 'L') +
          ' x ' +
          this.formatLengthWidth(oversizeShipment.width, 'W') +
          ' x ' +
          oversizeShipment.height +
          'H';
      } else {
        // If there are more, add one oversize dimension and return.
        handlingUnitsString = handlingUnitsString.concat(', ' + this.formatFirstOversizedMeasurement(oversizeShipment));
        // break after adding the 2nd dimension to the string. 2 shipments max.
        return false;
      }
    });
    return handlingUnitsString;
  }

  formatFirstOversizedMeasurement(oversizeShipment: OversizeShipment): string {
    if (this.isOversizedMeasurement(oversizeShipment.length)) {
      return this.formatLengthWidth(oversizeShipment.length, 'L') + '...';
    }
    if (this.isOversizedMeasurement(oversizeShipment.width)) {
      return this.formatLengthWidth(oversizeShipment.width, 'W') + '...';
    }
  }

  formatLengthWidth(measurement: number, unit: string): string {
    if (this.isOversizedMeasurement(measurement)) {
      return '<b>' + measurement + unit + '</b>';
    }
    return measurement + unit;
  }

  isOversizedMeasurement(measurement: number): boolean {
    return measurement > 48;
  }

  refresh(params: OversizeCellRendererParams): boolean {
    this.params = params;
    this.params.oversizeAlert = this.setOverSizeAlert(params);
    this.params.handlingUnitAlert = this.setHandlingUnitAlert(params);
    if (this.params.isAvailableForPastDueAlert) {
      this.params.pastDueAlert = this.setPastDueAlert(params, this.currentPlanDate);
    }
    return true;
  }

  onFlagClicked(): void {
    this.updateOversizeData();
  }

  isMultipleUnits(alert: OversizeAlert): boolean {
    return alert.isMultiplePros || alert.isMultipleHandlingUnits;
  }

  getProString(pro: string, lastItem: boolean): string {
    if (!pro) {
      return '';
    }
    const concat = lastItem ? '' : ', ';
    return this.proFormatterPipe.transform(pro, 10) + concat;
  }

  /**
   * Show the shipment details dialog.
   */
  onProClicked(pro: string): void {
    this.xpoLtlShipmentDetailsService.showShipmentDetailsDialog(
      { proNbr: pro, shipmentInstId: undefined },
      {
        moveable: true,
        componentConfig: this.shipmentDetailsConfig,
        showShipmentSelector: false,
      },
      {
        width: '1290px',
        maxHeight: '1200px',
      }
    );
  }

  /**
   * Refresh popover data
   */
  updateOversizeData(): void {
    let overSizeCellRendererData: OversizeCellRendererData;

    overSizeCellRendererData = {
      alert: this.params?.oversizeAlert,
      handlingUnitAlert: this.params?.handlingUnitAlert,
      pastDueAlert: this.params?.pastDueAlert,
      position: 'right' as TooltipPosition,
      caretPosition: 'center' as HorizontalConnectionPos,
      color: 'primary' as ThemePalette,
    };

    this.overSizeCellDataSubject.next(overSizeCellRendererData);
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
