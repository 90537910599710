<div class="weather-widget">
  <mat-radio-group (change)="onLayerChange($event)" [(ngModel)]="selectedLayer">
    <mat-radio-button [value]="layer" *ngFor="let layer of weatherLayers">{{ layer.shape_name }}</mat-radio-button>
  </mat-radio-group>

  <div class="weather-legend-container" *ngIf="selectedLayer && selectedLayer.layer_option">
    <div class="legend-body">
      <div class="legend-min-scale">{{ selectedLayer.layer_option.legendMin }}</div>
      <div class="legend-avg-scale">{{ selectedLayer.layer_option.legendAvg }}</div>
      <div class="legend-max-scale">{{ selectedLayer.layer_option.legendMax }}</div>
      <div [ngClass]="['legend-scale', selectedLayer.layer_option.scaleClass]"></div>
    </div>
  </div>
</div>
