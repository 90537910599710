<div class="toolbar" [ngClass]="{ collapsed: !isExpanded, expanded: isExpanded }">
  <div class="toolbar-item" *ngIf="!(isRouteBalancingActive$ | async) && !isDispatchAreaChecked">
    <pnd-polygon-selection
        data-test="pnd-MapToolbar__drawModeSelection"
        matTooltip="Draw"
        matTooltipPosition="left"
        (toggleDrawMode)="onPolygonDrawToggle($event)">
    </pnd-polygon-selection>
  </div>
  <div class="toolbar-item no-border-bottom" *ngIf="!(isRouteBalancingActive$ | async) && !isDispatchAreaChecked">
    <pnd-geo-filter-polygon-selection
        data-test="pnd-MapToolbar__geoFilterSelection"
        matTooltip="Geo Filter Draw"
        matTooltipPosition="left"
        (toggleDrawMode)="onGeoFilterDrawToggle($event)">
    </pnd-geo-filter-polygon-selection>
  </div>

  <div class="divider"></div>

  <div class="toolbar-item UNDL-item">
    <mat-checkbox
        matTooltip="Unassigned DL's"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isUnassignedDeliveriesChecked"
        (change)="onUnassignedDeliveriesLayerChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon UNDL-icon">
        <div>UN</div>
        <div>DL</div>
      </div>
      <div class="desc">Unassigned DL's</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item UNPU-item" [ngClass]="{ 'no-border-bottom': !isUnassignedPickupsChecked }">
    <mat-checkbox
        matTooltip="Unassigned PU's"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isUnassignedPickupsChecked"
        (change)="onUnassignedPickupsLayerChange($event, true)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon UNPU-icon">
        <div>UN</div>
        <div>PU</div>
      </div>
      <div class="desc">Unassigned PU's</div>
    </mat-checkbox>
  </div>

  <div class="sub-items UNPU" *ngIf="isUnassignedPickupsChecked">
    <div class="toolbar-item">
      <mat-checkbox
          matTooltip="Pickups"
          matTooltipPosition="left"
          class="layer-toggle-checkbox"
          [(ngModel)]="isNormalPickupsChecked"
          (change)="handleDrawNormalPickups($event)"
          [disabled]="controlsDisabled$ | async">
        <div class="icon">PU</div>
        <div class="desc">Normal Pickups</div>
      </mat-checkbox>
    </div>
    <div class="toolbar-item">
      <mat-checkbox
          matTooltip="Hook Loads"
          matTooltipPosition="left"
          class="layer-toggle-checkbox"
          [(ngModel)]="isHookLoadPickupsChecked"
          (change)="handleDrawHookLoadPickups($event)"
          [disabled]="controlsDisabled$ | async">
        <div class="icon">HL</div>
        <div class="desc">Hook Loads</div>
      </mat-checkbox>
    </div>
    <div class="toolbar-item">
      <mat-checkbox
          matTooltip="Hook Empties"
          matTooltipPosition="left"
          class="layer-toggle-checkbox"
          [(ngModel)]="isHookEmptyPickupsChecked"
          (change)="handleDrawHookEmptyPickups($event)"
          [disabled]="controlsDisabled$ | async">
        <div class="icon">HE</div>
        <div class="desc">Hook Empties</div>
      </mat-checkbox>
    </div>
    <div class="toolbar-item">
      <mat-checkbox
          matTooltip="Spot Empties"
          matTooltipPosition="left"
          class="layer-toggle-checkbox"
          [(ngModel)]="isSpotEmptyPickupsChecked"
          (change)="handleDrawSpotEmptyPickups($event)"
          [disabled]="controlsDisabled$ | async">
        <div class="icon">SE</div>
        <div class="desc">Spot Empties</div>
      </mat-checkbox>
    </div>
  </div>

  <div class="divider"></div>

  <div class="toolbar-item FRPU-item" *ngIf="isForecastedPickupsActive$ | async">
    <mat-checkbox
        matTooltip="Forecasted Pickups are used only for delivery optimization"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isForecastedPickupsChecked"
        (change)="onForecastedPickupsLayerChange($event)"
        data-test="pnd-MapToolbar__forecastedPickupsLayer"
        [disabled]="controlsDisabled$ | async">
      <div class="icon FRPU-icon">
        <div>FR</div>
        <div>PU</div>
      </div>
      <div class="desc">Forecasted PU's</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item">
    <mat-checkbox
        matTooltip="Drivers Location"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isDriverLocationChecked"
        (change)="onDriverLocationLayerChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon svgIcon="driverLocation" class="xpo-Icon"></mat-icon>
        <!-- <xpo-icon iconName="driverLocation"></xpo-icon> -->
      </div>
      <div class="desc">Driver Location</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item">
    <mat-checkbox
        matTooltip="Completed Stops"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isCompletedStopsChecked"
        (change)="onCompletedStopsLayerChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon svgIcon="completedStops" class="xpo-Icon"></mat-icon>
      </div>
      <div class="desc">Completed Stops</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item">
    <mat-checkbox
        matTooltip="Spots and Drops"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isSpotsAndDropsChecked"
        (change)="onSpotsAndDropsLayerChange($event)"
        data-test="pnd-MapToolbar__spotsAndDrops"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon svgIcon="spotsAndDrops" class="xpo-Icon" xpoIconLarge></mat-icon>
      </div>
      <div class="desc">Spots & Drops</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item" #mapToolbarIndicatorsBar>
    <mat-checkbox
        #ssTrigger
        matTooltip="Indicators"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [checked]="indicatorBarOpen$ | async"
        (change)="onToggleIndicatorsBar()"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon svgIcon="ss_special_service" class="xpo-Icon"></mat-icon>
      </div>
      <div class="desc">Indicators</div>
    </mat-checkbox>
    <section
        xpoTriggerMenu
        [trigger]="ssTrigger"
        [isActive$]="indicatorBarOpen$"
        [refreshPosition$]="refreshSSBarPosition$">
      <div class="xpoTriggerMenuIndicator" matTooltip="Show panel" matTooltipPosition="left">
        <button mat-icon-button class="xpoTriggerShowContentButton">
          <xpo-icon iconName="chevron-left"></xpo-icon>
        </button>
      </div>
      <div class="xpoTriggerMenuContent">
        <mat-checkbox [checked]="allIndicatorsComplete" (change)="setAll($event.checked)">
          Select All
        </mat-checkbox>
        <hr />
        <mat-checkbox
            *ngFor="let ss of specialServicesList"
            [(ngModel)]="ss.selected"
            (ngModelChange)="onSpecialServiceChange()">
          {{ sanitizeSpecialServiceName(ss.item) }}
          <pnd-special-services [value]="[ss.item]"></pnd-special-services>
        </mat-checkbox>
        <hr />
        <button
            #mapToolbarHideButton
            class="xpoTriggerHideContentButton mat-focus-indicator mat-stroked-button mat-button-base">
          HIDE PANEL
        </button>
      </div>
    </section>
  </div>

  <div class="toolbar-item" *ngIf="isLastBestStopFeatureActive$ | async">
    <mat-checkbox
        matTooltip="Pickup Clusters"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isPickupClustersChecked"
        (change)="onPickupClustersChange($event)"
        data-test="pnd-MapToolbar__pickupClusterLayer"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <xpo-icon iconName="pickup-cluster"></xpo-icon>
      </div>
      <div class="desc">Pickup Clusters</div>
    </mat-checkbox>
  </div>
  <div class="toolbar-item" *ngIf="isMoreOptionsOpened">
    <mat-checkbox
        matTooltip="Delivery Heatmap"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isDeliveryHeatmapChecked"
        (change)="handleDrawDeliveryHeatmap($event)"
        data-test="pnd-MapToolbar__deliveryHeatmap"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <xpo-icon iconName="heatmap-deliveries"></xpo-icon>
      </div>
      <div class="desc">Delivery Heatmap</div>
    </mat-checkbox>
  </div>
  <div class="toolbar-item" *ngIf="isMoreOptionsOpened">
    <mat-checkbox
        matTooltip="Pickup Heatmap"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isPickupHeatmapChecked"
        (change)="handleDrawPickupHeatmap($event)"
        data-test="pnd-MapToolbar__pickupHeatmap"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <xpo-icon iconName="heatmap-pickups"></xpo-icon>
      </div>
      <div class="desc">Pickup Heatmap</div>
    </mat-checkbox>
  </div>
  <div class="toolbar-item" *ngIf="isMoreOptionsOpened && !(isDispatchAreaHidden$ | async)">
    <mat-checkbox
        matTooltip="Dispatch Areas"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isDispatchAreaChecked"
        (change)="onDispatchAreaLayerChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="#4A4A4A">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
          <circle cx="8" cy="14" r="2" />
          <circle cx="12" cy="8" r="2" />
          <circle cx="16" cy="14" r="2" />
        </svg>
      </div>

      <div class="desc">Dispatch Areas</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item">
    <mat-checkbox
        matTooltip="Geo Overlays"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isGeoAreasDrawChecked"
        (change)="onGeoAreasDrawerChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon>public</mat-icon>
      </div>
      <div class="desc">Geo Area Layers</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item">
    <mat-checkbox
        matTooltip="Traffic"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isTrafficChecked"
        (change)="onTrafficLayerChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon>traffic</mat-icon>
      </div>
      <div class="desc">Traffic</div>
    </mat-checkbox>
  </div>

  <div class="toolbar-item">
    <mat-checkbox
        matTooltip="Weather"
        matTooltipPosition="left"
        class="layer-toggle-checkbox"
        [(ngModel)]="isWeatherChecked"
        (change)="onWeatherWidgetChange($event)"
        data-test="pnd-MapToolbar__unassignedDeliveries"
        [disabled]="controlsDisabled$ | async">
      <div class="icon">
        <mat-icon>filter_drama</mat-icon>
      </div>
      <div class="desc">Weather</div>
    </mat-checkbox>
  </div>

  <div class="divider thin"></div>
  <div class="toolbar-item more" (click)="onToggleMoreOptions()">
    <div class="icon">
      <xpo-icon *ngIf="isMoreOptionsOpened" iconName="chevron-top"></xpo-icon>
      <xpo-icon *ngIf="!isMoreOptionsOpened" iconName="chevron-bottom"></xpo-icon>
    </div>
    <div class="desc">
      {{ isMoreOptionsOpened ? 'Less' : 'More' }}
    </div>
  </div>
  <div class="divider thin"></div>

  <div
      [matTooltip]="isExpanded ? '' : 'Expand'"
      matTooltipPosition="left"
      class="toolbar-item collapse-btn moveUp"
      (click)="onToggleToolbar()">
    <div class="icon">
      <xpo-icon *ngIf="isExpanded" iconName="chevron-right"></xpo-icon>
      <xpo-icon *ngIf="!isExpanded" iconName="chevron-left"></xpo-icon>
    </div>
    <div class="desc">Collapse</div>
  </div>

  <div
      class="toolbar-item compass-btn"
      matTooltip="Click on the compass to relocate the map to your SIC."
      matTooltipPosition="left"
      (click)="onCompassClicked()">
    <div class="icon">
      <app-sic-marker-compass [rotationAngle]="sicMarkerRotationAngle" data-test="pnd-MapToolbar__sicMarker">
      </app-sic-marker-compass>
    </div>
    <div class="desc">XPO Location</div>
  </div>
</div>
<app-map-weather
    *ngIf="isWeatherModeActive$ | async"
    (weatherLayerChange)="onWeatherLayerChange($event)"
    [(selectedLayer)]="selectedWeatherLayer"
    [(weatherLayers)]="mapWeatherLayers"
    [(map)]="googleMap"
    data-test="pnd-MapToolbar__weatherLayer">
</app-map-weather>

<app-map-dispatch-area
    *ngIf="isDispatchAreaChecked"
    [isDrawOptionsPanelOpened]="isDrawOptionsPanelOpened"
    [isMapToolbarExpanded]="isExpanded">
</app-map-dispatch-area>
