<div class="board-header-errors" *ngIf="errors$ | async as errors">
  <div
      [xpoLtlpopoverTriggerFor]="popover"
      data-test="board-header-errors-button"
      class="board-header-errors__btn"
      *ngIf="displayErrors$ | async">
    <p data-test="board-header-errors-label">{{ errorLabel }}</p>
    <xpo-icon iconName="report-problem"></xpo-icon>
  </div>
  <xpo-ltl-popover
      data-test="board-header-errors-dropdown"
      class="board-header-errors"
      #popover
      position="below"
      caretPosition="end"
      [hideCloseButton]="true">
    <xpo-ltl-popover-header>
      <div class="board-header-errors__title">
        <xpo-icon iconName="report-problem"></xpo-icon>
        <span>Unable to proceed due to the following errors.</span>
      </div>
    </xpo-ltl-popover-header>
    <xpo-ltl-popover-content>
      <div class="board-header-errors__content">
        <div *ngFor="let error of errors$ | async | keyvalue">
          <div
              *ngFor="let columnErrors of error.value | keyvalue; let i = index"
              [attr.data-test]="'board-header-error_' + i">
            <ng-container
                *ngIf="error.key !== 'globalError'"
                [ngTemplateOutlet]="errorDisplay"
                [ngTemplateOutletContext]="{
                columnErrors: columnErrors,
                rowId: error.key
              }">
            </ng-container>
            <ng-container *ngIf="error.key === 'globalError'">
              <ng-container
                  *ngFor="let globalError of error.value; let i = index"
                  [ngTemplateOutlet]="errorText"
                  [ngTemplateOutletContext]="{
                  errorText: globalError.errorDescription
                }">
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </xpo-ltl-popover-content>
  </xpo-ltl-popover>
</div>

<ng-template #errorDisplay let-columnErrors="columnErrors" let-rowId="rowId">
  <div *ngIf="rowId && columnErrors.key && rowId !== 'globalError'" class="board-header-error__header">
    Row {{ rowId }}, Column {{ columnErrors.key }}
  </div>
  <ng-container
      *ngFor="let cellError of columnErrors.value"
      [ngTemplateOutlet]="errorText"
      [ngTemplateOutletContext]="{
      errorText: cellError.errorDescription
    }">
  </ng-container>
</ng-template>

<ng-template #errorText let-errorText="errorText">
  <div class="board-header-error__value">
    {{ errorText }}
  </div>
</ng-template>
