import { Injectable } from '@angular/core';
import { EquipmentPipe, XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  CreatePnDTripResp,
  CreatePnDTripRqst,
  DispatchDriver,
  DispatchGroup,
  ExistingRouteSummary,
  ListPnDDriversPath,
  ListPnDDriversQuery,
  ListPnDDriversResp,
  ListPnDSuggestedRouteNamesPath,
  ListPnDSuggestedRouteNamesQuery,
  ListPnDSuggestedRouteNamesResp,
  PnDDispatchGroupRegion,
  PnDTrip,
  SearchDriversQuery,
  SearchDriversResp,
  SuggestedRouteName,
  TripDriver,
  TripEquipment,
  Equipment as oEquipment,
} from '@xpo-ltl/sdk-cityoperations';
import {
  EquipmentStatusCd,
  EquipmentTypeCd,
  LicenseValidityCd,
  ListInfo,
  RouteCategoryCd,
  ZoneIndicatorCd,
} from '@xpo-ltl/sdk-common';
import {
  DockOperationsApiService,
  Equipment,
  ListEquipmentBySicPath,
  ListEquipmentBySicQuery,
  ListEquipmentBySicResp,
} from '@xpo-ltl/sdk-dockoperations';
import { RouteService } from 'app/inbound-planning/shared';
import { SicZoneSelectionType } from 'app/inbound-planning/shared/enums/sic-zone-selection-type';
import { PdoEquipmentStatusPipe } from 'app/inbound-planning/shared/pipes/pdo-equipment-status.pipe';
import { DispatchAreaService } from 'app/inbound-planning/shared/services/dispatch-area/dispatch-area.service';
import { FeatureTypes } from 'core/services/features/feature-types.enum';
import {
  isEmpty as _isEmpty,
  orderBy as _orderBy,
  padStart as _padStart,
  toUpper as _toUpper,
  unionBy as _unionBy,
  uniqBy as _uniqBy,
  forEach as _forEach,
} from 'lodash';
import moment from 'moment-timezone';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, retryWhen, take, tap } from 'rxjs/operators';
import { AutoCompleteItem } from '../../../../shared/components/autocomplete/autocomplete.component';
import { PndEquipmentTypeCd } from '../enums/equipment-type.enum';

export interface RouteNames {
  suggestedRouteNames: SuggestedRouteName[];
  existingRouteNames: ExistingRouteSummary[];
}

/**
 * Lists of equipmeent for populating AutoComplete controls
 */
export interface AutoCompleteEquipmentLists {
  trailers: AutoCompleteItem[];
  tractors: AutoCompleteItem[];
  dollies: AutoCompleteItem[];
}

@Injectable({
  providedIn: 'root',
})
export class CreateTripService {
  private trailers: Equipment[] = [];
  private dollies: Equipment[] = [];
  private drivers: TripDriver[] = [];
  private tractors: Equipment[] = [];
  private dispatchAreas: DispatchGroup[] = [];

  private listEquipmentsBySicCache: { [key: string]: AutoCompleteEquipmentLists } = {};
  private listDriversCache: { [key: string]: AutoCompleteItem[] } = {};
  private listDispatchAreasCache: { [key: string]: AutoCompleteItem[] } = {};

  constructor(
    private dockOperationsService: DockOperationsApiService,
    private cityOperationsApiService: CityOperationsApiService,
    private dispatchAreaService: DispatchAreaService,
    private equipmentPipe: EquipmentPipe,
    private pdoEquipmentStatus: PdoEquipmentStatusPipe,
    private featuresService: XpoLtlFeaturesService
  ) {}

  private tripEquipmentToEquipment(tripEquipment: TripEquipment): Equipment {
    const equipment = new Equipment();

    equipment.equipmentId = tripEquipment.equipmentInstId;
    equipment.equipmentIdPrefix = tripEquipment.equipmentIdPrefix;
    equipment.equipmentIdSuffixNbr = tripEquipment.equipmentIdSuffixNbr;
    equipment.equipmentTypeCd = tripEquipment.equipmentTypeCd;

    return equipment;
  }

  listEquipmentsBySic$(sic: string, shouldReload = true): Observable<AutoCompleteEquipmentLists> {
    if (this.listEquipmentsBySicCache[sic] && !shouldReload) {
      return of(this.listEquipmentsBySicCache[sic]);
    }

    const path = { ...new ListEquipmentBySicPath(), sicCd: sic };
    const query = {
      ...new ListEquipmentBySicQuery(),
      equipmentTypeCds: [
        EquipmentTypeCd.TRACTOR,
        EquipmentTypeCd.STRAIGHT_TRUCK,
        EquipmentTypeCd.DOLLY,
        EquipmentTypeCd.L_H_TRAILER,
      ],
      listInfo: {
        ...new ListInfo(),
        levelOfDetail: 'EQUIPMENT_BASIC',
      },
    };

    return this.dockOperationsService.listEquipmentBySic(path, query).pipe(
      take(1),
      map((response: ListEquipmentBySicResp) => {
        this.tractors = [];
        this.trailers = [];
        this.dollies = [];

        response?.equipment
          ?.filter(
            (equipment) => equipment?.currentStatus?.toUpperCase() !== 'OSVC' && equipment?.scheduleSequence === 0
          )
          .forEach((equipment: Equipment) => {
            // Hack to get the prefix formatted to 4 chars
            equipment.equipmentIdPrefix = _padStart(equipment.equipmentIdPrefix, 4, '0');
            switch (equipment.equipmentTypeCd) {
              case PndEquipmentTypeCd.STRAIGHT_TRUCK:
              case PndEquipmentTypeCd.TRACTOR:
                this.tractors.push(equipment);
                break;

              case PndEquipmentTypeCd.DOLLY:
                this.dollies.push(equipment);
                break;

              case PndEquipmentTypeCd.TRAILER:
                this.trailers.push(equipment);
                break;
            }
          });

        this.tractors = _uniqBy(this.tractors, (e: Equipment) => e.equipmentId);
        this.trailers = _uniqBy(this.trailers, (e: Equipment) => e.equipmentId);
        this.dollies = _uniqBy(this.dollies, (e: Equipment) => e.equipmentId);

        return {
          trailers: _orderBy(
            this.trailers.map((trailer: Equipment) => this.getTrailerId(trailer)),
            (e) => e.value
          ),
          tractors: _orderBy(
            this.tractors.map((tractor: Equipment) => this.getTractorId(tractor)),
            (e) => e.value
          ),
          dollies: _orderBy(
            this.dollies.map((dolly: Equipment) => this.getEquipmentId(dolly)),
            (e) => e.value
          ),
        };
      }),
      tap((data) => {
        this.listEquipmentsBySicCache[sic] = data;
      })
    );
  }

  /**
   * Return the AutoCompleteItem for the passed Equipment.
   * The `data` field contains info aboout the equipment
   */
  getTrailerId(equipment: Equipment): AutoCompleteItem {
    const equipmentId: string = this.equipmentPipe.transform(
      equipment.equipmentIdPrefix,
      equipment.equipmentIdSuffixNbr
    );

    const manufactureYear: number = equipment?.mfrYr;

    let length = '';
    if (equipment?.trailerLoad?.trailerLengthFeet > 0) {
      length = `${equipment?.trailerLoad?.trailerLengthFeet}'`;
    }

    let liftGate = '';
    if (equipment?.trailerLoad?.liftgateInd) {
      liftGate = 'LG';
    }

    let value = equipmentId;
    if (!_isEmpty(length) && !_isEmpty(liftGate)) {
      value = `${equipmentId} (${length}, ${liftGate})`;
    } else if (!_isEmpty(length) && _isEmpty(liftGate)) {
      value = `${equipmentId} (${length})`;
    } else if (_isEmpty(length) && !_isEmpty(liftGate)) {
      value = `${equipmentId} (${liftGate})`;
    }

    return {
      id: equipmentId,
      value,
      data: {
        isEmpty:
          equipment?.trailerLoad?.currentStatus === EquipmentStatusCd.EMPTY ||
          equipment?.trailerLoad?.currentStatus === 'EMTY',
        status: this.pdoEquipmentStatus.transform(equipment?.trailerLoad?.currentStatus),
        statusCd: equipment?.trailerLoad?.currentStatus,
        length,
        liftGate,
        manufactureYear,
        evntDoor: equipment?.trailerLoad?.evntDoor,
      },
    };
  }

  getTractorId(equipment: Equipment): AutoCompleteItem {
    const equipmentName: string = this.equipmentPipe.transform(
      equipment.equipmentIdPrefix,
      equipment.equipmentIdSuffixNbr
    );

    return {
      id: equipmentName,
      value: `${equipmentName}`,
      data: {
        equipmentId: equipment?.equipmentId,
        equipmentTypeCd: equipment?.equipmentTypeCd === PndEquipmentTypeCd.STRAIGHT_TRUCK ? 'Straight Truck' : '',
        currentStatus: this.pdoEquipmentStatus.transform(equipment?.currentStatus),
      },
    };
  }

  getEquipmentId(equipment: Equipment | TripEquipment): AutoCompleteItem {
    const equipmentId: string = this.equipmentPipe.transform(
      equipment.equipmentIdPrefix,
      equipment.equipmentIdSuffixNbr
    );

    return {
      id: equipmentId,
      value: `${equipmentId}`,
      data: {
        status: this.pdoEquipmentStatus.transform((<Equipment>equipment)?.currentStatus),
      },
    };
  }

  findTrailerById(autoCompleteItem: AutoCompleteItem): Equipment {
    if (!autoCompleteItem) {
      return undefined;
    }

    return this.trailers.find(
      (trailer: Equipment) =>
        _toUpper(this.equipmentPipe.transform(trailer.equipmentIdPrefix, trailer.equipmentIdSuffixNbr)) ===
        _toUpper(autoCompleteItem.id)
    );
  }

  findDollyById(autoCompleteItem: AutoCompleteItem): Equipment {
    if (!autoCompleteItem) {
      return undefined;
    }

    return this.dollies.find(
      (dolly: Equipment) =>
        _toUpper(this.equipmentPipe.transform(dolly.equipmentIdPrefix, dolly.equipmentIdSuffixNbr)) ===
        _toUpper(autoCompleteItem.id)
    );
  }

  getDriverStatus(driver: DispatchDriver): string {
    return driver?.activeTripStatusCd ?? driver?.availableStatusCd;
  }

  listDriversByName$(name, sic): Observable<AutoCompleteItem[]> {
    const req: SearchDriversQuery = {
      employeeName: name,
      planDate: moment().format('YYYY-MM-DD'),
    };

    return this.cityOperationsApiService.searchDrivers(req).pipe(
      map((response: SearchDriversResp) => {
        const items: AutoCompleteItem[] = [];
        const drivers: TripDriver[] = [];

        response.dispatchDrivers.forEach((driver: DispatchDriver) => {
          const featureEnabled: boolean =
            this.featuresService?.getFeatureValue(driver?.sicCd, FeatureTypes.DSR_BLOCK, 'N') === 'Y';
          const item = <AutoCompleteItem>{
            id: driver?.dsrEmployeeId,
            value: driver?.dsrName,
            data: {
              preferredEquipmentInstId: driver?.preferredTractorInstId,
              employeeStatus: this.getDriverStatus(driver),
              dailyRestartTime: driver?.startTime?.substr(0, 5),
              sicCd: driver?.sicCd,
              isDriverFromOtherSic: true,
              currentlyAssignedTractorInstId: driver?.currentlyAssignedTractorInstId,
              preferredTractorInstId: driver?.preferredTractorInstId,
              allowedToDrive: featureEnabled ? driver.allowedToDriveInd : true,
              optionEnabled: featureEnabled ? driver.allowedToDriveInd : true,
            },
          };
          if (driver?.allowedToDriveInd || !featureEnabled) {
            if (item.value && item.id) {
              items.push(item);
              drivers.push({
                ...new TripDriver(),
                dsrEmployeeId: driver.dsrEmployeeId,
              });
            }
          }
        });

        this.drivers = _uniqBy([...this.drivers, ...drivers], (driver: TripDriver) => driver.dsrEmployeeId);

        const result = _orderBy(items, (i) => i.value);
        return result;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }

  listDrivers$(sic: string, shouldReload = true): Observable<AutoCompleteItem[]> {
    if (this.listDriversCache[sic] && !shouldReload) {
      return of(this.listDriversCache[sic]);
    }

    const pathParams: ListPnDDriversPath = {
      sicCd: sic,
    };
    const queryParams: ListPnDDriversQuery = {
      planDate: moment().format('YYYY-MM-DD'),
    };

    return this.cityOperationsApiService.listPnDDrivers(pathParams, queryParams).pipe(
      map((response: ListPnDDriversResp) => {
        const items: AutoCompleteItem[] = [];
        const drivers: TripDriver[] = [];

        const featureEnabled: boolean = this.featuresService.getFeatureValue(sic, FeatureTypes.DSR_BLOCK, 'N') === 'Y';
        response.pnDDrivers.forEach((driver: DispatchDriver) => {
          const item = <AutoCompleteItem>{
            id: driver?.dsrEmployeeId,
            value: driver?.dsrName,
            data: {
              preferredEquipmentInstId: driver?.preferredTractorInstId,
              employeeStatus: this.getDriverStatus(driver),
              dailyRestartTime: driver?.startTime?.substr(0, 5),
              sicCd: driver?.sicCd,
              isDriverFromOtherSic: false,
              currentlyAssignedTractorInstId: driver?.currentlyAssignedTractorInstId,
              preferredTractorInstId: driver?.preferredTractorInstId,
              allowedToDrive: featureEnabled ? driver.allowedToDriveInd : true,
              optionEnabled:
                featureEnabled || driver.medExamDateValidityCd === LicenseValidityCd.EXPIRED
                  ? driver.allowedToDriveInd && !(driver.medExamDateValidityCd === LicenseValidityCd.EXPIRED)
                  : true,
              preferredTractorPrefix: driver.preferredTractorPrefix,
              preferredTractorSuffixNbr: driver.preferredTractorSuffixNbr,
              hazmatLicenseValidityCd: driver.hazmatLicenseValidityCd,

              cdlLicenseValidityCd: driver.cdlLicenseValidityCd,

              tankLicenseValidityCd: driver.tankLicenseValidityCd,

              doubleTripleLicenseValidityCd: driver.doubleTripleLicenseValidityCd,

              medExamDateValidityCd: driver.medExamDateValidityCd,

              hazmatLicenseExpirationDaysText: `Expiring in ${driver.hazmatLicenseExpirationDaysNbr} days`,

              cdlLicenseExpirationDaysText: `Expiring in ${driver.cdlLicenseExpirationDaysNbr} days`,

              tankLicenseExpirationDaysText: `Expiring in ${driver.tankLicenseExpirationDaysNbr} days`,

              doubleTripleLicenseExpirationDaysText: `Expiring in ${driver.doubleTripleLicenseExpirationDaysNbr} days`,

              medExamDateDueInDaysText: `Medical Exam Date Due in ${driver.medExamDateDueInDaysNbr} days`,
            },
          };
          if (item.value && item.id) {
            items.push(item);
            drivers.push({
              ...new TripDriver(),
              dsrEmployeeId: driver.dsrEmployeeId,
            });
          }
        });

        this.drivers = drivers;
        const result = _orderBy(items, (i) => i.value);
        return result;
      }),
      catchError((error) => {
        return of([]);
      }),
      tap((data) => {
        this.listDriversCache[sic] = data;
      })
    );
  }

  findDriverById(autoCompleteItem: AutoCompleteItem | string): TripDriver {
    return (<AutoCompleteItem>autoCompleteItem)?.id
      ? this.drivers.find(
          (driver: TripDriver) => _toUpper(driver.dsrEmployeeId) === _toUpper((<AutoCompleteItem>autoCompleteItem)?.id)
        )
      : (<AutoCompleteItem>autoCompleteItem)?.value
      ? ({
          dsrName: (<AutoCompleteItem>autoCompleteItem)?.value,
        } as TripDriver)
      : <string>autoCompleteItem
      ? ({
          dsrName: autoCompleteItem,
        } as TripDriver)
      : undefined;
  }

  findTractorById(autoCompleteItem: AutoCompleteItem): Equipment {
    return autoCompleteItem
      ? this.tractors.find(
          (tractor: Equipment) =>
            _toUpper(this.equipmentPipe.transform(tractor.equipmentIdPrefix, tractor.equipmentIdSuffixNbr)) ===
            _toUpper(autoCompleteItem.id)
        )
      : undefined;
  }

  listDispatchAreas$(sic: string, shouldReload = true): Observable<AutoCompleteItem[]> {
    if (this.listDispatchAreasCache[sic] && !shouldReload) {
      return of(this.listDispatchAreasCache[sic]);
    }

    return this.dispatchAreaService
      .loadDispatchGroups$({
        currentSelection: SicZoneSelectionType.HOST_ONLY,
        host: sic,
        hostSatellites: [],
        zoneSatellites: [],
        zones: [],
      })
      .pipe(
        take(1),
        catchError(() => of([])),
        map((groupRegions: PnDDispatchGroupRegion[]) => {
          const dispatchAreas = [];
          const dispatchAreasItems = groupRegions.map((groupRegion) => {
            let groupName = groupRegion?.dispatchGroup?.groupName;
            const groupDescription = groupRegion?.dispatchGroup?.groupDescription;

            if (!_isEmpty(groupDescription)) {
              groupName += ` - ${groupDescription}`;
            }

            dispatchAreas.push(groupRegion?.dispatchGroup);

            return {
              id: groupRegion?.dispatchGroup?.groupId?.toString(),
              value: groupName,
            };
          });

          this.dispatchAreas = dispatchAreas;

          return dispatchAreasItems;
        }),
        tap((data) => {
          this.listDispatchAreasCache[sic] = data;
        })
      );
  }

  findDispatchAreaById(autoCompleteItem: AutoCompleteItem): DispatchGroup {
    return autoCompleteItem
      ? this.dispatchAreas.find((dispatchArea) => dispatchArea.groupId.toString() === autoCompleteItem.id)
      : undefined;
  }

  listRouteNames$(sic: string, tripDate: Date): Observable<RouteNames> {
    const path: ListPnDSuggestedRouteNamesPath = {
      sicCd: sic,
    };

    const query: ListPnDSuggestedRouteNamesQuery = {
      planDate: moment(tripDate).format('YYYY-MM-DD'),
      satelliteInd: false,
      zoneIndicatorCd: ZoneIndicatorCd.NO_ZONES,
    };

    return this.cityOperationsApiService.listPnDSuggestedRouteNames(path, query).pipe(
      take(1),
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((error, i) => {
            if (i >= 10) {
              return throwError({ ...error });
            }
            return of(error).pipe(delay(250));
          })
        );
      }),
      catchError(() => of({ suggestedRouteNames: [] })),
      map((response: ListPnDSuggestedRouteNamesResp) => {
        let suggestedRouteNames: SuggestedRouteName[] = [];
        let existingRouteNames: ExistingRouteSummary[] = [];

        if (response?.suggestedRouteNames) {
          suggestedRouteNames = suggestedRouteNames.filter(
            (suggestedRouteName) =>
              RouteService.PLANNING_ROUTE_PREFIXES.indexOf(suggestedRouteName?.geoArea?.geoAreaName) === -1
          );

          suggestedRouteNames = _orderBy(response.suggestedRouteNames || [], (area) => area.geoArea.geoAreaName);
          suggestedRouteNames = suggestedRouteNames.filter(
            (suggestedRouteName: SuggestedRouteName) => suggestedRouteName.categoryCd === RouteCategoryCd.DELIVERY
          );
        }

        if (response?.existingRouteSummaries) {
          existingRouteNames = response?.existingRouteSummaries.filter(
            (existingRouteSummary) =>
              RouteService.PLANNING_ROUTE_PREFIXES.indexOf(existingRouteSummary?.routePrefix) === -1 &&
              existingRouteSummary.categoryCd === RouteCategoryCd.DELIVERY
          );
        }

        return <RouteNames>{ suggestedRouteNames, existingRouteNames };
      })
    );
  }

  createTrip$(pndTrip: PnDTrip, overrideDsrLicenseInd: boolean): Observable<void | CreatePnDTripResp> {
    const request: CreatePnDTripRqst = {
      equipmentCheckInd: true,
      pndTrip,
      overrideDsrLicenseInd: overrideDsrLicenseInd,
    };

    return this.cityOperationsApiService.createPnDTrip(request).pipe(take(1));
  }

  addTrailer(trailer: TripEquipment): void {
    this.trailers = _unionBy([this.tripEquipmentToEquipment(trailer)], this.trailers, (t: Equipment) => t.equipmentId);
  }

  addDolly(dolly: TripEquipment): void {
    this.dollies = _unionBy([this.tripEquipmentToEquipment(dolly)], this.dollies, (d: Equipment) => d.equipmentId);
  }

  addTractor(tractor: TripEquipment): void {
    this.tractors = _unionBy([this.tripEquipmentToEquipment(tractor)], this.tractors, (t: Equipment) => t.equipmentId);
  }

  addDriver(driver: TripDriver): void {
    this.drivers = _unionBy([driver], this.drivers, (d: TripDriver) => d.dsrEmployeeId);
  }

  addDispatchArea(dispatchArea: DispatchGroup): void {
    this.dispatchAreas = _unionBy([dispatchArea], this.dispatchAreas, (g: DispatchGroup) => g.groupId);
  }

  mapDollies(dolliesData: Equipment[]): oEquipment[] {
    const dollies: oEquipment[] = [];
    _forEach(dolliesData, (dolly) => {
      dollies.push({
        ...dolly,
        loadedInd: false,
      });
    });

    return dollies;
  }

  mapTractor(tractorData: Equipment): oEquipment {
    if (!tractorData) {
      return undefined;
    }

    const tractor: oEquipment = { ...tractorData, loadedInd: false };
    return tractor;
  }
}
