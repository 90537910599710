import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { XpoTagClass } from '../../enums/xpo-tag-class';

export interface AccordionGroupTag {
  text: string;
  chipClass: XpoTagClass;
}

export interface CustomAccordionGroupCellRendererParams extends ICellRendererParams {
  getTags: (params: CustomAccordionGroupCellRendererParams) => AccordionGroupTag[];
  onActionClick: (params: CustomAccordionGroupCellRendererParams) => void;
  onViewMode?: (params: CustomAccordionGroupCellRendererParams) => void;
}

@Component({
  selector: 'pnd-custom-accordion-group-cell-renderer',
  templateUrl: './custom-accordion-group-cell-renderer.component.html',
  styleUrls: ['./custom-accordion-group-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAccordionGroupCellRendererComponent implements ICellRendererAngularComp {
  params: CustomAccordionGroupCellRendererParams;
  groupName: string;
  rowCount: number;
  isRowExpanded: boolean = false;
  node: RowNode;
  tags: AccordionGroupTag[];
  hasAction: boolean = false;

  agInit(params: CustomAccordionGroupCellRendererParams): void {
    this.params = params;
    this.groupName = params.value;
    this.rowCount = params.node && params.node.allChildrenCount ? params.node.allChildrenCount : 0;
    this.tags = params.getTags ? params.getTags(params) : [];
    this.node = params.node;
    this.hasAction = !!params.onActionClick;
  }

  onActionClick() {
    if (this.params && this.params.onActionClick) {
      this.params.onActionClick(this.params);
    }
  }

  onViewMode() {
    if (this.params.onViewMode) {
      this.params.onViewMode(this.params);
    }
  }

  refresh(): boolean {
    return false;
  }
}
