<span
    #typeCountDisplay
    class="pnd-typeCountCellRenderer"
    [matTooltip]="tooltipVal"
    [matTooltipDisabled]="typeCountDisplay.clientWidth < params.column.getActualWidth()"
    position="above"
    *ngFor="let typeCd of typeCdMap; let i = index">
  <span class="pnd-typeCountCellRenderer__count">{{ typeCd.key }} </span>
  <span class="pnd-typeCountCellRenderer__value">{{ typeCd.count }}</span>
  <span *ngIf="!!typeCdMap[i + 1]">, </span>
</span>
