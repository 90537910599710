<div
    class="pnd-GridToolbarViewManager"
    *ngIf="{
    userDefinedViews: userDefinedViews$ | async,
    systemDefinedViews: systemDefinedViews$ | async,
    activeView: activeView$ | async,
    expandViews: expandViews$ | async
  } as state">
  <div
      class="pnd-GridToolbarViewManager__dropdown"
      data-test="grid-toolbar-view-manager-menu"
      [matMenuTriggerFor]="viewMenu"
      [matTooltipDisabled]="activeViewName?.scrollWidth <= activeViewName?.clientWidth"
      [matTooltip]="state?.activeView?.name">
    <span #activeViewName data-test="grid-toolbar-view-manager-active-view">
      {{ state?.activeView?.name }}
    </span>
    <xpo-icon iconName="chevron-bottom"></xpo-icon>
  </div>

  <mat-menu data-test="pnd-GridToolbarViewManagerMenu" class="pnd-GridToolbarViewManager__menu" #viewMenu="matMenu">
    <ng-template
        *ngFor="let view of state.systemDefinedViews"
        [ngTemplateOutlet]="viewItem"
        [ngTemplateOutletContext]="{ view: view }"></ng-template>
    <div class="pnd-GridToolbarViewManager__viewGroup">
      <div
          class="pnd-GridToolbarViewManager__viewGroup--header"
          (click)="toggleViewGroupExpanded($event)"
          data-test="pnd-GridToolbarViewManagerHeader">
        <div class="pnd-GridToolbarViewManager__icons">
          <ng-container *ngIf="!state.expandViews; else groupCollapsed">
            <mat-icon>add</mat-icon>
          </ng-container>
        </div>
        <mat-icon>folder_open</mat-icon>
        <span
            class="pnd-GridToolbarViewManager__viewGroup--title"
            [matTooltipDisabled]="groupName?.scrollWidth <= groupName?.clientWidth"
            matTooltip="My Views"
            #groupName>Custom Views</span>
        <span
            class="pnd-GridToolbarViewManager__badge"
            [matBadge]="state?.userDefinedViews?.length"
            matBadgePosition="after"
            matBadgeOverlap="false"></span>
      </div>
      <ng-container *ngIf="state?.expandViews">
        <ng-template
            *ngFor="let view of state?.userDefinedViews"
            [ngTemplateOutlet]="viewItem"
            [ngTemplateOutletContext]="{ view: view }"></ng-template>
      </ng-container>
    </div>
  </mat-menu>
</div>

<ng-template #viewItem let-view="view">
  <button
      mat-menu-item
      [class.pnd-GridToolbarViewManager__view--selected]="view?.isActive"
      class="pnd-GridToolbarViewManager__view"
      (click)="handleViewSelected(view.viewId)"
      [matTooltipDisabled]="viewName?.scrollWidth <= viewName?.clientWidth"
      [matTooltip]="view.name"
      data-test="grid-toolbar-view-manager-view">
    <span #viewName class="pnd-GridToolbarViewManager__view--name">{{ view.name }}</span>
  </button>
</ng-template>

<ng-template #groupCollapsed>
  <mat-icon class="pnd-GridToolbarViewManager__icons--minimize">minimize</mat-icon>
</ng-template>
