<div
    class="pnd-MapWatchedDriver"
    data-test="pnd-MapWatchedDriver__container"
    *ngIf="watchedDrivers && watchedDrivers.length > 0">
  <div class="pnd-MapWatchedDriver-list">
    <div class="pnd-MapWatchedDriver-list-header">
      Watching List
    </div>
    <div *ngFor="let watchedDriver of watchedDrivers" class="pnd-MapWatchedDriver-list-item">
      <div class="pnd-MapWatchedDriver-list-item-column-1" [style.background-color]="watchedDriver.color">
        {{ watchedDriver.initials }}
      </div>
      <div class="pnd-MapWatchedDriver-list-item-column-2">
        <div class="pnd-MapWatchedDriver-list-item-time">{{ watchedDriver.timerCounter | date: 'mm:ss' }}</div>
        <mat-menu #watchedDriverMenu="matMenu">
          <button mat-menu-item (click)="stopWatch(watchedDriver)">
            Stop Watch
          </button>
          <button [disabled]="!addMoreTimeEnabled(watchedDriver)" mat-menu-item (click)="addMoreTime(watchedDriver)">
            Add 5 minutes
          </button>
        </mat-menu>
        <button
            mat-icon-button
            [matMenuTriggerFor]="watchedDriverMenu"
            (click)="$event.stopPropagation()"
            class="pnd-MapWatchedDriver-list-item-button">
          <mat-icon matTooltip="More Options">more_vert</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
