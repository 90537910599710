<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title class="pnd-DmanPanelComponent_header">
        <div>
          <mat-checkbox (click)="$event.stopPropagation()" (change)="onDmanSelected($event, routeIndex)"
            >DPE {{ routeIndex }}</mat-checkbox
          >
        </div>

        <div class="pnd-DmanPanelComponent_trailer">Trailer: {{ dmanControl.get(DmanFormFields.TRAILER).value }}</div>
        <div class="pnd-DmanPanelComponent_trailerInfo">
          <div class="pnd-DmanPanelComponent_sealNo">Seal No:</div>
          <div>
            <input
              (click)="$event.stopPropagation()"
              matInput
              [value]="dmanControl.get(DmanFormFields.SEALNO).value"
              [disabled]="dmanControl.get(DmanFormFields.IS_ALREADY_PRINTED).value"
              [formControl]="dmanControl.get(DmanFormFields.SEALNO)"
              class="pnd-DmanPanelComponent_input"
            />
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <ng-container *ngIf="dmanControl.get(DmanFormFields.CONSIGNEE) as consigneesData">
        <ng-container *ngFor="let consignee of consigneesData.controls; let rowIndex = index" [formGroup]="consignee">
          <div class="pnd-DmanPanelComponent_consignee">
            <div>Consignee :</div>
            <div class="pnd-DmanPanelComponent_consignee__name">
              {{ consignee.get(DmanFormFields.CONSIGNEE_NAME).value }}
            </div>
          </div>
          <div class="pnd-DmanPanelComponent_my-custom-table">
            <table>
              <thead *ngIf="rowIndex === 0">
                <th class="pnd-DmanPanelComponent_my-custom-table__pro">PRO</th>
                <th class="pnd-DmanPanelComponent_my-custom-table__shipper">Shipper</th>
                <th class="pnd-DmanPanelComponent_my-custom-table__blnumber">
                  B/L Number
                </th>
                <th class="pnd-DmanPanelComponent_my-custom-table__ponumber">
                  PO Number
                </th>
                <th class="pnd-DmanPanelComponent_my-custom-table__poweight">PO Weight</th>
                <th class="pnd-DmanPanelComponent_my-custom-table__pocount">
                  PO Pieces/ <br />
                  Count
                </th>
                <th class="pnd-DmanPanelComponent_my-custom-table__billweight">Billed Weight</th>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let control of consignee.get(DmanFormFields.SHIPMENTS).controls"
                  [formGroup]="control"
                >
                  <tr *ngFor="let r of control.get(DmanFormFields.PO_WEIGHT).controls; let i = index">
                    <td
                      *ngIf="i === 0"
                      [rowSpan]="control.get(DmanFormFields.PO_WEIGHT).value.length"
                      class="pnd-DmanPanelComponent_my-custom-table__pro"
                    >
                      {{ control.get(DmanFormFields.PRO).value }}
                    </td>
                    <td
                      *ngIf="i === 0"
                      [rowSpan]="control.get(DmanFormFields.PO_WEIGHT).value.length"
                      class="pnd-DmanPanelComponent_my-custom-table__shipper"
                    >
                      {{ control.get(DmanFormFields.SHIPPER).value }}
                    </td>
                    <td
                      *ngIf="i === 0"
                      [rowSpan]="control.get(DmanFormFields.PO_WEIGHT).value.length"
                      class="pnd-DmanPanelComponent_my-custom-table__blnumber"
                    >
                      {{ control.get(DmanFormFields.BILL_NUMBER).value }}
                    </td>
                    <td class="pnd-DmanPanelComponent_my-custom-table__ponumber">
                      {{ control.get(DmanFormFields.PO_NUMBER).value[i] }}
                    </td>

                    <td
                      class="pnd-DmanPanelComponent_my-custom-table__poweightEdit pnd-DmanPanelComponent_my-custom-table__poweight"
                    >
                      <input matInput type="text" [formControl]="control.get(DmanFormFields.PO_WEIGHT).controls[i]" />
                    </td>
                    <td
                      class="pnd-DmanPanelComponent_my-custom-table__pocountEdit pnd-DmanPanelComponent_my-custom-table__pocount"
                    >
                      <input matInput type="text" [formControl]="control.get(DmanFormFields.PO_PIECES).controls[i]" />
                    </td>
                    <td
                      *ngIf="i === 0"
                      [rowSpan]="control.get(DmanFormFields.PO_WEIGHT).value.length"
                      class="pnd-DmanPanelComponent_my-custom-table__billweight"
                    >
                      {{ control.get(DmanFormFields.BILL_WEIGHT).value }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
