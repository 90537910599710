export enum PrintFormFields {
  Printer = 'PRINTER',
  Tray = 'TRAY',
  DocumentType = 'DOCUMENT_TYPE',
  PaperType = 'PAPER_TYPE',
  Language = 'LANGUAGE',
  Reprint = 'REPRINT',
  FbdsPrinter = 'FBDS_PRINTER',
  EbolPrinter = 'EBOL_PRINTER',
  DmanPrinter = 'DMAN_PRINTER',
  FbdsTwoPartPrinter = 'FBDS_TWO_PART_PRINTER',
  FbdsTray = 'FBDS_TRAY',
  PndPrinter = 'PND_PRINTER',
  PndTray = 'PND_TRAY',
  DockPrinter = 'DOCK_PRINTER',
  EbolTray = 'EBOL_TRAY',
  DmanTray = 'DMAN_TRAY',
  DsrPrinter = 'DSR_PRINTER',
  DsrTray = 'DSR_TRAY',
  OutboundPrinter = 'OUTBOUND_PRINTER',
  OutboundTray = 'OUTBOUND_TRAY',
  FbdsDocTypeSelect = 'FBDS_DOCTYPE_SELECT',
  PndDocTypeSelect = 'PND_DOCTYPE_SELECT',
  DockDocTypeSelect = 'DOCK_DOCTYPE_SELECT',
  DsrDocTypeSelect = 'DSR_DOCTYPE_SELECT',
  OutboundDocTypeSelect = 'OUTBOUND_DOCTYPE_SELECT',
  EbolDocTypeSelect = 'EBOL',
  DmanTypeSelect = 'DMAN',
}
