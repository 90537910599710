export enum UnassignedPickupsDetailGridFieldsWidth {
  BOL = 65,
  PRO_NBR = 130,
  ZIP_CODE = 111,
  DEST_SIC = 100,
  REMARKS = 1000,
  WEIGHT = 100,
  PALLETS = 100,
  PIECES = 100,
  CUBE = 85,
  MM = 85,
  SPECIAL_SERVICES = 480,
  NOTES = 1500,
}
