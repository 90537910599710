<div class="pnd-oversizeCellRenderer" *ngIf="overSizeCellData$ | async as data">
  <button mat-icon-button (click)="onFlagClicked()" class="pnd-oversizeCellRenderer__button">
    <mat-icon
        [xpoPopoverTriggerFor]="popover"
        [ngClass]="{
        'pnd-oversizeCellRenderer__icon': !isOrangeFlag,
        'pnd-oversizeCellRenderer__icon--orange': isOrangeFlag
      }">
      flag
    </mat-icon>
  </button>
  <xpo-popover
      #popover="xpoPopover"
      [position]="data.position"
      [color]="data.color"
      [caretPosition]="data.caretPosition"
      triggerOn="hover">
    <xpo-popover-content>
      <div class="pnd-oversizeCellRenderer__alert" *ngIf="data?.alert">
        <xpo-status-indicator
            statusText="EXCEP"
            class="pnd-oversizeCellRenderer__alert--label"
            [statusColor]="XpoColors.XPO_YELLOW_350"
            isLabel="true"></xpo-status-indicator>
        <div>
          <span *ngIf="!isMultipleUnits(data?.alert)" class="pnd-oversizeCellRenderer__alert--main">
            Oversized Handing Unit
          </span>
          <span *ngIf="isMultipleUnits(data?.alert)" class="pnd-oversizeCellRenderer__alert--main">
            Oversized Handing Units
          </span>
          <div class="pnd-oversizeCellRenderer__alert-wrapper">
            <span *ngIf="data?.alert?.pros?.length === 1">PRO: </span>
            <span *ngIf="data?.alert?.pros?.length > 1">PROs: </span>
            <span class="pnd-oversizeCellRenderer__alert-wrapper__link" *ngFor="let pro of data?.alert?.pros">
              <a (click)="onProClicked(pro)">{{
                getProString(pro, (data?.alert?.pros)[data?.alert?.pros.length - 1] === pro)
              }}</a>
            </span>
            <div *ngIf="data?.alert.handlingUnits">
              Dimensions (in.): <span [innerHtml]="data?.alert.handlingUnits"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="pnd-oversizeCellRenderer__alert" *ngIf="data?.handlingUnitAlert?.isPartial">
        <xpo-status-indicator
            statusText="PARTIAL"
            class="pnd-oversizeCellRenderer__alert--label"
            [statusColor]="XpoColors.XPO_YELLOW_350"
            isLabel="true"></xpo-status-indicator>
        <div>
          <span class="pnd-oversizeCellRenderer__alert--main">
            Partial Handing Unit
          </span>
          <div class="pnd-oversizeCellRenderer__alert-wrapper" *ngIf="data?.handlingUnitAlert?.handlingUnitsCount > 0">
            <a xpo-link (click)="openHandlingUnitsDialog($event)">Handling Units</a>
          </div>
        </div>
      </div>
      <div class="pnd-oversizeCellRenderer__alert" *ngIf="data?.handlingUnitAlert?.isSplit">
        <xpo-status-indicator
            statusText="SPLIT"
            class="pnd-oversizeCellRenderer__alert--label"
            [statusColor]="XpoColors.XPO_YELLOW_350"
            isLabel="true"></xpo-status-indicator>

        <div>
          <span class="pnd-oversizeCellRenderer__alert--main">
            Split Handing Unit
          </span>
          <div class="pnd-oversizeCellRenderer__alert-wrapper" *ngIf="data?.handlingUnitAlert?.handlingUnitsCount > 0">
            <a xpo-link (click)="openHandlingUnitsDialog()">Handling Units</a>
          </div>
        </div>
      </div>
      <div class="pnd-oversizeCellRenderer__alert" *ngIf="data?.pastDueAlert?.isPastDue">
        <xpo-status-indicator
            statusText="Past Due"
            class="pnd-oversizeCellRenderer__alert--label"
            [statusColor]="XpoColors.XPO_YELLOW_350"
            isLabel="true"></xpo-status-indicator>

        <div>
          <span class="pnd-oversizeCellRenderer__alert--main">
            Shipment Past Service
          </span>
          <div class="pnd-oversizeCellRenderer__alert--main">Date: {{ data?.pastDueAlert?.dueDate }}</div>
        </div>
      </div>
    </xpo-popover-content>
  </xpo-popover>
</div>
