<ng-container *ngIf="activePanel === tripDetailsPanels.TRIP_DETAILS">
  <div class="pnd-ModifyTripDetails">
    <div class="pnd-ModifyTripDetails__header" data-test="modifyTripDetails-title">
      <div class="pnd-ModifyTripDetails__header-title">
        Update Trip
      </div>
      <ng-container *ngTemplateOutlet="panelActions"></ng-container>
    </div>
    <hr />

    <pnd-modify-trip-details-overview
        [pendingChanges]="pendingChanges.bind(this)"
        [showSaveChangesModal]="showSaveChangesModal.bind(this)">
    </pnd-modify-trip-details-overview>

    <div class="pnd-ModifyTripDetails__body">
      <div class="pnd-ModifyTripDetails__body__details">
        <div class="pnd-ModifyTripDetails__body__details__header">
          <button mat-button xpoSmallButton (click)="toogleShowTripDetailsSection()">
            <xpo-icon *ngIf="!(showTripDetailsSection$ | async)" iconName="add"></xpo-icon>
            <xpo-icon *ngIf="showTripDetailsSection$ | async" iconName="remove"></xpo-icon>
          </button>

          <h1>General Information</h1>
        </div>

        <div class="pnd-ModifyTripDetails__body__details__content" [class.hidden]="!(showTripDetailsSection$ | async)">
          <pnd-progress-spinner [showSpinner$]="showSpinner$">
            <form class="pnd-ModifyTripDetails__body__details__content__form" [formGroup]="formGroup">
              <div class="pnd-ModifyTripDetails__body__details__content__form-row">
                <div class="pnd-ModifyTripDetails__body__details__content__form-row__subRow">
                  <pnd-trip-date-times
                      [formGroup]="formGroup"
                      [sics]="sics$ | async"
                      [dispatchAreas]="dispatchAreas"
                      [estimatedTimes]="estimatedTimes"
                      [formMode]="FormMode.UPDATE"
                      [tripStatus]="tripStatus"></pnd-trip-date-times>

                  <pnd-driver-tractor
                      [formGroup]="formGroup"
                      [tractorsNames]="tractorsNames"
                      [driversNames]="driversNames"
                      [formMode]="FormMode.UPDATE"
                      [previousSavedTractorExist]="previousSavedTractorExist"></pnd-driver-tractor>
                </div>
                <div class="pnd-ModifyTripDetails__body__details__content__form-row__remarks">
                  <pnd-remarks [formGroup]="formGroup" [formMode]="FormMode.UPDATE"></pnd-remarks>
                </div>
              </div>
              <div class="pnd-ModifyTripDetails__body__details__content__form-row">
                <div class="pnd-ModifyTripDetails__body__details__content__form-row__subRow">
                  <pnd-update-route-equipments
                      [formMode]="FormModeEnum.UPDATE"
                      [tripStatus]="tripStatus"
                      [formGroup]="formGroup"
                      [trailersNames]="trailersNames"
                      [dolliesNames]="dolliesNames"
                      [suggestedRouteNames]="suggestedRouteNames"
                      [existingRouteNames]="existingRouteNames"
                      [canAddExistingTrip]="canAddExistingTrip"
                      [routesAndEquipmentSectionTemplate]="routesAndEquipmentSectionTemplate"
                      [tripsSource]="tripsSource"></pnd-update-route-equipments>
                </div>
              </div>
            </form>
          </pnd-progress-spinner>
        </div>
      </div>

      <hr />

      <div class="pnd-ModifyTripDetails__body__routes" *ngIf="!(error$ | async); else error">
        <div class="pnd-ModifyTripDetails__body__routes__header">
          <button mat-button xpoSmallButton (click)="showRoutesSection = !showRoutesSection">
            <xpo-icon *ngIf="!showRoutesSection" iconName="add"></xpo-icon>
            <xpo-icon *ngIf="showRoutesSection" iconName="remove"></xpo-icon>
          </button>
          <h1>Route Details</h1>
        </div>

        <div
            class="pnd-ModifyTripDetails__body__routes__content"
            [class.hidden]="!showRoutesSection"
            *ngIf="{
            routesGridReady: routesGridReady$ | async,
            selectedBoard: selectedBoard$ | async,
            showSpinner: showSpinner$ | async
          } as state">
          <ng-container *ngIf="hideRouteGrid(state)">
            <div class="pnd-ModifyTripDetails__loading-spinner">
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
          </ng-container>

          <div class="pnd-ModifyTripDetails__grids">
            <pnd-modify-trip-details-stops
                class="pnd-ModifyTripDetails__board"
                [class.hidden]="state.selectedBoard !== ModifyTripDetailsBoard.Stops || hideRouteGrid(state)">
            </pnd-modify-trip-details-stops>

            <pnd-modify-trip-details-shipments
                class="pnd-ModifyTripDetails__board"
                [class.hidden]="state.selectedBoard !== ModifyTripDetailsBoard.Shipments || hideRouteGrid(state)">
            </pnd-modify-trip-details-shipments>
          </div>
        </div>
      </div>
    </div>

    <div class="pnd-ModifyTripDetails__footer">
      <hr />
      <div class="pnd-ModifyTripDetails__footer__buttons">
        <div class="pnd-ModifyTripDetails__footer__actions">
          <div>
            <button
                mat-flat-button
                (click)="saveChanges()"
                [disabled]="!formGroup.valid || !formGroup.dirty || (showSpinner$ | async)">
              SAVE CHANGES
            </button>
          </div>

          <div>
            <button
                mat-flat-button
                (click)="returnTrip()"
                *ngIf="tripStatus === TripStatusCd.DISPATCHED"
                [disabled]="isReturnTripButtonDisabled$ | async">
              RETURN
            </button>
          </div>

          <div>
            <button
                mat-flat-button
                (click)="completeTrip()"
                *ngIf="tripStatus === TripStatusCd.RETURNING"
                [disabled]="!formGroup.valid || (isProcessing$ | async) || (showSpinner$ | async)">
              COMPLETE
            </button>
          </div>
          <div
              [matTooltipDisabled]="!(isDispatchButtonEnabled$ | async)"
              [matTooltip]="dispatchTripButtonValidationMessage">
            <button
                mat-flat-button
                (click)="dispatch()"
                *ngIf="tripStatus === TripStatusCd.NEW_TRIP"
                [disabled]="(!isDispatchButtonEnabled$ | async) || (isProcessing$ | async) || (showSpinner$ | async)">
              DISPATCH
            </button>
          </div>
          <div>
            <button
                mat-stroked-button
                (click)="convertToCartage()"
                *ngIf="isConvertToCartageButtonEnabled$ | async"
                [disabled]="(showSpinner$ | async) || (isProcessing$ | async)">
              {{ convertToCartageButtonText }}
            </button>
          </div>
        </div>
        <div class="pnd-ModifyTripDetails__footer__actions">
          <button
              mat-stroked-button
              (click)="delete()"
              [disabled]="(isDeleteButtonDisabled$ | async) || (isProcessing$ | async)"
              *ngIf="isDeleteButtonVisible$ | async"
              [matTooltipDisabled]="!(isDeleteButtonDisabled$ | async)"
              matToolTip="Trip has completed stops">
            DELETE
          </button>

          <button mat-stroked-button (click)="close(true)" [disabled]="showSpinner$ | async">
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #panelActions>
    <div class="pnd-ModifyTripDetails__actions">
      <pnd-trip-navigation
          [pendingChanges]="pendingChanges.bind(this)"
          [showSaveChangesModal]="showSaveChangesModal.bind(this)"
          [shouldReload]="shouldReload"
          class="pnd-ModifyTripDetails__actions__item"></pnd-trip-navigation>

      <button
          mat-button
          class="pnd-ModifyTripDetails__actions__item pnd-ModifyTripDetails__actions__button_color"
          (click)="showBreadcrumbs()"
          [matTooltip]="breadcrumbsText"
          [disabled]="showSpinner$ | async"
          *ngIf="breadcrumbsButtonVisible$ | async"
          [ngClass]="{
          'pnd-ModifyTripDetails__actions__button_color': breadcrumbsText === BreadcrumbsTooltipText.HIDE_BREADCRUMBS
        }">
        <xpo-icon iconName="marker-path"></xpo-icon>
      </button>
      <button
          mat-button
          class="pnd-ModifyTripDetails__actions__item"
          (click)="onSendMessage($event)"
          matTooltip="Driver Messages"
          *ngIf="messageSate$ | async"
          [disabled]="showSpinner$ | async">
        <xpo-icon [iconName]="driverMessageIcon$ | async"></xpo-icon>
      </button>

      <button
          *ngFor="let headerAction of headerActions$ | async"
          class="pnd-ModifyTripDetails__actions__item"
          matTooltipPosition="above"
          mat-icon-button
          [matTooltip]="headerAction.tooltip"
          [disabled]="headerAction.disabled$ | async"
          (click)="headerAction.callbackFn()">
        <mat-icon>{{ headerAction.iconName }}</mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #error>
    <!-- <xpo-empty-state class="pnd-ModifyTripDetails__empty-state">
      <xpo-empty-state-icon>
        <mat-icon>insert_drive_file</mat-icon>
      </xpo-empty-state-icon>
      <xpo-empty-state-header>No trip data.</xpo-empty-state-header>
    </xpo-empty-state> -->
  </ng-template>
</ng-container>

<ng-container *ngIf="activePanel === tripDetailsPanels.ASSIGN_DROP">
  <pnd-assign-drop-location></pnd-assign-drop-location>
</ng-container>
