<div class="pnd-refresh-notification">
  <mat-icon class="pnd-refresh-notification-icon">info</mat-icon>
  <p class="pnd-refresh-notification-text">
    <span data-test="pnd-refresh-notification__last-update-time">
      Last updated on {{ lastUpdateTimeDisplay$ | async }}.
    </span>
    <span *ngIf="numberOfItemsChanged$ | async as itemsChanged" data-test="pnd-refresh-notification__number-changed">
      <span *ngIf="!hideNumberItemsChanged; else showUnknownItemsChanged">
        Since then <b>{{ itemsChanged }}</b> {{ itemsChanged | i18nPlural: PluralMaps.items }} changed for this section.
      </span>
    </span>
  </p>
  <a
    *ngIf="showRefresh$ | async"
    class="pnd-refresh-notification-action"
    (click)="onRefreshIntent()"
    data-test="pnd-refresh-notification__refresh-button"
    >Refresh</a
  >
</div>

<ng-template #showUnknownItemsChanged>
  Items changed for this section.
</ng-template>
