import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import {
  CityOperationsApiService,
  ListPnDRoutesPath,
  ListPnDRoutesQuery,
  ListPnDUnassignedStopsResp,
  Route,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import {
  filter as _filter,
  forEach as _forEach,
  has as _has,
  isEmpty as _isEmpty,
  set as _set,
  size as _size,
  some as _some,
} from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  concatMapTo,
  map,
  pluck,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  EventItem,
  PlanningRouteShipmentIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { PlanningRoutesCacheService } from '../../inbound-planning/shared/services/planning-routes-cache.service';
import { GlobalFilterStoreSelectors } from '../global-filters-store';
import * as PndStoreState from '../pnd-store.state';
import { RoutesSearchCriteria } from './routes-search-criteria.interface';
import {
  ActionTypes,
  RefreshPlanningRoutes,
  SetPlanningRoutesLastUpdate,
  SetPlanningRoutesLoading,
  SetSearchCriteria,
  SetSelectedPlanningRoutesAction,
  SetSelectedPlanningRoutesShipmentsAction,
  SetStopsForSelectedPlanningRoutesLastUpdate,
  SetStopsForSelectedPlanningRoutes,
} from './routes-store.actions';
import * as RoutesStoreSelectors from './routes-store.selectors';

@Injectable()
export class RoutesStoreEffects {
  constructor(
    private actions$: Actions,
    private cityOpsService: CityOperationsApiService,
    private store$: PndStore<PndStoreState.State>,
    private planningRoutesCacheService: PlanningRoutesCacheService
  ) {}

  // #region Planning Routes
  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new RefreshPlanningRoutes()])
  );

  @Effect()
  refreshPlanningRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<RefreshPlanningRoutes>(ActionTypes.refreshPlanningRoutes),
    tap(() => this.store$.dispatch(new SetPlanningRoutesLoading({ loading: true }))),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.searchCriteria)),
    concatMap(([_, criteria]) => this.fetchPlanningRoutes(criteria)),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.selectedPlanningRoutes)),
    concatMap(([routes, currentSelectedRoutes]) => {
      const selPlanningRoutes = _filter(currentSelectedRoutes, (curSelRouteInstId) => {
        return _some(routes, (route) => curSelRouteInstId === route.routeInstId);
      });
      this.planningRoutesCacheService.setPlanningRoutes(routes);
      return [
        new SetPlanningRoutesLastUpdate({ planningRoutesLastUpdate: new Date() }),
        new SetSelectedPlanningRoutesAction({ selectedPlanningRoutes: selPlanningRoutes }),
        new SetPlanningRoutesLoading({ loading: false }),
      ];
    }),
    catchError(() => {
      // error loading routes, so clear existing ones
      this.planningRoutesCacheService.setPlanningRoutes([]);
      return [
        new SetPlanningRoutesLastUpdate({ planningRoutesLastUpdate: new Date() }),
        new SetSelectedPlanningRoutesAction({ selectedPlanningRoutes: [] }),
        new SetPlanningRoutesLoading({ loading: false }),
      ];
    })
  );

  @Effect()
  setSelectedPlanningRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelectedPlanningRoutesAction>(ActionTypes.setSelectedPlanningRoutes),
    map((action) => action.payload.selectedPlanningRoutes),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.selectedPlanningRoutesShipments)),
    switchMap(([selPlanningRoutes, selectedShipments]: [number[], EventItem<PlanningRouteShipmentIdentifier>[]]) => {
      if (_size(selPlanningRoutes) === 0) {
        // No selected planning routes, so short-circuit to clear stops
        this.planningRoutesCacheService.setStopsForSelectedPlanningRoutes({});
        return [
          new SetStopsForSelectedPlanningRoutesLastUpdate({ stopsForSelectedPlanningRoutesLastUpdate: new Date() }),
          new SetSelectedPlanningRoutesShipmentsAction({ selectedPlanningRoutesShipments: [] }),
          new SetStopsForSelectedPlanningRoutes({
            stopsForSelectedPlanningRoutes: [],
          }),
        ];
      } else {
        // get all of the Stops for all of the selected Planning Routes
        this.planningRoutesCacheService.updateLoadingStops(true);
        return forkJoin(selPlanningRoutes.map((routeInstId) => this.fetchUnassignedStopsForRoute(routeInstId))).pipe(
          takeUntil(this.actions$.pipe(ofType<SetSelectedPlanningRoutesAction>(ActionTypes.setSelectedPlanningRoutes))),
          map((originalResults: { routeInstId: number; stops: UnassignedStop[] }[]) => {
            const stopsForRoutes = {};
            _forEach(originalResults, (value) => {
              _set(stopsForRoutes, value.routeInstId, value.stops);
            });
            return { originalResults, stopsForRoutes };
          }),
          switchMap((stopsForSelectedPlanningRoutes) => {
            this.planningRoutesCacheService.setStopsForSelectedPlanningRoutes(
              stopsForSelectedPlanningRoutes.stopsForRoutes
            );
            // determine if any of the selected Shipments is no longer in the selected Routes list
            const originalShipmentCount = _size(selectedShipments);
            const newSelectedShipments = _filter(
              selectedShipments,
              (selectedShipment: PlanningRouteShipmentIdentifier) => {
                return _has(stopsForSelectedPlanningRoutes.stopsForRoutes, selectedShipment.routeInstId);
              }
            ) as EventItem<PlanningRouteShipmentIdentifier>[];

            const lastUpdateAction = new SetStopsForSelectedPlanningRoutesLastUpdate({
              stopsForSelectedPlanningRoutesLastUpdate: new Date(),
            });
            this.planningRoutesCacheService.updateLoadingStops(false);
            if (originalShipmentCount !== _size(newSelectedShipments)) {
              // remove selected shipments that are no longer part of the selected Routes
              return [
                lastUpdateAction,
                new SetSelectedPlanningRoutesShipmentsAction({ selectedPlanningRoutesShipments: newSelectedShipments }),
                new SetStopsForSelectedPlanningRoutes({
                  stopsForSelectedPlanningRoutes: stopsForSelectedPlanningRoutes.originalResults,
                }),
              ];
            } else {
              return [
                lastUpdateAction,
                new SetStopsForSelectedPlanningRoutes({
                  stopsForSelectedPlanningRoutes: stopsForSelectedPlanningRoutes.originalResults,
                }),
              ];
            }
          })
        );
      }
    }),
    catchError(() => {
      this.planningRoutesCacheService.updateLoadingStops(false);
      this.planningRoutesCacheService.setStopsForSelectedPlanningRoutes({});
      return [
        new SetStopsForSelectedPlanningRoutesLastUpdate({ stopsForSelectedPlanningRoutesLastUpdate: new Date() }),
        new SetSelectedPlanningRoutesShipmentsAction({ selectedPlanningRoutesShipments: [] }),
        new SetStopsForSelectedPlanningRoutes({ stopsForSelectedPlanningRoutes: [] }),
      ];
    })
  );

  // #endregion

  // Utility methods

  // return mapping of stops for this routeInstId
  private fetchUnassignedStopsForRoute(
    routeInstId: number
  ): Observable<{ routeInstId: number; stops: UnassignedStop[] }> {
    return this.store$.select(GlobalFilterStoreSelectors.geoFilterArea).pipe(
      take(1),
      switchMap((boundingAreas) => {
        return this.planningRoutesCacheService.searchPlanningRouteShipments({
          routeInstId: `${routeInstId}`,
          hostDestSicCd: undefined,
          consigneeGeoCoordinatesGeo: !_isEmpty(boundingAreas) ? boundingAreas : undefined,
        });
      }),
      map((value: ListPnDUnassignedStopsResp) => {
        return {
          routeInstId,
          stops: value.unassignedStops,
        };
      })
    );
  }

  private fetchPlanningRoutes(criteria: RoutesSearchCriteria): Observable<Route[]> {
    if (_isEmpty(criteria.sicCd)) {
      return of([]);
    }

    const pathParams: ListPnDRoutesPath = {
      sicCd: criteria.sicCd,
    };
    const queryParams: ListPnDRoutesQuery = {
      planDate: criteria.planDate,
      zoneInd: criteria.zoneInd,
      plannerInd: criteria.plannerInd,
      newReleaseInd: criteria.newReleaseInd,
      categoryCd: criteria.categoryCd,
      statusCd: criteria.statusCd,
      specialServices: criteria.specialServices,
    };
    return this.cityOpsService.listPnDRoutes(pathParams, queryParams).pipe(
      catchError((err) => of([])),
      takeUntil(this.actions$.pipe(ofType<RefreshPlanningRoutes>(ActionTypes.refreshPlanningRoutes))),
      pluck('routes')
    );
  }
}
