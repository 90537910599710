import { Component, ViewEncapsulation, Input, ViewChild, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Metric } from '@xpo-ltl/sdk-cityoperations';
import { MetricValueKeyCd, MetricVarianceCd } from '@xpo-ltl/sdk-common';
import GoldenLayout from 'golden-layout';
import { take } from 'rxjs/operators';
import { LayoutManagerService, LayoutPreferenceService } from 'shared/layout-manager';
import { MetricsUtils } from '../../classes/metrics-utils';
import { SkeletonView } from '../../classes/skeleton-view';

@Component({
  selector: 'pnd-metric-bar',
  templateUrl: 'metric-bar.component.html',
  styleUrls: ['metric-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricBarComponent implements OnInit {
  private _metrics: Metric[];
  displaySkeletonViews: boolean;

  metricsForOverview: MetricValueKeyCd[] = [
    MetricValueKeyCd.DELIVERY_PPMH,
    MetricValueKeyCd.DELIVERY_WEIGHT_PER_TRIP,
    MetricValueKeyCd.DELIVERY_COST_PER_TRIP,
  ];

  skeletonMetrics = Array(6).fill(new SkeletonView());
  @ViewChild(MatExpansionPanel, { static: false }) panel: MatExpansionPanel;
  private defaultLayoutHeigh: number = 0;
  private defaultCurrentLayout: string;
  private layoutManager: GoldenLayout.ContentItem['layoutManager'];
  private anyMaximizedItem: GoldenLayout.ContentItem;
  panelTitle = 'Expand';
  @Input()
  set metrics(value: Metric[]) {
    this._metrics = value;
    this.displaySkeletonViews = !this.metrics;
  }

  get metrics(): Metric[] {
    return this._metrics;
  }

  constructor(
    private layoutManagerService: LayoutManagerService,
    private layoutPreferenceService: LayoutPreferenceService
  ) {}

  ngOnInit(): void {
    this.subscribeToLayoutChanges();
  }

  getVarianceIcon(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceIcon(variance);
  }

  getVarianceClassColor(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceClassColor(variance);
  }

  subscribeToLayoutChanges() {
    this.layoutPreferenceService.activeLayout$.subscribe((layout) => {
      if (!this.defaultCurrentLayout) {
        this.defaultCurrentLayout = layout.name;
      } else if (this.defaultCurrentLayout !== layout.name) {
        this.defaultCurrentLayout = layout.name;
        this.panel.close();
        this.onToggle();
      }
    });

    this.layoutManagerService.panelActivated$.subscribe((res) => {
      if (res && this.panel.expanded) {
        this.onPanelChangeWhileExpand();
      }
    });
  }

  onToggle() {
    this.anyMaximizedItem = this.layoutManagerService.findMaximisedStack();
    this.layoutManager = this.layoutManagerService.getOpenedPanels()[0].layoutManager;
    this.defaultLayoutHeigh = this.defaultLayoutHeigh === 0 ? this.layoutManager.height : this.defaultLayoutHeigh;
    this.panel._bodyAnimationDone.pipe(take(1)).subscribe(() => {
      if (this.panel.expanded) {
        this.panelTitle = 'Collapse';
        this.layoutManager.updateSize(
          this.layoutManager.width,
          this.defaultLayoutHeigh - this.panel._body.nativeElement.offsetHeight
        );
        if (this.anyMaximizedItem) {
          this.anyMaximizedItem
            .getActiveContentItem()
            ['container'].getElement()
            .removeAttr('style')
            .attr(
              'style',
              `width:${this.layoutManager.width}px; height:${this.defaultLayoutHeigh -
                this.panel._body.nativeElement.offsetHeight -
                14}px`
            );
        }
      } else {
        this.layoutManager.updateSize(
          this.layoutManager.width,
          this.defaultLayoutHeigh + this.panel._body.nativeElement.offsetHeight
        );
        if (this.anyMaximizedItem) {
          this.anyMaximizedItem
            .getActiveContentItem()
            ['container'].getElement()
            .removeAttr('style')
            .attr(
              'style',
              `width:${this.layoutManager.width}px; height:${this.defaultLayoutHeigh +
                this.panel._body.nativeElement.offsetHeight -
                14}px`
            );
        }
        this.panelTitle = 'Expand';
      }
    });
  }

  onPanelChangeWhileExpand() {
    this.layoutManager.updateSize(
      this.layoutManager.width,
      this.defaultLayoutHeigh - this.panel._body.nativeElement.offsetHeight
    );
    if (this.anyMaximizedItem) {
      this.anyMaximizedItem
        .getActiveContentItem()
        ['container'].getElement()
        .removeAttr('style')
        .attr(
          'style',
          `width:${this.layoutManager.width}px; height:${this.defaultLayoutHeigh -
            this.panel._body.nativeElement.offsetHeight -
            14}px`
        );
    }
  }
}
