import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { head as _head } from 'lodash';
import { Observable } from 'rxjs';
import { map, skip, startWith, takeUntil } from 'rxjs/operators';
import { ComponentChangeUtils } from '../../classes/component-change-utils';
import { AutocompleteValidationKeys } from '../../enums/autocomplete-validation-keys.enum';
@Component({
  selector: 'autocomplete-editor',
  templateUrl: './autocomplete-editor.component.html',
  styleUrls: ['./autocomplete-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor__autocomplete-editor',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnInit {
  protected unsubscriber = new Unsubscriber();

  @ViewChild('input', { static: true, read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  params: any;
  maxLength: number;
  filterValues$: Observable<string[]>;
  formControl = new UntypedFormControl();
  allowCustomValue = false;
  allowEmptyValue: boolean;
  regExp: string | RegExp;
  populateValueOntab: boolean = false;

  get inputFieldElement() {
    return this.autoTrigger?.['_element']?.nativeElement;
  }

  protected values: string[] = [];

  ngAfterViewInit() {
    this.autoTrigger.panelClosingActions.pipe(skip(1), takeUntil(this.unsubscriber.done$)).subscribe(() => {
      const currentValue = this.formControl.value;

      if (this.autoTrigger.activeOption) {
        const value = this.autoTrigger.activeOption.value;
        this.formControl.setValue(value);
      } else {
        const filterValues = this.filterMethod(currentValue);
        if (filterValues.length > 0) {
          this.formControl.setValue(_head(filterValues));
        } else {
          this.formControl.setValue('');
        }
      }
      ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.inputFieldElement.focus();
    }, 200);
    this.params.options$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((values: string[]) => {
      this.values = this.allowEmptyValue ? ['', ...values] : values;
      this.formControl.updateValueAndValidity();
      ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    });

    this.filterValues$ = this.formControl.valueChanges?.pipe(startWith(''), map(this.filterMethod.bind(this)));
  }

  filterMethod(value: string): string[] {
    if (this.values) {
      return this.values.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
    } else {
      return [];
    }
  }

  agInit(params): void {
    this.params = params;
    this.formControl.setValue(this.params.value);
    this.maxLength = this.params.maxLength;
    this.allowCustomValue = this.params.allowCustomValue;
    this.allowEmptyValue = this.params.allowEmptyValue ?? true;
    this.regExp = this.params.regExp;
    this.populateValueOntab = this.params.populateValueOntab ?? false;
  }

  getValue(): string {
    return this.formControl.value;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (!this.isEventKeyValid(event.key) && this.regExp && !this.isKeyValid(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  isCancelAfterEnd(): boolean {
    const currentValue = this.getValue();

    if (!this.populateValueOntab) {
      const isCustomValue = !this.values.some((option) => option.toLowerCase() === currentValue.toLocaleLowerCase());
      return (
        (currentValue && currentValue.toString().length > this.maxLength) ||
        (isCustomValue && !this.allowCustomValue) ||
        (!currentValue && !this.allowEmptyValue)
      );
    } else {
      const filterValues = this.filterMethod(currentValue);
      if (filterValues.length > 0) {
        this.formControl.setValue(_head(filterValues));
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        return false;
      }
      return true;
    }
  }

  onFocusIn(): void {
    this.inputFieldElement.focus();
  }

  isKeyValid(key: string): boolean {
    return RegExp(this.regExp).test(key);
  }

  private isEventKeyValid(key: string): boolean {
    return (
      key === AutocompleteValidationKeys.TAB ||
      key === AutocompleteValidationKeys.BACKSPACE ||
      key === AutocompleteValidationKeys.DELETE
    );
  }
}
