import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ModifyTripActivityId } from '@pnd-store/modify-trip-details-store/modify-trip-details.state';
import { PlanningProfileInterface } from '@pnd-store/planning-profiles-store/planning-profile.interface';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlDocType, XpoLtlDocumentService } from '@xpo-ltl/ngx-ltl';
import {
  XpoLtlShipmentDescriptor,
  XpoLtlShipmentDetailsService,
  XpoLtlShipmentDetailTabCd,
} from '@xpo-ltl/ngx-ltl-shipment-details';
import {
  BillOfLadingApiService,
  GenerateBillOfLadingPdfQuery,
  GenerateBillOfLadingPdfResp,
} from '@xpo-ltl/sdk-billoflading';
import { TripNode } from '@xpo-ltl/sdk-cityoperations';
import { GetDocumentResp, SearchDmsDocumentResp } from '@xpo-ltl/sdk-documentmanagement';
import { size as _size } from 'lodash';
import { Observable, Subject, throwError } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { decode } from 'typescript-base64-arraybuffer';

import { PrintDialogComponent } from 'core/dialogs/print-dialog/print-dialog.component';
import { PrintDialogData } from 'core/dialogs/print-dialog/print-dialog.data';
import { EventItem, AssignedStopIdentifier } from '../../app/inbound-planning/shared';

import { PdfViewerComponent } from '../../app/inbound-planning/shared/components/pdf-viewer/pdf-viewer.component';
import { StopMapComponent } from '../../app/inbound-planning/shared/components/stop-map/stop-map.component';
import { ConfigManagerProperties } from '../../core/enums';
import { ConfirmCancelAction } from './confirm-cancel/confirm-cancel-action.enum';
import { ConfirmCancelData } from './confirm-cancel/confirm-cancel-data';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { ConfirmMergeDialogData, ConfirmMergeComponent } from './confirm-merge/confirm-merge.component';
import { ConfirmProfileComponent, ConfirmProfileDialogData } from './confirm-profile/confirm-profile.component';
import { ConfirmSplitDialogData, ConfirmSplitComponent } from './confirm-split/confirm-split.component';
import { PrintPreferenceDialogComponent } from './print-preference-dialog/print-preference-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PndDialogService {
  private _lastStopMapDialogRef: MatDialogRef<StopMapComponent> = undefined;

  constructor(
    private billOfLadingApiService: BillOfLadingApiService,
    private configService: ConfigManagerService,
    private dialog: MatDialog,
    private xpoLtlShipmentDetailsService: XpoLtlShipmentDetailsService,
    private xpoLtlDocumentService: XpoLtlDocumentService
  ) {}

  /**
   * Basic confirmation dialog
   */
  showConfirmCancelDialog(
    title: string,
    message: string,
    cancelButtonText: string = 'Cancel',
    confirmButtonText: string = 'Ok',
    altActionButtonText: string = '',
    config: any = {}
  ): Observable<boolean> {
    const subject = new Subject<boolean>();
    const data = new ConfirmCancelData(
      title,
      message,
      _size(cancelButtonText) > 0 ? cancelButtonText : undefined,
      _size(confirmButtonText) > 0 ? confirmButtonText : undefined,
      _size(altActionButtonText) > 0 ? altActionButtonText : undefined
    );
    const dialogRef = this.dialog.open(ConfirmCancelComponent, {
      data,
      disableClose: true,
      panelClass: 'confirm-cancel-dialog',
      ...config,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: ConfirmCancelAction) => {
        subject.next(result === ConfirmCancelAction.CONFIRM);
        subject.complete();
      });

    return subject.asObservable();
  }

  /**
   * Display dialog contianing the ShipmentDetails
   * @param shipmentInstId the shipment to display
   */
  showShipmentDetailsDialog(
    shipmentDescriptors: XpoLtlShipmentDescriptor | XpoLtlShipmentDescriptor[]
  ): Observable<void> {
    if (this.dialog) {
      this.dialog.closeAll();
    }

    return this.xpoLtlShipmentDetailsService.showShipmentDetailsDialog(
      shipmentDescriptors,
      {
        moveable: true,
        componentConfig: {
          suppressCopyPRO: false,
          tabs: [
            XpoLtlShipmentDetailTabCd.APPOINTMENTS,
            XpoLtlShipmentDetailTabCd.DOCUMENTS,
            XpoLtlShipmentDetailTabCd.EDI,
            XpoLtlShipmentDetailTabCd.CLAIMS,
            XpoLtlShipmentDetailTabCd.DISPUTES,
            XpoLtlShipmentDetailTabCd.EXCEPTIONS,
            XpoLtlShipmentDetailTabCd.INVOICING,
            XpoLtlShipmentDetailTabCd.NOTES,
          ],
        },
        showShipmentSelector: _size(shipmentDescriptors) > 1,
      },
      {
        width: '1290px',
        maxHeight: '1200px',
      }
    );
  }

  /**
   * Display stop in Map Satellite View
   */
  showCustomerInMapSatelliteView(name: string, latitude: number, longitude: number): Observable<void> {
    const dialogClosed = new Subject<void>();

    if (this._lastStopMapDialogRef) {
      this._lastStopMapDialogRef.close();
    }

    this._lastStopMapDialogRef = this.dialog.open(StopMapComponent, {
      data: {
        name,
        latitude,
        longitude,
      },
      hasBackdrop: false,
    });

    this._lastStopMapDialogRef
      .keydownEvents()
      .pipe(takeUntil(dialogClosed))
      .subscribe((e: KeyboardEvent) => {
        if (e && e.code === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          this._lastStopMapDialogRef.close();
        }
      });

    this._lastStopMapDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        dialogClosed.next();
        dialogClosed.complete();
      });

    return dialogClosed.asObservable();
  }

  /**
   * Returns eBOL pdf document from Bill Of Lading API
   * @param bolInstId
   *
   */
  private getEBOLContent$(bolInstId: number): Observable<Uint8Array[]> {
    const query = {
      ...new GenerateBillOfLadingPdfQuery(),
      bolInstId: [`${bolInstId}`],
    };

    return this.billOfLadingApiService.generateBillOfLadingPdf(query).pipe(
      take(1),
      map((bol: GenerateBillOfLadingPdfResp) => [decode(bol?.fileContents?.fileContents ?? '')])
    );
  }

  /**
   * Given a proNbr, return the latest of the associated BOL pdf documents
   * @param proNbr
   * @param accessToken
   *
   */
  private getBOLContent$(proNbr: string): Observable<Uint8Array[]> {
    return this.xpoLtlDocumentService.listAvailableDocuments(proNbr).pipe(
      switchMap((documentResults: SearchDmsDocumentResp) => {
        const blResults = documentResults.documentInfo
          .filter((info) => info?.cdt?.docClass === XpoLtlDocType.BillOfLading)
          .sort((a, b) => -a?.timestampISO.localeCompare(b?.timestampISO));
        if (_size(blResults) > 0) {
          return this.xpoLtlDocumentService.getDocument(
            blResults[0].cdt.timestamp,
            XpoLtlDocType.BillOfLading,
            this.configService.getSetting<string>(ConfigManagerProperties.imageCorpCode),
            'pdf'
          );
        } else {
          return throwError({
            code: 404,
            error: {
              errorCode: '',
              message: 'No Documents found',
            },
          });
        }
      }),
      map((doc: GetDocumentResp) => {
        const uint8Arrays: Uint8Array[] = [];
        uint8Arrays.push(decode(doc.documentData));
        return uint8Arrays;
      })
    );
  }

  /**
   * Display eBOL dialog
   */
  showEBoLDialog(bolInstId: number): void {
    if (!!bolInstId) {
      this.dialog.open(PdfViewerComponent, {
        data: {
          retrieveContent$: this.getEBOLContent$(bolInstId),
        },
      });
    }
  }

  /**
   * Display BOL document image
   */
  showBoLDialog(proNbr: string): void {
    if (!!proNbr) {
      this.dialog.open(PdfViewerComponent, {
        data: {
          title: 'Bill of Lading',
          retrieveContent$: this.getBOLContent$(proNbr),
        },
      });
    }
  }

  /**
   * Display Print FBDS dialog
   */

  showPrintDialog(dialogData: PrintDialogData, afterPrintAction?: () => void): void {
    const dialogRef = this.dialog.open(PrintDialogComponent, {
      data: dialogData,
      disableClose: false,
      hasBackdrop: true,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        (actionPerformed) => {
          if (afterPrintAction && actionPerformed) {
            afterPrintAction();
          }
        },
        (error) => {}
      );
  }

  showPrintPreferenceDialog(sicCd: string): void {
    if (!!sicCd) {
      this.dialog.open(PrintPreferenceDialogComponent, {
        data: {
          sicCd: sicCd,
        },
      });
    }
  }

  showConfirmProfileDialog(input: PlanningProfileInterface[]): Observable<PlanningProfileInterface> {
    const subject = new Subject<PlanningProfileInterface>();
    const data = new ConfirmProfileDialogData(input);
    const dialogRef = this.dialog.open(ConfirmProfileComponent, {
      data,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result?.apply === true) {
          subject.next(result?.value);
        } else {
          subject.next(undefined);
        }
        subject.complete();
      });

    return subject.asObservable();
  }

  showConfirmMergeDialog(input: EventItem<AssignedStopIdentifier>[]): Observable<EventItem<AssignedStopIdentifier>> {
    const subject = new Subject<EventItem<AssignedStopIdentifier>>();
    const data = new ConfirmMergeDialogData(input);
    const dialogRef = this.dialog.open(ConfirmMergeComponent, {
      data,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        subject.next(result?.value);
        subject.complete();
      });

    return subject.asObservable();
  }

  showConfirmSplitDialog(input: ModifyTripActivityId[]): Observable<TripNode> {
    const subject = new Subject<TripNode>();
    const data = new ConfirmSplitDialogData(input);
    const dialogRef = this.dialog.open(ConfirmSplitComponent, {
      data,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        subject.next(result?.value);
        subject.complete();
      });

    return subject.asObservable();
  }
}
