import { padStart as _padStart } from 'lodash';
import moment from 'moment-timezone';

export class TimeOffset {
  static getOffsetFromTimezone(timezone: string): string {
    let offset = moment()
      .tz(timezone)
      .utcOffset();

    const sign = offset < 0 ? '-' : '+';
    if (offset < 0) {
      offset *= -1;
    }
    const hours = _padStart(`${offset / 60}`, 2, '0');
    const minutes = _padStart(`${offset % 60}`, 2, '0');

    offset = +`${sign}${hours}${minutes}`;

    return offset.toString();
  }
}
