<div class="pnd-GridToolbar">
  <ng-container *ngIf="availableViews$ | async">
    <pnd-grid-toolbar-view-manager
      data-test="pnd-grid-toolbar-view-manager"
      [hidden]="!(showToolbarOptions$ | async)"
      [views$]="availableViews$"
      (viewSelected)="handleViewSelected($event)"
    ></pnd-grid-toolbar-view-manager>
  </ng-container>
  <div class="pnd-GridToolbar__actions">
    <div class="pnd-GridToolbar__actions--left">
      <button
        *ngIf="extendedToolbarActions?.length && showToolbarOptions$ | async"
        class="pnd-GridToolbar__actions--menu-btn"
        mat-button
        xpoSmallButton
        data-test="pnd-GridToolbarMoreMenuBtn"
        [matMenuTriggerFor]="filterMenu"
      >
        More <xpo-icon iconName="chevron-bottom"></xpo-icon>
      </button>
      <ng-content select="[leftActions]"></ng-content>
      <div *ngFor="let config of visibleToolbarActions">
        <button
          *ngIf="config?.visible$ | async"
          mat-button
          xpoSmallButton
          [attr.data-test]="config?.dataTestAttr"
          [disabled]="config?.disabled$ | async"
          (click)="config?.callbackFn()"
        >
          <ng-container *ngIf="config?.iconName">
            <mat-icon class="pnd-GridToolbar__actions--icon" *ngIf="config?.isMatIcon">{{ config?.iconName }}</mat-icon>
            <xpo-icon
              class="pnd-GridToolbar__actions--icon"
              *ngIf="!config?.isMatIcon"
              [iconName]="config?.iconName"
            ></xpo-icon>
          </ng-container>
          <span class="pnd-GridToolbar__actions--label">{{ config?.actionLabel }}</span>
        </button>
      </div>

      <mat-menu class="pnd-GridToolbar__actions--menu" data-test="shipmentListGridMenu" #filterMenu="matMenu">
        <button
          *ngFor="let config of extendedToolbarActions"
          class="pnd-GridToolbar__actions--menu-item"
          mat-menu-item
          [disabled]="config?.disabled$ | async"
          [attr.data-test]="config?.dataTestAttr"
          (click)="config?.callbackFn()"
        >
          {{ config?.actionLabel }}
        </button>
      </mat-menu>
    </div>
    <div class="pnd-GridToolbar__actions--right">
      <ng-content select="[rightActions]"></ng-content>
    </div>
  </div>
  <ng-container *ngIf="!gridToolbarOptions?.suppressSearch">
    <pnd-grid-toolbar-search
      [searchDisplayText]="gridToolbarOptions?.searchDisplayText"
      [searchCriteriaKey]="gridToolbarOptions?.searchCriteriaKey"
      [boardState$]="boardState$ | async"
    ></pnd-grid-toolbar-search>
  </ng-container>
</div>
