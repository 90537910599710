import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Unsubscriber, XpoLtlFeaturesService, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  ListPnDPositioningPointsPath,
  ListPnDPositioningPointsQuery,
  ListPnDPositioningPointsResp,
  PositioningPoint,
  Route,
} from '@xpo-ltl/sdk-cityoperations';
import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import { MapLayerType } from 'app/inbound-planning/shared/enums/map-layer-type.enum';
import { NotificationMessageService, NotificationMessageStatus } from 'core';
import { FeatureTypes } from 'core/services/features/feature-types.enum';
import { isEqual as _isEqual, size as _size } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { PndZoneUtils } from '../../../../../../shared/zone-utils';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../../store';
import { PndStore } from '../../../../../store/pnd-store';
import { RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { ComponentChangeUtils } from '../../../../shared/classes/component-change-utils';
import { SicZonesAndSatellites } from '../../../../shared/models/sic-zones-and-satellites.model';
import { MapToolbarService } from '../../../../shared/services/map-toolbar.service';
import { PickupClusterPointsService } from '../../../../shared/services/pickup-cluster-points-service/pickup-cluster-points.service';
import {
  PickupClusterContextMenuComponent,
  PickupClusterContextMenuItem,
} from '../../components/pickup-cluster-context-menu/pickup-cluster-context-menu.component';
import { ContextMenuItemId } from '../../enums/contextual-menu-item.enum';
import { MapMarker } from './../../../../shared/models/markers/map-marker';
import { MapMarkersService } from './../../../../shared/services/map-markers.service';

export interface PickupClusterPoint {
  pointData: PositioningPoint;
  infoWindowPos: google.maps.LatLngLiteral;
  openInfoWindow: boolean;
  radius: number;
  strokeColor?: string;
  fillColor?: string;
  strokeWeight?: number;
  opacity?: number;
  zIndex?: number;
  pickupClusterMarker?: MapMarker;
  assignedRoutes?: Route[];
  isClusterCardHover?: boolean;
}

// Extending NodeTypeCd enum with a new value for PickupCluster
export enum PickupClusterNodeTypeCd {
  PICKUP_CLUSTER = 'PickupCluster',
}

@Component({
  selector: 'pickup-positioning-points-layer',
  templateUrl: './pickup-positioning-points-layer.component.html',
  styleUrls: ['./pickup-positioning-points-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupPositioningPointsLayerComponent implements OnInit, OnDestroy {
  @Input() googleMap: any;
  @ViewChild(PickupClusterContextMenuComponent) contextMenu: PickupClusterContextMenuComponent;

  pickupClustersRoutesMap: Map<number, Route[]>;

  private unsubscriber: Unsubscriber = new Unsubscriber();
  private isRouteBalancerActive: boolean = false;
  pickupClusterPoints: PickupClusterPoint[];

  private readonly isLastBestStopFeatureActiveSubject = new BehaviorSubject(false);
  readonly isLastBestStopFeatureActive$ = this.isLastBestStopFeatureActiveSubject.asObservable();
  private preserveUserSelection: boolean = false;
  isLayerVisible$: Observable<boolean> = this.mapToolbarService.isPickupClustersLayerActive$;
  zIndex = MapMarkersService.layerMarkerZIndex[MapLayerType.PICKUP_CLUSTER]();
  contextMenuItems: PickupClusterContextMenuItem[] = [
    {
      id: ContextMenuItemId.PIN_LAST_TO_ROUTE,
      label: 'Pin Last To Route...',
      nested: true,
      triggerFor: 'routesMenu',
    },
    {
      id: ContextMenuItemId.REMOVE_FROM_ROUTE,
      label: 'Remove...',
      nested: true,
      triggerFor: 'removeMenu',
    },
  ];

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private cityOperationsService: CityOperationsApiService,
    private mapToolbarService: MapToolbarService,
    private changeRef: ChangeDetectorRef,
    private notificationMessageService: NotificationMessageService,
    private pickupClusterPointsService: PickupClusterPointsService,
    private markersService: MapMarkersService,
    private featuresService: XpoLtlFeaturesService,
    private timeService: XpoLtlTimeService
  ) {}

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  ngOnInit() {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(
        distinctUntilChanged(),
        map((sic: string) => {
          const featureValue = this.featuresService.getFeatureValue(sic, FeatureTypes.LastBestStop);
          return featureValue === 'Y';
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((enable: boolean) => this.isLastBestStopFeatureActiveSubject.next(enable));

    this.isLayerVisible$
      .pipe(
        filter((isVisible) => !!isVisible),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(() => {
        if (!(_size(this.pickupClusterPoints) > 0) && this.isLastBestStopFeatureActiveSubject.value) {
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, 'No pickup cluster information available yet.')
            .subscribe(() => {});
        }
      });

    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(withLatestFrom(this.isLayerVisible$), takeUntil(this.unsubscriber.done$))
      .subscribe(([isOpen, isLayerVisible]: [boolean, boolean]) => {
        this.isRouteBalancerActive = isOpen;
        if (isLayerVisible && isOpen) {
          this.preserveUserSelection = true;
        } else if (this.preserveUserSelection) {
          this.mapToolbarService.togglePickupClustersLayer(true);
          this.preserveUserSelection = false;
        } else {
          this.preserveUserSelection = false;
          this.mapToolbarService.togglePickupClustersLayer(isOpen);
        }
      });

    combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
    ])
      .pipe(
        filter(
          ([sic, sicZonesAndSatellites, planDate]: [string, SicZonesAndSatellites, Date]) =>
            !!sic && !!sicZonesAndSatellites && !!planDate
        ),
        distinctUntilChanged((a, b) => _isEqual(a, b)),
        switchMap(([sic, sicZonesAndSatellites, planDate]: [string, SicZonesAndSatellites, Date]) => {
          const zoneCd: ZoneIndicatorCd = PndZoneUtils.getZoneIndicatorCd(sicZonesAndSatellites);
          const path: ListPnDPositioningPointsPath = {
            sicCd: sic,
            planDate: this.timeService.formatDate(planDate, 'YYYY-MM-DD'),
          };
          const query: ListPnDPositioningPointsQuery = {
            zoneIndicatorCd: zoneCd,
          };

          return this.cityOperationsService.listPnDPositioningPoints(path, query).pipe(take(1));
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((resp: ListPnDPositioningPointsResp) => {
        this.pickupClusterPoints = this.pickupClusterPointsService.transformToPickupClusterPoints(
          resp?.positioningPoints
        );
        this.pickupClusterPointsService.updatePositioningPoints(resp?.positioningPoints);
        ComponentChangeUtils.detectChanges(this.changeRef);
      });

    this.pndStore$
      .select(RouteBalancingSelectors.resequencedRouteData)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((resequencedRouteData) => {
        const validRouteIds = Object.keys(resequencedRouteData) || [];
        if (_size(validRouteIds) === 0) {
          this.pickupClusterPointsService.resetPickupClustersRoutesMap();
        }
      });

    this.pickupClusterPointsService.pickupClustersRoutesMap$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((pickupClustersRoutesMap: Map<number, Route[]>) => {
        this.pickupClustersRoutesMap = pickupClustersRoutesMap;
        this.updateClusterAsLastStop();
        ComponentChangeUtils.detectChanges(this.changeRef);
      });

    this.pickupClusterPointsService.focusedPositioningPoint$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((point: PickupClusterPoint) => {
        this.showInfoWindowOnClusterCardHover(point);
        ComponentChangeUtils.detectChanges(this.changeRef);
      });
  }

  showInfoWindowOnClusterCardHover(positionPoint: PickupClusterPoint) {
    if (this.pickupClusterPoints) {
      this.pickupClusterPoints.forEach((point) => {
        if (_isEqual(point.pointData, positionPoint.pointData)) {
          point.openInfoWindow = positionPoint.openInfoWindow;
          if (this.googleMap && positionPoint.isClusterCardHover) {
            this.googleMap.panTo(new google.maps.LatLng(point.pointData.latitudeNbr, point.pointData.longitudeNbr));
          }
        }
      });
    }
  }

  updateClusterAsLastStop() {
    if (this.pickupClusterPoints && this.pickupClustersRoutesMap) {
      this.pickupClusterPoints.forEach((point) => {
        point.assignedRoutes = this.pickupClustersRoutesMap.get(point.pointData.positioningPointId) || [];
        if (_size(point.assignedRoutes) > 0) {
          point.pickupClusterMarker = new MapMarker();
          point.pickupClusterMarker.icon = this.markersService.pickupClusterIcon();
          point.pickupClusterMarker.latitude = point.pointData.latitudeNbr;
          point.pickupClusterMarker.longitude = point.pointData.longitudeNbr;
        } else {
          point.pickupClusterMarker = undefined;
        }
      });
    }
  }

  onMouseOver(point: PickupClusterPoint) {
    point.openInfoWindow = true;
    this.pickupClusterPointsService.setFocusedPositionPoint(point);
  }

  onMouseOut(point: PickupClusterPoint) {
    point.openInfoWindow = false;
    this.pickupClusterPointsService.setFocusedPositionPoint(point);
  }

  handleContextMenuSelection(item: PickupClusterContextMenuItem, point: PositioningPoint) {
    if (item) {
      switch (item.id) {
        case ContextMenuItemId.PIN_LAST_TO_ROUTE:
          this.pickupClusterPointsService.pinPickupClusterLastToRoute(point, item.route);
          break;
        case ContextMenuItemId.REMOVE_FROM_ROUTE:
          this.pickupClusterPointsService.removePickupClusterFromRoute(point, item.route);
          break;
      }
    }
  }

  onRightClick(point: PositioningPoint, event: MouseEvent) {
    if (this.isRouteBalancerActive) {
      this.contextMenu.openMenu(event.clientX, event.clientY, point).subscribe((item: PickupClusterContextMenuItem) => {
        this.handleContextMenuSelection(item, point);
      });
    }
  }
}
