import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { padStart as _padStart, range as _range } from 'lodash';

@Component({
  selector: 'pnd-route-timeline-header-renderer',
  templateUrl: './route-timeline-header-renderer.component.html',
  styleUrls: ['./route-timeline-header-renderer.component.scss'],
})
export class RouteTimelineHeaderRendererComponent implements IHeaderAngularComp {
  timelineRange: string[];
  private start: number = 5;
  private end: number = 21;

  constructor() {}

  agInit(params: IHeaderParams): void {
    this.timelineRange = _range(this.start, this.end).map((hour) =>
      hour < 10 ? _padStart(hour.toString(), 2, '0') : hour.toString()
    );
  }

  refresh(params: IHeaderParams): boolean {
    this.agInit(params);
    return true;
  }
}
