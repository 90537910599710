import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { size as _size } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DispatcherTripsAlert } from '../../../components/dispatcher-trips/models/dispatcher-trips-alert';
import { DispatcherTripsGridItem } from '../../../components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { XpoColors } from '../../services/xpo-colors';

export interface DispatchTripExceptionsCellRendererParams extends ICellRendererParams {
  getAlertsFn: (item: DispatcherTripsGridItem) => DispatcherTripsAlert[];
}

/**
 * The data that is displayed in the Popover
 */
interface DispatchTripExceptionsCellRendererData {
  alerts: DispatcherTripsAlert[];
  position: TooltipPosition;
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos;
  color: ThemePalette;
  formattedTripStartTime: string;
  driverName: string;
}

@Component({
  selector: 'pnd-dispatch-trip-exceptions-cell-renderer',
  templateUrl: './dispatch-trip-exceptions-cell-renderer.component.html',
  styleUrls: ['./dispatch-trip-exceptions-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispatchTripExceptionsCellRendererComponent implements ICellRendererAngularComp {
  readonly XpoColors = XpoColors;
  private params: DispatchTripExceptionsCellRendererParams;

  private readonly dataSubject = new BehaviorSubject<DispatchTripExceptionsCellRendererData>(undefined);
  readonly data$ = this.dataSubject.asObservable();

  constructor(private timeService: XpoLtlTimeService) {}

  agInit(params: DispatchTripExceptionsCellRendererParams): void {
    this.params = params;
    this.updateData(params);
  }

  refresh(params: DispatchTripExceptionsCellRendererParams): boolean {
    this.params = params;
    this.updateData(params);
    return true;
  }

  /**
   * Refresh popover data when opening (driver speed may have changed).
   */
  onFlagClicked(clickEvent: MouseEvent) {
    this.updateData(this.params);
    clickEvent.stopPropagation();
  }

  /**
   * Refresh popover data
   */
  private updateData(params: DispatchTripExceptionsCellRendererParams) {
    let newData: DispatchTripExceptionsCellRendererData;
    const gridItem = params.data as DispatcherTripsGridItem;
    const alerts = gridItem ? params.getAlertsFn(gridItem) : undefined;
    if (_size(alerts) > 0) {
      newData = {
        alerts,
        position: 'right' as TooltipPosition,
        caretPosition: 'center' as HorizontalConnectionPos,
        color: 'primary' as ThemePalette,
        formattedTripStartTime: !!gridItem.tripStart
          ? this.timeService.to24Time(gridItem.tripStart, gridItem.sicCd)
          : '',
        driverName: gridItem?.driver?.name ?? '',
      };
    }
    this.dataSubject.next(newData);
  }
}
