import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { PlanningProfileInterface } from '@pnd-store/planning-profiles-store/planning-profile.interface';

export class ConfirmProfileDialogData {
  profiles: PlanningProfileInterface[];

  constructor(profiles) {
    this.profiles = profiles;
  }
}
@Component({
  selector: 'pnd-confirm-profile',
  templateUrl: './confirm-profile.component.html',
  styleUrls: ['./confirm-profile.component.scss'],
})
export class ConfirmProfileComponent implements OnInit {
  title: string = 'Choose Profile';
  selectedProfile: PlanningProfileInterface;

  constructor(
    public dialogRef: MatDialogRef<ConfirmProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmProfileDialogData
  ) {}

  ngOnInit(): void {}

  setPlanningProfile(value: any): void {
    this.selectedProfile = value;
  }

  confirmAction() {
    this.dialogRef.close({ apply: true, value: this.selectedProfile });
  }

  cancelAction() {
    this.dialogRef.close({ apply: false });
  }
}
