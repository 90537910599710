import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { PndStoreState, TripsStoreActions, TripsStoreSelectors } from '@pnd-store/index';
import { ModifyTripDetailsActions, ModifyTripDetailsSelectors } from '@pnd-store/modify-trip-details-store';
import { ModifyTripActivityId } from '@pnd-store/modify-trip-details-store/modify-trip-details.state';
import { PndStore } from '@pnd-store/pnd-store';
import {
  ProFormatterPipe,
  Unsubscriber,
  XpoLtlFormatValidationService,
  XpoLtlShipmentDescriptor,
  XpoLtlTimeService,
} from '@xpo-ltl/ngx-ltl';
import { PickupFormTypeEnum } from '@xpo-ltl/ngx-ltl-pickup-request';
import { CustomerOperationsNote, CustomerProfileNote } from '@xpo-ltl/sdk-cityoperations';
import { CustOperationNoteTypeCd, TenderDetail } from '@xpo-ltl/sdk-common';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { HandlingUnitsCellRendererComponent } from 'app/inbound-planning/shared/components/handling-units-cell-renderer';
import { OversizeCellRendererComponent } from 'app/inbound-planning/shared/components/oversize-cell-renderer/oversize-cell-renderer.component';
import { StoreSourcesEnum } from 'app/inbound-planning/shared/enums/store-sources.enum';
import { CartageService } from 'app/inbound-planning/shared/services/cartage.service';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import { NotificationMessageService } from 'core/services/notification-message.service';
import { defaultTo as _defaultTo, map as _map, size as _size, uniqBy as _uniqBy } from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import {
  AssignedStopIdentifier,
  EventItem,
  GrandTotals,
  ModifyTripDetailsDetailsGrandTotals,
} from '../../../../shared';
import { ActionLinkCellRendererComponent } from '../../../../shared/components/action-link-cell-renderer/action-link-cell-renderer';
import { NotesCellRendererComponent } from '../../../../shared/components/notes-cell-renderer/notes-cell-renderer.component';
import { SpecialServicesCellRendererComponent } from '../../../../shared/components/special-services-cell-renderer/special-services-cell-renderer.component';
import { TotalTextCellRendererComponent } from '../../../../shared/components/total-text-cell-renderer/total-text-cell-renderer.component';
import { VisibilityCellRendererComponent } from '../../../../shared/components/visibility-cell-renderer/visibility-cell-renderer.component';
import { CarrierTenderStatusCdPipe } from '../../../../shared/pipes/carrier-tender-status-cd.pipe';
import { SpecialServicesService } from '../../../../shared/services/special-services.service';
import { ModifyTripDetailsUtils } from '../classes/modify-trip-details-utils.class';
import { EntityTypeCd } from '../enums/entity-type-cd.enum';
import { ModifyTripDetailsDetailType } from '../enums/modify-trip-details-detail-type.enum';
import { ModifyTripDetailsSharedGridFields } from '../enums/modify-trip-details-shared-grid-fields.enum';
import {
  ModifyTripDetailsActivityDetailGridItem,
  ModifyTripDetailsBaseGridItem,
  ModifyTripDetailsShipmentGridItem,
  ModifyTripDetailsStopGridItem,
} from '../models/modify-trip-details-grid-item.model';
import { ModifyTripDetailsRowHeightConfig } from '../models/modify-trip-details-row-height-config.model';
import { ModifyTripDetailsShipmentsGridFields } from '../modify-trip-details-shipments/modify-trip-details-shipments-grid-fields.enum';
import { ModifyTripDetailsService } from '../services/modify-trip-details.service';
import { ModifyTripDetailsDetailColumnDefinitions } from './modify-trip-details-detail-column-definitions.class';

export interface ModifyTripDetailsDetailGridRendererParams extends ICellRendererParams {
  detailType: (nodeId: string) => ModifyTripDetailsDetailType;
  getCurrentRowHeight: () => number;
}

@Component({
  selector: 'pnd-modify-trip-details-detail-grid-renderer',
  templateUrl: './modify-trip-details-detail-grid-renderer.component.html',
  styleUrls: ['./modify-trip-details-detail-grid-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModifyTripDetailsDetailGridRendererComponent implements ICellRendererAngularComp, OnDestroy {
  protected prevSelectedNodes: RowNode[] = [];

  readonly ModifyTripDetailsDetailType = ModifyTripDetailsDetailType;
  readonly EntityTypeCd = EntityTypeCd;

  readonly remarksRowHeight = ModifyTripDetailsRowHeightConfig.DETAIL_ROW_HEIGHT_NOTE_DESCRIPTION;
  readonly detailGridMarginTop = ModifyTripDetailsRowHeightConfig.DETAIL_MARGIN_TOP;
  readonly detailGridMarginBottom = ModifyTripDetailsRowHeightConfig.DETAIL_MARGIN_BOTTOM;
  readonly CustOperationNoteTypeCd = CustOperationNoteTypeCd;

  private gridApi: GridApi;
  private unsubscriber = new Unsubscriber();

  detailType: ModifyTripDetailsDetailType;

  lineItemsRowData: ModifyTripDetailsShipmentGridItem[];
  tenderDetails: TenderDetail[];
  carrierName: string;
  customerProfileNotes: CustomerProfileNote;
  entityTypeCd: EntityTypeCd;
  getCurrentRowHeight: () => number;

  lineItemsGridOptions: GridOptions = {
    frameworkComponents: {
      specialServicesCellRenderer: SpecialServicesCellRendererComponent,
      visibilityCellRenderer: VisibilityCellRendererComponent,
      notesCellRenderer: NotesCellRendererComponent,
      actionLinkCellRenderer: ActionLinkCellRendererComponent,
      totalTextCellRenderer: TotalTextCellRendererComponent,
      handlingUnitsCellRenderer: HandlingUnitsCellRendererComponent,
      OversizeCellRenderer: OversizeCellRendererComponent,
    },
    enableCellTextSelection: false,
    headerHeight: ModifyTripDetailsRowHeightConfig.HEADER_HEIGHT,
    floatingFilter: false,
    suppressCellSelection: true,
    onGridReady: (event) => this.onGridReady(event),
    onSelectionChanged: (event: SelectionChangedEvent) => this.onSelectionChanged(event),
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      sortable: false,
      filter: false,
    },
    rowClassRules: {
      ['pnd-masterDetail__totals']: (params) => {
        return !!params.node && !!params.node.isRowPinned();
      },
    },
  };

  readonly pickupLineItemsColumnDefs: ColDef[] = ModifyTripDetailsDetailColumnDefinitions.pickupLineItemsColumnDefs(
    this.formatValidationService,
    this.specialServicesService,
    this.proFormatterPipe,
    CartageService.isCartageTrip(this.modifyTripDetailsService.trip),
    this.tenderStatusCdPipe,
    (id: XpoLtlShipmentDescriptor) => this.modifyTripDetailsService.showShipmentDetails(id),
    (gridItem: ModifyTripDetailsBaseGridItem) => this.modifyTripDetailsService.showBoLDialogForItem(gridItem),
    this.onCallNbrClicked.bind(this)
  );

  readonly deliveryLineItemsColumnDefs: ColDef[] = ModifyTripDetailsDetailColumnDefinitions.deliveryLineItemsColumnDefs(
    this.formatValidationService,
    this.specialServicesService,
    this.proFormatterPipe,
    this.timeService,
    CartageService.isCartageTrip(this.modifyTripDetailsService.trip),
    this.tenderStatusCdPipe,
    (id: XpoLtlShipmentDescriptor) => this.modifyTripDetailsService.showShipmentDetails(id),
    (gridItem: ModifyTripDetailsBaseGridItem) => this.modifyTripDetailsService.showBoLDialogForItem(gridItem)
  );

  readonly equipmentLineItemsColumnDefs = ModifyTripDetailsDetailColumnDefinitions.equipmentLineItemsColumnDefs();

  constructor(
    protected formatValidationService: XpoLtlFormatValidationService,
    protected proFormatterPipe: ProFormatterPipe,
    private tenderStatusCdPipe: CarrierTenderStatusCdPipe,
    private pndStore$: PndStore<PndStoreState.State>,
    private specialServicesService: SpecialServicesService,
    private modifyTripDetailsService: ModifyTripDetailsService,
    private timeService: XpoLtlTimeService,
    private notificationMessageService: NotificationMessageService
  ) {}

  refresh(params: ModifyTripDetailsDetailGridRendererParams): boolean {
    return false;
  }

  agInit(params: ModifyTripDetailsDetailGridRendererParams): void {
    const item = params.data as ModifyTripDetailsStopGridItem;
    this.lineItemsRowData = item.activitiesGridItems;
    this.tenderDetails = item.tenderDetails;
    this.carrierName = this.modifyTripDetailsService.supplementalTripDetails?.carrier?.carrierName;
    this.customerProfileNotes = item.customerProfileNotes;
    this.detailType = params.detailType(item.uniqueId);
    this.entityTypeCd = !_size(item.activitiesGridItems)
      ? undefined
      : ModifyTripDetailsUtils.getEntityTypeFromActivityType(item.activitiesGridItems[0].activityCd);
    this.getCurrentRowHeight = params.getCurrentRowHeight;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  onGridReady(gridEvent: GridReadyEvent) {
    this.gridApi = gridEvent.api;
    this.subscribeToStoreSelectionChange();
    this.setTotalsRow();
  }

  private setTotalsRow(): void {
    if (this.entityTypeCd === EntityTypeCd.Pickup || this.entityTypeCd === EntityTypeCd.Shipment) {
      const totals: GrandTotals = this.computeTotals();
      this.gridApi.setPinnedBottomRowData([totals]);
    }
  }

  private computeTotals(): GrandTotals {
    const deliveryGrandTotals: ModifyTripDetailsDetailsGrandTotals = {
      [ModifyTripDetailsSharedGridFields.PALLETS]: 0,
      [ModifyTripDetailsSharedGridFields.LOOSE_PIECES]: 0,
      [ModifyTripDetailsSharedGridFields.WEIGHT]: 0,
      [ModifyTripDetailsSharedGridFields.MOTOR_MOVES]: 0,
      [ModifyTripDetailsSharedGridFields.CUBE]: 0,
      [ModifyTripDetailsShipmentsGridFields.PRO]: '',
    };

    let totalShipmentsCount: number = 0;

    this.gridApi.forEachNode((rowNode: RowNode) => {
      const data: ModifyTripDetailsActivityDetailGridItem = rowNode.data;

      if (data) {
        deliveryGrandTotals[ModifyTripDetailsSharedGridFields.PALLETS] += _defaultTo(data.palletCount, 0);
        deliveryGrandTotals[ModifyTripDetailsSharedGridFields.LOOSE_PIECES] += _defaultTo(data.piecesCount, 0);
        deliveryGrandTotals[ModifyTripDetailsSharedGridFields.WEIGHT] += _defaultTo(data.weightLbs, 0);
        deliveryGrandTotals[ModifyTripDetailsSharedGridFields.MOTOR_MOVES] += _defaultTo(data.motorMovesNbr, 0);
        deliveryGrandTotals[ModifyTripDetailsSharedGridFields.CUBE] += _defaultTo(data.cubeNbr, 0);

        if (!!data.proNbr) {
          totalShipmentsCount++;
        }
      }
    });

    deliveryGrandTotals[ModifyTripDetailsShipmentsGridFields.PRO] = `${totalShipmentsCount} ${
      totalShipmentsCount === 1 ? 'PRO' : 'PROs'
    }`;

    return <GrandTotals>deliveryGrandTotals;
  }

  private onSelectionChanged(event: SelectionChangedEvent) {
    let selectedActivities: ModifyTripActivityId[] = this.pndStore$.selectSnapshot(
      ModifyTripDetailsSelectors.selectedActivities
    );
    let selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[] = this.pndStore$.selectSnapshot(
      TripsStoreSelectors.selectedStopsForSelectedRoutes
    );

    this.gridApi.forEachNode((node: RowNode) => {
      const item = node.data as ModifyTripDetailsActivityDetailGridItem;
      if (node.isSelected()) {
        // add this item too the selection
        selectedActivities.push(ModifyTripDetailsUtils.getModifyTripActivityIdFromItem(item));
        selectedStopsForSelectedRoutes.push({
          id: {
            routeInstId: item.routeInstId,
            origSeqNo: item.stopSequenceNbr,
            seqNo: item.stopSequenceNbr,
          },
          source: StoreSourcesEnum.UPDATE_TRIP_GRID,
        });
      } else {
        // remove this activity from the selection
        selectedActivities = selectedActivities.filter((selection) => selection.uniqueId !== item.uniqueId);
        selectedStopsForSelectedRoutes = selectedStopsForSelectedRoutes.filter(
          (selection) =>
            selection?.id?.routeInstId !== item.routeInstId ||
            (selection?.id?.routeInstId === item.routeInstId && selection?.id?.seqNo !== item.stopSequenceNbr)
        );
      }
    });

    // Set selected Stops
    this.pndStore$.dispatch(
      new TripsStoreActions.SetSelectedStopsForSelectedRoutes({
        selectedStopsForSelectedRoutes: selectedStopsForSelectedRoutes,
      })
    );

    // Set selected Activities
    this.pndStore$.dispatch(
      new ModifyTripDetailsActions.SetSelectedActivities({
        selectedActivities: _uniqBy(selectedActivities, 'uniqueId'),
      })
    );
  }

  /**
   * Update the selected nodes in this grid when store changes
   */
  private subscribeToStoreSelectionChange() {
    this.pndStore$
      .select(ModifyTripDetailsSelectors.selectedActivities)
      .pipe(
        filter(() => !!this.gridApi),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((selectedActivitiesFromStore) => {
        this.updateNodeSelectionFromStore(selectedActivitiesFromStore);
      });
  }

  /**
   * Update the selected nodes in this grid based on the store selection
   */
  private updateNodeSelectionFromStore(selectedActivitiesFromStore: ModifyTripActivityId[]) {
    const selectedActivityIds = new Set<string>();
    selectedActivitiesFromStore.forEach((activityId) => {
      selectedActivityIds.add(activityId.uniqueId);
    });

    this.gridApi.forEachNode((node) => {
      const activityItem: ModifyTripDetailsShipmentGridItem = node.data as ModifyTripDetailsShipmentGridItem;
      if (activityItem) {
        const isSelected = selectedActivityIds.has(activityItem.uniqueId);
        node.setSelected(isSelected, false, true);
      }
    });
  }

  copyToClipboard(notesToCopy: CustomerOperationsNote[]) {
    const noteDescriptions: string[] = _map(notesToCopy, (note) => note.note);

    navigator.clipboard.writeText(noteDescriptions.join('\n')).then(() => {
      // Success
      this.notificationMessageService.openSnackBar(
        'Customer notes copied to clipboard',
        NotificationMessageStatus.Success
      );
    });
  }

  copyTenderNotesToClipboard(): void {
    const noteDescriptions: string[] = _map(this.tenderDetails, (tenderDetail) => tenderDetail.statusMessage);

    navigator.clipboard.writeText(noteDescriptions.join('\n')).then(() => {
      this.notificationMessageService.openSnackBar(
        'Tender notes copied to clipboard',
        NotificationMessageStatus.Success
      );
    });
  }

  onCallNbrClicked(item: ModifyTripDetailsActivityDetailGridItem) {
    const request = {
      id: item.pickupRequestInstId,
      callNbr: item.callNbr,
    };
    this.modifyTripDetailsService.openPickupRequest(PickupFormTypeEnum.UPDATE_PICKUP, request);
  }
}
