<form [formGroup]="group">
  <mat-form-field [floatLabel]="floatLabel">
    <mat-label>{{ label }}</mat-label>
    <mat-icon matSuffix *ngIf="group.get(controlName).enabled && showIcon">
      {{ icon }}
    </mat-icon>
    <input
        #input
        matInput
        autocomplete="off"
        type="text"
        [maxlength]="maxlength"
        [readonly]="readonly"
        [placeholder]="placeholder"
        [formControlName]="controlName"
        [matAutocomplete]="autocomplete"
        [xpo-allow-characters]="xpoAllowCharacters | toRegex"
        (blur)="onBlur()"
        (input)="onTextClear($event.target.value)" />
    <mat-error>
      <ng-content></ng-content>
    </mat-error>
    <mat-hint>{{ hint }}</mat-hint>
  </mat-form-field>
  <mat-autocomplete
      [autoActiveFirstOption]="autoActiveFirstOption"
      #autocomplete="matAutocomplete"
      [displayWith]="getText"
      (closed)="onPanelClosed()"
      (optionActivated)="onOptionActivated($event)"
      (optionSelected)="onOptionSelected($event)">
    <mat-option
        class="actionLink"
        [id]="actionLinkOptionId"
        [value]="actionLinkItem"
        *ngIf="actionLinkConfig?.isVisible">
      <button mat-button class="actionLink-btn" (click)="onActionLinkClick($event)">
        <mat-icon>search</mat-icon>
        <span>{{ actionLinkConfig?.text }}</span>
      </button>
      <div class="actionLink-loader" *ngIf="actionLinkConfig?.isLoading">
        <div>Retrieving results...</div>
        <div>
          <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>
    </mat-option>
    <mat-option
        *ngFor="let item of itemsFiltered$ | async"
        [value]="item"
        (onSelectionChange)="onSelectionChange($event)"
        [class.pnd-mat-option]="!!optionTemplate"
        [disabled]="!isEnabled(item)"
        [ngClass]="{ 'disabled-item': !isEnabled(item) }">
      <ng-container
          *ngTemplateOutlet="optionTemplate ? optionTemplate : defaultOptionTemplate; context: { $implicit: item }">
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</form>

<ng-template #defaultOptionTemplate let-item>
  {{ item.value }}
</ng-template>
