<pnd-progress-spinner [showSpinner$]="showSpinner$">
  <div class="pnd-geo-area-drawer__header">
    <h1>Geo Areas ({{ (geoAreaDrawList$ | async)?.length }})</h1>
    <button
        matTooltip="Add Geo Area"
        color="primary"
        matTooltipPosition="above"
        mat-flat-button
        xpoSmallButton
        (click)="drawNewGeoArea()"
        [disabled]="isCreating">
      ADD
    </button>
  </div>
  <div class="pnd-geo-area-drawer__header">
    <mat-form-field class="pnd-geo-area-drawer__header-search" floatLabel="never">
      <input #searchField autocomplete="off" placeholder="Search" type="text" matInput [formControl]="searchCtrl" />
    </mat-form-field>
  </div>
  <pnd-geo-area-drawer-detail
      *ngFor="let geoArea of geoAreaDrawList$ | async"
      [geoAreaDraw]="geoArea"
      [googleMap]="googleMap"
      [showGeoAreaDetails]="showGeoAreaDetails"
      [isCreating]="isCreating"
      (geoAreaReady)="onGeoAreaReady($event)"
      (geoAreaEdit)="onGeoAreaEdit($event)"
      (geoAreaDeleted)="onGeoAreaDeleted($event)"
      (geoAreaDisabled)="onGeoAreaDisabled()"
      (geoAreaSaved)="onGeoAreaSaved($event)">
  </pnd-geo-area-drawer-detail>
</pnd-progress-spinner>
