import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input } from '@angular/core';
import { Metric } from '@xpo-ltl/sdk-cityoperations';
import { MetricVarianceCd } from '@xpo-ltl/sdk-common';
import { MetricsUtils } from '../../../classes/metrics-utils';

@Component({
  selector: 'pnd-metric-bar-card',
  templateUrl: 'metric-bar-card.component.html',
  styleUrls: ['metric-bar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-MetricBarCard' },
})
export class MetricBarCardComponent {
  @Input()
  metric: Metric;
  readonly descName: string = 'Delivery Stops per Hour';
  constructor() {}

  getVarianceIcon(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceIcon(variance);
  }

  getVarianceClassColor(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceClassColor(variance);
  }
}
