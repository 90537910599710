import { ColumnApi, ColumnState, GridApi, RowNode } from 'ag-grid-community';
import {
  defaultTo as _defaultTo,
  forEach as _forEach,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  isNil as _isNil,
  isNumber as _isNumber,
  omitBy as _omitBy,
  size as _size,
  values as _values,
} from 'lodash';
import * as md5 from 'md5';

/**
 * Utility functions for use with XpoBoard
 */
export class BoardUtils {
  /**
   * Format the enums using the passed formatters
   */
  static formatEnumComplexFilterList(
    enums: any,
    formatterFn: (val) => string,
    fallbackFormatterFn: (val) => string = (val) => val
  ): { [key: string]: any } {
    return _values(enums).reduce((codeMap, value: any) => {
      codeMap[value] = formatterFn(value);
      if (codeMap[value] === value) {
        codeMap[value] = fallbackFormatterFn(value);
      }
      return codeMap;
    }, {});
  }

  /**
   * compares the values in for each key in a and b and returns true if they are all equal, else false
   */
  static areCriteriaObjectsEquivalent(a: Object, b: Object): boolean {
    const removeEmptyValues = (obj: Object) => _omitBy(obj, (v) => _isNil(v) || (_isEmpty(v) && !_isNumber(v)));
    return _isEqual(removeEmptyValues(a), removeEmptyValues(b));
  }

  /**
   * Returns true if the passed row is a Totals row (ie, pinned or a footer with aggregated data)
   **/
  static isTotalsRow(rowNode: RowNode): boolean {
    return rowNode.isRowPinned() || (rowNode.footer && !!rowNode.aggData);
  }

  /**
   * Returns true if the two row values equivalent
   * @param rowListA
   * @param rowListB
   */
  static areRowsValuesEquivalent(rowListA: any[], rowListB: any[]): boolean {
    if (_size(rowListA) !== _size(rowListB)) {
      return false;
    }

    const hashMappedRowListA = _defaultTo(rowListA, []).reduce(
      (hashMap: { [key: string]: boolean }, rowItem: { [key: string]: any }) => {
        const rowHash = md5(JSON.stringify(rowItem));
        hashMap[rowHash] = true;
        return hashMap;
      },
      {}
    );

    let isEqual: boolean = true;

    _forEach(_defaultTo(rowListB, []), (rowItem) => {
      const rowHash = md5(JSON.stringify(rowItem));
      if (!hashMappedRowListA[rowHash]) {
        isEqual = false;
        return isEqual; // break from _forEach loop since we don't want to iterate after knowing it's not equal
      }
    });

    return isEqual;
  }
  /** returns the sort model using colApi */
  static getSortModel(
    colApi: ColumnApi
  ): {
    colId: string;
    sort: string;
  }[] {
    return (
      colApi
        .getColumnState()
        ?.map((c: ColumnState) => ({ colId: c.colId, sort: c.sort }))
        ?.filter((c: { colId: string; sort: string }) => c.sort) || []
    );
  }

  static getFilteredRowNodes(gridApi: GridApi): RowNode[] {
    const filteredRowNodes: RowNode[] = [];
    gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
      filteredRowNodes.push(rowNode);
    });

    return filteredRowNodes;
  }
}
