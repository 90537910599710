<div
  class="print-dialog-component"
  *ngIf="{
    isLoading: isLoading$ | async,
    isPrintAllowed: isPrintAllowed$ | async,
    isServiceDisabled: isServiceDisabled$ | async,
    isManifestPrintDisabled: isManifestPrintDisabled$ | async,
    isOpenPdfAllowed: isOpenPdfAllowed$ | async
  } as modalState"
>
  <xpo-responsive-loading-spinner *ngIf="modalState.isLoading"></xpo-responsive-loading-spinner>

  <form [formGroup]="formGroup">
    <h1 mat-dialog-title xmlns="http://www.w3.org/1999/html">
      Print
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>

    <div mat-dialog-content>
      <div>
        <h3>
          <span>{{ selectedItemLabel }}</span>
          Selected
        </h3>
        <div class="selected-items-container">
          <ng-container *ngFor="let selectedItem of selectedItems; last as isLast">
            <ng-container *ngIf="selectedItem">
              <span *ngIf="selectedItemLabel === 'Shipments'">{{ selectedItem | proFormatter: 10 }}</span>
              <span *ngIf="selectedItemLabel !== 'Shipments'">{{ selectedItem }}</span>
              <span *ngIf="!isLast">,&nbsp;</span>
            </ng-container>
          </ng-container>
        </div>
        <div class="error-routes-container marginTop" *ngIf="routeNamesWithError.length > 0">
          <p class="error-message">
            <xpo-icon iconName="error" class="error-icon"></xpo-icon> The following routes don't have fbds documents
            available to print:
          </p>
          <div>
            <span *ngFor="let routeName of routeNamesWithError; last as isLast">
              {{ routeName }}
              <span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </div>

      <div class="document-type-container marginTop">
        <h3>Document Type</h3>
        <xpo-checkbox-group>
          <mat-checkbox
            [formControlName]="PrintFormFields.FbdsDocTypeSelect"
            [disabled]="modalState.isServiceDisabled || modalState.isLoading || data?.isFbdsDisabled"
          >
            FBDS (Freight Bill Delivery Set)
          </mat-checkbox>
          <div class="paper-type-container marginLeft" *ngIf="isFbdsDocTypeSelected">
            <h3>Paper Type</h3>
            <mat-radio-group [formControlName]="PrintFormFields.PaperType">
              <mat-radio-button [value]="PrintPaperTypeEnum.PerforatedPaper">
                {{ PrintPaperTypeEnum.PerforatedPaper }}
              </mat-radio-button>
              <mat-radio-button [value]="PrintPaperTypeEnum.TwoPartPaper">
                {{ PrintPaperTypeEnum.TwoPartPaper }}
              </mat-radio-button>
            </mat-radio-group>
            <div
              class="select-printer-container marginTop"
              *ngIf="selectedPaperType === PrintPaperTypeEnum.PerforatedPaper"
            >
              <h3>Language</h3>
              <mat-radio-group [formControlName]="PrintFormFields.Language">
                <mat-radio-button [value]="LanguageOptions.ENGLISH">{{
                  LanguageTypeEnum.ENGLISH_ONLY
                }}</mat-radio-button>
                <mat-radio-button [value]="LanguageOptions.FRENCH">{{ LanguageTypeEnum.FRENCH_ONLY }}</mat-radio-button>
                <mat-radio-button [value]="LanguageOptions.BILINGUAL">{{
                  LanguageTypeEnum.ENGLISH_FRENCH_BOTH
                }}</mat-radio-button>
              </mat-radio-group>
              <h3 class="marginTop">Printer</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.FbdsPrinter"
                    [group]="formGroup"
                    [items]="activePrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
              <div class="select-tray-container">
                <h3>Paper Source</h3>
                <div class="tray-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select tray or source"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.FbdsTray"
                    [group]="formGroup"
                    [items]="activeFbdsTrays$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
            <div
              class="select-printer-container marginTop"
              *ngIf="selectedPaperType === PrintPaperTypeEnum.TwoPartPaper"
            >
              <h3>Printers</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.FbdsTwoPartPrinter"
                    [group]="formGroup"
                    [items]="legacyPrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
            <div class="reprint" *ngIf="isReprintOptionAvailable" data-test="reprint">
              <mat-checkbox labelPosition="after" [formControlName]="PrintFormFields.Reprint">
                Reprint already printed delivery receipts
              </mat-checkbox>
            </div>
          </div>
          <mat-divider class="printDivider" *ngIf="isFbdsDocTypeSelected"></mat-divider>
          <mat-checkbox
            [formControlName]="PrintFormFields.DockDocTypeSelect"
            [disabled]="modalState.isServiceDisabled || modalState.isLoading || data?.isDockDisabled"
          >
            Dock Copy</mat-checkbox
          >
          <div class="paper-type-container marginLeft" *ngIf="isDockDocTypeSelected">
            <div class="select-printer-container marginTop">
              <h3>Printer</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.DockPrinter"
                    [group]="formGroup"
                    [items]="legacyPrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <mat-divider class="printDivider" *ngIf="isDockDocTypeSelected"></mat-divider>
          <mat-checkbox
            [formControlName]="PrintFormFields.PndDocTypeSelect"
            [disabled]="
              modalState.isManifestPrintDisabled || modalState.isLoading || data?.isPndDisabled || isPnDManiFestDisabled
            "
          >
            P&D Manifest
            <p *ngIf="disableLocationPrint" class="service-enabled">(Coming Soon)</p>
          </mat-checkbox>
          <div class="paper-type-container marginLeft" *ngIf="isPndDocTypeSelected">
            <div class="select-printer-container marginTop">
              <h3>Printer</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.PndPrinter"
                    [group]="formGroup"
                    [items]="activePrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
            <div class="select-tray-container">
              <h3>Tray</h3>
              <div class="tray-autocomplete">
                <pnd-autocomplete
                  floatLabel="always"
                  maxlength="30"
                  placeholder="Select Source or Tray"
                  [strictSelection]="strictSelection"
                  [autoActiveFirstOption]="false"
                  [controlName]="PrintFormFields.PndTray"
                  [group]="formGroup"
                  [items]="activePndTrays$ | async"
                ></pnd-autocomplete>
              </div>
            </div>
          </div>
          <mat-divider class="printDivider" *ngIf="isPndDocTypeSelected"></mat-divider>
          <mat-checkbox
            [formControlName]="PrintFormFields.DsrDocTypeSelect"
            [disabled]="
              modalState.isLoading ||
              modalState.isManifestPrintDisabled ||
              data?.isDsrDocDisabled ||
              isDSRCheckInDisabled
            "
          >
            DSR Check-in
            <p *ngIf="disableLocationPrint" class="service-enabled">(Coming Soon)</p>
          </mat-checkbox>
          <div class="paper-type-container marginLeft" *ngIf="isDsrDocTypeSelected">
            <div class="select-printer-container marginTop">
              <h3>Printer</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.DsrPrinter"
                    [group]="formGroup"
                    [items]="activePrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
            <div class="select-tray-container">
              <h3>Tray</h3>
              <div class="tray-autocomplete">
                <pnd-autocomplete
                  floatLabel="always"
                  maxlength="30"
                  placeholder="Select Source or Tray"
                  [strictSelection]="strictSelection"
                  [autoActiveFirstOption]="false"
                  [controlName]="PrintFormFields.DsrTray"
                  [group]="formGroup"
                  [items]="activeDsrTray$ | async"
                ></pnd-autocomplete>
              </div>
            </div>
          </div>
          <mat-divider class="printDivider" *ngIf="isDsrDocTypeSelected"></mat-divider>
          <mat-checkbox
            [formControlName]="PrintFormFields.OutboundDocTypeSelect"
            [disabled]="modalState.isLoading || modalState.isManifestPrintDisabled || data?.isObmDisabled"
          >
            Outbound Breakout Manifest
            <p *ngIf="disableLocationPrint" class="service-enabled">(Coming Soon)</p>
          </mat-checkbox>
          <div class="paper-type-container marginLeft" *ngIf="isOutBoundDocTypeSelected">
            <div class="select-printer-container marginTop">
              <h3>Printer</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.OutboundPrinter"
                    [group]="formGroup"
                    [items]="activePrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
            <div class="select-tray-container">
              <h3>Tray</h3>
              <div class="tray-autocomplete">
                <pnd-autocomplete
                  floatLabel="always"
                  maxlength="30"
                  placeholder="Select Source or Tray"
                  [strictSelection]="strictSelection"
                  [autoActiveFirstOption]="false"
                  [controlName]="PrintFormFields.OutboundTray"
                  [group]="formGroup"
                  [items]="activeOubtoundTray$ | async"
                ></pnd-autocomplete>
              </div>
            </div>
          </div>
          <mat-checkbox
            [formControlName]="PrintFormFields.EbolDocTypeSelect"
            [disabled]="modalState.isLoading || isEbolDisabled"
          >
            EBOL
            <p *ngIf="disableLocationPrint" class="service-enabled">(Coming Soon)</p>
          </mat-checkbox>
          <div class="paper-type-container marginLeft" *ngIf="isEbolDocTypeSelected">
            <div class="select-printer-container marginTop">
              <h3>Printer</h3>
              <div class="flex-container">
                <div class="printer-autocomplete">
                  <pnd-autocomplete
                    floatLabel="always"
                    maxlength="30"
                    placeholder="Select or Type"
                    [strictSelection]="strictSelection"
                    [autoActiveFirstOption]="false"
                    [controlName]="PrintFormFields.EbolPrinter"
                    [group]="formGroup"
                    [items]="activePrintersList$ | async"
                  ></pnd-autocomplete>
                </div>
              </div>
            </div>
            <div class="select-tray-container">
              <h3>Tray</h3>
              <div class="tray-autocomplete">
                <pnd-autocomplete
                  floatLabel="always"
                  maxlength="30"
                  placeholder="Select Source or Tray"
                  [strictSelection]="strictSelection"
                  [autoActiveFirstOption]="false"
                  [controlName]="PrintFormFields.EbolTray"
                  [group]="formGroup"
                  [items]="activeEbolTrays$ | async"
                ></pnd-autocomplete>
              </div>
            </div>
          </div>
          <!-- DMAN-->
          <mat-checkbox [formControlName]="PrintFormFields.DmanTypeSelect" [disabled]="modalState.isLoading">
            DMAN
            <p *ngIf="disableLocationPrint" class="service-enabled">(Coming Soon)</p>
          </mat-checkbox>
        </xpo-checkbox-group>
      </div>
      <div class="dman-container" *ngIf="isDmanTypeSelected">
        <!--radio buttons -->
        <div class="dman-radio-buttons">
          <mat-radio-group aria-labelledby="dman-radio-group-label" class="dman-radio-group">
            <mat-radio-button
              class="example-radio-button"
              *ngFor="let option of dmanPrintOptions; let i = index"
              [value]="option"
              (change)="radioChange($event)"
              [checked]="i === 0"
            >
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <!--Grid -->
        <div class="dman-grid">
          <ng-container [formGroup]="dmanForm">
            <ng-container *ngFor="let control of dmanForm.get(DmanFormFields.DMAN).controls; let i = index">
              <pnd-dman-panel
                [dmanControl]="dmanForm.get(DmanFormFields.DMAN).controls[i]"
                [routeIndex]="i + 1"
              ></pnd-dman-panel>
            </ng-container>
          </ng-container>
          {{ dmanForm.valid ? 'Form is valid' : 'Form is invalid' }}
        </div>
      </div>
      <hr class="dman-separator" *ngIf="isDmanTypeSelected" />
      <!--Printer-->
      <div *ngIf="isDmanTypeSelected" class="dmanPrinter">
        <div>
          <h3>Printer</h3>
          <div class="printer-autocomplete">
            <pnd-autocomplete
              floatLabel="always"
              maxlength="30"
              placeholder="Select or Type"
              [strictSelection]="strictSelection"
              [autoActiveFirstOption]="false"
              [controlName]="PrintFormFields.DmanPrinter"
              [group]="formGroup"
              [items]="activePrintersList$ | async"
            ></pnd-autocomplete>
          </div>
        </div>
        <div>
          <h3>Tray</h3>
          <div class="tray-autocomplete">
            <pnd-autocomplete
              floatLabel="always"
              maxlength="30"
              placeholder="Select Source or Tray"
              [strictSelection]="strictSelection"
              [autoActiveFirstOption]="false"
              [controlName]="PrintFormFields.DmanTray"
              [group]="formGroup"
              [items]="activeEbolTrays$ | async"
            ></pnd-autocomplete>
          </div>
        </div>
      </div>
    </div>

    <div mat-dialog-actions class="print-actions-container">
      <xpo-button-group>
        <button
          mat-flat-button
          xpoButton
          (click)="onPrint()"
          [disabled]="!modalState.isPrintAllowed || modalState.isLoading"
        >
          PRINT
        </button>
        <button mat-stroked-button xpoButton (click)="cancel()">CANCEL</button>
      </xpo-button-group>
      <button mat-stroked-button xpoButton (click)="onPrintLocalMachine()" [disabled]="!modalState.isOpenPdfAllowed">
        OPEN PDF
      </button>
    </div>
  </form>
</div>
