import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import {
  CityOperationsApiService,
  ListPnDStopsPath,
  ListPnDStopsQuery,
  ListPnDStopsResp,
  ListPnDTripsPath,
  ListPnDTripsQuery,
  Stop,
  TripDetail,
  TripSummary,
} from '@xpo-ltl/sdk-cityoperations';
import { StagedTripsService } from './../../inbound-planning/components/trips/modify-trip-details/services/staged-trips.service';

import { TripsGridItemConverterService } from 'app/inbound-planning/shared/services/trips-grid-item-converter/trips-grid-item-converter.service';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  set as _set,
  some as _some,
  flatMap as _flatMap,
} from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  concatMapTo,
  finalize,
  map,
  pluck,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { TripPlanningGridItem } from '../../inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import {
  areStopsEqual,
  AssignedStopIdentifier,
  EventItem,
  RouteItemIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { TripsService } from '../../inbound-planning/shared/services/trips.service';
import { GlobalFilterStoreSelectors } from '../global-filters-store';
import { NumberToValueMap } from '../number-to-value-map';
import * as PndStoreState from '../pnd-store.state';
import { RouteBalancingActions } from '../route-balancing-store';
import { TripsSearchCriteria } from './trips-search-criteria.interface';
import {
  ActionTypes,
  Refresh,
  SetLastUpdate,
  SetSearchCriteria,
  SetSelected,
  SetSelectedRoutes,
  SetSelectedStopsForSelectedRoutes,
  SetStopsForSelectedRoutes,
  UpdateStopsForSelectedRoute,
} from './trips-store.actions';
import {
  searchCriteria,
  selectedStopsForSelectedRoutes,
  selectedTrips,
  stopsForSelectedRoutes,
} from './trips-store.selectors';

@Injectable()
export class TripsStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: PndStore<PndStoreState.State>,
    private tripsService: TripsService,
    private tripsGridItemConverterService: TripsGridItemConverterService,
    private cityOpsService: CityOperationsApiService,
    private stagedTripsService: StagedTripsService
  ) {}

  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new Refresh()])
  );

  @Effect()
  refresh$: Observable<Action> = this.actions$.pipe(
    ofType<Refresh>(ActionTypes.refresh),
    concatMap(() => this.store$.select(searchCriteria).pipe(take(1))),
    withLatestFrom(this.store$.select(GlobalFilterStoreSelectors.globalFilterPlanDate)),
    switchMap(([criteria, planDate]: [TripsSearchCriteria, Date]) => {
      return this.tripsService.searchTrips(criteria, planDate);
    }),
    withLatestFrom(this.store$.select(selectedTrips)),
    switchMap(([trips, currentSelectedTrips]) => {
      const newSelectedTrips: TripDetail[] = _filter(trips, (trip: TripDetail) => {
        return _some(currentSelectedTrips, (selected) => selected.tripInstId === trip.trip.tripInstId);
      });

      const newSelectedTripsItems = this.tripsGridItemConverterService.getTripsGridItems(newSelectedTrips);

      return [new SetLastUpdate({ lastUpdate: new Date() }), new SetSelected({ selectedTrips: newSelectedTripsItems })];
    }),
    catchError(() => {
      // error loading trips, so clear existing ones
      return [new SetLastUpdate({ lastUpdate: new Date() }), new SetSelected({ selectedTrips: [] })];
    })
  );

  // Set the selected Routes to be the Routes in the selected Trips
  @Effect()
  setSelectedTrips$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelected>(ActionTypes.setSelected),
    concatMap((action) => {
      return [
        new SetSelectedRoutes({
          selectedRoutes: (action.payload.selectedTrips as TripPlanningGridItem[]).map((trip) => {
            return { routeInstId: trip.route.routeInstId, tripInstId: trip.tripInstId } as RouteItemIdentifier;
          }),
        }),
      ];
    })
  );

  @Effect()
  setSelectedRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelectedRoutes>(ActionTypes.setSelectedRoutes),
    switchMap((action: SetSelectedRoutes) => {
      const selectedRoutes: RouteItemIdentifier[] = action.payload.selectedRoutes;

      if (!selectedRoutes || selectedRoutes.length === 0) {
        return of(new SetStopsForSelectedRoutes({ stopsForSelectedRoutes: {} }));
      }
      // check staged trips
      this.tripsService.updateLoadingStops(true);
      return this.stagedTripsService.stagedTripsMap$.pipe(
        take(1),
        map((stagedTripMap: Map<number, { tripSummary: TripSummary; tripDetail: TripDetail }>) =>
          Array.from(stagedTripMap.values())
        ),
        map(
          (
            stagedTrips: {
              tripSummary: TripSummary;
              tripDetail: TripDetail;
            }[]
          ) => {
            const results: {
              routeInstId: number;
              stops: Stop[];
            }[] = [];

            for (const selectedRoute of selectedRoutes) {
              const filteredTrips: {
                tripSummary: TripSummary;
                tripDetail: TripDetail;
              }[] = stagedTrips.filter(
                (stagedTrip) => selectedRoute.tripInstId === stagedTrip.tripDetail.trip.tripInstId
              );

              if (filteredTrips.length > 0) {
                const stops: Stop[] = _flatMap(filteredTrips, (stagedTrip) =>
                  _flatMap(stagedTrip.tripSummary.routeSummary, (route) => route.stops)
                );
                const routeStops = { routeInstId: selectedRoute.routeInstId, stops };
                results.push(routeStops);
              }
            }

            return results;
          }
        ),
        switchMap(
          (
            stagedResults: {
              routeInstId: number;
              stops: Stop[];
            }[]
          ) => {
            if (stagedResults.length > 0) {
              const stopsForRoutes: NumberToValueMap<Stop[]> = {};
              _forEach(stagedResults, (value) => {
                _set(stopsForRoutes, value.routeInstId, value.stops);
              });

              return of(new SetStopsForSelectedRoutes({ stopsForSelectedRoutes: stopsForRoutes }));
            } else {
              // Fetch stops for the selected routes
              return forkJoin(
                selectedRoutes.map((routeId) =>
                  this.tripsService.fetchStopsForRoute(routeId.routeInstId, routeId.tripInstId)
                )
              ).pipe(
                catchError(() => {
                  this.tripsService.updateLoadingStops(false);
                  return of(undefined);
                }),
                map((results: { routeInstId: number; stops: Stop[] }[]) => {
                  this.tripsService.updateLoadingStops(false);
                  const stopsForRoutes: NumberToValueMap<Stop[]> = {};
                  _forEach(results, (value) => {
                    _set(stopsForRoutes, value.routeInstId, value.stops);
                  });
                  return stopsForRoutes;
                }),
                map((stopsForRoutes: NumberToValueMap<Stop[]>) => {
                  return new SetStopsForSelectedRoutes({ stopsForSelectedRoutes: stopsForRoutes });
                }),
                finalize(() => {})
              );
            }
          }
        ),
        catchError(() => {
          this.tripsService.updateLoadingStops(false);
          return of(new SetStopsForSelectedRoutes({ stopsForSelectedRoutes: {} }));
        })
      );
    })
  );

  @Effect()
  updateStopsForSelectedRoute$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateStopsForSelectedRoute>(ActionTypes.updateStopsForSelectedRoute),
    map((action) => action.payload.route),
    withLatestFrom(this.store$.select(stopsForSelectedRoutes)),
    switchMap(([route, selectedRoutesStops]) => {
      const pathParams: ListPnDStopsPath = { ...new ListPnDStopsPath(), routeInstId: `${route.routeInstId}` };
      const queryParams: ListPnDStopsQuery = { ...new ListPnDStopsQuery() };

      return this.cityOpsService.listPnDStops(pathParams, queryParams).pipe(
        catchError((error) => {
          this.store$.dispatch(
            new RouteBalancingActions.SetCanOpenRouteBalancing({
              canOpenRouteBalancing: true,
            })
          );
          return of(new ListPnDStopsResp());
        }),
        map((response) => {
          const results: NumberToValueMap<Stop[]> = {};
          _forOwn(selectedRoutesStops, (stops, routeInstId) => {
            if (+routeInstId === route.routeInstId) {
              _set(results, routeInstId, response.stops);
            } else {
              _set(results, routeInstId, stops);
            }
          });

          return new SetStopsForSelectedRoutes({
            stopsForSelectedRoutes: results,
          });
        })
      );
    })
  );

  @Effect()
  setStopsForSelectedRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<SetStopsForSelectedRoutes>(ActionTypes.setStopsForSelectedRoutes),
    withLatestFrom(this.store$.select(selectedStopsForSelectedRoutes)),
    switchMap(([stopsForRoutes, selectedStops]) => {
      // remove selectedStops that are no longer part of a selectedRoute
      const newSelectedStops = _filter(selectedStops, (selectedStop) => {
        const routeStops = stopsForRoutes.payload.stopsForSelectedRoutes[selectedStop.id.routeInstId];
        return !!_find(routeStops, (stop: Stop) =>
          areStopsEqual(selectedStop.id, {
            routeInstId: selectedStop.id.routeInstId,
            seqNo: stop.tripNode.stopSequenceNbr,
            origSeqNo: stop.tripNode.stopSequenceNbr,
          })
        );
      }) as EventItem<AssignedStopIdentifier>[];
      return [
        new SetSelectedStopsForSelectedRoutes({ selectedStopsForSelectedRoutes: newSelectedStops }),
        new RouteBalancingActions.SetCanOpenRouteBalancing({ canOpenRouteBalancing: true }),
      ];
    })
  );

  /// Utility methods
  private fetchTrips(criteria: TripsSearchCriteria): Observable<TripDetail[]> {
    const path: ListPnDTripsPath = {
      sicCd: criteria.sicCd,
    };
    const query: ListPnDTripsQuery = {
      zoneIndicatorCd: criteria.zoneIndicatorCd,
      tripStatusCd: [...criteria.tripStatusCd],
      tripDate: criteria.tripDate,
      tripDetailCds: undefined,
    };
    return this.cityOpsService.listPnDTrips(path, query).pipe(
      catchError(() => of([])),
      take(1),
      pluck('tripDetails')
    );
  }
}
