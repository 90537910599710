import { Injectable, OnDestroy } from '@angular/core';
import { DockRoutesActivityId } from '@pnd-store/dock-routes-store/dock-routes.state';
import { RouteBalancingSelectors } from '@pnd-store/route-balancing-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  Activity,
  ActivityShipment,
  DeliveryShipmentSearchRecord,
  InterfaceAcct,
  Route,
  RouteShipment,
  Stop,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { NodeTypeCd, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { TripsService } from 'app/inbound-planning/shared/services/trips.service';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  includes as _includes,
  map as _map,
  set as _set,
  size as _size,
  uniq as _uniq,
  sortBy as _sortBy,
} from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  PndStoreState,
  RoutesStoreActions,
  RoutesStoreSelectors,
  TripsStoreActions,
  TripsStoreSelectors,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
  DockRoutesStoreSelectors,
  DockRoutesStoreActions,
  PastDueShipmentsStoreSelectors,
  PastDueShipmentsStoreActions,
} from '../../../../store';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '../../../../store/dispatcher-trips-store';
import { ModifyTripDetailsActions, ModifyTripDetailsSelectors } from '../../../../store/modify-trip-details-store';
import { ModifyTripActivityId } from '../../../../store/modify-trip-details-store/modify-trip-details.state';
import { PndStore } from '../../../../store/pnd-store';
import { PluralMaps } from '../../../shared/classes/plural-maps';
import { SelectionSummaryData } from '../../../shared/components/selection-summary/selection-summary-data.class';
import { MapSplitPanelItemsId } from '../../../shared/enums/map-split-panel-items-id.enum';
import { SpecialServicesHelper } from '../../../shared/helpers/special-services/special-services.helper';
import {
  AssignedStopIdentifier,
  consigneeToId,
  EventItem,
  PastDueShipmentIdentifier,
  PlanningRouteShipmentIdentifier,
  routeStopToId,
  UnassignedDeliveryIdentifier,
  UnassignedPickupIdentifier,
} from '../../../shared/interfaces/event-item.interface';
import {
  MapSplitPanelService,
  SpecialServicesService,
  UnassignedDeliveriesCacheService,
} from '../../../shared/services';
import { PastDueShipmentsCacheService } from '../../../shared/services/past-due-cache.service';
import { PlanningRoutesCacheService } from '../../../shared/services/planning-routes-cache.service';
import { DispatcherTripsRouteRenderingService } from '../../dispatcher-trips/services/dispatcher-trips-route-rendering.service';
import {
  MapSplitPanelItem,
  MapSplitPanelItemData,
  SplitPanelComponentType,
} from '../../planning-map/components/map-split-panels/map-split-panel-item';
import { AssignShipmentsPanelTitle } from '../enums/assign-shipments-panel-title.enum';
import { AssignShipmentsSelectionSource } from '../enums/assign-shipments-selection-source.enum';
import { AssignType } from '../enums/assign-type.enum';
import { AssignShipmentsSplitPanelParams } from '../models/assign-shipments-split-panel-params.model';
import { LayoutPreferenceService } from './../../../../../shared/layout-manager/services/layout-preference.service';
import { TripPlanningGridItem } from './../../trip-planning/models/trip-planning-grid-item.model';

@Injectable({
  providedIn: 'root',
})
export class AssignShipmentsService implements OnDestroy {
  private readonly assignCompletedSubject = new Subject<AssignShipmentsSelectionSource>();
  readonly assignCompleted$ = this.assignCompletedSubject.asObservable();

  private readonly selectionChangedSubject = new Subject();
  readonly selectionChanged$ = this.selectionChangedSubject.asObservable();

  private readonly summarySubject = new BehaviorSubject<SelectionSummaryData>(new SelectionSummaryData());
  readonly summary$ = this.summarySubject.asObservable();

  readonly isRouteBalancingActive$: Observable<boolean>;

  currentSelectionSources: AssignShipmentsSelectionSource[] = [];
  selectedUnassignedDeliveriesShipments: DeliveryShipmentSearchRecord[] = [];
  selectedUnassignedPickupsShipments: EventItem<UnassignedPickupIdentifier>[] = [];
  selectedPlanningRouteShipments: DeliveryShipmentSearchRecord[] = [];
  selectedPastDueShipments: DeliveryShipmentSearchRecord[] = [];
  selectedTripDetailsShipments: Activity[] = [];
  selectedTripDetailsShipmentsActivityId: ModifyTripActivityId[] = [];
  selectedRouteStopShipments: Activity[] = [];
  selectedCustomers: InterfaceAcct[] = [];
  selectedStops: Stop[] = [];
  selectedDockStops: DockRoutesActivityId[] = [];

  private fromTripDetailsRouteIds: number[] = [];

  private fromPlanningRoutesRouteIds: number[] = [];
  private fromPastDueShipmentsIds: number[] = [];
  private fromRouteStopsRouteIds: number[] = [];
  private unsubscriber = new Unsubscriber();
  private lastAssignType: AssignType;

  private _selectedShipmentIds: number[] = [];
  set selectedShipmentIds(selectedShipmentIds: number[]) {
    this._selectedShipmentIds = selectedShipmentIds;
    this.updateSummary();
  }
  get selectedShipmentIds() {
    return this._selectedShipmentIds;
  }

  private _unmappedDeliveriesShipments: DeliveryShipmentSearchRecord[] = [];
  set unmappedDeliveriesShipments(unmappedDeliveriesShipments: DeliveryShipmentSearchRecord[]) {
    this._unmappedDeliveriesShipments = unmappedDeliveriesShipments;
    this.onSelectionUpdated();
  }
  get unmappedDeliveriesShipments() {
    return this._unmappedDeliveriesShipments;
  }

  private _selectedRouteAdditions: DeliveryShipmentSearchRecord[] = [];
  set selectedRouteAdditions(selectedRouteAdditions: DeliveryShipmentSearchRecord[]) {
    this._selectedRouteAdditions = selectedRouteAdditions;
    this.updateSummary();
  }
  get selectedRouteAdditions() {
    return this._selectedRouteAdditions;
  }

  private isUnassignedDeliveriesGridRefreshDisabledSubject = new BehaviorSubject<boolean>(false);
  readonly isUnassignedDeliveriesGridRefreshDisabled$ = this.isUnassignedDeliveriesGridRefreshDisabledSubject.asObservable();
  get isUnassignedDeliveriesGridRefreshDisabled() {
    return this.isUnassignedDeliveriesGridRefreshDisabledSubject.value;
  }

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private unassignedDeliveriesCacheService: UnassignedDeliveriesCacheService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private mapSplitPanelService: MapSplitPanelService,
    private dispatcherTripsRouteRenderingService: DispatcherTripsRouteRenderingService,
    private specialServicesService: SpecialServicesService,
    private layoutPreferenceService: LayoutPreferenceService,
    private tripsService: TripsService,
    private pastDueCacheService: PastDueShipmentsCacheService
  ) {
    this.subscribeToSelectedUnassignedDeliveriesShipments();
    this.subscribeToSelectedPlanningRouteShipments();
    this.subscribeToSelectedPastDueShipments();
    this.subscribeToSelectedRouteStops();
    this.subscribeToTripDetails();
    this.subscribeToSelectedUnassignedPickupsShipments();
    this.subscribeToDockRouteStops();
    this.isRouteBalancingActive$ = this.pndStore$.select(RouteBalancingSelectors.openRouteBalancingPanel);
  }

  private selectedTrip$(): Observable<number[]> {
    const selectedTripSelector = this.layoutPreferenceService.isDispatcherLayout()
      ? DispatcherTripsStoreSelectors.selectedTrips
      : TripsStoreSelectors.selectedTrips;

    const dispatchMapFun = (selectedTripsIds) => {
      const ids = [...selectedTripsIds].map((id) => +id);
      return [...ids];
    };

    const plannerMapFun = (tripPlanningItems) => {
      return tripPlanningItems?.map((item) => item?.tripInstId);
    };

    return this.pndStore$.select<TripPlanningGridItem[] | number[]>(selectedTripSelector).pipe(
      takeUntil(this.unsubscriber.done$),
      map((value) => (this.layoutPreferenceService.isDispatcherLayout() ? dispatchMapFun(value) : plannerMapFun(value)))
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Notifies assignCompleted$ subscribers that a new assign/reassign has been completed,
   * so they can act on accordingly.
   *
   * @param source
   */
  onAssignCompleted(source: AssignShipmentsSelectionSource) {
    this.assignCompletedSubject.next(source);

    // refresh data that may have been changed
    this.pndStore$.dispatch(new TripsStoreActions.Refresh());
    this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
    this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
    this.pndStore$.dispatch(new DockRoutesStoreActions.Refresh());
    this.pndStore$.dispatch(new PastDueShipmentsStoreActions.Refresh());
  }

  private isCurrentlySelectedSource(source: AssignShipmentsSelectionSource): boolean {
    return this.currentSelectionSources.some((current) => current === source);
  }

  private removeFromCurrentSources(source: AssignShipmentsSelectionSource): void {
    const sourceIndex = this.currentSelectionSources.indexOf(source);
    if (sourceIndex !== -1) {
      this.currentSelectionSources.splice(sourceIndex, 1);
    }
  }

  private addToCurrentSources(source: AssignShipmentsSelectionSource) {
    if (!this.isCurrentlySelectedSource(source)) {
      this.currentSelectionSources.push(source);
    }
  }

  /**
   * Updates the currentSelectionSources value, used to disable the form when there are
   * more than 1 selection sources, or no selections at all.
   */
  private onSelectionUpdated() {
    if (_size(this.selectedUnassignedDeliveriesShipments) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.UnassignedDeliveries);
    } else if (_size(this.selectedUnassignedDeliveriesShipments) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.UnassignedDeliveries);
    }

    if (_size(this.selectedPlanningRouteShipments) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.PlanningRouteShipments);
    } else if (_size(this.selectedPlanningRouteShipments) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.PlanningRouteShipments);
    }
    if (_size(this.selectedPastDueShipments) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.PastDueShipments);
    } else if (_size(this.selectedPastDueShipments) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.PastDueShipments);
    }

    if (_size(this.selectedStops) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.RouteStops);
    } else if (_size(this.selectedStops) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.RouteStops);
    }

    if (_size(this.unmappedDeliveriesShipments) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.UnmappedDeliveries);
    } else if (_size(this.unmappedDeliveriesShipments) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.UnmappedDeliveries);
    }

    if (_size(this.selectedTripDetailsShipments) > 0 || _size(this.selectedTripDetailsShipmentsActivityId) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.TripDetails);
    } else if (
      _size(this.selectedTripDetailsShipments) === 0 ||
      _size(this.selectedTripDetailsShipmentsActivityId) === 0
    ) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.TripDetails);
    }

    if (_size(this.selectedUnassignedPickupsShipments) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.UnassignedPickups);
    } else {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.UnassignedPickups);
    }

    if (_size(this.selectedUnassignedDeliveriesShipments) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.UnassignedDeliveries);
    } else if (_size(this.selectedUnassignedDeliveriesShipments) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.UnassignedDeliveries);
    }

    if (_size(this.selectedDockStops) > 0) {
      this.addToCurrentSources(AssignShipmentsSelectionSource.DockRoutes);
    } else if (_size(this.selectedDockStops) === 0) {
      this.removeFromCurrentSources(AssignShipmentsSelectionSource.DockRoutes);
    }

    this.updateSummary();
    this.selectionChangedSubject.next();

    if (this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.UnassignedPickups)) {
      this.toggleAssignPickupsInMapSplitPanel(true);
    } else if (!this.arePickupsInTripDetails()) {
      this.toggleAssignPickupsInMapSplitPanel(false);
    }

    if (
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.UnassignedDeliveries) ||
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.PlanningRouteShipments) ||
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.PastDueShipments) ||
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.RouteStops) ||
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.TripDetails) ||
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.UnmappedDeliveries) ||
      this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.DockRoutes)
    ) {
      this.toggleAssignShipmentsInMapSplitPanel(true);
    } else {
      this.toggleAssignShipmentsInMapSplitPanel(false);
    }
  }

  private subscribeToSelectedUnassignedDeliveriesShipments() {
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(
        withLatestFrom(this.unassignedDeliveriesCacheService.unassignedDeliveries$),
        withLatestFrom(this.unassignedDeliveriesCacheService.unmappedDeliveries$),
        withLatestFrom(this.unassignedDeliveriesCacheService.unBilledDeliveries$),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(([[[selecteds, unassigneds], unmappedDeliveries], unBilledDeliveries]) => {
        const selectedIds = _map(selecteds, (item) => item.id);
        const arrayOfUnmappedAndUnassignedDL: UnassignedStop[] = [
          ...unassigneds,
          ...unmappedDeliveries,
          ...unBilledDeliveries,
        ];
        this.selectedUnassignedDeliveriesShipments = this.deliveriesFromSelected(
          selectedIds,
          arrayOfUnmappedAndUnassignedDL
        );
        this.onSelectionUpdated();
      });
  }

  private subscribeToSelectedUnassignedPickupsShipments(): void {
    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((pickupsSelected: EventItem<UnassignedPickupIdentifier>[]) => {
        this.selectedUnassignedPickupsShipments = pickupsSelected;
        this.onSelectionUpdated();
      });
  }

  private subscribeToSelectedPlanningRouteShipments() {
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((selectedShipments) => {
        // build map of all DeliveryShipmentSearchRecords for selected shipmetns
        const idToIdentity: { [key: string]: PlanningRouteShipmentIdentifier } = {};
        const idToShipments: { [key: string]: DeliveryShipmentSearchRecord[] } = {};

        _forEach(selectedShipments, (shipmentId: EventItem<PlanningRouteShipmentIdentifier>) => {
          const sid = routeStopToId(shipmentId.id);
          _set(idToIdentity, sid, shipmentId);

          const deliveryShipment = this.planningRoutesCacheService.getDeliveryShipmentSearchRecord(shipmentId.id);
          if (deliveryShipment) {
            const deliveryShipments = idToShipments?.[sid] ?? [];
            _set(idToShipments, sid, deliveryShipments.concat(deliveryShipment));
          }
        });

        const selectedPlanningShipments: DeliveryShipmentSearchRecord[] = [];
        _forOwn(idToIdentity, (identity, sid) => {
          const shipments = idToShipments?.[sid] ?? [];
          selectedPlanningShipments.push(...shipments);
        });

        this.selectedPlanningRouteShipments = selectedPlanningShipments;

        // used to disable assigning (only shipments from one route at a time are allowed)
        this.fromPlanningRoutesRouteIds = _uniq(
          this.selectedPlanningRouteShipments.map((shipment) => shipment.routeInstId).filter((routeName) => !!routeName)
        );

        this.onSelectionUpdated();
      });
  }

  private subscribeToSelectedPastDueShipments() {
    this.pndStore$
      .select(PastDueShipmentsStoreSelectors.selectedPastDueShipments)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((selectedShipments) => {
        // build map of all DeliveryShipmentSearchRecords for selected shipmetns
        const idToIdentity: { [key: string]: PastDueShipmentIdentifier } = {};
        const idToShipments: { [key: string]: DeliveryShipmentSearchRecord[] } = {};

        _forEach(selectedShipments, (shipmentId: EventItem<PastDueShipmentIdentifier>) => {
          const sid = routeStopToId(shipmentId.id);
          _set(idToIdentity, sid, shipmentId);

          const deliveryShipment = this.pastDueCacheService.getPastDueShipmentSearchRecord(
            shipmentId.id.shipmentInstId
          );
          if (deliveryShipment) {
            const deliveryShipments = idToShipments?.[sid] ?? [];
            _set(idToShipments, sid, deliveryShipments.concat(deliveryShipment));
          }
        });

        const selectedPlanningShipments: DeliveryShipmentSearchRecord[] = [];
        _forOwn(idToIdentity, (identity, sid) => {
          const shipments = idToShipments?.[sid] ?? [];
          selectedPlanningShipments.push(...shipments);
        });

        this.selectedPastDueShipments = selectedPlanningShipments;

        // used to disable assigning (only shipments from one route at a time are allowed)
        this.fromPastDueShipmentsIds = _uniq(
          this.selectedPastDueShipments.map((shipment) => shipment.routeInstId).filter((routeName) => !!routeName)
        );

        this.onSelectionUpdated();
      });
  }

  private subscribeToSelectedRouteStops() {
    combineLatest([
      this.pndStore$.select(TripsStoreSelectors.stopsForSelectedRoutes),
      this.dispatcherTripsRouteRenderingService.stopsForSelectedRoutes$,
      this.pndStore$.select(TripsStoreSelectors.selectedStopsForSelectedRoutes),
    ])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([stopsForSelectedRoutes, stopsForSelectedRoutesService, selectedStopsForSelectedRoutes]) => {
        this.selectedStops = [];
        this.selectedRouteStopShipments = [];
        selectedStopsForSelectedRoutes.forEach((identifier: EventItem<AssignedStopIdentifier>) => {
          let allStops: Stop[] = stopsForSelectedRoutes?.[identifier.id.routeInstId] ?? [];

          if (allStops.length === 0) {
            allStops = stopsForSelectedRoutesService?.[identifier.id.routeInstId] ?? [];
          }

          const selectedStop: Stop = allStops.find(
            (stop) => stop?.tripNode?.stopSequenceNbr === identifier.id.origSeqNo
          );

          if (selectedStop) {
            _forEach(selectedStop.activities, (activity: Activity) => {
              if (activity.routeShipment === undefined) {
                activity.routeShipment = { ...new RouteShipment(), routeInstId: identifier.id.routeInstId };
              }
              this.selectedRouteStopShipments.push(activity);
            });
            this.selectedStops.push(selectedStop);
          }
        });

        // used to disable assigning (only shipments from one route at a time are allowed)
        this.fromRouteStopsRouteIds = _uniq(
          selectedStopsForSelectedRoutes.map((eventItem) => eventItem?.id?.routeInstId).filter((routeId) => !!routeId)
        );

        this.onSelectionUpdated();
      });
  }

  private subscribeToTripDetails() {
    let selectedTripDetailsShipmentsActivityId: ModifyTripActivityId[] = [];
    combineLatest([
      this.pndStore$.select(ModifyTripDetailsSelectors.selectedActivities).pipe(
        withLatestFrom(this.selectedTrip$()),
        map(([activities, selectedTrips]: [ModifyTripActivityId[], number[]]) => {
          let tripInstIds = [];
          const selectedShipmentIds: number[] = [];
          _forEach(activities, (activity: ModifyTripActivityId) => {
            selectedTripDetailsShipmentsActivityId.push(activity);
            tripInstIds.push(activity.tripInstId);
            selectedShipmentIds.push(activity.shipmentInstId);
          });
          if (activities?.length > 0) {
            tripInstIds = [...tripInstIds, ...selectedTrips];
          }
          tripInstIds = _uniq(tripInstIds);
          if (tripInstIds.length > 0) {
            this.dispatcherTripsRouteRenderingService.getStops(tripInstIds);
          }
          if (activities.length === 0) {
            selectedTripDetailsShipmentsActivityId = [];
          }
          this.selectedTripDetailsShipmentsActivityId = selectedTripDetailsShipmentsActivityId;
          return selectedShipmentIds;
        })
      ),
      this.dispatcherTripsRouteRenderingService.stopsForSelectedRoutes$,
      this.dispatcherTripsRouteRenderingService.selectedRoutes$,
    ])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([selectedShipmentIds, stopsForSelectedRoutes, selectedRoutes]) => {
        this.selectedTripDetailsShipments = [];

        const selectedShipmentsInfo = [];
        this.selectedCustomers = [];
        const tripDetailsSelectedStop: Map<number, Stop[]> = new Map<number, Stop[]>();

        _forEach(selectedRoutes, (selectedRoute: Route) => {
          const stopsForRoute = stopsForSelectedRoutes[selectedRoute?.routeInstId];

          _forEach(selectedShipmentIds, (shipmentId) => {
            _forEach(stopsForRoute, (stop: Stop) => {
              const activitiesInStop: Activity[] = stop?.activities ?? [];
              const customer = stop?.customer;
              const activityInStop: Activity = activitiesInStop.find(
                (activity: Activity) => activity?.tripNodeActivity?.shipmentInstId === shipmentId
              );
              if (activityInStop) {
                tripDetailsSelectedStop.set(selectedRoute?.routeInstId, stopsForRoute);
                selectedShipmentsInfo.push(activityInStop);
                this.selectedCustomers.push(customer);
                return false;
              }
            });
          });
        });
        this.fromTripDetailsRouteIds = [...tripDetailsSelectedStop.keys()];
        this.selectedCustomers = _uniq(this.selectedCustomers);

        this.selectedTripDetailsShipments = selectedShipmentsInfo;
        this.onSelectionUpdated();
      });
  }

  private subscribeToDockRouteStops() {
    this.pndStore$
      .select(DockRoutesStoreSelectors.selectedDockActivities)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((pickupsSelected) => {
        this.selectedDockStops = pickupsSelected;
        this.onSelectionUpdated();
      });
  }

  /**
   * Get selected deliveries from Unassigned Deliveries grid
   */
  private deliveriesFromSelected(
    selectedIds: UnassignedDeliveryIdentifier[],
    unassignedStops: UnassignedStop[]
  ): DeliveryShipmentSearchRecord[] {
    const selectedDeliveries: DeliveryShipmentSearchRecord[] = [];

    // gather all shipments in selected stops
    const selectedConsignees = _filter(selectedIds, (item: UnassignedDeliveryIdentifier) => {
      return !item.shipmentInstId;
    });

    _forEach(selectedConsignees, (id: UnassignedDeliveryIdentifier) => {
      // find stop for this selected shipment
      const stop = _find(unassignedStops, (us: UnassignedStop) => {
        return consigneeToId(us) === consigneeToId(id);
      });

      const deliveryShipments = stop?.deliveryShipments ?? [];
      selectedDeliveries.push(
        ...deliveryShipments.map((deliveryShipmentRecord: DeliveryShipmentSearchRecord) => ({
          ...deliveryShipmentRecord,
          appointmentStatusCd: stop?.appointmentStatusCd,
        }))
      );
    });

    // gather all selected shipments from Items
    const selectedShipments = _filter(selectedIds, (item: UnassignedDeliveryIdentifier) => {
      return !!item.shipmentInstId;
    });
    _forEach(selectedShipments, (id: UnassignedDeliveryIdentifier) => {
      // find stop for this selected shipment
      const stop = _find(unassignedStops, (us: UnassignedStop) => {
        return consigneeToId(us) === consigneeToId(id);
      });

      const shipment = stop?.deliveryShipments?.find(
        (ds: DeliveryShipmentSearchRecord) => ds.shipmentInstId === id.shipmentInstId
      );

      if (shipment) {
        selectedDeliveries.push(shipment);
      }
    });

    return selectedDeliveries;
  }

  /**
   * Clear the selection of sources different than the one received as a parameter.
   *
   * @param source
   */
  selectSource(source: AssignShipmentsSelectionSource) {
    const sourcesToDeselect = this.currentSelectionSources.filter((s) => s !== source);

    sourcesToDeselect.forEach((deselectSource) => {
      this.removeFromCurrentSources(deselectSource);
      switch (deselectSource) {
        case AssignShipmentsSelectionSource.UnmappedDeliveries: {
          this.unmappedDeliveriesShipments = [];
          this.onSelectionUpdated();
          break;
        }
        case AssignShipmentsSelectionSource.UnassignedDeliveries: {
          this.pndStore$.dispatch(
            new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
              selectedDeliveries: [],
            })
          );
          break;
        }
        case AssignShipmentsSelectionSource.PlanningRouteShipments: {
          this.pndStore$.dispatch(
            new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
              selectedPlanningRoutesShipments: [],
            })
          );
          break;
        }
        case AssignShipmentsSelectionSource.PastDueShipments: {
          this.pndStore$.dispatch(
            new PastDueShipmentsStoreActions.SetSelectedPastDueShipmentsAction({
              setSelectedPastDueShipments: [],
            })
          );
          break;
        }
        case AssignShipmentsSelectionSource.RouteStops: {
          this.pndStore$.dispatch(
            new TripsStoreActions.SetSelectedStopsForSelectedRoutes({
              selectedStopsForSelectedRoutes: [],
            })
          );
          break;
        }
        case AssignShipmentsSelectionSource.TripDetails: {
          this.pndStore$.dispatch(
            new ModifyTripDetailsActions.SetSelectedActivities({
              selectedActivities: [],
            })
          );
          break;
        }
        case AssignShipmentsSelectionSource.UnassignedPickups: {
          this.pndStore$.dispatch(
            new UnassignedPickupsStoreActions.SetSelectedUnassignedPickups({
              selectedPickups: [],
            })
          );
          break;
        }
        case AssignShipmentsSelectionSource.DockRoutes: {
          this.pndStore$.dispatch(
            new DockRoutesStoreActions.SetSelectedDockActivities({
              selectedDockActivities: [],
            })
          );
          break;
        }
      }
    });

    switch (source) {
      case AssignShipmentsSelectionSource.UnassignedPickups:
        this.toggleAssignShipmentsInMapSplitPanel(false);
        this.toggleAssignPickupsInMapSplitPanel(true, true, true);
        break;
      case AssignShipmentsSelectionSource.UnassignedDeliveries:
        this.toggleAssignPickupsInMapSplitPanel(false);
        this.toggleAssignShipmentsInMapSplitPanel(true, true, true);
        break;
    }
  }

  /**
   * Returns the route ids from which we are reassigning.
   * Only applicable for RouteStops, PlanningRouteShipments & TripDetails
   */
  getFromRouteIds(): number[] {
    if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.RouteStops) {
      return this.fromRouteStopsRouteIds;
    } else if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PlanningRouteShipments) {
      return this.fromPlanningRoutesRouteIds;
    } else if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PastDueShipments) {
      return this.fromPastDueShipmentsIds;
    } else if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.TripDetails) {
      return this.fromTripDetailsRouteIds;
    } else {
      return [];
    }
  }

  /**
   * Returns an array of the shipments selected for assign/reassign.
   * The source can be:
   *   - Unmapped Deliveries
   *   - Unassigned Deliveries
   *   - TripDetailss
   *   - Planning Route Shipments
   */
  getSelectedShipments(): (DeliveryShipmentSearchRecord | Activity)[] {
    let shipments = [];
    if (_size(this.selectedUnassignedDeliveriesShipments) > 0) {
      shipments = this.selectedUnassignedDeliveriesShipments;
    } else if (_size(this.selectedUnassignedPickupsShipments) > 0) {
      shipments = this.selectedUnassignedPickupsShipments;
    } else if (_size(this.selectedPlanningRouteShipments) > 0) {
      shipments = this.selectedPlanningRouteShipments;
    } else if (_size(this.selectedPastDueShipments) > 0) {
      shipments = this.selectedPastDueShipments;
    } else if (_size(this.selectedTripDetailsShipments) > 0) {
      shipments = this.selectedTripDetailsShipments;
    } else if (_size(this.selectedDockStops) > 0) {
      shipments = this.selectedDockStops;
    } else {
      shipments = this.unmappedDeliveriesShipments;
    }
    return shipments;
  }

  private clearSummary() {
    this.summarySubject.next(undefined);
  }

  private updateSummary() {
    this.clearSummary();

    const selectedShipments = [...this.getSelectedShipments()];
    const additions = [];
    if (
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PlanningRouteShipments ||
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.UnassignedDeliveries ||
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.UnassignedPickups ||
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PastDueShipments
    ) {
      // add selected recommendations
      additions.push(...this.selectedRouteAdditions);
    }
    if (
      _size(selectedShipments) > 0 &&
      _includes(this.currentSelectionSources, AssignShipmentsSelectionSource.TripDetails)
    ) {
      this.summarySubject.next(this.buildSummaryForTripDetailsShipments(<Activity[]>selectedShipments));
    } else if (
      _size(selectedShipments) > 0 &&
      _includes(this.currentSelectionSources, AssignShipmentsSelectionSource.DockRoutes)
    ) {
      this.summarySubject.next(this.buildSummaryForSelectedDockStops(this.selectedDockStops));
    } else if (_size(selectedShipments) > 0) {
      this.summarySubject.next(
        this.buildSummaryForSelectedShipments(<DeliveryShipmentSearchRecord[]>selectedShipments, additions)
      );
    } else if (_size(this.selectedStops) > 0) {
      this.summarySubject.next(this.buildSummaryForSelectedStops(this.selectedStops));
    }
  }

  buildSummaryForTripDetailsShipments(activities: Activity[]) {
    const newSummary = new SelectionSummaryData();
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];

    newSummary.shipments = _size(activities);

    _forEach(activities, (activity: Activity) => {
      const shipment = activity?.tripNodeActivity;
      newSummary.motorMoves += shipment?.totalMmCount ?? 0;
      newSummary.weight += shipment?.totalWeightCount ?? 0;
      servicesAccumulator = servicesAccumulator.concat(
        SpecialServicesHelper.getSpecialServicesForSummary(activity.shipmentSpecialServices)
      );

      activity?.activityShipments?.forEach((activityShipment: ActivityShipment) => {
        this.specialServicesService.getSpecialServiceAppointmentSummaryMark(
          activityShipment,
          newSummary,
          'appointmentStatusCd'
        );
      });
    });
    newSummary.stops = _size(this.selectedCustomers);
    newSummary.specialServices = _uniq(servicesAccumulator);

    this.specialServicesService.addPendingMarksToSpecialServices(
      newSummary.specialServices,
      newSummary.specialServicesMarks
    );

    if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.TripDetails) {
      newSummary.selectedDisplayName = PluralMaps.Customers;
    }
    return newSummary;
  }

  private buildSummaryForSelectedShipments(
    selectedShipments: DeliveryShipmentSearchRecord[],
    additions: DeliveryShipmentSearchRecord[]
  ) {
    const consigneeIds: number[] = [];
    // gather all the stats for selected shipments
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];
    const newSummary = new SelectionSummaryData();
    const allShipments = [...selectedShipments, ...additions];

    newSummary.shipments = _size(allShipments);
    _forEach(allShipments, (shipment) => {
      consigneeIds.push(shipment?.consignee?.acctInstId);

      newSummary.motorMoves += shipment.motorizedPiecesCount;
      newSummary.weight += shipment.totalWeightLbs;

      this.specialServicesService.getSpecialServiceAppointmentSummaryMark(shipment, newSummary, 'appointmentStatusCd');

      servicesAccumulator = servicesAccumulator.concat(
        SpecialServicesHelper.getSpecialServicesForSummary(shipment.specialServiceSummary)
      );
    });

    newSummary.stops = _size(_uniq(consigneeIds));
    newSummary.specialServices = _uniq(servicesAccumulator);
    this.specialServicesService.addPendingMarksToSpecialServices(
      newSummary.specialServices,
      newSummary.specialServicesMarks
    );

    // Set label name (Stops or Customers for example)
    if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.UnassignedDeliveries) {
      newSummary.selectedDisplayName = PluralMaps.Customers;
    }
    return newSummary;
  }

  private buildSummaryForSelectedStops(selectedStops: Stop[]) {
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];
    const newSummary = new SelectionSummaryData();

    newSummary.shipments = 0;
    newSummary.motorMoves = 0;
    newSummary.weight = 0;

    newSummary.stops = 0;
    newSummary.specialServices = [];

    selectedStops
      .filter((s) => s?.tripNode?.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER)
      .forEach((stop) => {
        if (stop.activities && stop.activities.length) {
          newSummary.stops++;
          stop.activities.forEach((act: Activity) => {
            if (act.tripNodeActivity) {
              newSummary.shipments += act.tripNodeActivity.totalBillCount;
              newSummary.motorMoves += act.tripNodeActivity.totalMmCount;
              newSummary.weight += act.tripNodeActivity.totalWeightCount;
            }
          });
        }
        servicesAccumulator = servicesAccumulator.concat(SpecialServicesHelper.getSpecialServicesForStop(stop));
      });

    newSummary.specialServices = _uniq(servicesAccumulator);
    return newSummary;
  }

  private arePickupsInTripDetails(): boolean {
    const tripActivity = this.pndStore$.selectSnapshot(ModifyTripDetailsSelectors.selectedActivities);
    return !!_find(tripActivity, (activity) => activity.pickupRequestInstId > 0);
  }

  openAssignShipmentsPanel(params: AssignShipmentsSplitPanelParams, expanded = true, overwrite = true) {
    const arePickupsInTripDetails = this.arePickupsInTripDetails();
    const hasDifferentSource = this.currentSelectionSources.some(
      (current) => current !== AssignShipmentsSelectionSource.TripDetails
    );

    if (arePickupsInTripDetails) {
      this.toggleAssignPickupsInMapSplitPanel(true, expanded, true);
    }

    if (
      (this.isCurrentlySelectedSource(AssignShipmentsSelectionSource.TripDetails) && !arePickupsInTripDetails) ||
      hasDifferentSource
    ) {
      params = params || { assignType: AssignType.Existing };
      this.lastAssignType = params.assignType;
      const config: MapSplitPanelItemData = {
        title: AssignShipmentsPanelTitle.ASSIGN,
        id: MapSplitPanelItemsId.ASSIGN_SHIPMENTS,
        orderIndex: 1,
        expanded,
        params,
      };
      const assignDeliveriesItem = new MapSplitPanelItem(SplitPanelComponentType.AssignShipments, config);
      this.isRouteBalancingActive$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((status) => {
        status
          ? this.mapSplitPanelService.collapseAllPanelItems()
          : this.mapSplitPanelService.addPanelItem(assignDeliveriesItem, overwrite);
      });
    }
  }

  /**
   * Change the visibility of 'Assign shipments' in the map's split panel.
   * If visible is false, remove the panel.
   * If visible is true and the panel is already open (expanded or minimized), keep it as it is.
   * If visible is true and the panel is not open, display it in a minimized state.
   *
   * @param visible
   */
  private toggleAssignShipmentsInMapSplitPanel(
    visible: boolean,
    expanded: boolean = false,
    overwrite: boolean = false
  ) {
    if (visible) {
      const assignType: AssignType = this.lastAssignType || AssignType.Existing;
      const params = { assignType: assignType };
      this.openAssignShipmentsPanel(params, expanded, overwrite);
    } else {
      this.mapSplitPanelService.removePanelById(MapSplitPanelItemsId.ASSIGN_SHIPMENTS);
    }
  }

  toggleAssignPickupsInMapSplitPanel(visible: boolean, expanded: boolean = false, overwrite: boolean = false) {
    if (visible) {
      const assignPickupsItem = new MapSplitPanelItem(SplitPanelComponentType.AssignPickups, {
        title: AssignShipmentsPanelTitle.ASSIGN_PICKUPS,
        id: MapSplitPanelItemsId.ASSIGN_PICKUPS,
        orderIndex: 1,
        expanded: expanded,
      });
      this.mapSplitPanelService.addPanelItem(assignPickupsItem, overwrite);
    } else {
      this.mapSplitPanelService.removePanelById(MapSplitPanelItemsId.ASSIGN_PICKUPS);
    }
  }

  isActionsEnabled(): boolean {
    if (this.currentSelectionSources.length === 1) {
      return true;
    } else if (
      this.currentSelectionSources.length === 2 &&
      _includes(this.currentSelectionSources, AssignShipmentsSelectionSource.RouteStops) &&
      _includes(this.currentSelectionSources, AssignShipmentsSelectionSource.TripDetails)
    ) {
      return this.isSameRouteTripSelections();
    }
    return false;
  }

  private isSameRouteTripSelections(): boolean {
    const routeStopShipments = _sortBy(
      _map(this.selectedRouteStopShipments, 'tripNodeActivity'),
      'tripNodeActivitySequenceNbr'
    );
    const tripDetailsShipments = _sortBy(
      _map(this.selectedTripDetailsShipments, 'tripNodeActivity'),
      'tripNodeActivitySequenceNbr'
    );
    return routeStopShipments?.length > 0 && tripDetailsShipments.length > 0;
  }

  private buildSummaryForSelectedDockStops(selectedDockStops: DockRoutesActivityId[]) {
    const servicesAccumulator: ShipmentSpecialServiceCd[] = [];
    const newSummary = new SelectionSummaryData();
    newSummary.shipments = 0;
    newSummary.motorMoves = 0;
    newSummary.weight = 0;

    newSummary.stops = 0;
    newSummary.specialServices = [];
    _forEach(selectedDockStops, (stop) => {
      newSummary.stops++;
      newSummary.shipments += stop?.billCount;
      newSummary.weight += stop?.weightLbs;
      newSummary.motorMoves += stop?.motorMovesNbr;
      _forEach(stop?.specialServices, (service) => {
        servicesAccumulator.push(service);
      });
    });
    newSummary.specialServices = _uniq(servicesAccumulator);
    return newSummary;
  }
}
