import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GridToolbarService {
  private readonly searchActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly searchActive$: Observable<boolean> = this.searchActiveSubject.asObservable();

  private readonly searchFilterValueSubject: BehaviorSubject<string> = new BehaviorSubject('');
  readonly searchFilterValue$: Observable<string> = this.searchFilterValueSubject.asObservable();

  constructor() {}

  setSearchActive(isActive: boolean): void {
    this.searchActiveSubject.next(isActive);
  }

  setSearchFilter(searchTxt: string): void {
    this.searchFilterValueSubject.next(searchTxt);
  }
}
