<div class="unassignedPickupsDetailGrid">
  <div *ngIf="detailGridType === detailGridTypes.LINE_ITEMS" class="unassignedPickupsDetailGrid__lineItems">
    <ag-grid-angular
      [columnDefs]="lineItemsColumnDefs"
      [rowData]="lineItemsRowData"
      [gridOptions]="lineItemsGridOptions"
      [rowHeight]="getCurrentRowHeight()"
      rowSelection="multiple"
      (rowSelected)="onRowSelected($event)"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
  <div *ngIf="detailGridType === detailGridTypes.REMARKS" class="unassignedPickupsDetailGrid__remarks">
    <ag-grid-angular
      [columnDefs]="remarksColumnDefs"
      [rowData]="notesRowData"
      [gridOptions]="notesGridOptions"
      [rowHeight]="remarksRowHeight"
    >
    </ag-grid-angular>
  </div>
</div>
