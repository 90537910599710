import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { defaultTo as _defaultTo } from 'lodash';
import { TypeCountTotal } from './interfaces/type-count-total.interface';

@Component({
  selector: 'pnd-type-count-cell-renderer',
  templateUrl: './type-count-cell-renderer.component.html',
  styleUrls: ['./type-count-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeCountCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  typeCdMap: TypeCountTotal[] = [];
  tooltipVal: string;

  constructor() {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.typeCdMap = _defaultTo(params.value, []);
    this.setTooltip();
  }

  refresh(params: ICellRendererParams) {
    this.agInit(params);
    return true;
  }

  private setTooltip(): void {
    this.tooltipVal = this.typeCdMap
      .map((typeCountItem: TypeCountTotal) => {
        return `${typeCountItem.count} ${typeCountItem.key}`;
      })
      .join(', ');
  }
}
