import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { NumberToValueMap } from '@pnd-store/number-to-value-map';
import { PndStore } from '@pnd-store/pnd-store';
import {
  InfrastructureApiService,
  ListPrintersQuery,
  ListPrintersResp,
  Printer,
} from '@xpo-ltl-2.0/sdk-infrastructure';
import { LocationApiService, ListLocationFeaturesQuery, ListLocationFeaturesResp } from '@xpo-ltl-2.0/sdk-location';
import {
  CreateFBDSDocumentsResp,
  CreateFBDSDocumentsRqst,
  PrintFBDSDocumentsRqst,
  RouteName,
  ShipmentApiService,
  PrintFBDSDocumentsResp,
} from '@xpo-ltl-2.0/sdk-shipment';
import { Unsubscriber, XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import {
  Activity,
  CityOperationsApiService,
  DeliverySetPrint,
  StartPrintDocumentEnsembleResp,
  StartPrintDocumentEnsembleRqst,
  Stop,
  UnassignedStop,
  PrintOption,
  GetServiceCenterPrintPreferencesPath,
  GetServiceCenterPrintPreferencesResp,
  PrintRequest,
  ManifestPrint,
  CreatePnDManifestDocumentsRqst,
  CreatePnDManifestDocumentsResp,
  CreateDsrCheckInDocumentsRqst,
  CreateDsrCheckInDocumentsResp,
  DsrCheckinPrint,
  PrintOutboundBreakoutManifestReportRqst,
  PrintOutboundBreakoutManifestReportPath,
  ListDeliveryManifestsRqst,
  ListDeliveryManifestsPath,
  ListDeliveryManifestsResp,
  DeliveryManifestForConsignee,
} from '@xpo-ltl/sdk-cityoperations';
import {
  DocumentFormTypeCd,
  PrintFBDSDocumentsTypeCd,
  ShipmentId,
  PnDReportTypeCd,
  TripNodeActivityCd,
  FbdsVersionCd,
} from '@xpo-ltl/sdk-common';

import { AutoCompleteItem } from 'app/inbound-planning/shared/components/autocomplete/autocomplete.component';
import { PlanningRoutesCacheService } from 'app/inbound-planning/shared/services/planning-routes-cache.service';
import { UserPreferencesService } from 'app/inbound-planning/shared/services/user-preferences.service';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import { NotificationMessageService } from 'core/services/notification-message.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { size as _size, sortBy as _sortBy, uniqBy as _uniqBy, isEqual as _isEqual } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, take, takeUntil, tap, startWith } from 'rxjs/operators';
import { LanguageOptions } from 'shared/enums/language-type.enum';
import { LanguageTypeEnum } from 'shared/enums/language.enum';
import { PndRouteUtils } from 'shared/route-utils';
import { GenericErrorLazyTypedModel } from '../../../app/inbound-planning/shared/models/generic-error-lazy-typed.model';

import {
  BillOfLadingApiService,
  GenerateBillOfLadingPdfQuery,
  GenerateBillOfLadingPdfResp,
} from '@xpo-ltl/sdk-billoflading';
import { ComponentChangeUtils } from 'app/inbound-planning/shared/classes/component-change-utils';
import { Consignee, Dman, DmanShipment } from 'app/inbound-planning/shared/components/dman-panel/dmanData.interface';
import { PndStoreState } from '../../../app/store';
import { DmanFormFields } from './dman-form-fields.enum';
import { PrintDialogData } from './print-dialog.data';
import { PrintFormFields } from './print-form-fields.enum';
import { PrintGridSources } from './print-grid-sources.enum';
import { PrintModeEnum } from './print-mode.enum';
import { PrintPaperTypeEnum } from './print-paper-type.enum';
import LegacyPrintersList from './printers.json';
import { ProNumberAndStopSequenceNumber } from './pronumber-stopsequencenumber';
import { UserPrintPreferences } from './user-print-preferences';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintDialogComponent implements OnInit, OnDestroy {
  protected unsubscriber = new Unsubscriber();

  readonly PrintPaperTypeEnum = PrintPaperTypeEnum;
  readonly LanguageTypeEnum = LanguageTypeEnum;
  readonly LanguageOptions = LanguageOptions;
  readonly PrintModeEnum = PrintModeEnum;
  readonly PrintGridSources = PrintGridSources;
  readonly PrintFormFields = PrintFormFields;
  readonly DmanFormFields = DmanFormFields;

  printOption: PrintOption;
  activePndTrays$: Observable<AutoCompleteItem[]>;
  activeFbdsTrays$: Observable<AutoCompleteItem[]>;
  activeDsrTray$: Observable<AutoCompleteItem[]>;
  activeOubtoundTray$: Observable<AutoCompleteItem[]>;
  activeEbolTrays$: Observable<AutoCompleteItem[]>;
  private readonly userPrintPreferenceKey = 'user-print-preference-key';

  dmanForm: FormGroup;

  formGroup: UntypedFormGroup;

  preferredPrinterFbds: string = '';
  preferredTrayFbds: string = '';
  preferredPrinterTwoPartFbds: string = '';
  preferredPrinterPnd: string = '';
  preferredTrayPnd: string = '';
  preferredPrinterDock: string = '';
  preferredPrinterDsr: string = '';
  preferredTrayDsr: string = '';
  preferredPrinterOutbound: string = '';
  preferredTrayOutbound: string = '';
  selectedPrinterFbds: string = '';
  selectedTrayFbds: string = '';
  selectedPrinterTwoPartFbds: string = '';
  selectedPrinterPnd: string = '';
  selectedTrayPnd: string = '';
  selectedPrinterDock: string = '';
  preferredReprint: string = 'N';
  disableGridPrint: boolean = false;
  disableLocationPrint: boolean = false;
  isFbdsDownloadError: boolean = false;
  isPndDownloadError: boolean = false;
  isEbolDownloadError: boolean = false;
  printSource: PrintGridSources;
  selectedItems: string[] = [];
  routeNames: RouteName[] = [];
  routeNamesWithError: string[] = [];
  dmanPrintOptions: string[] = ['Print', 'Reprint'];

  selectedItemLabel: string = 'Shipments';

  private readonly activePrintersListSubject = new BehaviorSubject<AutoCompleteItem[]>([]);
  readonly activePrintersList$ = this.activePrintersListSubject.asObservable();

  private readonly legacyPrinterListSubject = new BehaviorSubject<AutoCompleteItem[]>([]);
  readonly legacyPrintersList$ = this.legacyPrinterListSubject.asObservable();

  isReprintOptionAvailable: boolean = false;

  printersListFromAPI: string[] = [];
  traysForSelectedPrinter: string[] = [];
  printerTrayMap = new Map<string, string[]>();

  private readonly isPrintAllowedSubject = new BehaviorSubject(false);
  readonly isPrintAllowed$ = this.isPrintAllowedSubject.asObservable();

  private readonly isLoadingSubject = new BehaviorSubject(false);
  readonly isLoading$ = this.isLoadingSubject.asObservable();

  private readonly isManifestPrintDisabledSubject = new BehaviorSubject(false);
  readonly isManifestPrintDisabled$ = this.isManifestPrintDisabledSubject.asObservable();

  private readonly isServiceDisabledSubject = new BehaviorSubject(false);
  readonly isServiceDisabled$ = this.isServiceDisabledSubject.asObservable();

  private readonly isOpenPdfAllowedSubject = new BehaviorSubject(false);
  readonly isOpenPdfAllowed$ = this.isOpenPdfAllowedSubject.asObservable();

  private readonly printDmanSubject = new BehaviorSubject<Dman[]>([]);
  readonly printDmans$ = this.printDmanSubject.asObservable();

  private readonly rePrintDmanSubject = new BehaviorSubject<boolean>(false);
  readonly rePrintDmans$ = this.rePrintDmanSubject.asObservable();

  readonly strictSelection: boolean = false;
  fbdsPrinterCtrl: UntypedFormControl;
  fbdsTrayCtrl: UntypedFormControl;
  fbdsTwoPartPrinterCtrl: UntypedFormControl;
  pndPrinterCtrl: UntypedFormControl;
  pndTrayCtrl: UntypedFormControl;
  dockPrinterCtrl: UntypedFormControl;
  dsrPrinterCtrl: UntypedFormControl;
  dsrTrayCtrl: UntypedFormControl;
  ebolTrayCtrl: UntypedFormControl;
  outboundPrinterCtrl: UntypedFormControl;
  outboundTrayCtrl: UntypedFormControl;
  isDockDocTypeSelectedCtrl: UntypedFormControl;
  isFbdsDocTypeSelectedCtrl: UntypedFormControl;
  isPndDocTypeSelectedCtrl: UntypedFormControl;
  isDsrDocTypeSelectCtrl: UntypedFormControl;
  isOutboundDocTypeSelectCtrl: UntypedFormControl;
  isEbolDocTypeSelectedCtrl: UntypedFormControl;
  isDmanTypeSelectedCtrl: UntypedFormControl;
  ebolPrinterCtrl: UntypedFormControl;
  reprintCtrl: UntypedFormControl;
  paperTypeCtrl: UntypedFormControl;
  languageCtrl: UntypedFormControl;
  userPrintPreferences: UserPrintPreferences;
  isPnDManiFestDisabled: boolean;
  isDSRCheckInDisabled: boolean;
  isEbolDisabled: boolean = true;

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    public dialogRef: MatDialogRef<PrintDialogComponent>,
    private shipmentApiService: ShipmentApiService,
    private infrastructureApi: InfrastructureApiService,
    private userPreferencesService: UserPreferencesService,
    private featuresService: XpoLtlFeaturesService,
    private notificationMessageService: NotificationMessageService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private cityOpsService: CityOperationsApiService,
    private formBuilder: UntypedFormBuilder,
    private cityOperationService: CityOperationsApiService,
    private locationService: LocationApiService,
    private billOfLadingApiService: BillOfLadingApiService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: PrintDialogData
  ) {
    this.dmanForm = this.formBuilder.group({
      dman: this.formBuilder.array([]),
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  get selectedPaperType(): PrintPaperTypeEnum {
    return this.paperTypeCtrl.value;
  }

  get selectedLanguage(): FbdsVersionCd {
    return this.languageCtrl.value;
  }

  get isReprint(): boolean {
    return this.reprintCtrl.value;
  }

  get isFbdsDocTypeSelected(): boolean {
    return this.isFbdsDocTypeSelectedCtrl.value === true;
  }

  get isDockDocTypeSelected(): boolean {
    return this.isDockDocTypeSelectedCtrl.value === true;
  }
  get isEbolDocTypeSelected(): boolean {
    return this.isEbolDocTypeSelectedCtrl.value === true;
  }

  get isPndDocTypeSelected(): boolean {
    return this.isPndDocTypeSelectedCtrl.value === true;
  }

  get isDsrDocTypeSelected(): boolean {
    return this.isDsrDocTypeSelectCtrl.value === true;
  }

  get isOutBoundDocTypeSelected(): boolean {
    return this.isOutboundDocTypeSelectCtrl.value === true;
  }

  get isDmanTypeSelected(): boolean {
    return this.isDmanTypeSelectedCtrl.value === true;
  }

  get fbdsPrinter(): string {
    return this.fbdsPrinterCtrl.value?.value ?? '';
  }

  get fbdsPrinterTray(): string {
    return this.fbdsTrayCtrl.value?.value ?? '';
  }

  get fbdsTwoPartPrinter(): string {
    return this.fbdsTwoPartPrinterCtrl.value?.value ?? '';
  }

  get pndPrinter(): string {
    return this.pndPrinterCtrl.value?.value ?? '';
  }

  get pndPrinterTray(): string {
    return this.pndTrayCtrl.value?.value ?? '';
  }

  get dockPrinter(): string {
    return this.dockPrinterCtrl.value?.value ?? '';
  }

  get dsrPrinter(): string {
    return this.dsrPrinterCtrl.value?.value ?? '';
  }

  get dsrPrinterTray(): string {
    return this.dsrTrayCtrl.value?.value ?? '';
  }

  get outboundPrinter(): string {
    return this.outboundPrinterCtrl.value?.value ?? '';
  }

  get outboundPrinterTray(): string {
    return this.outboundTrayCtrl.value?.value ?? '';
  }

  get ebolPrinter(): string {
    return this.ebolPrinterCtrl.value?.value ?? '';
  }

  get ebolPrinterTray(): string {
    return this.ebolTrayCtrl.value?.value ?? '';
  }

  private initializePrintForSource(source: PrintGridSources) {
    switch (source) {
      case PrintGridSources.TripPlanning:
        this.setSelectedItemsForTripPlanning();
        break;
      case PrintGridSources.RoutePlanning:
        this.setSelectedItemsForRoutePlanning();
        this.disableGridPrint = true;
        break;
      case PrintGridSources.DispatchTrip:
        this.setSelectedItemsForDispatchTrip();
        break;
      case PrintGridSources.ModifyTripDetails:
        this.selectedItems = this.data?.proNbrs;
        if (
          this.selectedItems.length === 0 &&
          this.data?.isFbdsDisabled === true &&
          this.data?.routeInstIds.length !== 0
        ) {
          this.selectedItemLabel = 'All Routes';
        }
        break;
      case PrintGridSources.PlanningRouteShipments:
        this.disableGridPrint = true;
        this.selectedItems = this.data?.proNbrs;
        break;
      case PrintGridSources.UnassignedDeliveries:
        this.disableGridPrint = true;
        this.selectedItems = this.data?.proNbrs;
        break;
      case PrintGridSources.PastDueShipments:
        this.disableGridPrint = true;
        this.selectedItems = this.data?.proNbrs;
        break;
      case PrintGridSources.UnassignedPickups:
        this.disableGridPrint = true;
        this.selectedItems = this.data?.proNbrs;
        break;
    }
  }

  private setSelectedItemsForRoutePlanning(): void {
    this.selectedItemLabel = 'Routes';

    const selectedRoutes = this.data?.routeInstIds?.map((routeId: number) => {
      return this.planningRoutesCacheService.getPlanningRoute(routeId);
    });

    this.routeNamesWithError = selectedRoutes
      .filter((route) => {
        return route.totalBillCount === 0;
      })
      .map((route) => {
        return PndRouteUtils.getRouteId(route);
      });

    this.routeNames = selectedRoutes
      .map((route) => {
        const name = PndRouteUtils.getRouteId(route);
        if (!this.routeNamesWithError.includes(name)) {
          return { routePrefix: route?.routePrefix, routeSuffix: route?.routeSuffix };
        }
      })
      .filter((routeName) => !!routeName);

    this.selectedItems = this.routeNames.map((routeName) => PndRouteUtils.getRouteId(routeName));
  }

  private setSelectedItemsForTripPlanning(): void {
    this.selectedItemLabel = 'Routes';
    const routesInfo: {
      routeNamesWithError: string[];
      routeNames: RouteName[];
    } = (this.data?.trips ?? []).reduce(
      (accumulate, current) => {
        let containsDL = false;
        this.data?.stops[current?.routeInstId]?.forEach((stop) => {
          stop?.activities?.forEach((activity) => {
            if (
              activity?.tripNodeActivity?.activityCd === TripNodeActivityCd.DELIVER_SHIPMENT ||
              activity?.tripNodeActivity?.activityCd === TripNodeActivityCd.SPOT_LOADED
            ) {
              containsDL = true;
            }
          });
        });
        if (containsDL === false) {
          accumulate.routeNamesWithError.push(PndRouteUtils.getRouteId(current.route));
        } else {
          accumulate.routeNames.push({
            routePrefix: current?.route?.routePrefix,
            routeSuffix: current?.route?.routeSuffix,
          });
        }
        return accumulate;
      },
      {
        routeNamesWithError: [],
        routeNames: [],
      }
    );

    this.routeNamesWithError = routesInfo.routeNamesWithError;
    this.routeNames = routesInfo.routeNames;
    this.selectedItems = this.routeNames.map((routeName) => PndRouteUtils.getRouteId(routeName));
  }

  private setSelectedItemsForDispatchTrip(): void {
    this.selectedItemLabel = 'Routes';
    const tripWithNoDL = this.data?.dispatchTrips?.filter((item) => {
      let containsDL = false;
      item.routes.forEach((route) => {
        if (this.data?.stops[route?.uniqRouteId]) {
          this.data?.stops[route?.uniqRouteId]?.forEach((stop) => {
            stop?.activities?.forEach((activity) => {
              if (
                activity?.tripNodeActivity?.activityCd === TripNodeActivityCd.DELIVER_SHIPMENT ||
                activity?.tripNodeActivity?.activityCd === TripNodeActivityCd.SPOT_LOADED
              ) {
                containsDL = true;
              }
            });
          });
        }
      });
      return containsDL === false;
    });

    tripWithNoDL?.forEach((item) => {
      const dispatchTripRoute = item?.dispatchTrip?.dispatchRoutes ?? [];
      dispatchTripRoute?.forEach((route) => {
        this.routeNamesWithError.push(PndRouteUtils.getRouteId(route));
      });
    });

    this.routeNamesWithError = [...new Set(this.routeNamesWithError)];

    this.data?.dispatchTrips?.forEach((item) => {
      const dispatchTripRoute = item?.dispatchTrip?.dispatchRoutes ?? [];
      dispatchTripRoute?.forEach((route) => {
        const name = PndRouteUtils.getRouteId(route);
        if (!this.routeNamesWithError.includes(name)) {
          this.routeNames.push({ routePrefix: route?.routePrefix, routeSuffix: route?.routeSuffix });
        }
      });
    });

    this.routeNames = _uniqBy(this.routeNames, (routeName) => [routeName.routePrefix, routeName.routeSuffix].join());

    this.selectedItems = this.routeNames.map((routeName) => PndRouteUtils.getRouteId(routeName));
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      [PrintFormFields.PaperType]: PrintPaperTypeEnum.PerforatedPaper,
      [PrintFormFields.Language]: LanguageOptions.ENGLISH,
      [PrintFormFields.Reprint]: false,
      [PrintFormFields.FbdsDocTypeSelect]: { value: false, disabled: this.data?.isFbdsDisabled },
      [PrintFormFields.FbdsPrinter]: '',
      [PrintFormFields.FbdsTwoPartPrinter]: '',
      [PrintFormFields.FbdsTray]: '',
      [PrintFormFields.DockDocTypeSelect]: { value: false, disabled: this.data?.isDockDisabled },
      [PrintFormFields.DockPrinter]: '',
      [PrintFormFields.PndDocTypeSelect]: {
        value: false,
        disabled: this.data?.source !== PrintGridSources.DockRoutes ? this.disableGridPrint : this.data?.isPndDisabled,
      },
      [PrintFormFields.PndPrinter]: '',
      [PrintFormFields.PndTray]: '',
      [PrintFormFields.DsrPrinter]: '',
      [PrintFormFields.DsrTray]: '',
      [PrintFormFields.DsrDocTypeSelect]: { value: false, disabled: this.data?.isDsrDocDisabled },
      [PrintFormFields.OutboundPrinter]: '',
      [PrintFormFields.OutboundTray]: '',
      [PrintFormFields.OutboundDocTypeSelect]: { value: false, disabled: this.data?.isObmDisabled },
      [PrintFormFields.EbolDocTypeSelect]: { value: false, disabled: this.isEbolDisabled },
      [PrintFormFields.EbolTray]: '',
      [PrintFormFields.EbolPrinter]: '',
      [PrintFormFields.DmanTypeSelect]: { value: false, disabled: this.isEbolDisabled },
      [PrintFormFields.DmanPrinter]: '',
      [PrintFormFields.DmanTray]: '',
    });

    this.fbdsPrinterCtrl = this.formGroup.get(PrintFormFields.FbdsPrinter) as UntypedFormControl;
    this.pndPrinterCtrl = this.formGroup.get(PrintFormFields.PndPrinter) as UntypedFormControl;
    this.dockPrinterCtrl = this.formGroup.get(PrintFormFields.DockPrinter) as UntypedFormControl;
    this.fbdsTrayCtrl = this.formGroup.get(PrintFormFields.FbdsTray) as UntypedFormControl;
    this.pndTrayCtrl = this.formGroup.get(PrintFormFields.PndTray) as UntypedFormControl;
    this.fbdsTwoPartPrinterCtrl = this.formGroup.get(PrintFormFields.FbdsTwoPartPrinter) as UntypedFormControl;
    this.dsrPrinterCtrl = this.formGroup.get(PrintFormFields.DsrPrinter) as UntypedFormControl;
    this.dsrTrayCtrl = this.formGroup.get(PrintFormFields.DsrTray) as UntypedFormControl;
    this.outboundPrinterCtrl = this.formGroup.get(PrintFormFields.OutboundPrinter) as UntypedFormControl;
    this.outboundTrayCtrl = this.formGroup.get(PrintFormFields.OutboundTray) as UntypedFormControl;
    this.isDockDocTypeSelectedCtrl = this.formGroup.get(PrintFormFields.DockDocTypeSelect) as UntypedFormControl;
    this.isFbdsDocTypeSelectedCtrl = this.formGroup.get(PrintFormFields.FbdsDocTypeSelect) as UntypedFormControl;
    this.isPndDocTypeSelectedCtrl = this.formGroup.get(PrintFormFields.PndDocTypeSelect) as UntypedFormControl;
    this.isDsrDocTypeSelectCtrl = this.formGroup.get(PrintFormFields.DsrDocTypeSelect) as UntypedFormControl;
    this.ebolTrayCtrl = this.formGroup.get(PrintFormFields.EbolTray) as UntypedFormControl;
    this.ebolPrinterCtrl = this.formGroup.get(PrintFormFields.EbolPrinter) as UntypedFormControl;
    this.isEbolDocTypeSelectedCtrl = this.formGroup.get(PrintFormFields.EbolDocTypeSelect) as UntypedFormControl;
    this.isOutboundDocTypeSelectCtrl = this.formGroup.get(PrintFormFields.OutboundDocTypeSelect) as UntypedFormControl;
    this.reprintCtrl = this.formGroup.get(PrintFormFields.Reprint) as UntypedFormControl;
    this.paperTypeCtrl = this.formGroup.get(PrintFormFields.PaperType) as UntypedFormControl;
    this.languageCtrl = this.formGroup.get(PrintFormFields.Language) as UntypedFormControl;
    this.isDmanTypeSelectedCtrl = this.formGroup.get(PrintFormFields.DmanTypeSelect) as UntypedFormControl;

    this.subscribeToPndPrinterChanges();
    this.subscribeToFbdsPrinterChanges();
    this.subscribeToDsrPrinterChanges();
    this.subscribeToOutboundPrinterChanges();
    this.subscribeToFormDataChanges();
    this.subscribeToDockPrinterChange();
    this.subscribeToEbolPrinterChange();
  }

  ngOnInit() {
    this.printSource = this.data?.source;
    this.data.isEbolDisabled = this.data.isEbolDisabled ?? true;
    this.isEbolDisabled = this.data.isEbolDisabled;
    this.isPnDManiFestDisabled = !!this.data?.dispatchTrips?.find((trip) => {
      return trip?.isDockDrop;
    });
    this.isDSRCheckInDisabled = this.isPnDManiFestDisabled;
    this.initializePrintForSource(this.printSource);
    this.isServiceEnabled();
    this.createForm();
    this.checkReprintOption();
    this.isManifestPrintDisabledSubject.next(true);
    this.isLoadingSubject.next(true);
    this.loadData$()
      .pipe(take(1))
      .subscribe(
        () => {
          this.isLoadingSubject.next(false);
        },
        (error) => {
          this.isLoadingSubject.next(false);
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, error?.error?.message)
            .subscribe(() => {});
        }
      );

    this.dmanCheckBoxClick();

    combineLatest([this.rePrintDmans$, this.printDmans$])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([isReprint, dmanArr]: [boolean, Dman[]]) => {
        this.dmanForm = this.formBuilder.group({
          dman: this.formBuilder.array([]),
        });
        this.createDmanFormArray(isReprint, dmanArr);
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  private dmanCheckBoxClick(): void {
    this.isDmanTypeSelectedCtrl?.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((isChecked) => {
      if (isChecked) {
        this.isLoadingSubject.next(true);
        const routeInstIdArr: number[] = [];
        this.data?.trips?.forEach((trip) => {
          routeInstIdArr.push(trip.routeInstId);
        });

        this.data?.dispatchTrips?.forEach((trip) => {
          trip.routes?.forEach((route) => {
            routeInstIdArr.push(+route.uniqRouteId);
          });
        });

        this.data?.routeInstIds?.forEach((routeInstId) => {
          routeInstIdArr.push(routeInstId);
        });
        const request: ListDeliveryManifestsRqst = new ListDeliveryManifestsRqst();
        request.routeInstIds = routeInstIdArr;
        const path = new ListDeliveryManifestsPath();
        path.sicCd = this.data.sicCd;

        this.cityOperationService
          .listDeliveryManifests(request, path)
          .pipe(
            take(1),
            catchError((error) => {
              this.isLoadingSubject.next(false);
              return EMPTY;
            })
          )
          .subscribe((response: ListDeliveryManifestsResp) => {
            const dmanArr: Dman[] = [];
            response?.deliveryManifests?.forEach((deliveryManifest) => {
              const dman = {} as Dman;
              const consigneeArr: Consignee[] = [];
              let sealNo: string;
              deliveryManifest.deliveryManifestForConsignees.forEach(
                (deliveryManifestForConsignee: DeliveryManifestForConsignee) => {
                  const consigneeData = {} as Consignee;
                  consigneeData.consigneeName = `${deliveryManifestForConsignee?.consignee?.name1} , ${deliveryManifestForConsignee?.consignee?.name2}`;
                  consigneeData.dmanId = deliveryManifestForConsignee?.dmanManifest?.dmanManifestId;

                  sealNo = deliveryManifestForConsignee.dmanManifest.trailerSealNbr;
                  const dmanShipmentArr: DmanShipment[] = [];
                  deliveryManifestForConsignee?.shipments?.forEach((shipment) => {
                    const dmanData = {} as DmanShipment;
                    dmanData.billWeight = shipment.billedWeight;
                    dmanData.proNumber = shipment?.dmanPro?.proNbr;
                    dmanData.shipper = `${shipment?.shipper?.name1}, ${shipment?.shipper?.name2}`;
                    dmanData.billNumber = shipment?.billNbr;
                    dmanData.billNumber = '10';

                    dmanData.poNumber = shipment?.dmanPurchaseOrders?.map((dmanPurchaseOrder) => {
                      return dmanPurchaseOrder?.purchaseOrderNbr;
                    });
                    dmanData.poPieces = shipment?.dmanPurchaseOrders?.map((dmanPurchaseOrder) => {
                      return dmanPurchaseOrder?.purchaseOrderPiecesCount;
                    });
                    dmanData.poWeight = shipment?.dmanPurchaseOrders?.map((dmanPurchaseOrder) => {
                      return dmanPurchaseOrder?.purchaseOrderWeightLbs;
                    });
                    dmanShipmentArr.push(dmanData);
                  });
                  consigneeData.shipments = dmanShipmentArr;
                  consigneeArr.push(consigneeData);
                }
              );
              dman.isAlreadyPrinted = deliveryManifest?.reprintInd;
              dman.trailer = `${deliveryManifest.route.equipmentIdPrefix}-${deliveryManifest.route.equipmentIdSuffixNbr}`;
              dman.routInstId = deliveryManifest.route.routeInstId;
              dman.consignees = consigneeArr;
              if (deliveryManifest.reprintInd) {
                dman.sealNo = sealNo;
              }
              dmanArr.push(dman);
            });
            this.printDmanSubject.next(dmanArr);
            this.isLoadingSubject.next(false);
          });
      }
    });
  }

  private createDmanFormArray(isReprint: boolean, dmanArr: Dman[]): void {
    dmanArr.forEach((dman: Dman) => {
      if (dman.isAlreadyPrinted === isReprint) {
        const dmanArray = this.formBuilder.group({
          isChecked: new FormControl(false),
          routInstId: new FormControl(dman.routInstId),
          trailer: new FormControl(dman.trailer),
          sealNo: new FormControl(dman.sealNo),
          isAlreadyPrinted: new FormControl(dman.isAlreadyPrinted),
          consignees: this.formBuilder.array([]),
        });

        dman.consignees.forEach((consignee) => {
          const consigneeArr = this.formBuilder.group({
            consigneeName: new FormControl(consignee.consigneeName),
            dmanId: new FormControl(consignee.dmanId),
            shipments: this.formBuilder.array([]),
          });

          consignee?.shipments?.forEach((shipment) => {
            const shipmentArr = this.formBuilder.group({
              pro: new FormControl(shipment.proNumber),
              shipper: new FormControl(shipment.shipper),
              billNumber: new FormControl(shipment.billNumber),
              poWeight: this.formBuilder.array([]),
              poPieces: this.formBuilder.array([]),
              poNumber: this.formBuilder.array([]),
              billWeight: new FormControl(shipment.billWeight),
            });

            shipment?.poWeight?.forEach((weight) => {
              const poWeightControl = new FormControl(weight, Validators.required);
              (<FormArray>shipmentArr.get(DmanFormFields.PO_WEIGHT)).push(poWeightControl);
            });

            shipment?.poPieces?.forEach((piece) => {
              const poPieceControl = new FormControl(piece, Validators.required);
              (<FormArray>shipmentArr.get(DmanFormFields.PO_PIECES)).push(poPieceControl);
            });

            shipment?.poNumber?.forEach((po) => {
              const poNumberControl = new FormControl(po);
              (<FormArray>shipmentArr.get(DmanFormFields.PO_NUMBER)).push(poNumberControl);
            });

            (<FormArray>consigneeArr.get(DmanFormFields.SHIPMENTS)).push(shipmentArr);
          });

          (<FormArray>dmanArray.get(DmanFormFields.CONSIGNEE)).push(consigneeArr);
        });

        (<FormArray>this.dmanForm.get(DmanFormFields.DMAN)).push(dmanArray);
      }
    });
  }

  private loadData$(): Observable<void> {
    return forkJoin([
      this.fetchUserPrintPreferences$().pipe(catchError(() => of(undefined))),
      this.fetchPrintersListFromAPI$().pipe(catchError(() => of(undefined))),
      this.getPrintPeferences$().pipe(catchError(() => of(undefined))),
      this.getLocationDeviceFlags$().pipe(catchError(() => of(undefined))),
    ]).pipe(
      tap(() => {
        this.updatePrintersList();
        this.setUserPreferences();
      }),
      mapTo(undefined)
    );
  }

  private fetchUserPrintPreferences$(): Observable<void> {
    return this.userPreferencesService.getPreferencesFor<UserPrintPreferences>(this.userPrintPreferenceKey).pipe(
      take(1),
      map(
        (resp: UserPrintPreferences) => {
          this.userPrintPreferences = resp ?? new UserPrintPreferences();
        },
        (error) => {}
      )
    );
  }

  private getPrintPeferences$(): Observable<void> {
    const request = new GetServiceCenterPrintPreferencesPath();
    request.sicCd = this.data.sicCd;

    return this.cityOperationService.getServiceCenterPrintPreferences(request).pipe(
      take(1),
      map(
        (resp: GetServiceCenterPrintPreferencesResp) => {
          this.printOption = resp.printPreferences;
        },
        (error) => {}
      )
    );
  }

  private getLocationDeviceFlags$(): Observable<void> {
    const request = new ListLocationFeaturesQuery();
    request.featureCd = 'EDGE_PRINT_MFST';
    request.sicCd = this.data?.sicCd;
    return this.locationService.listLocationFeatures(request).pipe(
      take(1),
      map(
        (resp: ListLocationFeaturesResp) => {
          this.disableLocationPrint = resp?.locationFeatures[0].settingValue !== 'Y';
          this.isManifestPrintDisabled();
        },
        (error) => {
          this.disableLocationPrint = true;
          this.isManifestPrintDisabled();
        }
      )
    );
  }

  private setUserPreferences(): void {
    const fbdsSelectedPrinter = this.userPrintPreferences?.fbdsPrinter ?? this.printOption?.fbdsPrinter ?? '';
    this.fbdsPrinterCtrl.setValue(<AutoCompleteItem>{ id: fbdsSelectedPrinter, value: fbdsSelectedPrinter });

    const fbdsSelectedTray = this.userPrintPreferences?.fbdsTray ?? this.printOption?.fbdsTray ?? '';
    this.fbdsTrayCtrl.setValue(<AutoCompleteItem>{ id: fbdsSelectedTray, value: fbdsSelectedTray });

    const pndSelectedPrinter = this.userPrintPreferences?.pndPrinter ?? this.printOption?.pndManifestPrinter ?? '';
    this.pndPrinterCtrl.setValue(<AutoCompleteItem>{ id: pndSelectedPrinter, value: pndSelectedPrinter });

    const pndSelectedTray = this.userPrintPreferences?.pndTray ?? this.printOption?.pndManifestTray ?? '';
    this.pndTrayCtrl.setValue(<AutoCompleteItem>{ id: pndSelectedTray, value: pndSelectedTray });

    this.pndPrinterCtrl.setValue(<AutoCompleteItem>{ id: pndSelectedPrinter, value: pndSelectedPrinter });
    this.pndTrayCtrl.setValue(<AutoCompleteItem>{ id: pndSelectedTray, value: pndSelectedTray });

    const dockSelectedPrinter = this.userPrintPreferences?.dockPrinter ?? '';
    this.dockPrinterCtrl.setValue(<AutoCompleteItem>{ id: dockSelectedPrinter, value: dockSelectedPrinter });

    const twoPartFbdsPrinter = this.userPrintPreferences?.fbdsTwoPartPrinter ?? '';
    this.fbdsTwoPartPrinterCtrl.setValue(<AutoCompleteItem>{ id: twoPartFbdsPrinter, value: twoPartFbdsPrinter });

    const ebolSelectedPrinter = this.userPrintPreferences?.ebolPrinter ?? '';
    this.ebolPrinterCtrl.setValue(<AutoCompleteItem>{ id: ebolSelectedPrinter, value: ebolSelectedPrinter });

    const ebolSelectedTray = this.userPrintPreferences?.ebolTray ?? '';
    this.ebolTrayCtrl.setValue(<AutoCompleteItem>{ id: ebolSelectedTray, value: ebolSelectedTray });

    const outboubndSelectedPrinter =
      this.userPrintPreferences?.outboundPrinter ?? this.printOption?.obBreakoutPrinter ?? '';
    this.outboundPrinterCtrl.setValue(<AutoCompleteItem>{
      id: outboubndSelectedPrinter,
      value: outboubndSelectedPrinter,
    });

    const outboundSelectedTray = this.userPrintPreferences?.outboundTray ?? this.printOption?.obBreakoutTray ?? '';
    this.outboundTrayCtrl.setValue(<AutoCompleteItem>{ id: outboundSelectedTray, value: outboundSelectedTray });

    const dsrSelectedPrinter = this.userPrintPreferences?.dsrPrinter ?? this.printOption?.dsrCheckinPrinter ?? '';
    this.dsrPrinterCtrl.setValue(<AutoCompleteItem>{ id: dsrSelectedPrinter, value: dsrSelectedPrinter });

    const dsrSelectedTray = this.userPrintPreferences?.dsrTray ?? this.printOption?.dsrCheckinTray ?? '';
    this.dsrTrayCtrl.setValue(<AutoCompleteItem>{ id: dsrSelectedTray, value: dsrSelectedTray });

    if (this.isReprintOptionAvailable) {
      if (this.shouldCheckReprintCheckbox()) {
        this.reprintCtrl.setValue(true);
      } else {
        this.reprintCtrl.setValue(this.userPrintPreferences?.reprint ?? false);
      }
    }
  }

  private saveUserPreferences() {
    const saveUserPreferences: UserPrintPreferences = {
      fbdsPrinter: this.fbdsPrinter !== '' ? this.fbdsPrinter : undefined,
      fbdsTray: this.fbdsPrinterTray !== '' ? this.fbdsPrinterTray : undefined,
      pndPrinter: this.pndPrinter !== '' ? this.pndPrinter : undefined,
      pndTray: this.pndPrinterTray !== '' ? this.pndPrinterTray : undefined,
      dockPrinter: this.dockPrinter !== '' ? this.dockPrinter : undefined,
      fbdsTwoPartPrinter: this.fbdsTwoPartPrinter !== '' ? this.fbdsTwoPartPrinter : undefined,
      dsrPrinter: this.dsrPrinter !== '' ? this.dsrPrinter : undefined,
      dsrTray: this.dsrPrinterTray !== '' ? this.dsrPrinterTray : undefined,
      outboundPrinter: this.outboundPrinter !== '' ? this.outboundPrinter : undefined,
      outboundTray: this.outboundPrinterTray !== '' ? this.outboundPrinterTray : undefined,
      reprint: this.reprintCtrl.value,
      ebolPrinter: this.ebolPrinter !== '' ? this.ebolPrinter : undefined,
      ebolTray: this.ebolPrinterTray !== '' ? this.ebolPrinterTray : undefined,
    };
    if (
      this.isFbdsDocTypeSelected ||
      this.isPndDocTypeSelected ||
      this.isDockDocTypeSelected ||
      this.isOutBoundDocTypeSelected ||
      this.isDsrDocTypeSelected ||
      this.isEbolDocTypeSelected
    ) {
      const isValidSave = !_isEqual(saveUserPreferences, this.userPrintPreferences);
      this.userPrintPreferences = isValidSave ? saveUserPreferences : this.userPrintPreferences;
      if (isValidSave) {
        this.userPreferencesService
          .updatePreferencesFor<UserPrintPreferences>(this.userPrintPreferenceKey, saveUserPreferences)
          .pipe(take(1))
          .subscribe();
      }
    }
  }

  private shouldCheckReprintCheckbox(): boolean {
    return (
      this.printSource === PrintGridSources.ModifyTripDetails ||
      this.printSource === PrintGridSources.PlanningRouteShipments ||
      this.printSource === PrintGridSources.PastDueShipments
    );
  }

  private fetchPrintersListFromAPI$(): Observable<void> {
    const request: ListPrintersQuery = {
      sicCds: [this.data?.sicCd],
    };

    return this.infrastructureApi.listPrinters(request).pipe(
      take(1),
      map(
        (resp: ListPrintersResp) => {
          this.printersListFromAPI = resp.printers.map((printer) => printer.name);

          resp?.printers.forEach((printer: Printer) => {
            this.printerTrayMap.set(printer.name, printer.paperSources);
          });

          this.updatePrintersList();
        },
        (errorResp) => {
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, errorResp?.error?.message)
            .subscribe(() => {});
        }
      )
    );
  }

  private subscribeToPndPrinterChanges() {
    this.activePndTrays$ = this.pndPrinterCtrl.valueChanges.pipe(
      startWith(''),
      takeUntil(this.unsubscriber.done$),
      map((selectedPrinter) => {
        const printerName = this.setPrinterAndTrayValues(this.pndPrinterCtrl, this.pndTrayCtrl, selectedPrinter);
        this.validate();
        this.saveUserPreferences();
        return this.setActiveTrayLists(printerName);
      })
    );

    this.pndTrayCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedTray) => {
      if (selectedTray !== '' && selectedTray?.value === undefined) {
        this.pndTrayCtrl.setValue(<AutoCompleteItem>{ id: selectedTray, value: selectedTray });
      }
      this.saveUserPreferences();
    });
  }

  private subscribeToFbdsPrinterChanges() {
    this.activeFbdsTrays$ = this.fbdsPrinterCtrl.valueChanges.pipe(
      startWith(''),
      takeUntil(this.unsubscriber.done$),
      map((selectedPrinter) => {
        const printerName = this.setPrinterAndTrayValues(this.fbdsPrinterCtrl, this.fbdsTrayCtrl, selectedPrinter);
        this.validate();
        this.saveUserPreferences();
        return this.setActiveTrayLists(printerName);
      })
    );
    this.fbdsTrayCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedTray) => {
      if (selectedTray !== '' && selectedTray?.value === undefined) {
        this.fbdsTrayCtrl.setValue(<AutoCompleteItem>{ id: selectedTray, value: selectedTray });
      }
      this.saveUserPreferences();
    });

    this.fbdsTwoPartPrinterCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedPrinter) => {
      if (selectedPrinter !== '' && selectedPrinter?.value === undefined) {
        this.fbdsTwoPartPrinterCtrl.setValue(<AutoCompleteItem>{ id: selectedPrinter, value: selectedPrinter });
      }
      this.saveUserPreferences();
    });
  }

  private subscribeToDsrPrinterChanges() {
    this.activeDsrTray$ = this.dsrPrinterCtrl.valueChanges.pipe(
      takeUntil(this.unsubscriber.done$),
      map((selectedPrinter) => {
        const printerName = this.setPrinterAndTrayValues(this.dsrPrinterCtrl, this.dsrTrayCtrl, selectedPrinter);
        this.validate();
        this.saveUserPreferences();
        return this.setActiveTrayLists(printerName);
      })
    );
    this.dsrTrayCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedTray) => {
      if (selectedTray !== '' && selectedTray?.value === undefined) {
        this.dsrTrayCtrl.setValue(<AutoCompleteItem>{ id: selectedTray, value: selectedTray });
      }
      this.saveUserPreferences();
    });
  }

  private subscribeToOutboundPrinterChanges() {
    this.activeOubtoundTray$ = this.outboundPrinterCtrl.valueChanges.pipe(
      takeUntil(this.unsubscriber.done$),
      map((selectedPrinter) => {
        const printerName = this.setPrinterAndTrayValues(
          this.outboundPrinterCtrl,
          this.outboundTrayCtrl,
          selectedPrinter
        );
        this.validate();
        this.saveUserPreferences();
        return this.setActiveTrayLists(printerName);
      })
    );
    this.outboundTrayCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedTray) => {
      if (selectedTray !== '' && selectedTray?.value === undefined) {
        this.outboundTrayCtrl.setValue(<AutoCompleteItem>{ id: selectedTray, value: selectedTray });
      }
      this.saveUserPreferences();
    });
  }

  subscribeToDockPrinterChange() {
    this.dockPrinterCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedPrinter) => {
      if (selectedPrinter !== '' && selectedPrinter?.value === undefined) {
        this.dockPrinterCtrl.setValue(<AutoCompleteItem>{ id: selectedPrinter, value: selectedPrinter });
      }
      this.saveUserPreferences();
    });
  }
  subscribeToEbolPrinterChange() {
    this.activeEbolTrays$ = this.ebolPrinterCtrl.valueChanges.pipe(
      startWith(''),
      takeUntil(this.unsubscriber.done$),
      map((selectedPrinter) => {
        const printerName = this.setPrinterAndTrayValues(this.ebolPrinterCtrl, this.ebolTrayCtrl, selectedPrinter);
        this.validate();
        this.saveUserPreferences();
        return this.setActiveTrayLists(printerName);
      })
    );

    this.ebolTrayCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe((selectedTray) => {
      if (selectedTray !== '' && selectedTray?.value === undefined) {
        this.ebolTrayCtrl.setValue(<AutoCompleteItem>{ id: selectedTray, value: selectedTray });
      }
      this.saveUserPreferences();
    });
  }
  setPrinterAndTrayValues(
    printerCtrl: UntypedFormControl,
    trayCtrl: UntypedFormControl,
    selectedPrinter: string | AutoCompleteItem
  ): string {
    let printerName: string = '';
    if (selectedPrinter === '' && (printerCtrl.value?.value !== '' || printerCtrl.value?.value !== undefined)) {
      printerName = printerCtrl.value?.value;
    } else if (selectedPrinter !== '' && (selectedPrinter as AutoCompleteItem)?.value === undefined) {
      printerCtrl.setValue(<AutoCompleteItem>{ id: selectedPrinter, value: selectedPrinter });
    } else {
      printerName = (selectedPrinter as AutoCompleteItem)?.value ?? (selectedPrinter as string);
    }
    if (printerName === '' || printerName === undefined) {
      trayCtrl.setValue('');
    }
    return printerName;
  }

  private updatePrintersList(): void {
    const legacyResult: string[] = LegacyPrintersList;
    this.legacyPrinterListSubject.next(
      legacyResult.map((val: string) => {
        return <AutoCompleteItem>{
          id: val,
          value: val,
        };
      })
    );
    const result: string[] = this.printersListFromAPI;
    this.activePrintersListSubject.next(
      result.map((val: string) => {
        return <AutoCompleteItem>{
          id: val,
          value: val,
        };
      })
    );
  }

  private subscribeToFormDataChanges() {
    this.formGroup.valueChanges.subscribe((data) => {
      this.validate();
    });
  }

  validate(): void {
    let isPrintAllowed = false;
    let isFbdsAllowed = true;
    let isDockAllowed = true;
    let isPndAllowed = true;
    let isDsrAllowed = true;
    let isOutboundAllowed = true;
    let isEbolAllowed = true;

    if (this.isFbdsDocTypeSelected) {
      if (
        this.selectedPaperType === PrintPaperTypeEnum.PerforatedPaper &&
        (this.fbdsPrinter === '' || this.fbdsPrinterTray === '')
      ) {
        isFbdsAllowed = false;
      }
      if (this.selectedPaperType === PrintPaperTypeEnum.TwoPartPaper && this.fbdsTwoPartPrinter === '') {
        isFbdsAllowed = false;
      }
    }
    if (this.isDockDocTypeSelected && this.dockPrinter === '') {
      isDockAllowed = false;
    }

    if (this.isPndDocTypeSelected && (this.pndPrinter === '' || this.pndPrinterTray === '')) {
      isPndAllowed = false;
    }

    if (this.isDsrDocTypeSelected && (this.dsrPrinter === '' || this.dsrPrinterTray === '')) {
      isDsrAllowed = false;
    }

    if (this.isOutBoundDocTypeSelected && (this.outboundPrinter === '' || this.outboundPrinterTray === '')) {
      isOutboundAllowed = false;
    }
    if (this.isEbolDocTypeSelected && (this.ebolPrinter === '' || this.ebolPrinterTray === '')) {
      isEbolAllowed = false;
    }
    if (
      this.isFbdsDocTypeSelected ||
      this.isPndDocTypeSelected ||
      this.isDockDocTypeSelected ||
      this.isOutBoundDocTypeSelected ||
      this.isDsrDocTypeSelected ||
      this.isEbolDocTypeSelected
    ) {
      isPrintAllowed =
        isPndAllowed && isFbdsAllowed && isDockAllowed && isDsrAllowed && isOutboundAllowed && isEbolAllowed;
    }
    this.isPrintAllowedSubject.next(isPrintAllowed);

    this.isOpenPdfAllowedSubject.next(
      this.isPndDocTypeSelected ||
        this.isFbdsDocTypeSelected ||
        this.isDsrDocTypeSelected ||
        this.isOutBoundDocTypeSelected ||
        this.isEbolDocTypeSelected
    );
  }

  private async mergePDFDocuments(documents: string[]): Promise<Uint8Array> {
    const mergedPdf = await PDFDocument.create();

    for (const document of documents) {
      const pdfDocument: PDFDocument = await PDFDocument.load(document);
      const copiedPages = await mergedPdf.copyPages(pdfDocument, pdfDocument.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    return await mergedPdf.save();
  }

  private openPrintFrameInBrowser(base64Array: string[]): void {
    this.mergePDFDocuments(base64Array).then((mergedPDF) => {
      const blob = new Blob([mergedPDF], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const name = url.slice(url.lastIndexOf('/') + 1, url.length);
      this.isLoadingSubject.next(false);
      importedSaveAs(blob, name);
    });
  }

  getSequencedProNbrsForStops(routeStopsMap: NumberToValueMap<Stop[]>): string[] {
    let result = [];
    if (routeStopsMap) {
      const selectedRouteInstIds = Object.keys(routeStopsMap);
      if (selectedRouteInstIds.length > 0) {
        selectedRouteInstIds.forEach((routeInstId) => {
          const stopsForRoute: Stop[] = routeStopsMap[routeInstId];
          if (stopsForRoute.length > 0) {
            const proNbrsForRoute = [];

            stopsForRoute.forEach((stop) => {
              const activities: Activity[] = stop?.activities ?? [];
              activities.forEach((activity) => {
                const proNbr = activity?.routeShipment?.proNbr;
                const routeSequenceNbr = activity?.routeShipment?.routeSequenceNbr;
                if (proNbr) {
                  proNbrsForRoute.push({ proNbr: proNbr, sequence: routeSequenceNbr });
                }
              });
            });

            const sequencedProNbrs = _sortBy(proNbrsForRoute, 'sequence').map((item) => item.proNbr);
            if (sequencedProNbrs && sequencedProNbrs.length > 0) {
              result = result.concat(sequencedProNbrs);
            } else {
              // no proNbrs found
            }
          } else {
            // no proNbrs found
          }
        });
      }
    }
    return result;
  }

  getSequencedProNbrsForStopsForDispatchTrip(routeStopsMap: NumberToValueMap<Stop[]>): string[] {
    let result: string[] = [];
    if (routeStopsMap) {
      const selectedRouteInstIds: string[] = Object.keys(routeStopsMap);
      selectedRouteInstIds?.forEach((routeInstId) => {
        const stopsForRoute: Stop[] = routeStopsMap[routeInstId];

        const proNbrsForRoute: ProNumberAndStopSequenceNumber[] = [];

        stopsForRoute?.forEach((stop) => {
          const activities: Activity[] = stop?.activities ?? [];
          const proNumberArr: string[] = [];
          activities.forEach((activity) => {
            activity?.activityShipments?.forEach((activityShipment) => {
              proNumberArr.push(activityShipment?.proNbr);
            });
          });
          const stopSequenceNbr: number = stop?.tripNode?.stopSequenceNbr;

          proNumberArr?.forEach((proNbr) => {
            proNbrsForRoute.push({
              proNumber: proNbr,
              sequence: stopSequenceNbr,
            } as ProNumberAndStopSequenceNumber);
          });
        });

        const sequencedProNbrs: string[] = _sortBy(proNbrsForRoute, 'sequence').map((item) => item.proNumber);
        if (sequencedProNbrs && sequencedProNbrs.length > 0) {
          result = result.concat(sequencedProNbrs);
        }
      });
    }
    return result;
  }

  private getProNbrsForPlanningRoutes(routeInstIds: number[]): string[] {
    const selectedRouteUsassignedStopsProNbrs: UnassignedStop[] = [];
    const deliveryShipmentProNbrs: string[] = [];

    routeInstIds?.forEach((routenstId: number) => {
      selectedRouteUsassignedStopsProNbrs.push(
        ...this.planningRoutesCacheService.getStopsForRoute(routenstId).filter((stops) => _size(stops))
      );
    });

    selectedRouteUsassignedStopsProNbrs.forEach((stop) => {
      stop.deliveryShipments.forEach((deliveryShipment) => {
        deliveryShipmentProNbrs.push(deliveryShipment.proNbr);
      });
    });

    return deliveryShipmentProNbrs;
  }

  onPrintLocalMachine(): void {
    this.isFbdsDownloadError = false;
    this.isPndDownloadError = false;
    const proNbrs: string[] = this.getSelectedProNbrs();
    let routeInstIds: number[] = [];
    let tripInstIds: number[] = [];
    const shipmentIds: ShipmentId[] = this.getShipmentIdsFromProNbrs(proNbrs);
    const printOpenPdfRequests = [];
    if (
      this.data.source === PrintGridSources.RoutePlanning ||
      this.data.source === PrintGridSources.ModifyTripDetails
    ) {
      routeInstIds = this.data?.routeInstIds;
      tripInstIds = this.data?.tripInstIds;
    } else if (this.data.source === PrintGridSources.TripPlanning) {
      routeInstIds = this.data?.trips.map((trip) => trip?.route?.routeInstId);
      tripInstIds = this.data?.trips.map((trip) => trip?.route?.tripInstId);
    } else if (this.data.source === PrintGridSources.DispatchTrip) {
      let routeInstIdArr: number[] = [];
      let tripInstIdArr: number[] = [];
      this.data?.dispatchTrips?.forEach((tripItem) => {
        tripItem?.dispatchTrip?.dispatchRoutes?.forEach((route) => {
          routeInstIdArr.push(route?.routeInstId);
        });
        tripInstIdArr.push(tripItem.dispatchTrip.tripInstId);
      });
      routeInstIdArr = [...new Set(routeInstIdArr)];
      tripInstIdArr = [...new Set(tripInstIdArr)];
      routeInstIds = routeInstIdArr;
      tripInstIds = tripInstIdArr;
    }

    if (this.isPndDocTypeSelected) {
      const createPndManifestDocRqst = new CreatePnDManifestDocumentsRqst();
      createPndManifestDocRqst.sicCd = this.data?.sicCd;
      createPndManifestDocRqst.routeInstIds = routeInstIds;
      createPndManifestDocRqst.includeWarningsPageInd = true;
      const createPndManifestDoc$ = this.cityOperationService.createPnDManifestDocuments(createPndManifestDocRqst).pipe(
        catchError((errorResp) => {
          this.isPndDownloadError = true;
          this.notificationMessageService
            .openNotificationMessage(
              NotificationMessageStatus.Error,
              'P&D manifest error: ' + errorResp?.error?.message
            )
            .subscribe(() => {});
          return of(errorResp);
        })
      );
      printOpenPdfRequests.push(createPndManifestDoc$);
    }
    if (this.isEbolDocTypeSelected) {
      const query = {
        ...new GenerateBillOfLadingPdfQuery(),
        bolInstId: this.data.bolInstId,
      };

      const createEbolDoc$ = this.billOfLadingApiService.generateBillOfLadingPdf(query).pipe(
        catchError((errorResp) => {
          this.isFbdsDownloadError = true;
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, 'Outbound error: ' + errorResp?.error?.message)
            .subscribe(() => {});
          return of(errorResp);
        })
      );

      createEbolDoc$.pipe(take(1)).subscribe((response: GenerateBillOfLadingPdfResp) => {
        if (response?.fileContents?.fileContents) {
          this.openPrintFrameInBrowser([response?.fileContents?.fileContents]);
        }
      });
    }
    if (this.isFbdsDocTypeSelected) {
      const createFBSDocRqst = new CreateFBDSDocumentsRqst();
      createFBSDocRqst.shipmentIds = shipmentIds;
      createFBSDocRqst.formType = DocumentFormTypeCd.FBDS;
      createFBSDocRqst.generateSingleDocInd = true;
      createFBSDocRqst.language = this.selectedLanguage;
      createFBSDocRqst.includeWarningsPageInd = true;
      createFBSDocRqst.reprintInd = this.isReprint;
      createFBSDocRqst.requestByRouteInd = this.includeInOpenPdfRequestPayload() ? true : undefined;
      const createFBSDoc$ = this.shipmentApiService.createFBDSDocuments(createFBSDocRqst).pipe(
        catchError((errorResp) => {
          this.isFbdsDownloadError = true;
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, 'FBDS error: ' + errorResp?.error?.message)
            .subscribe(() => {});
          return of(errorResp);
        })
      );
      printOpenPdfRequests.push(createFBSDoc$);
    }

    if (this.isDsrDocTypeSelected) {
      const createDsrDocumentRqst = new CreateDsrCheckInDocumentsRqst();
      createDsrDocumentRqst.tripInstIds = [...new Set(tripInstIds)];
      createDsrDocumentRqst.sicCd = this.data?.sicCd;
      createDsrDocumentRqst.includeWarningsPageInd = true;
      const createDsrDoc$ = this.cityOperationService.createDsrCheckInDocuments(createDsrDocumentRqst).pipe(
        catchError((errorResp) => {
          this.isFbdsDownloadError = true;
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, 'DSR error: ' + errorResp?.error?.message)
            .subscribe(() => {});
          return of(errorResp);
        })
      );
      printOpenPdfRequests.push(createDsrDoc$);
    }

    if (this.isOutBoundDocTypeSelected) {
      const createOutboundDocumentRqst = new PrintOutboundBreakoutManifestReportRqst();
      const createOutboundDocumentRqstPath = new PrintOutboundBreakoutManifestReportPath();
      createOutboundDocumentRqstPath.sicCd = this.data?.sicCd;
      createOutboundDocumentRqst.tripInstId = undefined;
      createOutboundDocumentRqst.routeInstIds = [...new Set(routeInstIds)];
      createOutboundDocumentRqst.dockActivities = this.data?.dockActivities;
      const createOutboundDoc$ = this.cityOperationService
        .printOutboundBreakoutManifestReport(createOutboundDocumentRqst, createOutboundDocumentRqstPath)
        .pipe(
          catchError((errorResp) => {
            this.isFbdsDownloadError = true;
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, 'Outbound error: ' + errorResp?.error?.message)
              .subscribe(() => {});
            return of(errorResp);
          })
        );
      printOpenPdfRequests.push(createOutboundDoc$);
    }

    this.isLoadingSubject.next(true);
    forkJoin(printOpenPdfRequests)
      .pipe(take(1))
      .subscribe(
        (resp: (CreatePnDManifestDocumentsResp | CreateFBDSDocumentsResp | CreateDsrCheckInDocumentsResp)[]) => {
          resp.forEach((response) => {
            if (response?.documentData) {
              this.openPrintFrameInBrowser(response?.documentData);
            }
          });
          if (this.isPndDownloadError || this.isFbdsDownloadError) {
            this.dialogRef.close();
          } else {
            this.dialogRef.close('Documents download complete');
          }
          this.isLoadingSubject.next(false);
        },
        (errorResp) => {
          this.isLoadingSubject.next(false);
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, errorResp?.error?.message)
            .subscribe(() => {});
        }
      );
  }

  private includeInOpenPdfRequestPayload(): boolean {
    return (
      this.printSource === PrintGridSources.TripPlanning ||
      this.printSource === PrintGridSources.DispatchTrip ||
      this.printSource === PrintGridSources.RoutePlanning ||
      this.printSource === PrintGridSources.PlanningRouteShipments
    );
  }

  private isValidPrintSource(): boolean {
    return (
      this.printSource === PrintGridSources.TripPlanning ||
      this.printSource === PrintGridSources.RoutePlanning ||
      this.printSource === PrintGridSources.DispatchTrip ||
      this.printSource === PrintGridSources.ModifyTripDetails ||
      this.printSource === PrintGridSources.UnassignedDeliveries ||
      this.printSource === PrintGridSources.PlanningRouteShipments ||
      this.printSource === PrintGridSources.PastDueShipments
    );
  }

  private getSelectedProNbrs(): string[] {
    let proNbrs: string[];

    if (this.printSource === PrintGridSources.TripPlanning) {
      proNbrs = this.getSequencedProNbrsForStops(this.data?.stops);
    } else if (this.printSource === PrintGridSources.RoutePlanning) {
      const routeInstIds: number[] = this.data?.routeInstIds;
      proNbrs = this.getProNbrsForPlanningRoutes(routeInstIds);
    } else if (this.printSource === PrintGridSources.DispatchTrip) {
      proNbrs = this.getSequencedProNbrsForStopsForDispatchTrip(this.data?.stops);
    } else {
      proNbrs = this.selectedItems;
    }

    return proNbrs;
  }

  private getShipmentIdsFromProNbrs(proNbrs: string[]): ShipmentId[] {
    const shipmentIds: ShipmentId[] = [];

    proNbrs.forEach((pro) => {
      const shipmentId = { shipmentInstId: undefined, proNumber: pro, pickupDate: undefined };
      shipmentIds.push(shipmentId);
    });

    return shipmentIds;
  }

  onPrint(): void {
    const proNbrs: string[] = this.getSelectedProNbrs();
    const printRequests: PrintRequest[] = [];
    let routeInstIds: number[] = [];
    let tripInstIds: number[] = [];
    let shipmentIds: ShipmentId[] = [];
    const printDocRequests = [];
    this.saveUserPreferences();
    if (
      (this.isFbdsDocTypeSelected && this.selectedPaperType === PrintPaperTypeEnum.PerforatedPaper) ||
      this.isPndDocTypeSelected ||
      this.isOutBoundDocTypeSelected ||
      this.isDsrDocTypeSelected ||
      this.isEbolDocTypeSelected
    ) {
      if (
        this.data.source === PrintGridSources.RoutePlanning ||
        this.data.source === PrintGridSources.ModifyTripDetails
      ) {
        routeInstIds = this.data?.routeInstIds;
        tripInstIds = this.data?.tripInstIds;
        shipmentIds = this.getShipmentIdsFromProNbrs(proNbrs);
      } else if (this.data.source === PrintGridSources.TripPlanning) {
        routeInstIds = this.data?.trips.map((trip) => trip?.route?.routeInstId);
        tripInstIds = this.data?.trips.map((trip) => trip?.route?.tripInstId);
      } else if (this.data.source === PrintGridSources.DispatchTrip) {
        let routeInstIdArr: number[] = [];
        let tripInstIdArr: number[] = [];
        this.data?.dispatchTrips?.forEach((tripItem) => {
          tripItem?.dispatchTrip?.dispatchRoutes?.forEach((route) => {
            routeInstIdArr.push(route?.routeInstId);
          });
          tripInstIdArr.push(tripItem?.dispatchTrip?.tripInstId);
        });
        routeInstIdArr = [...new Set(routeInstIdArr)];
        tripInstIdArr = [...new Set(tripInstIdArr)];
        routeInstIds = routeInstIdArr;
        tripInstIds = tripInstIdArr;
      } else {
        shipmentIds = this.getShipmentIdsFromProNbrs(proNbrs);
      }

      if (this.isPndDocTypeSelected && this.pndPrinter !== '') {
        const pndPrintRequest = new PrintRequest();
        const manifestPrint = new ManifestPrint();
        manifestPrint.pnDReportTypeCd = PnDReportTypeCd.PN_D_MANIFEST;
        manifestPrint.sicCd = this.data.sicCd;
        manifestPrint.routeInstIds = [...new Set(routeInstIds)];
        manifestPrint.shipmentIds = shipmentIds;
        pndPrintRequest.printerId = <string>this.pndPrinter;
        pndPrintRequest.paperSource = <string>this.pndPrinterTray;
        pndPrintRequest.manifestPrint = manifestPrint;
        pndPrintRequest.includeWarningsPageInd = true;
        pndPrintRequest.deliverySetPrint = undefined;
        printRequests.push(pndPrintRequest);
      }

      if (this.isFbdsDocTypeSelected && this.fbdsPrinter !== '') {
        const fbdsPrintRequest = new PrintRequest();
        const deliverySetPrint = new DeliverySetPrint();
        deliverySetPrint.sicCd = this.data.sicCd;
        if (this.isValidPrintSource()) {
          deliverySetPrint.reprintInd = this.isReprint;
        } else {
          deliverySetPrint.reprintInd = true;
        }

        if (this.data.source === PrintGridSources.ModifyTripDetails) {
          deliverySetPrint.routeInstIds = undefined;
        } else {
          deliverySetPrint.routeInstIds = [...new Set(routeInstIds)] ?? undefined;
        }
        deliverySetPrint.shipmentIds = !this.includeInFbdsPrintPayload() ? shipmentIds ?? undefined : undefined;
        fbdsPrintRequest.printerId = <string>this.fbdsPrinter;
        fbdsPrintRequest.paperSource = <string>this.fbdsPrinterTray;
        fbdsPrintRequest.manifestPrint = undefined;
        fbdsPrintRequest.includeWarningsPageInd = true;
        fbdsPrintRequest.deliverySetPrint = deliverySetPrint;
        fbdsPrintRequest.language = this.selectedLanguage;
        printRequests.push(fbdsPrintRequest);
      }

      if (this.isOutBoundDocTypeSelected && this.outboundPrinter !== '') {
        const outboundPrintRequest = new PrintRequest();
        const manifestPrint = new ManifestPrint();
        manifestPrint.pnDReportTypeCd = PnDReportTypeCd.OUTBOUND_BREAKOUT_MANIFEST;
        manifestPrint.sicCd = this.data?.sicCd;
        manifestPrint.tripInstIds = undefined;
        manifestPrint.routeInstIds = [...new Set(routeInstIds)];
        manifestPrint.dockActivities = this.data?.dockActivities;
        outboundPrintRequest.printerId = <string>this.outboundPrinter;
        outboundPrintRequest.paperSource = <string>this.outboundPrinterTray;
        outboundPrintRequest.manifestPrint = manifestPrint;
        outboundPrintRequest.includeWarningsPageInd = true;
        outboundPrintRequest.deliverySetPrint = undefined;
        outboundPrintRequest.dsrCheckinPrint = undefined;
        printRequests.push(outboundPrintRequest);
      }
      if (this.isEbolDocTypeSelected && this.ebolPrinter !== '') {
        const ebolPrintRequest = new PrintRequest();
        const ebolPrint: number[] = this.data?.bolInstId?.map(Number);
        ebolPrintRequest.bolInstIds = ebolPrint;
        ebolPrintRequest.printerId = <string>this.ebolPrinter;
        ebolPrintRequest.paperSource = <string>this.ebolPrinterTray;

        printRequests.push(ebolPrintRequest);
      }

      if (this.isDsrDocTypeSelected && this.dsrPrinter !== '') {
        const dsrPrintRequest = new PrintRequest();
        const dsrCheckinPrint = new DsrCheckinPrint();
        dsrCheckinPrint.sicCd = this.data?.sicCd;
        dsrCheckinPrint.tripInstIds = tripInstIds;
        dsrCheckinPrint.dsrCopyInd = true;
        dsrPrintRequest.printerId = <string>this.dsrPrinter;
        dsrPrintRequest.paperSource = <string>this.dsrPrinterTray;
        dsrPrintRequest.dsrCheckinPrint = dsrCheckinPrint;
        dsrPrintRequest.manifestPrint = undefined;
        dsrPrintRequest.deliverySetPrint = undefined;
        printRequests.push(dsrPrintRequest);
      }

      const req: StartPrintDocumentEnsembleRqst = {
        ...new StartPrintDocumentEnsembleRqst(),
        ensembleName: 'start-printdocument',
        businessKey1: this.data?.sicCd,
        businessKey2:
          this.data.source === PrintGridSources.ModifyTripDetails ? undefined : [...new Set(routeInstIds)].toString(),
        businessKey3:
          shipmentIds.length === 0
            ? shipmentIds?.map((shipment) => shipment.proNumber)?.toString()
            : this.data?.bolInstId?.toString(),
        payload: {
          printRequests: printRequests,
        },
      };
      if (printRequests.length > 0) {
        const docTypeRequest = this.startPrintEnsemble(req);
        printDocRequests.push(docTypeRequest);
      }
    }
    if (this.isDockDocTypeSelected || this.isFbdsDocTypeSelected) {
      if (this.isDockDocTypeSelected && this.dockPrinter !== '') {
        const docTypeRequest = this.setPrintFBDSDocumentsRqst(PrintModeEnum.Dock);
        printDocRequests.push(this.printFBDSDocuments(docTypeRequest));
      }
      if (
        this.isFbdsDocTypeSelected &&
        this.fbdsTwoPartPrinter !== '' &&
        this.selectedPaperType === PrintPaperTypeEnum.TwoPartPaper
      ) {
        const docTypeRequest = this.setPrintFBDSDocumentsRqst(PrintModeEnum.Fbds);
        printDocRequests.push(this.printFBDSDocuments(docTypeRequest));
      }
    }
    if (printDocRequests.length > 0) {
      this.isLoadingSubject.next(true);
      forkJoin(printDocRequests)
        .pipe(take(1))
        .subscribe(
          (resp: (PrintFBDSDocumentsResp | StartPrintDocumentEnsembleResp)[]) => {
            this.dialogRef.close('Sent to Printer');
            this.isLoadingSubject.next(false);
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Success, 'Print requests sent successfully to printer')
              .subscribe(() => {});
          },
          (errorResp: GenericErrorLazyTypedModel) => {
            this.isLoadingSubject.next(false);
            const message: string = this.notificationMessageService.parseErrorMessage(errorResp);
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, `${message}`)
              .subscribe(() => {});
          }
        );
    } else {
      this.notificationMessageService
        .openNotificationMessage(NotificationMessageStatus.Error, 'Please make a printer selection to continue')
        .subscribe(() => {});
    }
  }

  private includeInFbdsPrintPayload(): boolean {
    return (
      this.printSource === PrintGridSources.TripPlanning ||
      this.printSource === PrintGridSources.DispatchTrip ||
      this.printSource === PrintGridSources.RoutePlanning
    );
  }

  private checkReprintOption(): void {
    this.isReprintOptionAvailable = this.isValidPrintSource();
  }

  private setActiveTrayLists(printerName): AutoCompleteItem[] {
    let selectedTray = [];
    if (this.printerTrayMap.has(printerName)) {
      selectedTray = this.printerTrayMap
        .get(printerName)
        .filter((option) => option.toLowerCase().indexOf('Tray'.toLowerCase()) === 0);
    } else {
      selectedTray = [];
    }

    return selectedTray.map((val: string) => {
      return <AutoCompleteItem>{
        id: val,
        value: val,
      };
    });
  }

  private setPrintFBDSDocumentsRqst(docType: PrintModeEnum): PrintFBDSDocumentsRqst {
    const request = new PrintFBDSDocumentsRqst();
    const proNbrs: string[] = this.getSelectedProNbrs();
    request.domicileSicCd = this.data.sicCd;
    request.hostDestinationSicCd = this.data.sicCd;
    request.validateInd = true;
    request.includeProsNotDestinedInd = true;
    request.includeProsDestinedInd = true;
    request.includeClosedTrailersInd = true;
    request.reportType = docType;
    request.formType = docType === PrintModeEnum.Fbds ? PrintModeEnum.Fbds : PrintModeEnum.Paper;
    request.printFBDSDocumentsTypeCd = PrintFBDSDocumentsTypeCd.DOT_MATRIX;

    if (this.isValidPrintSource()) {
      request.reprintInd = this.isReprint;
    } else {
      request.reprintInd = true;
    }

    if (request.printFBDSDocumentsTypeCd === PrintFBDSDocumentsTypeCd.DOT_MATRIX) {
      if (
        this.printSource === PrintGridSources.TripPlanning ||
        this.printSource === PrintGridSources.RoutePlanning ||
        this.printSource === PrintGridSources.DispatchTrip
      ) {
        request.routeNames = this.routeNames;
      } else {
        request.proNbrs = proNbrs;
      }
      if (docType === PrintModeEnum.Fbds) {
        request.printerCd = <string>this.fbdsTwoPartPrinter;
      } else {
        request.printerCd = <string>this.dockPrinter;
      }
    } else if (request.printFBDSDocumentsTypeCd === PrintFBDSDocumentsTypeCd.LASER) {
      request.proNbrs = proNbrs;
    }
    return request;
  }

  private startPrintEnsemble(request: StartPrintDocumentEnsembleRqst): Observable<StartPrintDocumentEnsembleResp> {
    return this.cityOperationService.startPrintDocumentEnsemble(request);
  }

  private printFBDSDocuments(request: PrintFBDSDocumentsRqst): Observable<PrintFBDSDocumentsResp> {
    return this.shipmentApiService.printFBDSDocuments(request);
  }

  isManifestPrintDisabled() {
    this.isManifestPrintDisabledSubject.next(
      (this.disableLocationPrint === true && this.disableGridPrint === true) ||
        (this.disableLocationPrint === false && this.disableGridPrint === true) ||
        (this.disableLocationPrint === true && this.disableGridPrint === false)
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  isServiceEnabled() {
    if (this.printSource === PrintGridSources.ModifyTripDetails) {
      this.isServiceDisabledSubject.next(
        this.selectedItems?.length === 0 &&
          (this.data?.isFbdsDisabled === false ||
            this.data?.routeInstIds.length === 0 ||
            this.data?.routeInstIds[0] === undefined)
      );
    } else {
      this.isServiceDisabledSubject.next(this.selectedItems?.length === 0);
    }
  }

  radioChange(event) {
    if (event?.value === 'Reprint') {
      this.rePrintDmanSubject.next(true);
    } else {
      this.rePrintDmanSubject.next(false);
    }
  }
}
