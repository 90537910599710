<div class="pnd-DriverEditor__autoComplete">
  <pnd-autocomplete
    floatLabel="always"
    maxlength="30"
    label="Driver"
    [controlName]="driverFormControl"
    [group]="formGroup"
    [items]="driverNames"
    [optionTemplate]="driverAutocompleteOptionTemplate"
    [sortItems]="false"
    [strictSelection]="false"
    [readonly]="params?.data?.cmsCarrierId"
    [focus$]="focus$"
  >
  </pnd-autocomplete>
</div>

<ng-template #driverAutocompleteOptionTemplate let-item>
  <div class="pnd-DriverEditor__optionTemplate__row">
    <div class="pnd-DriverEditor__optionTemplate__row__info">
      <span class="pnd-DriverEditor__optionTemplate__row__info--header"
        >{{ item.value }}
        <xpo-ssi
          matTooltip="License's expired"
          aria-tooltip="License's expired"
          *ngIf="!item.data?.allowedToDrive"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_commercial_driver_license_expired"
        >
        </xpo-ssi>
        <xpo-ssi
          matTooltip="Medical Exam Date Overdue/Doesn't Exists"
          aria-tooltip="Medical Exam Date Overdue/Doesn't Exists"
          *ngIf="item?.data?.medExamDateValidityCd === LicenseValidityCd.EXPIRED"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss-med-red"
          matTooltipClass="med-Exam-Tooltip"
        ></xpo-ssi>
        <xpo-ssi
          [matTooltip]="item?.data?.medExamDateDueInDaysText"
          [aria-tooltip]="item?.data?.medExamDateDueInDaysText"
          *ngIf="item.data?.medExamDateValidityCd === LicenseValidityCd.WARNING"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss-med-orange"
          matTooltipClass="med-Exam-Tooltip"
        ></xpo-ssi>
        <xpo-ssi
          [matTooltip]="item?.data?.cdlLicenseExpirationDaysText"
          [aria-tooltip]="item?.data?.cdlLicenseExpirationDaysText"
          *ngIf="item.data?.cdlLicenseValidityCd === LicenseValidityCd.WARNING"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_commercial_driver_license_expiring_soon"
        ></xpo-ssi>
        <xpo-ssi
          matTooltip="License's expired/Doesn't Exist"
          aria-tooltip="License's expired/Doesn't Exist"
          *ngIf="item.data?.tankLicenseValidityCd === LicenseValidityCd.EXPIRED"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_tank_endorsement_expired"
        ></xpo-ssi>
        <xpo-ssi
          [matTooltip]="item?.data?.tankLicenseExpirationDaysText"
          [aria-tooltip]="item?.data?.tankLicenseExpirationDaysText"
          *ngIf="item.data?.tankLicenseValidityCd === LicenseValidityCd.WARNING"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_tank_endorsement_expiring_soon"
        ></xpo-ssi>
        <xpo-ssi
          matTooltip="License's expired/Doesn't Exist"
          aria-tooltip="License's expired/Doesn't Exist"
          *ngIf="item?.data?.hazmatLicenseValidityCd === LicenseValidityCd.EXPIRED"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_hazmat_endorsement_expired"
        ></xpo-ssi>
        <xpo-ssi
          [matTooltip]="item?.data?.hazmatLicenseExpirationDaysText"
          [aria-tooltip]="item?.data?.hazmatLicenseExpirationDaysText"
          *ngIf="item?.data?.hazmatLicenseValidityCd === LicenseValidityCd.WARNING"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_hazmat_endorsement_expiring_soon"
        ></xpo-ssi>
        <xpo-ssi
          matTooltip="License's expired/Doesn't Exist"
          aria-tooltip="License's expired/Doesn't Exist"
          *ngIf="item?.data?.doubleTripleLicenseValidityCd === LicenseValidityCd.EXPIRED"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_dbl-trp-red"
        ></xpo-ssi>
        <xpo-ssi
          [matTooltip]="item?.data?.doubleTripleLicenseExpirationDaysText"
          [aria-tooltip]="item?.data?.doubleTripleLicenseExpirationDaysText"
          *ngIf="item?.data?.doubleTripleLicenseValidityCd === LicenseValidityCd.WARNING"
          class="pnd-DriverEditor__optionTemplate__row__info--icon"
          name="ss_dbl-trp-orange"
        ></xpo-ssi>
      </span>
      <span class="pnd-DriverEditor__optionTemplate__row__info--status">{{ item.data?.employeeStatus }}</span>
    </div>
  </div>
  <div class="pnd-DriverEditor__optionTemplate__row--bottom">
    <span class="pnd-DriverEditor__optionTemplate__row--sic">{{ item.data?.sicCd }}</span>
    <span class="pnd-DriverEditor__optionTemplate__row__info--startTime" *ngIf="item.data?.dailyRestartTime">
      Start - <span>{{ item.data?.dailyRestartTime }}</span>
    </span>
  </div>
</ng-template>
