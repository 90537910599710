import { ProFormatterPipe, XpoLtlShipmentDescriptor, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import moment from 'moment-timezone';
import { BoardUtils } from '../../../../../../shared/board-utils';
import { TimeUtil } from '../../../../shared/services/time-format.util';
import { ModifyTripDetailsMainGroupType } from '../enums/modify-trip-details-main-group-type.enum';
import { ModifyTripDetailsSharedGridFields } from '../enums/modify-trip-details-shared-grid-fields.enum';
import { ModifyTripDetailsSharedGridHeaders } from '../enums/modify-trip-details-shared-grid-headers.enum';
import {
  ModifyTripDetailsBaseGridItem,
  ModifyTripDetailsShipmentGridItem,
} from '../models/modify-trip-details-grid-item.model';
import { ModifyTripDetailsShipmentsGridFields } from './modify-trip-details-shipments-grid-fields.enum';
import { ModifyTripDetailsShipmentsGridHeaders } from './modify-trip-details-shipments-grid-headers.enum';

/**
 * ColumnDefinitions for the ModifyTrips Shipments Grid view
 */
export class ModifyTripDetailsShipmentsColumnDefinitions {
  static [ModifyTripDetailsSharedGridFields.SEQUENCE_NBR]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.SEQUENCE_NBR,
    field: ModifyTripDetailsSharedGridFields.SEQUENCE_NBR,
    colId: ModifyTripDetailsSharedGridFields.SEQUENCE_NBR,
    width: 55,
    minWidth: 55,
    pinned: 'left',
    cellClass: 'sequence-nbr-cell',
    sort: 'asc',
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsShipmentGridItem;

      return BoardUtils.isTotalsRow(params.node) ? '' : item?.stopSequenceNbr;
    },
  };

  static [ModifyTripDetailsSharedGridFields.FBDS_PRINT_COUNT]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.FBDS_PRINT_COUNT,
    field: ModifyTripDetailsSharedGridHeaders.FBDS_PRINT_COUNT,
    colId: ModifyTripDetailsSharedGridHeaders.FBDS_PRINT_COUNT,
    headerComponent: 'iconHeaderRenderer',
    headerComponentParams: {
      iconName: 'print',
      tooltip: 'DR Count',
    },
    cellStyle: { justifyContent: 'center' },
    width: 50,
    minWidth: 50,
    pinned: 'left',
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsShipmentGridItem;
      return BoardUtils.isTotalsRow(params.node) ? '' : item.fbdsPrintCount;
    },
  };

  static [ModifyTripDetailsShipmentsGridFields.ORIGIN_SIC]: ColDef = {
    headerName: ModifyTripDetailsShipmentsGridHeaders.ORIGIN_SIC,
    field: ModifyTripDetailsShipmentsGridFields.ORIGIN_SIC,
    colId: ModifyTripDetailsShipmentsGridFields.ORIGIN_SIC,
    width: 90,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsShipmentGridItem;
      return item?.originSicCd;
    },
  };

  static [ModifyTripDetailsShipmentsGridFields.SERVICE_DATE]: ColDef = {
    headerName: ModifyTripDetailsShipmentsGridHeaders.SERVICE_DATE,
    field: ModifyTripDetailsShipmentsGridFields.SERVICE_DATE,
    colId: ModifyTripDetailsShipmentsGridFields.SERVICE_DATE,
    width: 90,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsShipmentGridItem;
      return item?.estimatedDeliveryDate;
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return params.value ? moment(params.value).format('MM/DD') : '';
    },
  };

  static [ModifyTripDetailsShipmentsGridFields.LOADED]: ColDef = {
    headerName: ModifyTripDetailsShipmentsGridHeaders.LOADED,
    field: ModifyTripDetailsShipmentsGridFields.LOADED,
    colId: ModifyTripDetailsShipmentsGridFields.LOADED,
    width: 90,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsShipmentGridItem;

      if (!item || !item.currentStatus) {
        return;
      }

      return item.currentStatus === 'Loaded';
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return BoardUtils.isTotalsRow(params.node) ? '' : params.value ? 'Yes' : 'No';
    },
  };
  static [ModifyTripDetailsShipmentsGridFields.HANDLING_UNITS]: ColDef = {
    headerName: ModifyTripDetailsShipmentsGridHeaders.HANDLING_UNITS,
    field: ModifyTripDetailsShipmentsGridFields.HANDLING_UNITS,
    colId: ModifyTripDetailsShipmentsGridFields.HANDLING_UNITS,
    width: 170,
    cellRenderer: 'handlingUnitsCellRenderer',
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsShipmentGridItem;
      return item?.totalHandlingUnitCount;
    },
  };

  static [ModifyTripDetailsShipmentsGridFields.CURRENT_LOCATION]: ColDef = {
    headerName: ModifyTripDetailsShipmentsGridHeaders.CURRENT_LOCATION,
    field: ModifyTripDetailsShipmentsGridFields.CURRENT_LOCATION,
    colId: ModifyTripDetailsShipmentsGridFields.CURRENT_LOCATION,
    width: 150,
  };

  // We need to use Functions for these column definitions

  static [ModifyTripDetailsShipmentsGridFields.DESTINATION_ETA] = (timeService: XpoLtlTimeService): ColDef => {
    return {
      headerName: ModifyTripDetailsShipmentsGridHeaders.DESTINATION_ETA,
      field: ModifyTripDetailsShipmentsGridFields.DESTINATION_ETA,
      colId: ModifyTripDetailsShipmentsGridFields.DESTINATION_ETA,
      width: 90,
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as ModifyTripDetailsShipmentGridItem;
        return item?.destSicEta;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (TimeUtil.isValidDate(params.value)) {
          return timeService.formatDate(params.value, 'MM/DD HH:mm', params.data.sicCd);
        } else {
          return '';
        }
      },
    };
  };

  static [ModifyTripDetailsShipmentsGridFields.PRO] = (
    shipmentClickCallback: (id: XpoLtlShipmentDescriptor) => void,
    proFormatterPipe: ProFormatterPipe
  ): ColDef => {
    return {
      headerName: ModifyTripDetailsShipmentsGridHeaders.PRO,
      field: ModifyTripDetailsShipmentsGridFields.PRO,
      colId: ModifyTripDetailsShipmentsGridFields.PRO,
      width: 100,
      cellRendererSelector: (params: ICellRendererParams) => {
        const item = params.data as ModifyTripDetailsBaseGridItem;

        // disable clicking on PRO if this is a non-completed Pickup
        const disableProLink = item?.mainGroupType === ModifyTripDetailsMainGroupType.PICKUPS_AND_EQUIPMENT_STOPS;

        let componentName = 'actionLinkCellRenderer';
        if (disableProLink || BoardUtils.isTotalsRow(params.node) || params.node.rowPinned) {
          // don't allow clicking on PRO if it is not a completed stop, or is the totals row
          componentName = null;
        }

        return {
          component: componentName,
          params,
        };
      },
      cellRendererParams: { onClick: shipmentClickCallback },
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as ModifyTripDetailsBaseGridItem;
        return item?.proNbr;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return proFormatterPipe.transform(params.value, 10);
      },
    };
  };
}
