import { Injectable } from '@angular/core';
import {
  CreatePickupRequestResp,
  CreatePickupRequestRqst,
  PickupRequestV2ApiService,
  PkrPickupRequest,
} from '@xpo-ltl-2.0/sdk-pickuprequestv2';
import { ConditioningService } from '@xpo-ltl/common-services';
import { XpoLtlFormatValidationService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  CompleteDeliveryStopPath,
  CompleteDeliveryStopRqst,
  CompleteDockPickupRqst,
  CompletePickupStopPath,
  CompletePickupStopResp,
  CompletePickupStopRqst,
  CompletePnDDropStopPath,
  CompletePnDDropStopRqst,
  CompleteSpotLoadedStopPath,
  CompleteSpotLoadedStopResp,
  CompleteSpotLoadedStopRqst,
  CompleteTripPath,
  CompleteTripRqst,
  CompleteTripStopFromDevicePath,
  CompleteTripStopFromDeviceRqst,
} from '@xpo-ltl/sdk-cityoperations';
import {
  CompleteDeliveryStopResp,
  CompleteEquipmentStopPath,
  CompleteEquipmentStopQuery,
  CompleteEquipmentStopResp,
  CompleteEquipmentStopRqst,
  CompleteHookLoadedStopResp,
  CompleteHookLoadedStopRqst,
  CompletePnDDropStopResp,
} from '@xpo-ltl/sdk-cityoperations/lib/api-cityoperations';
import {
  CountryCd,
  PickupRequesterRoleCd,
  PickupSourceCd,
  PickupStatusCd,
  PickupTypeCd,
  ShipmentSpecialServiceCd,
  ShipperCustomerTypeCd,
  SpecialEquipmentCd,
} from '@xpo-ltl/sdk-common';
import {
  FinalWithExceptionSubQualifierCodes,
  QualifierCodes,
} from 'app/inbound-planning/shared/enums/qualifier-codes.enum';
import { isEmpty as _isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DeliveryShipmentDetailComponent } from '../components/delivery-stop/delivery-activity/components/delivery-shipment-detail/delivery-shipment-detail.component';

export interface ReasonCode {
  code: string;
  description: string;
}

export interface EquipmentsCode {
  id: string;
  value: string;
}

export interface AdditionalServicesCode {
  id: string;
  value: string;
}

export interface DeliveryShipmentDetail {
  [key: string]: DeliveryShipmentDetailComponent;
}

export interface HookShipper {
  shipperCisCustomerNbr: number;
  shipperName1: string;
  shipperAddress1: string;
  shipperCity: string;
  shipperStateCd: string;
  shipperCountryCd: CountryCd;
  shipperZip6: string;
}

export interface HookEmptyContact {
  contactName: string;
  contactPhoneAreaCd: string;
  contactPhoneNbr: string;
  contactPhoneExtension: string;
}

@Injectable({
  providedIn: 'root',
})
export class CompleteTripService {
  constructor(
    private cityOperationsService: CityOperationsApiService,
    private xpoLtlFormatValisdationService: XpoLtlFormatValidationService,
    private conditioningService: ConditioningService,
    private pickupRequest: PickupRequestV2ApiService
  ) {}
  protected pltHuExemption = new BehaviorSubject<number>(0);
  readonly pltHuExemption$ = this.pltHuExemption.asObservable();

  protected pltOptOutSubejct = new BehaviorSubject<boolean>(false);
  readonly pltOptOut$ = this.pltOptOutSubejct.asObservable();

  readonly AttemptReasonCodes: ReasonCode[] = [
    { code: '1', description: 'Residential consignee not at home' },
    { code: '2', description: 'Closed for holiday' },
    { code: '3', description: 'Closed for inventory' },
    { code: '4', description: 'Customer requested future delivery' },
    { code: '5', description: 'Consignee related' },
    { code: '6', description: 'Shipper related' },
    { code: '7', description: 'Recipient unavailable — delivery delayed' },
    { code: '8', description: 'Improper unloading facility or equipment' },
    { code: '9', description: 'Credit hold' },
    { code: 'A', description: 'Customer on shutdown' },
    { code: 'B', description: 'Customer strike' },
    { code: 'C', description: 'COD required' },
    { code: 'D', description: 'Cash not available from consignee' },
  ];

  readonly ReturnReasonCodes: ReasonCode[] = [
    { code: '1', description: 'Driver related' },
    { code: '2', description: 'Insufficient time to complete delivery' },
    { code: '3', description: 'Mechanical breakdown' },
    { code: '4', description: 'Missed delivery' },
    { code: '5', description: 'Other carrier-related reason' },
    { code: '6', description: 'Package mis-sorted' },
    { code: '7', description: 'Previous stop caused delay' },
    { code: '8', description: 'Shipper related' },
  ];

  readonly CartageReasonCodes: ReasonCode[] = [{ code: 'CRTG', description: 'Cartage' }];

  readonly RefusedReasonCodes: ReasonCode[] = [
    { code: '1', description: 'Unauthorized return' },
    { code: '2', description: `Consignee won't accept freight collect` },
    { code: '3', description: 'Arrived too late' },
    { code: 'A', description: 'Arrived too early' },
    { code: '4', description: 'Order cancelled' },
    { code: '5', description: 'Did not order' },
    { code: '6', description: 'Wrong order' },
    { code: 'B', description: 'Duplicate order' },
    { code: '7', description: 'Customer wanted earlier' },
    { code: '8', description: 'Order sent to wrong location' },
    { code: '9', description: `Manufacturer's defect` },
  ];

  readonly RefusedReasonCodesForDockRoutes: ReasonCode[] = [
    { code: '1', description: 'Construction site, not deliverable within 2 day' },
    { code: '2', description: 'No response to arrival notification after 2 working days' },
    { code: '3', description: 'Consignee involved in labor dispute' },
    { code: '4', description: 'Consignee cannot be located at address or phone number shown' },
    { code: '5', description: 'Consignee has moved' },
    { code: '6', description: 'Credit hold' },
    { code: '7', description: 'Susp at Cust request' },
  ];

  readonly UndeliveredReasonCodes: ReasonCode[] = [
    { code: '3', description: 'Consignee involved in labor dispute' },
    { code: '4', description: 'Consignee cannot be located at address or phone number shown' },
    { code: '5', description: 'Consignee has moved' },
  ];

  readonly DeliveryQualifierCodes: ReasonCode[] = [
    { code: QualifierCodes.FINAL, description: 'Final' },
    { code: QualifierCodes.FINAL_WITH_EXCEPTION, description: 'Final w/ Exception' },
    { code: QualifierCodes.PARTIAL_SHORT, description: 'Part Short' },
    { code: QualifierCodes.REFUSED, description: 'Refused' },
    { code: QualifierCodes.DAMAGED, description: 'Returned Damaged' },
    { code: QualifierCodes.RETURNED, description: 'Return' },
    { code: QualifierCodes.ATTEMPTED, description: 'Attempt' },
    { code: QualifierCodes.ALL_SHORT, description: 'All Short' },
  ];

  readonly FinalWithExceptionSubQualifierCodes: ReasonCode[] = [
    { code: FinalWithExceptionSubQualifierCodes.ACCEPTED, description: 'Accepted' },
    { code: FinalWithExceptionSubQualifierCodes.ACCEPTED_DAMAGED, description: 'Accepted Damaged' },
    { code: FinalWithExceptionSubQualifierCodes.REFUSED, description: 'Refused' },
    { code: FinalWithExceptionSubQualifierCodes.REFUSED_DAMAGED, description: 'Refused Damaged' },
    { code: FinalWithExceptionSubQualifierCodes.SHORT, description: 'Short' },
  ];

  readonly ChildSubQualifierCodes: ReasonCode[] = [
    { code: FinalWithExceptionSubQualifierCodes.ACCEPTED, description: 'Accepted' },
    { code: FinalWithExceptionSubQualifierCodes.ACCEPTED_DAMAGED, description: 'Accepted Damaged' },
    { code: FinalWithExceptionSubQualifierCodes.REFUSED, description: 'Refused' },
    { code: FinalWithExceptionSubQualifierCodes.REFUSED_DAMAGED, description: 'Refused Damaged' },
    { code: FinalWithExceptionSubQualifierCodes.SHORT, description: 'Short' },
    { code: FinalWithExceptionSubQualifierCodes.RETURN, description: 'Return' },
    { code: FinalWithExceptionSubQualifierCodes.ATTEMPTED, description: 'Attempt' },
    { code: FinalWithExceptionSubQualifierCodes.UNDL, description: 'Undelivered' },
  ];

  readonly CallNbrAdditionalServicesList: AdditionalServicesCode[] = [
    { id: ShipmentSpecialServiceCd.INSIDE_PICKUP, value: 'Inside Pickup' },
    { id: ShipmentSpecialServiceCd.VOLUME_PICKUP, value: 'Volume Pickup' },
    { id: ShipmentSpecialServiceCd.DRIVER_COLLECT, value: 'Driver Collect' },
    { id: ShipmentSpecialServiceCd.STRAND, value: 'Strand' },
    { id: ShipmentSpecialServiceCd.AFTER_HOURS_PICKUP, value: 'After Hours Pickup' },
  ];

  readonly LineItemAdditionalServicesList: AdditionalServicesCode[] = [
    { id: ShipmentSpecialServiceCd.GUARANTEED, value: 'Guaranteed' },
    { id: ShipmentSpecialServiceCd.GUARANTEED_BY_NOON, value: 'Guaranteed By Noon' },
    { id: ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE, value: 'Rapid Remote Service' },
    { id: ShipmentSpecialServiceCd.HAZMAT, value: 'Hazmat' },
    { id: ShipmentSpecialServiceCd.DESTINATION_APPOINTMENT, value: 'Destination Appointment' },
    { id: ShipmentSpecialServiceCd.FREEZABLE, value: 'Freezable' },
    { id: ShipmentSpecialServiceCd.FOOD, value: 'Food' },
    { id: ShipmentSpecialServiceCd.BULK_LIQUID, value: 'BulkLiquid' },
  ];

  // TODO: Add the additionalServicesList
  readonly SpecialEquipmentList: EquipmentsCode[] = [
    { id: SpecialEquipmentCd.UNDEFINED, value: 'None' },
    { id: SpecialEquipmentCd.FORKLIFT, value: 'Fork Lift' },
    { id: SpecialEquipmentCd.LIFTGATE, value: 'Lift Gate' },
    { id: SpecialEquipmentCd.PALLET_JACK, value: 'Pallet Jack' },
  ];

  private deliveryShipmentDetails: DeliveryShipmentDetail = {};

  private stopSelectedSubject = new BehaviorSubject<boolean>(false);
  readonly stopsSelected$ = this.stopSelectedSubject.asObservable();

  setPltHuExemption(val: number) {
    this.pltHuExemption.next(val);
  }
  getPltHuExemption() {
    return this.pltHuExemption.value;
  }

  getPltOptOut() {
    return this.pltOptOutSubejct.value;
  }
  setPltOptOut(val: boolean) {
    this.pltOptOutSubejct.next(val);
  }

  createPickupRequest$(
    shipper: HookShipper,
    contact: HookEmptyContact,
    pickupTypeCd: PickupTypeCd,
    numberOfHooks?: number
  ): Observable<CreatePickupRequestResp[]> {
    const request = new CreatePickupRequestRqst();
    request.completePickupRequest = {
      pkrPickupRequest: {
        ...new PkrPickupRequest(),
        sourceCd: PickupSourceCd.INTERNAL,
        pickupDate: new Date().toISOString(),
        readyTime: '17:00:00',
        closeTime: '23:59:00',
        pickupTypeCd: pickupTypeCd,
        cashDueInd: false,
        insidePickupInd: false,
        volumeInd: false,

        shipperCisCustomerNbr: shipper?.shipperCisCustomerNbr,
        shipperCustomerTypeCd: ShipperCustomerTypeCd.CUSTOMER,
        shipperName1: shipper?.shipperName1,
        shipperAddress1: shipper?.shipperAddress1,
        shipperCity: shipper?.shipperCity,
        shipperStateCd: shipper?.shipperStateCd,
        shipperCountryCd: shipper?.shipperCountryCd,
        shipperZip6: shipper?.shipperZip6,

        requesterRoleCd: PickupRequesterRoleCd.SHIPPER,
        statusCd: PickupStatusCd.NEW,

        contactName: contact?.contactName,
        contactCompanyName: `@SL Confirm - ${moment(new Date()).format('MM/DD')}`,

        contactPhoneAreaCd: contact?.contactPhoneAreaCd,
        contactPhoneNbr: contact?.contactPhoneNbr,
        contactPhoneExtension: contact?.contactPhoneExtension,
      },
      pickupRequestShipments: [],
    };

    if (pickupTypeCd === PickupTypeCd.HL) {
      const arrOfApiCalls: Observable<CreatePickupRequestResp>[] = [];
      for (let i = 0; i < numberOfHooks; i++) {
        arrOfApiCalls.push(
          this.pickupRequest.createPickupRequest(request).pipe(
            map((response: any) => {
              return response as CreatePickupRequestResp;
            }),
            catchError((error) => of(error))
          )
        );
      }

      return forkJoin(arrOfApiCalls).pipe(
        take(1),
        map((res: CreatePickupRequestResp[]) => {
          return res;
        })
      );
    } else {
      return this.pickupRequest.createPickupRequest(request).pipe(
        map((response: any) => {
          return [response as CreatePickupRequestResp];
        })
      );
    }
  }

  completeSpotEmptyOrHookEmpty$(
    request: CompleteEquipmentStopRqst,
    path: CompleteEquipmentStopPath,
    queryParams: CompleteEquipmentStopQuery
  ): Observable<CompleteEquipmentStopResp> {
    return this.cityOperationsService.completeEquipmentStop(request, path, queryParams);
  }

  completeSpotLoadStop$(
    request: CompleteSpotLoadedStopRqst,
    path: CompleteSpotLoadedStopPath
  ): Observable<CompleteSpotLoadedStopResp> {
    return this.cityOperationsService.completeSpotLoadedStop(request, path);
  }

  completeDeliveryStop$(
    request: CompleteDeliveryStopRqst,
    path: CompleteDeliveryStopPath
  ): Observable<CompleteDeliveryStopResp> {
    return this.cityOperationsService.completeDeliveryStop(request, path);
  }

  completeDropStop$(
    request: CompletePnDDropStopRqst,
    path: CompletePnDDropStopPath
  ): Observable<CompletePnDDropStopResp> {
    return this.cityOperationsService.completePnDDropStop(request, path);
  }

  completeHookStop$(
    request: CompleteEquipmentStopRqst,
    path: CompleteEquipmentStopPath,
    queryParams: CompleteEquipmentStopQuery
  ): Observable<CompleteEquipmentStopResp> {
    return this.cityOperationsService.completeEquipmentStop(request, path, queryParams);
  }

  completeHookLoadedStop$(
    request: CompleteHookLoadedStopRqst,
    path: CompleteEquipmentStopPath
  ): Observable<CompleteHookLoadedStopResp> {
    return this.cityOperationsService.completeHookLoadedStop(request, path);
  }

  completePickupStop$(
    request: CompletePickupStopRqst,
    path: CompletePickupStopPath
  ): Observable<CompletePickupStopResp> {
    return this.cityOperationsService.completePickupStop(request, path);
  }

  completeDockStop$(request: CompleteDockPickupRqst): Observable<void> {
    return this.cityOperationsService.completeDockPickup(request);
  }

  completeTrip$(tripInstId: number, tripCompleteDateTime: Date): Observable<void> {
    const request = { ...new CompleteTripRqst(), invokeFromHandheldInd: false, tripCompleteDateTime };
    const path = { ...new CompleteTripPath(), tripInstId };
    return this.cityOperationsService.completeTrip(request, path);
  }

  completePendingStop$(
    request: CompleteTripStopFromDeviceRqst,
    path: CompleteTripStopFromDevicePath
  ): Observable<void> {
    return this.cityOperationsService.completeTripStopFromDevice(request, path);
  }

  addDeliveryShipmentDetail(key: string, component: DeliveryShipmentDetailComponent) {
    this.deliveryShipmentDetails[key] = component;
  }

  navigateToDeliveryShipmentPro(childPro: string, activity) {
    activity?.shipments.forEach((shipment) => {
      const detail = this.deliveryShipmentDetails[shipment.shipmentInstId];

      if (detail) {
        detail.navigateToPro(childPro);
      }
    });
  }

  clearHighlighted(): void {
    Object.keys(this.deliveryShipmentDetails).forEach((key) => {
      this.deliveryShipmentDetails[key].cleanHighlightsRow();
    });
  }

  formatProString(rawProNbr: string): string {
    if (
      !_isEmpty(rawProNbr) &&
      rawProNbr.length >= 9 &&
      this.xpoLtlFormatValisdationService.isValidProNumber(rawProNbr)
    ) {
      rawProNbr = this.conditioningService.conditionProNumber(rawProNbr, 10);
    }
    return rawProNbr;
  }

  setStopsSelected(val: boolean): void {
    this.stopSelectedSubject.next(val);
  }
}
