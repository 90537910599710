import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ShipmentSpecialServiceCd, SpecialEquipmentCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject } from 'rxjs';
import { SpecialServiceMark } from '../model/special-service-mark';
import { SpecialServicePipeProp } from './../model/special-service-pipe-prop';

const special_service_pnd = {
  [ShipmentSpecialServiceCd.DRIVER_COLLECT]: 'ss_cash_on_delivery',
  [ShipmentSpecialServiceCd.BULK_LIQUID]: 'ss_bulkliquid',
  [ShipmentSpecialServiceCd.AFTER_HOURS_PICKUP]: 'ss_after_hours', // TODO: retrieve this icon from library (NGXLTL-1294)
  [SpecialEquipmentCd.LIFTGATE]: 'ss_lift_gate', // needed to match to tags
  [SpecialEquipmentCd.FORKLIFT]: 'ss_fork_lift', // needed to match to tags
};

const markable_special_services = {
  [ShipmentSpecialServiceCd.APPOINTMENT]: 'ss_appointment_pending',
};

@Component({
  selector: 'pnd-special-services',
  templateUrl: './special-services.component.html',
  styleUrls: ['./special-services.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialServicesComponent {
  readonly ShipmentSpecialServiceCd = ShipmentSpecialServiceCd;
  private specialServicesSubject = new BehaviorSubject<ShipmentSpecialServiceCd[]>([]);
  readonly specialServices$ = this.specialServicesSubject.asObservable();
  private marks: SpecialServiceMark[] = [];

  constructor() {}

  @Input()
  set specialServiceMarks(value) {
    this.marks = value;
  }

  @Input()
  set value(specialServices: ShipmentSpecialServiceCd[]) {
    this.specialServicesSubject.next(specialServices);
  }

  iconForCd(serviceCd: ShipmentSpecialServiceCd): string {
    // Special icon names
    if (special_service_pnd[serviceCd]) {
      return special_service_pnd[serviceCd];
    }

    if (markable_special_services[serviceCd]) {
      if (this.isServiceMarked(serviceCd)) {
        return markable_special_services[serviceCd];
      }
    }

    const iconName = serviceCd
      ?.match(/[A-Z][a-z]+/g)
      ?.join('_')
      ?.toLowerCase();
    return `ss_${iconName}`;
  }

  isServiceMarked(specialService: ShipmentSpecialServiceCd): boolean {
    if (this.marks?.length) {
      const specialServiceMark = this.getMark(specialService);
      return specialServiceMark?.active;
    }
  }

  getMark(specialService): SpecialServiceMark {
    return this.marks?.find((ssm) => ssm.specialService === specialService);
  }

  getMarkPipeProps(specialService): SpecialServicePipeProp {
    const specialServiceMark = this.getMark(specialService);
    return <SpecialServicePipeProp>{ applyMark: !!specialServiceMark, active: specialServiceMark?.active };
  }
}
