import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { WeatherLayer } from '../../models';

@Component({
  selector: 'app-map-weather',
  templateUrl: './map-weather.component.html',
  styleUrls: ['./map-weather.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapWeatherComponent implements OnInit {
  @Output() weatherLayerChange = new EventEmitter<MatRadioChange>();
  @Input() selectedLayer: WeatherLayer;
  @Input() weatherLayers: Array<WeatherLayer>;
  @Input() map;

  constructor() {}

  ngOnInit(): void {}

  onLayerChange(event: MatRadioChange) {
    this.weatherLayerChange.emit(event);
  }
}
