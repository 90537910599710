<div class="pnd-actionsCellRenderer" *ngIf="!shouldHide">
  <ng-container *ngIf="actions && actions.length > 0">
    <ng-container *ngFor="let action of actions">
      <button
        mat-icon-button
        class="pnd-actionsCellRenderer__button"
        (click)="stopEventPropagation($event); action.onClick(data)"
        [matTooltip]="action?.tooltip"
        matTooltipPosition="above"
      >
        <mat-icon class="pnd-actionsCellRenderer__icon">{{ action.iconName }}</mat-icon>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="menus && menus.length > 0">
    <ng-container *ngFor="let menu of menus">
      <ng-container *ngIf="!menu.shouldHide || !(menu.shouldHide(data) | async)">
        <button
          mat-icon-button
          class="pnd-actionsCellRenderer__button"
          matTooltipPosition="above"
          [matTooltip]="menu?.tooltip"
          [matMenuTriggerFor]="optionsMenu"
          (click)="stopEventPropagation($event)"
        >
          <mat-icon class="pnd-actionsCellRenderer__icon">{{ menu.iconName }}</mat-icon>
        </button>

        <mat-menu #optionsMenu="matMenu" class="xpo-Popover-panel">
          <ng-container *ngFor="let option of menu.options">
            <button
              *ngIf="!(option.shouldHide(data) | async)"
              mat-menu-item
              (click)="option.onClick(data, gridApi, rowIndex, rowNode, $event)"
            >
              {{ option.text || option.getText(data) }}
              <ng-container *ngIf="option?.icon?.shouldHide">
                <xpo-icon
                  *ngIf="!(option?.icon.shouldHide(data) | async)"
                  iconName="{{option.icon.name}}"
                  class="{{option.icon.name}}"
                ></xpo-icon>
              </ng-container>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
