import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { DomSanitizer } from '@angular/platform-browser';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber, XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';

import {
  isUndefined as _isUndefined,
  reduce as _reduce,
  size as _size,
  startCase as _startCase,
  uniqBy as _uniqBy,
} from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, takeUntil } from 'rxjs/operators';
import { FeatureTypes } from '../../../../../core/services/features/feature-types.enum';
import { LayoutManagerService } from '../../../../../shared/layout-manager/services/layout-manager.service';
import {
  DefaultLayout,
  LayoutPreferenceService,
} from '../../../../../shared/layout-manager/services/layout-preference.service';
import { GeoLocationStoreSelectors, GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { RouteBalancingSelectors } from '../../../../store/route-balancing-store';
import { CustomEventEmitter } from '../../emitters/custom-event-emitter';
import { WeatherLayer } from '../../models/weather-layer.interface.js';
import { SpecialServicesService } from '../../services';
import { MapToolbarSelectionItem, MapToolbarService } from '../../services/map-toolbar.service';
import { UnassignedDeliveriesCacheService } from '../../services/unassigned-deliveries-cache.service';
import { UnassignedPickupsService } from '../../services/unassigned-pickups.service';
import { UnmappedStopsEditMode } from '../unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { UserPreferencesService } from './../../services/user-preferences.service';
import { WeatherLayerOptions } from './weather-layer-options';

@Component({
  selector: 'map-toolbar-widget',
  templateUrl: './map-toolbar-widget.component.html',
  styleUrls: ['./map-toolbar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapToolbarWidgetComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('mapToolbarIndicatorsBar') mapToolbarIndicatorsBar: ElementRef;
  @ViewChild('mapToolbarHideButton') mapToolbarHideButton: ElementRef;
  private readonly specialServicePreferenceKey = 'special-service-preference-key';
  private readonly drawOptionsPreferenceKey = 'draw-options-preference-key';
  @Input()
  isExpanded: boolean = false;
  @Input()
  sicMarkerRotationAngle: number = 0;
  @Input()
  mapInitialized$: Observable<boolean>;

  @Output()
  unassignedDeliveriesLayerChange = new EventEmitter<boolean>();

  @Output()
  normalDeliveriesChange = new EventEmitter<boolean>();
  @Output()
  spotLoadDeliveriesChange = new EventEmitter<boolean>();
  @Output()
  normalPickupsChange = new EventEmitter<boolean>();
  @Output()
  deliveryHeatmapChange = new EventEmitter<boolean>();
  @Output()
  pickupHeatmapChange = new EventEmitter<boolean>();
  @Output()
  hookLoadPickupsChange = new EventEmitter<boolean>();
  @Output()
  hookEmptyPickupsChange = new EventEmitter<boolean>();
  @Output()
  spotEmptyPickupsChange = new EventEmitter<boolean>();
  @Output()
  unassignedPickupsLayerChange = new EventEmitter<boolean>();
  @Output()
  forecastedPickupsLayerChange = new EventEmitter<boolean>();
  @Output()
  driverLocationLayerChange = new EventEmitter<boolean>();
  @Output()
  trafficLayerChange = new CustomEventEmitter<boolean>();
  @Output()
  weatherLayerChange = new EventEmitter<WeatherLayer>();
  @Output()
  dispatchAreaLayerChange = new CustomEventEmitter<boolean>();
  @Output()
  geoAreasDrawerChange = new CustomEventEmitter<boolean>();
  @Output()
  completedStopsLayerChange = new EventEmitter<boolean>();
  @Output()
  spotsAndDropsLayerChange = new CustomEventEmitter<boolean>();
  @Output()
  drawModeChange = new EventEmitter<boolean>();
  @Output()
  drawOptionsPanelChange = new EventEmitter<boolean>();
  @Output()
  compassClicked = new EventEmitter<void>();
  @Output()
  toggleToolbar = new EventEmitter<void>();

  @Output()
  toggleUnmappedDeliveriesChange = new EventEmitter<boolean>();
  @Output()
  toggleUnmappedPickupsChange = new EventEmitter<boolean>();

  isDrawOptionsPanelOpened: boolean = false;
  isPickupClustersChecked: boolean = false;
  isUnassignedDeliveriesChecked: boolean = true;
  isNormalDeliveriesChecked: boolean = true;
  isSpotLoadDeliveriesChecked: boolean = true;
  isUnassignedPickupsChecked: boolean = false;
  isForecastedPickupsChecked: boolean = false;
  isNormalPickupsChecked: boolean = true;
  isDeliveryHeatmapChecked: boolean = false;
  isPickupHeatmapChecked: boolean = false;
  isHookLoadPickupsChecked: boolean = true;
  isHookEmptyPickupsChecked: boolean = true;
  isSpotEmptyPickupsChecked: boolean = true;

  isDriverLocationChecked: boolean = false;
  isCompletedStopsChecked: boolean = false;
  isSpotsAndDropsChecked: boolean = false;
  isDispatchAreaChecked: boolean = false;
  isGeoAreasChecked: boolean = false;
  @Input() isGeoAreasDrawChecked: boolean = false;
  isTrafficChecked: boolean = false;
  isWeatherChecked: boolean = false;
  isMoreOptionsOpened: boolean = false;

  mapWeatherLayers: Array<WeatherLayer> = WeatherLayerOptions;

  selectedWeatherLayer = this.mapWeatherLayers[0];

  allIndicatorsComplete: boolean;
  specialServicesList = SpecialServicesService.specialServiceMapSelectable.map((item) => ({
    item: item.specialService,
    selected: false,
  }));

  private isWeatherModeActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isWeatherModeActive$ = this.isWeatherModeActiveSubject.asObservable();

  private unsubscriber = new Unsubscriber();

  googleMap: google.maps.Map;
  readonly controlsDisabled$: Observable<boolean>;
  readonly isRouteBalancingActive$: Observable<boolean>;
  readonly isDispatchAreaHidden$: Observable<boolean>;
  readonly isLastBestStopFeatureActive$: Observable<boolean>;
  readonly isForecastedPickupsActive$: Observable<boolean>;

  private readonly indicatorBarOpenSubject = new BehaviorSubject<boolean>(false);
  readonly indicatorBarOpen$ = this.indicatorBarOpenSubject.asObservable();

  private readonly refreshSSBarPositionSubject = new Subject<void>();
  readonly refreshSSBarPosition$ = this.refreshSSBarPositionSubject.asObservable();

  private readonly checkBoxChecked: MatCheckboxChange = { ...new MatCheckboxChange(), checked: true };
  private readonly checkBoxUnchecked: MatCheckboxChange = { ...new MatCheckboxChange(), checked: false };

  readonly specialServiceMapSelected$ = this.specialServicesService.specialServiceMapSelected$;
  readonly showSpecialServiceMapSelected$ = this.specialServicesService.showSpecialServiceMapSelected$;

  private $toolbarTimer: Observable<number>;
  private toolbarTimerSubscription: Subscription;
  private toolbarTimerPaused: boolean = false;

  isFirstLoad = true;

  sanitizeSpecialServiceName(specialService: ShipmentSpecialServiceCd) {
    return _startCase(specialService);
  }

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private mapToolbarService: MapToolbarService,
    private unassignedPickupsService: UnassignedPickupsService,
    private unassignedDeliveriesCacheService: UnassignedDeliveriesCacheService,
    private layoutPreferences: LayoutPreferenceService,
    private specialServicesService: SpecialServicesService,
    private userPreferencesService: UserPreferencesService,
    private featuresService: XpoLtlFeaturesService,
    private layoutManagerService: LayoutManagerService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    this.registerIcons([
      { name: 'driverLocation', url: '../../../../assets/xpo-icons/driverLocation.svg' },
      { name: 'completedStops', url: '../../../../assets/xpo-icons/completedStops.svg' },
      { name: 'spotsAndDrops', url: '../../../../assets/xpo-icons/spotsAndDrops.svg' },
      { name: 'ss_special_service', url: '../../../../assets/xpo-icons/ss_special_service.svg' },
    ]);

    this.controlsDisabled$ = this.pndStore$.select(GeoLocationStoreSelectors.editMode).pipe(
      takeUntil(this.unsubscriber.done$),
      map((mode: UnmappedStopsEditMode) => !_isUndefined(mode))
    );

    this.isDispatchAreaHidden$ = this.layoutManagerService.layoutActivated$.pipe(
      takeUntil(this.unsubscriber.done$),
      map((activeLayout) => activeLayout?.['name'] !== DefaultLayout.Dispatcher)
    );

    this.isRouteBalancingActive$ = this.pndStore$.select(RouteBalancingSelectors.openRouteBalancingPanel);

    this.isLastBestStopFeatureActive$ = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic).pipe(
      distinctUntilChanged(),
      map((sic: string) => {
        const featureValue = this.featuresService.getFeatureValue(sic, FeatureTypes.LastBestStop);
        return featureValue === 'Y';
      }),
      takeUntil(this.unsubscriber.done$)
    );

    this.isForecastedPickupsActive$ = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic).pipe(
      distinctUntilChanged(),
      map((sic: string) => {
        const featureValue = this.featuresService.getFeatureValue(sic, FeatureTypes.Optimize);
        return featureValue === 'Y';
      }),
      takeUntil(this.unsubscriber.done$)
    );
  }

  private registerIcons(icons: { name: string; url: string }[]) {
    for (const icon of icons) {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url));
    }
  }

  ngOnInit(): void {
    this.userPreferencesService
      .getPreferencesFor<ShipmentSpecialServiceCd[]>(this.specialServicePreferenceKey)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((specialServices) => {
        this.updateSpecialServiceList(specialServices);
      });

    this.mapToolbarService.isPickupClustersLayerActive$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((isActive: boolean) => {
        this.isPickupClustersChecked = isActive;
      });

    this.mapToolbarService.mapToolbarSelectionItems = [];
    this.uncheckAllLayers();
  }

  setAll(checked: boolean) {
    this.allIndicatorsComplete = checked;
    this.specialServicesList.forEach((item) => (item.selected = checked));
    this.onSpecialServiceChange();
  }

  updateAllComplete() {
    this.allIndicatorsComplete = this.specialServicesList.every((t) => t.selected);
  }

  getSelectedSpecialServices(): ShipmentSpecialServiceCd[] {
    return this.specialServicesList.filter((t) => t.selected).map((item) => item.item);
  }

  onSpecialServiceChange() {
    this.specialServicesService.updateSpecialServiceMapSelected(this.getSelectedSpecialServices());

    this.userPreferencesService
      .updatePreferencesFor<ShipmentSpecialServiceCd[]>(
        this.specialServicePreferenceKey,
        this.getSelectedSpecialServices()
      )
      .subscribe();
    this.updateAllComplete();
  }

  updateSpecialServiceList(specialServices: ShipmentSpecialServiceCd[]) {
    this.specialServicesList = this.specialServicesList.map((specialService) => ({
      ...specialService,
      selected: specialServices?.some((ss) => ss === specialService.item),
    }));

    this.specialServicesService.updateSpecialServiceMapSelected(this.getSelectedSpecialServices());
    this.updateAllComplete();
  }

  ngAfterViewInit(): void {
    this.mapToolbarService.reviewUnmappedDeliveries$.pipe(skip(1), takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.onUnmappedDeliveriesClicked();
    });

    this.mapToolbarService.reviewUnmappedPickups$.pipe(skip(1), takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.onUnmappedPickupsClicked();
    });

    this.mapToolbarService.mapInstance$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((thisMap: google.maps.Map) => {
        this.googleMap = thisMap;
      });

    this.unassignedPickupsService.unmappedPickups$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(
      (unmappedPickups) => {
        const unmappedPickupsCount = _size(
          _uniqBy(unmappedPickups, (unmappedPickup) => unmappedPickup.pickupHeader.pickupRequestInstId)
        );

        this.mapToolbarService.setUnmappedPickupShipmentsCount(unmappedPickupsCount);
        this.mapToolbarService.setUnmappedPickupsCount(_size(unmappedPickups));
      },
      () => {
        this.mapToolbarService.setUnmappedPickupShipmentsCount(0);
        this.mapToolbarService.setUnmappedPickupsCount(0);
      }
    );

    this.unassignedDeliveriesCacheService.unmappedDeliveries$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(
      (unmappedDeliveries) => {
        const filteredUnmappedDeliveries = unmappedDeliveries.filter(
          (unmappedDelivery) =>
            unmappedDelivery?.consignee?.geoCoordinatesGeo?.lat === 0 ||
            unmappedDelivery?.consignee?.geoCoordinatesGeo?.lon === 0
        );
        const unmappedDeliveriesCount = _reduce(
          filteredUnmappedDeliveries,
          (a, b) => a + _size(b.deliveryShipments),
          0
        );

        this.mapToolbarService.setUnmappedDeliveryShipmentsCount(unmappedDeliveriesCount);
        this.mapToolbarService.setUnmappedDeliveriesCount(_size(filteredUnmappedDeliveries));
      },
      () => {
        this.mapToolbarService.setUnmappedDeliveryShipmentsCount(0);
        this.mapToolbarService.setUnmappedDeliveriesCount(0);
      }
    );

    // TODO - we should not be testing for Dispatcher as a layout here since user can save
    // their own custom layouts.  We need a better way of  with this
    this.mapInitialized$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.layoutPreferences.isDispatcherLayout()) {
        this.setDefaultMapToolbarSelection(DefaultLayout.Dispatcher);
        this.setFiltersForLayout(DefaultLayout.Dispatcher);
      } else {
        this.setDefaultMapToolbarSelection(DefaultLayout.InboundPlanner);
        this.setFiltersForLayout(DefaultLayout.InboundPlanner);
      }
    });
  }

  setFiltersForLayout(layout: DefaultLayout): void {
    for (const item of this.mapToolbarService.mapToolbarSelectionItems) {
      switch (item) {
        case MapToolbarSelectionItem.UNASSIGNED_DELIVERIES:
          this.onUnassignedDeliveriesLayerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.COMPLETED_STOPS:
          this.onCompletedStopsLayerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.UNASSIGNED_PICKUPS:
          this.onUnassignedPickupsLayerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.DRIVERS_LOCATION:
          this.onDriverLocationLayerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.COMPLETED_STOPS:
          this.onCompletedStopsLayerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.TRAFFIC:
          this.onTrafficLayerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.GEO_AREAS:
          this.onGeoAreasDrawerChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.GEO_FILTER_DRAW:
          this.onGeoFilterDrawToggle(true);
          break;

        case MapToolbarSelectionItem.POLYGON_DRAW:
          this.onPolygonDrawToggle(true);
          break;

        case MapToolbarSelectionItem.WEATHER_WIDGET:
          this.onWeatherWidgetChange(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.INDICATOR:
          this.onToggleIndicatorsBar();
          break;

        case MapToolbarSelectionItem.NORMAL_PICKUPS:
          this.handleDrawNormalPickups(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.LOAD_PICKUPS:
          this.handleDrawHookLoadPickups(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.EMPTY_PICKUPS:
          this.handleDrawHookEmptyPickups(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.SPOT_EMPTY_PICKUPS:
          this.handleDrawSpotEmptyPickups(this.checkBoxChecked);
          break;

        case MapToolbarSelectionItem.GEO_FILTER_DRAW:
          this.onGeoFilterDrawToggle(this.isDrawOptionsPanelOpened);
          this.mapToolbarService.handleDrawOptionsPanelChange(this.isDrawOptionsPanelOpened);
          break;
      }
    }
    if (layout === DefaultLayout.InboundPlanner) {
      this.onDispatchAreaLayerChange(this.checkBoxUnchecked, layout);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onPolygonDrawToggle(inDrawMode: boolean): void {
    this.drawOptionsPanelChange.emit(inDrawMode);
    this.drawModeChange.emit(inDrawMode);
    this.isDrawOptionsPanelOpened = inDrawMode;
    this.updateSelectionOutDispatchArea(inDrawMode, MapToolbarSelectionItem.POLYGON_DRAW);

    if (inDrawMode && this.isExpanded) {
      this.onToggleToolbar();
    }
  }

  onGeoFilterDrawToggle(inDrawMode: boolean): void {
    this.drawModeChange.emit(inDrawMode);
  }

  onUnassignedDeliveriesLayerChange(event: MatCheckboxChange): void {
    this.isUnassignedDeliveriesChecked = event.checked;
    this.unassignedDeliveriesLayerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.UNASSIGNED_DELIVERIES);
  }

  onUnassignedPickupsLayerChange(event: MatCheckboxChange, fromTemplate?: boolean): void {
    this.isUnassignedPickupsChecked = event.checked;
    this.unassignedPickupsLayerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.UNASSIGNED_PICKUPS);

    if (this.isFirstLoad && fromTemplate) {
      this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.NORMAL_PICKUPS);
      this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.LOAD_PICKUPS);
      this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.EMPTY_PICKUPS);
      this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.SPOT_EMPTY_PICKUPS);

      this.isFirstLoad = false;
    }
  }

  onForecastedPickupsLayerChange(event: MatCheckboxChange): void {
    this.isForecastedPickupsChecked = event.checked;
    this.forecastedPickupsLayerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.FORECASTED_PICKUPS);
  }

  handleDrawNormalPickups(event: MatCheckboxChange): void {
    this.isNormalPickupsChecked = event.checked;
    this.normalPickupsChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.NORMAL_PICKUPS);
  }

  handleDrawDeliveryHeatmap(event: MatCheckboxChange) {
    this.isDeliveryHeatmapChecked = event.checked;
    this.deliveryHeatmapChange.emit(event.checked);
  }

  handleDrawPickupHeatmap(event: MatCheckboxChange) {
    this.isPickupHeatmapChecked = event.checked;
    this.pickupHeatmapChange.emit(event.checked);
  }

  handleDrawHookLoadPickups(event: MatCheckboxChange): void {
    this.isHookLoadPickupsChecked = event.checked;
    this.hookLoadPickupsChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.LOAD_PICKUPS);
  }

  handleDrawHookEmptyPickups(event: MatCheckboxChange): void {
    this.isHookEmptyPickupsChecked = event.checked;
    this.hookEmptyPickupsChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.EMPTY_PICKUPS);
  }

  handleDrawSpotEmptyPickups(event: MatCheckboxChange): void {
    this.isSpotEmptyPickupsChecked = event.checked;
    this.spotEmptyPickupsChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.SPOT_EMPTY_PICKUPS);
  }

  onDriverLocationLayerChange(event: MatCheckboxChange): void {
    this.isDriverLocationChecked = event.checked;
    this.driverLocationLayerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.DRIVERS_LOCATION);
  }

  onPickupClustersChange(event: MatCheckboxChange): void {
    this.isPickupClustersChecked = event.checked;
    this.mapToolbarService.togglePickupClustersLayer(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.PICKUP_CLUSTERS);
  }

  onTrafficLayerChange(event: MatCheckboxChange): void {
    this.isTrafficChecked = event.checked;
    this.trafficLayerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.TRAFFIC);
  }

  onWeatherWidgetChange(event: MatCheckboxChange): void {
    this.isWeatherChecked = event.checked;
    this.isWeatherModeActiveSubject.next(event.checked);
    if (event.checked) {
      this.weatherLayerChange.emit(this.selectedWeatherLayer);
    } else {
      this.weatherLayerChange.emit(null);
    }
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.WEATHER_WIDGET);
  }

  onWeatherLayerChange(event: MatRadioChange): void {
    this.selectedWeatherLayer = event.value;
    this.weatherLayerChange.emit(event.value as WeatherLayer);
  }

  onDispatchAreaLayerChange(event: MatCheckboxChange, layout: DefaultLayout): void {
    this.isDispatchAreaChecked = event.checked;
    this.dispatchAreaLayerChange.emit(event.checked);

    if (layout && layout !== DefaultLayout.Dispatcher) {
      return;
    }

    if (this.isDispatchAreaChecked) {
      this.mapToolbarService.removeDrawSelection();
      this.mapToolbarService.copyMatToolbarSelectionCache();
      this.uncheckAllLayers();
      this.handleDrawPickupHeatmap(this.checkBoxChecked);
      if (this.indicatorBarOpenSubject.value) {
        this.onToggleIndicatorsBar();
      }
    } else {
      this.uncheckAllLayers();
      this.handleDrawPickupHeatmap(this.checkBoxUnchecked);
      this.mapToolbarService.retrieveMatToolbarSelectionCache();
      this.setFiltersForLayout(DefaultLayout.Dispatcher);
    }
  }

  onGeoAreasDrawerChange(event: MatCheckboxChange): void {
    this.isGeoAreasDrawChecked = event.checked;
    this.geoAreasDrawerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.GEO_AREAS);
  }

  onCompletedStopsLayerChange(event: MatCheckboxChange): void {
    this.isCompletedStopsChecked = event.checked;
    this.completedStopsLayerChange.emit(event.checked);
    this.updateSelectionOutDispatchArea(event.checked, MapToolbarSelectionItem.COMPLETED_STOPS);
  }

  onSpotsAndDropsLayerChange(event: MatCheckboxChange): void {
    this.isSpotsAndDropsChecked = event.checked;
    this.spotsAndDropsLayerChange.emit(event.checked);
  }

  onUnmappedPickupsClicked(): void {
    this.toggleUnmappedPickupsChange.emit();
  }

  onUnmappedDeliveriesClicked(): void {
    this.toggleUnmappedDeliveriesChange.emit();
  }

  onToggleToolbar(): void {
    this.toggleToolbar.emit();
    this.refreshSSBarPositionSubject.next();
  }

  onCompassClicked(): void {
    this.compassClicked.emit();
  }

  onToggleIndicatorsBar(): void {
    const nextToggleValue = !this.indicatorBarOpenSubject.value;
    this.indicatorBarOpenSubject.next(nextToggleValue);
    this.specialServicesService.updateShowSpecialServiceMapSelected(nextToggleValue);
    this.updateSelectionOutDispatchArea(nextToggleValue, MapToolbarSelectionItem.INDICATOR);
  }

  @HostListener('document:click', ['$event.target'])
  indicatorsBarClickOutside(targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.mapToolbarIndicatorsBar?.nativeElement.contains(targetElement);
    if (!clickedInside && this.indicatorBarOpenSubject.value) {
      this.mapToolbarHideButton.nativeElement.click();
    }
  }

  onToggleMoreOptions() {
    this.isMoreOptionsOpened = !this.isMoreOptionsOpened;
  }

  private setDefaultMapToolbarSelection(layout: DefaultLayout) {
    if (layout === DefaultLayout.InboundPlanner && _size(this.mapToolbarService.mapToolbarSelectionItems) === 0) {
      this.mapToolbarService.mapToolbarSelectionItems.push(MapToolbarSelectionItem.UNASSIGNED_DELIVERIES);
      this.mapToolbarService.mapToolbarSelectionItems.push(MapToolbarSelectionItem.COMPLETED_STOPS);
    } else if (layout === DefaultLayout.Dispatcher && _size(this.mapToolbarService.mapToolbarSelectionItems) === 0) {
      this.mapToolbarService.mapToolbarSelectionItems.push(MapToolbarSelectionItem.DRIVERS_LOCATION);
      this.mapToolbarService.mapToolbarSelectionItems.push(MapToolbarSelectionItem.COMPLETED_STOPS);
      this.mapToolbarService.mapToolbarSelectionItems.push(MapToolbarSelectionItem.TRAFFIC);
      this.mapToolbarService.mapToolbarSelectionItems.push(MapToolbarSelectionItem.UNASSIGNED_PICKUPS);
    }

    this.updateSelectionOutDispatchArea(this.isNormalPickupsChecked, MapToolbarSelectionItem.NORMAL_PICKUPS);
    this.updateSelectionOutDispatchArea(this.isHookLoadPickupsChecked, MapToolbarSelectionItem.LOAD_PICKUPS);
    this.updateSelectionOutDispatchArea(this.isHookEmptyPickupsChecked, MapToolbarSelectionItem.EMPTY_PICKUPS);
    this.updateSelectionOutDispatchArea(this.isSpotEmptyPickupsChecked, MapToolbarSelectionItem.SPOT_EMPTY_PICKUPS);
  }

  private uncheckAllLayers(): void {
    this.onCompletedStopsLayerChange(this.checkBoxUnchecked);
    this.onDriverLocationLayerChange(this.checkBoxUnchecked);
    this.onGeoAreasDrawerChange(this.checkBoxUnchecked);
    this.onPickupClustersChange(this.checkBoxUnchecked);
    this.onTrafficLayerChange(this.checkBoxUnchecked);
    this.onUnassignedDeliveriesLayerChange(this.checkBoxUnchecked);
    this.onUnassignedPickupsLayerChange(this.checkBoxUnchecked);
    this.onWeatherWidgetChange(this.checkBoxUnchecked);
    this.onGeoFilterDrawToggle(false);
    this.onPolygonDrawToggle(false);
    this.onSpotsAndDropsLayerChange(this.checkBoxUnchecked);
    this.mapToolbarService.handleDrawOptionsPanelChange(false);
    this.mapToolbarService.handleDrawModeChange(false);
    this.mapToolbarService.toggleDrawModeOff();
  }

  private updateSelectionOutDispatchArea(checked: boolean, selection: MapToolbarSelectionItem): void {
    if (!this.isDispatchAreaChecked) {
      this.mapToolbarService.updateMapToolbarSelectionItems(checked, selection);
    }
  }
}
