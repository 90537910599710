import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DmanFormFields } from 'core/dialogs/print-dialog/dman-form-fields.enum';
import { DmanShipment } from './dmanData.interface';

@Component({
  selector: 'pnd-dman-panel',
  templateUrl: './dman-panel.component.html',
  styleUrls: ['./dman-panel.component.scss'],
})
export class DmanPanelComponent implements OnInit, OnChanges {
  @Input() dmanControl: FormGroup;

  @Input()
  routeIndex: number;

  dmanData: DmanShipment[] = [];

  readonly DmanFormFields = DmanFormFields;

  constructor(private formBuilder: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  getDummyArray(input: number) {
    return new Array(input).fill(1);
  }

  onDmanSelected(event, index) {
    console.log(event, index); // TODO will remove it
  }
}
