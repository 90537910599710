<div class="pndContextMenu">
  <span
      *ngIf="menuPosition$ | async as menuPosition"
      #contextMenuTrigger
      class="pndContextMenu__trigger"
      [matMenuTriggerFor]="contextMenu"
      (menuClosed)="menuClosed()"
      [style.left.px]="menuPosition.x"
      [style.top.px]="menuPosition.y">
  </span>
  <mat-menu #contextMenu="matMenu" class="pndContextMenu__panel">
    <ng-template
        matMenuContent
        *ngIf="{ routesMenuList: routesMenuList$ | async, removeMenuList: removeMenuList$ | async } as menuLists">
      <ng-container *ngFor="let item of menuItems$ | async">
        <button class="menuTrigger" mat-menu-item *ngIf="!item.nested" (click)="itemSelected(item)">
          {{ item.label }}
        </button>
        <button
            mat-menu-item
            *ngIf="item.nested && item.triggerFor == 'routesMenu' && menuLists?.routesMenuList?.length > 0"
            [matMenuTriggerFor]="routesMenu">
          {{ item.label }}
        </button>
        <button
            mat-menu-item
            *ngIf="item.nested && item.triggerFor == 'removeMenu' && menuLists?.removeMenuList?.length > 0"
            [matMenuTriggerFor]="removeMenu">
          {{ item.label }}
        </button>
        <mat-menu #routesMenu="matMenu" class="pndContextMenu__panel">
          <ng-template matMenuContent>
            <ng-container *ngFor="let route of menuLists.routesMenuList">
              <button mat-menu-item (click)="routeSelected(item, route)">
                <span> {{ route.routePrefix }}-{{ route.routeSuffix }} </span>
              </button>
            </ng-container>
          </ng-template>
        </mat-menu>
        <mat-menu #removeMenu="matMenu" class="pndContextMenu__panel">
          <ng-template matMenuContent>
            <ng-container *ngFor="let route of menuLists.removeMenuList">
              <button mat-menu-item (click)="routeSelected(item, route)">
                <span> {{ route.routePrefix }}-{{ route.routeSuffix }} </span>
              </button>
            </ng-container>
          </ng-template>
        </mat-menu>
      </ng-container>
    </ng-template>
  </mat-menu>
</div>
