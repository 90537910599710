import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, interval, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

@Component({
  selector: 'pnd-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit, OnChanges {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  private scrollingInterval = interval(500);
  private scrollingLeftSubscription: Subscription;
  private scrollingRightSubscription: Subscription;
  private remainingItemsSubscription: Subscription;
  private childWidth: number = 0;

  leftOverlayPosition: {
    top: number;
    left: number;
    height: number;
    width: number;
  } = {
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  };

  rightOverlayPosition: {
    top: number;
    left: number;
    height: number;
    width: number;
  } = {
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  };

  dragging: boolean = false;
  remainingLeft: number = 0;
  remainingRight: number = 0;
  canScrollLeft: boolean = true;
  canScrollRight: boolean = true;

  @Input() hideRemainingItemsText: boolean = false;

  @ViewChild('carouselItems') carouselItems: ElementRef<HTMLElement>;
  @ViewChild('leftContainer') leftContainer: ElementRef<HTMLElement>;
  @ViewChild('rightContainer') rightContainer: ElementRef<HTMLElement>;

  // Outputs
  @Output() scroll: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemOrderChange: EventEmitter<CdkDragDrop<string[]>> = new EventEmitter<CdkDragDrop<string[]>>();

  // Core inputs
  @Input() sortable: boolean = false;
  @Input() items: Array<any> = [];
  @Input() $prev: TemplateRef<any>;
  @Input() $next: TemplateRef<any>;
  @Input() $item: TemplateRef<any>;

  // Configuration inputs
  @Input() snapMultiplier: number = 1;
  @Input() scrollSnapAlign: string = 'center';
  @Input() displayScrollBar: boolean = false;
  @Input() displayRemainingItems: boolean = false;
  @Input() scrollWhileDragging: boolean = false;
  // Note: Angular material support. Use cdkDragStarted and cdkDragReleased to capture dragging event
  @Input() isDragging: boolean = false;

  // Input functions
  @Input() beforeClickLeft: Function = () => {};
  @Input() beforeClickRight: Function = () => {};
  @Input() afterClickLeft: Function = () => {};
  @Input() afterClickRight: Function = () => {};

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.items &&
      !changes.items.firstChange &&
      changes.items.currentValue !== changes.items.previousValue &&
      this.items.length > 0
    ) {
      timer(25)
        .pipe(take(1))
        .subscribe(() => {
          const container = this.carouselItems.nativeElement;
          if (container) {
            // Assuming that all the items have the same width
            if (container.firstElementChild) {
              this.childWidth = container.firstElementChild.clientWidth;
            }

            if (this.displayRemainingItems) {
              container.removeEventListener('scroll', () => {
                this.calculateRemainingItemsHandler();
              });
              this.calculateRemainingItemsHandler();
              container.addEventListener('scroll', () => {
                this.calculateRemainingItemsHandler();
              });
            }
          }
        });
    }

    if (
      changes.isDragging &&
      !changes.isDragging.firstChange &&
      changes.isDragging.currentValue !== changes.isDragging.previousValue &&
      !changes.isDragging.currentValue
    ) {
      this.dragging = this.isDragging;

      if (this.scrollingLeftSubscription) {
        this.scrollingLeftSubscription.unsubscribe();
      }

      if (this.scrollingRightSubscription) {
        this.scrollingRightSubscription.unsubscribe();
      }
    }
  }
  ngAfterViewInit() {
    if (this.items.length > 0) {
      const container = this.carouselItems.nativeElement;

      if (container) {
        // Force scroll the first time
        container.scrollLeft = 0;

        // Assuming that all the items have the same width
        if (container.firstElementChild) {
          this.childWidth = container.firstElementChild.clientWidth;
        }

        if (this.displayRemainingItems) {
          this.calculateRemainingItemsHandler();
          container.addEventListener('scroll', () => {
            this.calculateRemainingItemsHandler();
          });
        }

        // Watch for native dragging
        if (this.scrollWhileDragging) {
          fromEvent(container, 'mousemove').subscribe((event: MouseEvent) => {
            const prev = this.dragging;

            this.dragging = event.buttons === 1;

            // Prevent spamming
            if (prev !== this.dragging) {
              ComponentChangeUtils.detectChanges(this.changeDetectorRef);
            }

            if (!this.dragging) {
              if (this.scrollingLeftSubscription) {
                this.scrollingLeftSubscription.unsubscribe();
              }

              if (this.scrollingRightSubscription) {
                this.scrollingRightSubscription.unsubscribe();
              }
            }
          });
        }
      }
    }

    this.calculateDraggingOverlayPosition();
  }

  /**
   * Calculate remainig items to display on the left/right controls.
   * It has a delay to handle event spamming.
   */
  private calculateRemainingItemsHandler(): void {
    const container = this.carouselItems.nativeElement;

    if (this.remainingItemsSubscription) {
      this.remainingItemsSubscription.unsubscribe();
    }

    this.remainingItemsSubscription = timer(25)
      .pipe(take(1))
      .subscribe(() => {
        const snapPosition = Math.round(container.scrollLeft / this.childWidth);
        const visibles = Math.round(container.clientWidth / this.childWidth);

        this.remainingLeft = snapPosition;
        this.remainingRight = this.items.length - snapPosition - visibles;
        if (this.remainingRight < 0) {
          this.remainingRight = 0;
        }

        this.canScrollLeft = container.scrollLeft !== 0;
        this.canScrollRight = this.remainingRight !== 0;

        this.remainingItemsSubscription.unsubscribe();
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        this.scroll.emit();
      });
  }

  private doScroll(multiplier: number): void {
    const container = this.carouselItems.nativeElement;
    const snapSize = this.childWidth;
    container.scrollBy(multiplier * snapSize, 0);
  }

  private calculateDraggingOverlayPosition(): void {
    const overlayWidth = 350;
    const leftContainerRect = this.leftContainer.nativeElement.getBoundingClientRect();
    this.leftOverlayPosition = {
      top: leftContainerRect.top,
      left: leftContainerRect.left - overlayWidth + leftContainerRect.width,
      height: leftContainerRect.height,
      width: overlayWidth,
    };

    const rightContainerRect = this.rightContainer.nativeElement.getBoundingClientRect();
    this.rightOverlayPosition = {
      top: rightContainerRect.top,
      left: rightContainerRect.left,
      height: rightContainerRect.height,
      width: overlayWidth,
    };
  }

  onLeft(): void {
    this.beforeClickLeft();
    this.doScroll(-this.snapMultiplier);
    this.afterClickLeft();
  }

  onRight(): void {
    this.beforeClickRight();
    this.doScroll(+this.snapMultiplier);
    this.afterClickRight();
  }

  onResize(event: ResizeObserverEntry) {
    if (this.displayRemainingItems) {
      this.calculateRemainingItemsHandler();
    }
    this.calculateDraggingOverlayPosition();
  }

  onMouseOverLeft() {
    if (this.scrollWhileDragging && (this.dragging || this.isDragging)) {
      if (this.scrollingLeftSubscription) {
        this.scrollingLeftSubscription.unsubscribe();
      }
      if (this.scrollingRightSubscription) {
        this.scrollingRightSubscription.unsubscribe();
      }
      this.scrollingLeftSubscription = this.scrollingInterval.subscribe(() => this.onLeft());
    }
  }

  onMouseOutLeft(): void {
    if (this.scrollingLeftSubscription) {
      this.scrollingLeftSubscription.unsubscribe();
    }
    if (this.scrollingRightSubscription) {
      this.scrollingRightSubscription.unsubscribe();
    }
  }
  onMouseOutRight(): void {
    if (this.scrollingLeftSubscription) {
      this.scrollingLeftSubscription.unsubscribe();
    }
    if (this.scrollingRightSubscription) {
      this.scrollingRightSubscription.unsubscribe();
    }
  }

  onMouseOverRight() {
    if (this.scrollWhileDragging && (this.dragging || this.isDragging)) {
      if (this.scrollingRightSubscription) {
        this.scrollingRightSubscription.unsubscribe();
      }
      if (this.scrollingLeftSubscription) {
        this.scrollingLeftSubscription.unsubscribe();
      }
      this.scrollingRightSubscription = this.scrollingInterval.subscribe(() => this.onRight());
    }
  }

  onItemOrderChange(event: CdkDragDrop<string[]>): void {
    this.itemOrderChange.emit(event);
  }
}
