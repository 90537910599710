import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { filter as _filter, groupBy as _groupBy, keys as _keys, map as _map } from 'lodash';
import { KeyboardShortcutsHelpService } from 'ng-keyboard-shortcuts';
import { takeUntil } from 'rxjs/operators';

export interface KeyboardShortcut {
  section: string;
  text: string;
  keyAlternatives: string[];
}

@Component({
  selector: 'pnd-keyboard-shortcuts',
  templateUrl: './keyboard-shortcuts.component.html',
  styleUrls: ['./keyboard-shortcuts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KeyboardShortcutsComponent implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  displayedColumns: string[] = ['text', 'keyAlternatives'];
  sections: string[];
  shortcuts: KeyboardShortcut[];

  constructor(private keyboardShortcutsHelpService: KeyboardShortcutsHelpService) {
    keyboardShortcutsHelpService.shortcuts$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((rawShortcuts: { key: string[]; label: string; description: string }[]) => {
        this.sections = this.extractSections(rawShortcuts);
        this.shortcuts = this.buildShortcuts(rawShortcuts);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  /**
   * Extract sections shortcuts
   * @param shortcuts
   * @private
   */
  private extractSections(shortcuts: { key: string[]; label: string; description: string }[]): string[] {
    return _keys(_groupBy(shortcuts, 'label'));
  }

  /**
   * Build shortcuts
   * @param shortcuts
   * @private
   */
  private buildShortcuts(shortcuts: { key: string[]; label: string; description: string }[]): KeyboardShortcut[] {
    return _map(
      shortcuts,
      (shortcut) =>
        <KeyboardShortcut>{
          section: shortcut.label,
          text: shortcut.description,
          keyAlternatives: shortcut.key,
        }
    );
  }

  getShortcuts(section: string): KeyboardShortcut[] {
    return _filter(this.shortcuts, (shortcut) => shortcut.section === section);
  }
}
