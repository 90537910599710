import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  MatLegacyAutocompleteActivatedEvent as MatAutocompleteActivatedEvent,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { isEmpty as _isEmpty, isNull as _isNull, isUndefined as _isUndefined, toUpper as _toUpper } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

export interface AutoCompleteItem {
  id: string;
  value: string;
  data?: any;
}

export interface AutocompleteActionLinkConfig {
  text: any;
  onClick: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

@Component({
  selector: 'pnd-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  host: { class: 'pnd-autocomplete' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  protected readonly unsubscriber = new Unsubscriber();
  private selectedValue: AutoCompleteItem;

  private readonly actionLinkOptionId: string = 'actionLinkOption';

  // matches anything by default
  @Input() xpoAllowCharacters: string = '.*?';
  @Input() readonly = 'false';
  @Input() showIcon: boolean = true;

  @Input() icon: string = 'arrow_drop_down';
  @Input() floatLabel: string = 'always';
  @Input() label: string = '';
  @Input() hint: string = '';
  @Input() maxlength: number = 10;
  @Input() placeholder: string = '';
  @Input() controlName: string = '';
  @Input() group: UntypedFormGroup;
  @Input() order: boolean = true;
  @Input() optionTemplate: TemplateRef<any>;

  @Input() items: AutoCompleteItem[] = [];
  @Input() focus$: Observable<void>;
  @Input() actionLinkConfig: AutocompleteActionLinkConfig;
  @Input() autoActiveFirstOption: boolean = true;

  @Input() sortItems: boolean = true;

  @ViewChild('input', { static: false })
  input: ElementRef;

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  /**
   * Filter applied for string search
   * indexOf | startsWith
   */
  @Input() searchFilter: string = 'indexOf';

  /**
   * The item select should be in the list of items
   */
  @Input() strictSelection: boolean = true;

  @Output() blur = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<AutoCompleteItem>();
  @Output() selectionChanged = new EventEmitter<AutoCompleteItem>();
  @Output() textClear = new EventEmitter<AutoCompleteItem>();
  private itemsFilteredSubject = new BehaviorSubject<AutoCompleteItem[]>([]);
  itemsFiltered$: Observable<AutoCompleteItem[]> = this.itemsFilteredSubject.asObservable();

  private readonly actionLinkItem: AutoCompleteItem = { id: '', value: '' };
  private filterSubscription: Subscription;

  private currentValCache: string;

  @HostListener('keydown.enter', ['$event'])
  handleEnterKeyEvent(event: KeyboardEvent) {
    if (this.actionLinkConfig?.isVisible) {
      event.preventDefault();
      this.actionLinkConfig?.onClick();
    }
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.focus$) {
      this.focus$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
        if (this.input) {
          setTimeout(() => {
            this.input.nativeElement.focus();
          });
        }
      });
    }
  }

  isEnabled(item: AutoCompleteItem): boolean {
    if (item?.data && item.data?.optionEnabled !== undefined) {
      return item.data.optionEnabled;
    }
    return true;
  }

  onTextClear(text: string): void {
    if (text === '') {
      this.textClear.emit({
        id: '',
        value: '',
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      if (changes.items.previousValue !== changes.items.currentValue) {
        if (this.order) {
          if (this.sortItems) {
            this.items?.sort((a, b) => a?.value?.localeCompare(b.value));
          }
        }

        const currentVal = this.group.get(this.controlName).value;

        if (currentVal?.hasOwnProperty('id')) {
          this.itemsFilteredSubject.next(this.items);
        } else {
          // When items list changes and there's some text in the input field, the new list should be filtered based on current value
          this.itemsFilteredSubject.next(this.filterItems(currentVal as string));
        }
      }
    }

    if (changes.group && changes.group.previousValue !== changes.group.currentValue) {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }

      this.filterSubscription = this.group
        .get(this.controlName)
        .valueChanges.pipe(
          takeUntil(this.unsubscriber.done$),
          filter((item: string | AutoCompleteItem) => !_isUndefined(item) && !_isNull(item)),
          map((item: string | AutoCompleteItem) => {
            if (item.hasOwnProperty('id') && item.hasOwnProperty('value')) {
              this.selectedValue = <AutoCompleteItem>item;
            } else {
              this.selectedValue = undefined;

              _isEmpty(item)
                ? this.itemsFilteredSubject.next(this.items)
                : this.itemsFilteredSubject.next(this.filterItems(item as string));
            }
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private filterItems(name: string): AutoCompleteItem[] {
    return this.items?.filter((item) => {
      if (this.searchFilter === 'indexOf') {
        return _toUpper(item.value).indexOf(_toUpper(name)) >= 0;
      } else {
        return _toUpper(item.value).startsWith(_toUpper(name));
      }
    });
  }

  validateControl(value: string | AutoCompleteItem): void {
    const control = this.group.get(this.controlName);

    const textValue: string = value && value.hasOwnProperty('id') ? value['value'] : (value as string);
    if (textValue) {
      const autoCompleteItem: AutoCompleteItem = value.hasOwnProperty('id')
        ? (value as AutoCompleteItem)
        : this.items?.find((item) => _toUpper(item.value) === _toUpper(textValue));
      let validationErrors = control.errors;

      if (autoCompleteItem) {
        // check if value is string
        if (!value.hasOwnProperty('id')) {
          control.setValue(autoCompleteItem);
          this.selectionChange.emit(autoCompleteItem);
        }

        this.selectedValue = autoCompleteItem;
      }

      if (this.strictSelection && !this.selectedValue && !autoCompleteItem) {
        control.setErrors({ ...validationErrors, invalid: true });
      } else {
        if (validationErrors?.hasOwnProperty('invalid')) {
          delete validationErrors['invalid'];
          if (_isEmpty(Object.keys(validationErrors))) {
            validationErrors = null;
          }

          control.setErrors(validationErrors);
        }
      }
    }
  }

  onBlur(): void {
    const control = this.group.get(this.controlName);
    const value: string | AutoCompleteItem = control.value;

    this.validateControl(value);
    this.blur.emit();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (event?.option?.id === this.actionLinkOptionId) {
      this.group.get(this.controlName).setValue(this.currentValCache);
      this.input?.nativeElement?.focus();
      setTimeout(() => {
        this.input.nativeElement.value = this.currentValCache;
        this.inputAutoComplete.openPanel();
      });
    }
  }

  onOptionActivated(event: MatAutocompleteActivatedEvent) {
    if (event?.option?.id === this.actionLinkOptionId) {
      this.currentValCache = this.group.get(this.controlName).value;
    }
  }

  onSelectionChange(event: MatOptionSelectionChange): void {
    this.validateControl(event.source.value);
    this.selectionChange.emit(event.source.value);
    if (event.isUserInput) {
      this.selectionChanged.emit(event.source.value);
    }
  }

  getText(autoCompleteItem: AutoCompleteItem): string {
    return autoCompleteItem?.value ?? '';
  }

  onActionLinkClick(clickEvent: MouseEvent): void {
    if (this.actionLinkConfig?.onClick) {
      const control = this.group.get(this.controlName);
      let validationErrors = control.errors;

      if (validationErrors?.hasOwnProperty('invalid')) {
        delete validationErrors['invalid'];
        if (_isEmpty(Object.keys(validationErrors))) {
          validationErrors = null;
        }

        control.setErrors(validationErrors);
      }

      this.actionLinkConfig?.onClick();
      this.input?.nativeElement?.focus();
      clickEvent.stopImmediatePropagation();
    }
  }

  onPanelClosed(): void {
    const control = this.group.get(this.controlName);
    const value: string | AutoCompleteItem = control.value;
    this.validateControl(value);
  }
}
