import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

// Skip SSO when running in Cypress test environment
const skipCanActivate = !!window['Cypress'];

const RouterDefinitions: Routes = [
  {
    path: 'docview',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard],
    loadChildren: () => import('./doc-view/doc-view.module').then((m) => m.DocviewModule),
  },
  {
    path: 'shipment-details',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard],
    loadChildren: () => import('./shipment-details/shipment-details.module').then((m) => m.ShipmentDetailsModule),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard],
    loadChildren: () => import('./inbound-planning/inbound-planning.module').then((m) => m.InboundPlanningModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
