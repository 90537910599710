<mat-card class="shadowed">
  <div class="pnd-metrics-item-value">{{ metric.displayValue }}</div>
  <div class="pnd-metrics-item-description">{{ metric.description }}</div>
  <div class="pnd-metrics-item-variance" *ngIf="metric.variance && metric.varianceDesc">
    <ng-container *ngIf="metric.description !== descName">
      <mat-icon class="pnd-metrics-item-variance-icon" [ngClass]="getVarianceClassColor(metric.variance)">
        {{ getVarianceIcon(metric.variance) }}
      </mat-icon>
      <div class="pnd-metrics-item-variance-legend">{{ metric.varianceDesc }}</div>
    </ng-container>
  </div>
</mat-card>
