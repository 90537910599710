import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PndStore } from '@pnd-store/pnd-store';
import { TripsStoreSelectors } from '@pnd-store/trips-store';
import { HttpOptions } from '@xpo-ltl/data-api';
import { PluralPipe } from '@xpo-ltl/ngx-ltl';
import {
  Activity,
  AssignShipmentsToRoutePath,
  AssignShipmentsToRouteResp,
  AssignShipmentsToRouteRqst,
  BalancePnDRoutesResp,
  CityOperationsApiService,
  CreateNewRouteResp,
  CreateNewRouteRqst,
  DeliveryShipmentSearchRecord,
  DockLocation,
  ListPnDSuggestedRouteNamesPath,
  ListPnDSuggestedRouteNamesQuery,
  ListPnDSuggestedRouteNamesResp,
  ReassignStopsResp,
  ReassignStopsRqst,
  Route,
  RouteDetail,
  Stop,
  SuggestedRouteName,
  Trip,
  TripNode,
  TripNodeId,
  UnassignPnDShipmentsFromRoutePath,
  UnassignPnDShipmentsFromRouteRqst,
  UnassignStopActivityRqst,
  UpdatePnDRoutesResp,
  UpdatePnDRoutesRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { AuditInfo, RouteCategoryCd, RouteStatusCd, ShipmentId } from '@xpo-ltl/sdk-common';
import {
  capitalize as _capitalize,
  filter as _filter,
  findIndex as _findIndex,
  first as _first,
  isEmpty as _isEmpty,
  map as _map,
  padStart as _padStart,
  result as _result,
  size as _size,
  some as _some,
} from 'lodash';
import moment from 'moment';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, delay, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { PndZoneUtils } from 'shared/zone-utils';
import { NotificationMessageStatus } from '../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../core/services/notification-message.service';
import { UserRoleService } from '../../../../core/services/user-role/user-role.service';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreActions,
  TripsStoreActions,
  UnassignedDeliveriesStoreActions,
  DockRoutesStoreActions,
} from '../../../store';
import { DispatcherTripsStoreActions } from '../../../store/dispatcher-trips-store';
import { ModifyTripDetailsActions } from '../../../store/modify-trip-details-store';
import { RouteBalancingActions } from '../../../store/route-balancing-store';
import { AssignShipmentsParams } from '../../components/assign-shipments/models/assign-shipments-params.model';
import { StoreSourcesEnum } from '../enums/store-sources.enum';
import { GenericErrorLazyTypedModel, PartialMoreInfo } from '../models/generic-error-lazy-typed.model';
import { TripsService } from './trips.service';

export const DEFAULT_ROUTE_SUFFIX_LENGTH = 5;

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  // valid prefixes for Planning Routes
  static readonly PLANNING_ROUTE_PREFIXES = ['LG', 'OSD', 'TRAP', 'CME'];

  static readonly ACTION_RELEASE = 'released';
  static readonly ACTION_UPDATE = 'updated';

  private MESSAGE = 'Changes made to routes that were ineligible for release were saved successfully.';

  formGroup: UntypedFormGroup;

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: PndStore<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService,
    private userRoleService: UserRoleService,
    private tripsService: TripsService,
    private pluralPipe: PluralPipe
  ) {}

  releaseRoutes(routesToRelease: Route[]): Observable<UpdatePnDRoutesResp> {
    const lastUpdateTime$ = this.pndStore$.select(TripsStoreSelectors.tripsLastUpdate).pipe(take(1));

    const request = {
      ...new UpdatePnDRoutesRqst(),
      routes: routesToRelease.map((route) => ({
        ...route,
        deliveryRouteDepartTime: route.deliveryRouteDepartTime ? `${route.deliveryRouteDepartTime}:00` : undefined,
        equipmentIdPrefix: !!route.equipmentIdPrefix ? route.equipmentIdPrefix : undefined,
        equipmentIdSuffixNbr: !!route.equipmentIdSuffixNbr ? route.equipmentIdSuffixNbr : undefined,
      })),
    };

    return lastUpdateTime$.pipe(
      take(1),
      switchMap((lastUpdatedTime: Date) => {
        const httpOptions: HttpOptions = {
          headers: {
            'Transaction-Timestamp': `${lastUpdatedTime.getTime()}`,
          },
        };

        return this.cityOperationsService.updatePnDRoutes(request, {}, httpOptions).pipe(
          map((result) => ({
            ...result,
            routes: result.routes.map((route) => ({
              ...route,
              statusCd: route.xdockReleaseInd ? RouteStatusCd.RELEASED : RouteStatusCd.UNRELEASED,
            })),
          })),
          take(1)
        );
      })
    );
  }

  releaseRoute(
    routeName: string,
    routeInstId: number,
    doorNbr: string,
    startTime: string,
    dockLocation: string,
    nearestDoor: string,
    trailer: string,
    release: boolean,
    sicCd: string
  ): Observable<UpdatePnDRoutesResp> {
    // TODO - this needs refactored to remove the multiple subscriptions!
    return new Observable((observer) => {
      const isTrailerAvailable = trailer && trailer.indexOf('-') > 0;
      const eqpPfx = isTrailerAvailable ? trailer.substr(0, trailer.indexOf('-')) : undefined;
      const eqpSfx = isTrailerAvailable ? +trailer.substr(trailer.indexOf('-') + 1) : undefined;

      const route = {
        ...new Route(),
        routeInstId: routeInstId,
        terminalSicCd: sicCd,
        plannedDoor: doorNbr,
        equipmentIdPrefix: eqpPfx,
        equipmentIdSuffixNbr: eqpSfx,
        deliveryRouteDepartTime: startTime ? `${startTime}:00` : undefined, // startTime is in HH:mm we need to include seconds as :00
        xdockReleaseInd: release,
        equipmentDockLocation: {
          ...new DockLocation(),
          dockName: (dockLocation || '').toUpperCase(),
          dockClosestDoorNbr: nearestDoor || '',
        },
      };
      const request = { ...new UpdatePnDRoutesRqst(), routes: [route] };

      this.pndStore$
        .select(TripsStoreSelectors.tripsLastUpdate)
        .pipe(take(1))
        .subscribe((lastUpdatedTime) => {
          const httpOptions: HttpOptions = {
            headers: {
              'Transaction-Timestamp': `${lastUpdatedTime.getTime()}`,
            },
          };

          this.cityOperationsService
            .updatePnDRoutes(request, {}, httpOptions)
            .pipe(
              take(1),
              finalize(() => {
                // Clear cached stops to force api call for this route and refetch all the stops
                this.tripsService.clearCachedStops([routeInstId]);

                // refresh Trips Grids after route update
                this.pndStore$.dispatch(new TripsStoreActions.Refresh());
                this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
              })
            )
            .subscribe(
              (results) => {
                // TODO: PCT-7946: API is returning statusCd: NEW, but it should be RELEASED or UNRELEASED
                const updatedRoute = _first(results.routes) || { ...new Route() };
                updatedRoute.statusCd = updatedRoute.xdockReleaseInd
                  ? RouteStatusCd.RELEASED
                  : RouteStatusCd.UNRELEASED;

                observer.next(results);
                observer.complete();

                if (!_isEmpty(results.messages)) {
                  this.notificationMessageService
                    .openNotificationMessage(NotificationMessageStatus.Info, `${results.messages.join('. ')}`)
                    .subscribe(() => {});
                } else {
                  this.notificationMessageService
                    .openNotificationMessage(NotificationMessageStatus.Success, `Route ${routeName} updated.`)
                    .subscribe(() => {});
                }
              },
              (error) => {
                this.checkTripEquipmentNotFound(error);

                observer.error(error);
                observer.complete();

                this.notificationMessageService
                  .openNotificationMessage(NotificationMessageStatus.Error, error)
                  .subscribe(() => {});
              }
            );
        });
    });
  }

  /**
   * Checks error for Equipment Not Found in moreInfo array and modifies message to empty string
   * @param error Error object from updatePnDRoute response
   */
  private checkTripEquipmentNotFound(error: GenericErrorLazyTypedModel): void {
    if (error?.code === '404') {
      const equipmentNotFoundIndex = _findIndex(
        error?.error?.moreInfo ?? [],
        (moreInfo: PartialMoreInfo) => moreInfo.message === 'Equipment Not Found'
      );

      if (equipmentNotFoundIndex > -1) {
        error.error.moreInfo[equipmentNotFoundIndex].message = '';
      }
    }
  }

  unassignShipments(routeName: string, routeInstId: number, shipmentList: ShipmentId[]): Observable<void> {
    const request: UnassignPnDShipmentsFromRouteRqst = {
      shipmentIds: shipmentList,
    };
    const pathParams: UnassignPnDShipmentsFromRoutePath = {
      routeInstId: routeInstId,
    };

    return this.cityOperationsService.unassignPnDShipmentsFromRoute(request, pathParams).pipe(
      tap(() => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Success, `Shipments removed from route ${routeName}.`)
          .subscribe(() => {});
      }),
      catchError((error) => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});

        return throwError(error);
      })
    );
  }

  unassignStops(routeName: string, tripInstId: number, tripNodeSequenceNbrs: number[]): Observable<void> {
    return new Observable((observer) => {
      const request: UnassignStopActivityRqst = new UnassignStopActivityRqst();
      request.tripInstId = tripInstId;
      request.tripNodes = tripNodeSequenceNbrs.map((tripNodeSequenceNbr) => ({
        ...new TripNodeId(),
        tripNodeSeqNbr: tripNodeSequenceNbr,
      }));
      this.cityOperationsService
        .unassignStopActivity(request)
        .pipe(take(1))
        .subscribe(
          () => {
            observer.next();
            observer.complete();

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Success, `Route ${routeName} updated.`)
              .subscribe(() => {});
          },
          (error) => {
            observer.error();
            observer.complete();

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});
          }
        );
    });
  }

  private getShipmentIdsFromSelectedShipments(
    selectedShipments: (DeliveryShipmentSearchRecord | Activity)[]
  ): ShipmentId[] {
    return selectedShipments.map((shipment: DeliveryShipmentSearchRecord | Activity) => {
      const ship = (<Activity>shipment)?.tripNodeActivity ?? <DeliveryShipmentSearchRecord>shipment;

      return <ShipmentId>{
        shipmentInstId: ship.shipmentInstId.toString(),
        proNumber: ship.proNbr,
        pickupDate: null,
      };
    });
  }

  /**
   * Assign shipments to a new route.
   *
   * @param params
   * @param suggestedRouteNames used to populate the areaInstId (geoarea)
   * @param overrideTdc
   * @param forceReassign
   * @param overrideSplitHandlingUnitInd
   * @param overrideCustomerClosedInd
   */
  assignShipmentsToNewRoute(
    params: AssignShipmentsParams,
    suggestedRouteNames: SuggestedRouteName[],
    overrideTdc: boolean,
    forceReassign: boolean,
    overrideSplitHandlingUnitInd: boolean,
    overrideCustomerClosedInd?: boolean
  ): Observable<CreateNewRouteResp> {
    // disable opening of Route Balancing
    this.updateCanOpenRouteBalancing(false);

    const shipmentIds: ShipmentId[] = this.getShipmentIdsFromSelectedShipments(params.selectedShipments);
    return this.createNewRoute(
      params.routePrefix,
      params.routeSuffix,
      params.categoryCd,
      params.routeDate,
      params.terminalSicCd,
      shipmentIds,
      suggestedRouteNames,
      overrideTdc,
      forceReassign,
      overrideSplitHandlingUnitInd,
      !!overrideCustomerClosedInd,
      params.startTime,
      params.doorNbr,
      params.dockLocation,
      params.nearestDoor,
      params.trailer,
      undefined,
      undefined,
      undefined,
      params.carrierId,
      params.locationId,
      params?.routeDate
    ).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Assign shipments to an existing route.
   *
   * @param params should include the routeInstId of the existing route
   * @param acceptWarningInd
   * @param forceReassign
   * @param overrideSplitHandlingUnitInd
   * @param overrideCustomerClosedInd
   */
  assignShipmentsToExistingRoute(
    params: AssignShipmentsParams,
    acceptWarningInd: boolean,
    forceReassign: boolean,
    overrideSplitHandlingUnitInd: boolean,
    overrideCustomerClosedInd?: boolean,
    overrideDsrLicenseInd?: boolean
  ): Observable<AssignShipmentsToRouteResp> {
    // disable opening of Route Balancing
    this.updateCanOpenRouteBalancing(false);

    const shipmentIds: ShipmentId[] = this.getShipmentIdsFromSelectedShipments(params.selectedShipments);
    const request = new AssignShipmentsToRouteRqst();
    request.shipmentIds = shipmentIds;
    request.overrideTdcInd = acceptWarningInd;
    request.forceReassignInd = forceReassign;
    request.overrideSplitHandlingUnitInd = overrideSplitHandlingUnitInd;
    request.forceWhenOfdSicNeShmDestInd = acceptWarningInd;
    request.forceShmNotAtTrailerSicInd = acceptWarningInd;
    request.forceShmOnEnrouteTrailerInd = acceptWarningInd;
    request.forceShmOnOutboundTrailerInd = acceptWarningInd;
    request.overrideSplitHandlingUnitInd = acceptWarningInd;
    request.overrideDsrLicenseInd = overrideDsrLicenseInd;

    if (overrideCustomerClosedInd) {
      request.overrideCustomerClosedInd = overrideCustomerClosedInd;
    }

    if (params.carrierId && params.locationId) {
      request.carrierId = params.carrierId;
      request.locationId = params.locationId;
    }

    const reqParams = new AssignShipmentsToRoutePath();
    reqParams.sicCd = params.terminalSicCd;
    reqParams.routeInstId = params.routeInstId;
    request.planDate = params?.routeDate;

    return this.cityOperationsService
      .assignShipmentsToRoute(request, reqParams)
      .pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(error) {
    this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe(() => {});

    this.updateCanOpenRouteBalancing(true);
    return throwError(error);
  }

  /**
   * Displays a success notification and triggers the related grids' updates.
   *
   * @param reassigningToExistingRoute
   * @param assignedShipmentCount
   * @param routeInstId
   * @param routePrefix
   * @param routeSuffix
   */
  onAssignShipmentsSuccess(
    reassigningToExistingRoute: boolean,
    assignedShipmentCount: number,
    routePrefix: string,
    routeSuffix: string,
    carrierId?: number
  ) {
    this.updateUnassignedDeliveries();

    this.updateModifyTripsGrid();
    this.updateTripsGrid();
    this.updateDispatcherTripsGrid();
    this.updatePlanningRoutesGrid();
    this.updateUnassignedDeliveriesGrid();
    this.updateDockRoutesGrid();
    let successMessage = '';

    if (carrierId) {
      if (reassigningToExistingRoute) {
        successMessage = 'Shipments successfully added to cartage drop stop.';
      } else {
        successMessage = `New cartage stop created on route ${routePrefix}-${routeSuffix}`;
      }
    } else {
      successMessage =
        `${assignedShipmentCount} ${assignedShipmentCount > 1 ? 'shipments have' : 'shipment has'} ` +
        `been assigned to ${reassigningToExistingRoute ? 'existing' : 'new'} route ${routePrefix}-${routeSuffix}`;
    }

    this.notificationMessageService
      .openNotificationMessage(NotificationMessageStatus.Success, successMessage)
      .subscribe(() => {});
  }

  private updateCanOpenRouteBalancing(canOpen: boolean) {
    this.pndStore$.dispatch(
      new RouteBalancingActions.SetCanOpenRouteBalancing({
        canOpenRouteBalancing: canOpen,
      })
    );
  }

  private updateUnassignedDeliveries() {
    this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.Refresh());
  }

  private updateTripsGrid() {
    this.pndStore$.dispatch(new TripsStoreActions.Refresh());
  }

  private updateModifyTripsGrid() {
    this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
  }

  private updateDockRoutesGrid() {
    this.pndStore$.dispatch(new DockRoutesStoreActions.Refresh());
  }

  private updatePlanningRoutesGrid() {
    this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
  }

  private updateUnassignedDeliveriesGrid() {
    this.pndStore$.dispatch(
      new RoutesStoreActions.UpdateUnassignedDeliveriesGridAction({
        updateUnassignedDeliveriesGrid: {
          source: StoreSourcesEnum.ASSIGN_TO_ROUTE_DIALOG,
          date: new Date(),
        },
      })
    );
  }

  private updateDispatcherTripsGrid() {
    this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
  }

  /**
   * Creates a new route.
   *
   * @param routePrefix
   * @param routeSuffix
   * @param categoryCd
   * @param routeDate
   * @param terminalSicCd
   * @param shipmentIds shipments to include in the new route
   * @param suggestedRouteNames used to populate the areaInstId (geoarea)
   * @param overrideTdc
   * @param startTime optional
   * @param doorNbr optional
   * @param dockLocation optional
   * @param nearestDoor optional
   * @param trailer optional
   * @param carrierId optional
   * @param locationId optional
   */
  private createNewRoute(
    routePrefix: string,
    routeSuffix: string,
    categoryCd: RouteCategoryCd,
    routeDate: string,
    terminalSicCd: string,
    shipmentIds: ShipmentId[],
    suggestedRouteNames: SuggestedRouteName[],
    overrideTdc: boolean,
    forceReassign: boolean,
    overrideSplitHandlingUnitInd: boolean,
    overrideCustomerClosedInd: boolean,
    startTime?: string,
    doorNbr?: string,
    dockLocation?: string,
    nearestDoor?: string,
    trailer?: string,
    oldRouteInstId?: number,
    oldTripInstId?: number,
    oldSourceStops?: TripNode[],
    carrierId?: number,
    locationId?: number,
    plandDate?: string
  ): Observable<CreateNewRouteResp> {
    const suggestedRoute = suggestedRouteNames.find((s: SuggestedRouteName) => s?.geoArea?.geoAreaName === routePrefix);

    const isTrailerAvailable = trailer && trailer.indexOf('-') > 0;
    const eqpPfx = isTrailerAvailable ? trailer.substr(0, trailer.indexOf('-')) : undefined;
    const eqpSfx = isTrailerAvailable ? +trailer.substr(trailer.indexOf('-') + 1) : undefined;

    const request: CreateNewRouteRqst = {
      ...new CreateNewRouteRqst(),
      shipmentList: shipmentIds,
      overrideTdcInd: overrideTdc,
      overrideSplitHandlingUnitInd: overrideSplitHandlingUnitInd,
      overrideCustomerClosedInd,
      areaInstId: suggestedRoute?.geoArea?.areaInstId ? +suggestedRoute.geoArea.areaInstId : undefined,
      forceReassignInd: forceReassign,
      oldSourceRoute: { ...new Route(), routeInstId: oldRouteInstId },
      oldSourceTrip: { ...new Trip(), tripInstId: oldTripInstId },
      oldSourceStops,
      route: {
        ...new Route(),
        routePrefix,
        routeSuffix,
        routeDate,
        categoryCd,
        terminalSicCd,
        equipmentIdPrefix: eqpPfx,
        equipmentIdSuffixNbr: eqpSfx,
        deliveryRouteDepartTime: this.timeStringToHHMMSS(startTime),
        xdockReleaseInd: false,
      },
      carrierId,
      locationId,
      planDate: plandDate,
    };

    if (nearestDoor || dockLocation) {
      request.route.equipmentDockLocation = {
        ...new DockLocation(),
        dockName: (dockLocation || '').toUpperCase(),
        dockClosestDoorNbr: nearestDoor ? _padStart(nearestDoor, 4, '0') : '',
      };
    }
    if (doorNbr) {
      request.route.plannedDoor = _padStart(doorNbr, 4, '0');
    }

    // NOTE: required to induce a delay between the creating of a new route and any
    //       actions on it so that the BE has time to sync the data.
    return this.cityOperationsService.createNewRoute(request).pipe(delay(250));
  }

  private beautifyMessage(separatedByColonMessage: string): string {
    const lastIndexOfColon = separatedByColonMessage.lastIndexOf(',');
    const firstMessage = separatedByColonMessage.slice(0, lastIndexOfColon);
    const lastMessage = separatedByColonMessage.slice(lastIndexOfColon, separatedByColonMessage.length);

    const replacement = lastMessage.replace(',', ' and');

    return firstMessage.concat(replacement);
  }

  /**
   * Ensure the passed time string is in HH:mm:ss format, or undefined
   * @param time string representation of time in HH:mm or HH:mm:ss format
   */
  timeStringToHHMMSS(time: string): string {
    if (_size(time) === 0) {
      return undefined;
    }

    const parts = time.split(':');
    if (parts.length !== 2) {
      // either already in format HH:mm:ss ,or unknown format.
      return time;
    } else {
      // in format HH:mm, so add empty seconds
      return `${time}:00`;
    }
  }

  /**
   * Displays a success notification and triggers the related grids' updates.
   *
   * @param routeInstId
   * @param routeName
   */
  onAssignPlanningShipmentsSuccess(routeName: string) {
    this.updateTripsGrid();
    this.updatePlanningRoutesGrid();
    this.updateUnassignedDeliveriesGrid();

    this.notificationMessageService
      .openNotificationMessage(NotificationMessageStatus.Success, `Shipments reassigned to ${routeName}.`)
      .subscribe(() => {});
  }

  /**
   * Assign stops to an existing route.
   *
   * @param selectedStops
   * @param newRouteInstId
   * @param newTripInstId
   */
  assignStopsToExistingRoute(
    selectedStops: Stop[],
    newRouteInstId: number,
    newTripInstId: number,
    overrideDsrLicenseInd?: boolean
  ): Observable<ReassignStopsResp> {
    // disable opening of Route Balancing
    this.updateCanOpenRouteBalancing(false);

    return this.reassignStops(newRouteInstId, newTripInstId, selectedStops, overrideDsrLicenseInd).pipe(
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Assign stops to a new route.
   *
   * @param params
   * @param selectedStops
   * @param newRouteInstId
   * @param newTripInstId
   * @param suggestedRouteNames used to populate the areaInstId (geoarea)
   */
  assignStopsToNewRoute(
    params: AssignShipmentsParams,
    selectedStops: Stop[],
    suggestedRouteNames: SuggestedRouteName[]
  ): Observable<CreateNewRouteResp> {
    // disable opening of Route Balancing
    this.updateCanOpenRouteBalancing(false);
    let oldRouteInstId = 0;
    let oldTripInstId = 0;
    const oldSourceStops = [];
    selectedStops.forEach((selectedStop) => {
      oldSourceStops.push(selectedStop.tripNode);
      if (!_isEmpty(selectedStop?.tripNode)) {
        oldTripInstId = selectedStop.tripNode.tripInstId;
      }
      selectedStop?.activities?.forEach((activity: Activity) => {
        if (activity?.routeShipment) {
          oldRouteInstId = activity.routeShipment.routeInstId;
        }
      });
    });
    return this.createNewRoute(
      params.routePrefix,
      params.routeSuffix,
      params.categoryCd,
      params.routeDate,
      params.terminalSicCd,
      [], // when reassigning stops, shipments will get assigned from oldRouteInstId/oldTripInstId/oldSourceStops and not from this parameter
      suggestedRouteNames,
      true,
      false,
      false,
      false,
      params.startTime,
      params.doorNbr,
      params.dockLocation,
      params.nearestDoor,
      params.trailer,
      oldRouteInstId,
      oldTripInstId,
      oldSourceStops
    ).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Displays a success notification and triggers the related grids' updates.
   *
   * @param selectedStops
   * @param routeInstId
   * @param routeName
   * @param reassigningToExistingRoute
   */
  onAssignStopsSuccess(selectedStops: Stop[], routeName: string, reassigningToExistingRoute: boolean) {
    this.updateTripsGrid();
    this.updatePlanningRoutesGrid();
    this.updateUnassignedDeliveriesGrid();

    let message: string;
    if (selectedStops.length === 1) {
      message =
        `Stop ${selectedStops[0]?.customer?.name1} has been assigned to ${
          reassigningToExistingRoute ? 'existing' : 'new'
        } route ` + `${routeName}`;
    } else {
      message =
        `${selectedStops.length} Stops have been assigned to ${
          reassigningToExistingRoute ? 'existing' : 'new'
        } route ` + `${routeName}`;
    }
    this.notificationMessageService
      .openNotificationMessage(NotificationMessageStatus.Success, message)
      .subscribe(() => {});
  }

  /**
   * Reassign stops to the specified route.
   *
   * @param newRouteInstId
   * @param newTripInstId
   * @param selectedStops
   */
  private reassignStops(
    newRouteInstId: number,
    newTripInstId: number,
    selectedStops: Stop[],
    overrideDsrLicenseInd?: boolean
  ): Observable<ReassignStopsResp> {
    let oldRouteInstId = 0;
    let oldTripInstId = 0;
    selectedStops.forEach((selectedStop) => {
      if (!_isEmpty(selectedStop?.tripNode)) {
        oldTripInstId = selectedStop.tripNode.tripInstId;
      }
      selectedStop?.activities?.forEach((activity: Activity) => {
        if (activity?.routeShipment) {
          oldRouteInstId = activity.routeShipment.routeInstId;
        }
      });
    });

    const request = new ReassignStopsRqst(); // In these requests he only wants route instance id and trip instance id.
    request.newRoute = { ...new Route(), routeInstId: newRouteInstId };
    request.newTrip = { ...new Trip(), tripInstId: newTripInstId };
    request.oldRoute = { ...new Route(), routeInstId: oldRouteInstId };
    request.oldTrip = { ...new Trip(), tripInstId: oldTripInstId };
    request.tripNode = selectedStops.map((selectedStop) => {
      return {
        ...new TripNode(),
        tripNodeSequenceNbr: selectedStop?.tripNode?.tripNodeSequenceNbr,
      };
    });
    request.overrideDsrLicenseInd = overrideDsrLicenseInd;
    request.auditInfo = this.createAuditInfo();
    return this.cityOperationsService.reassignStops(request);
  }

  /**
   * Used to create audit info for proxy calls. Uses the current date time and the employee id to create the audit info object.
   * TODO we want the backend to populate the updateById. Passing back the original modified time is planned.
   */
  private createAuditInfo(): AuditInfo {
    const auditInfo = new AuditInfo();
    auditInfo.updatedTimestamp = new Date();
    auditInfo.updateById = _result(this.userRoleService, 'user.employeeId');
    return auditInfo;
  }

  showReleaseRouteMessage(results: BalancePnDRoutesResp): void {
    const routes: RouteDetail[] = [];

    results?.tripDetails?.forEach((tripDetail) => {
      tripDetail?.route?.forEach((route) => {
        if (route?.route?.xdockReleaseInd) {
          routes.push(route);
        }
      });
    });

    let routesUpdated: string = _map(
      routes,
      (routeDetail: RouteDetail) =>
        `${routeDetail?.route?.routePrefix ?? ''.toUpperCase()}-${routeDetail?.route?.routeSuffix ?? ''.toUpperCase()}`
    ).join(', ');

    if (_size(routes) >= 5) {
      this.notificationMessageService.openSnackBar(
        `Routes have been successfully released.`,
        NotificationMessageStatus.Success
      );
    } else if (_size(routes) === 1) {
      routesUpdated = this.beautifyMessage(routesUpdated);
      this.notificationMessageService.openSnackBar(
        `Route ${routesUpdated} have been successfully released.`,
        NotificationMessageStatus.Success
      );
    } else {
      routesUpdated = this.beautifyMessage(routesUpdated);
      this.notificationMessageService.openSnackBar(
        `Routes ${routesUpdated} have been successfully released.`,
        NotificationMessageStatus.Success
      );
    }
  }

  showTripMessage(
    results: UpdatePnDRoutesResp,
    action = RouteService.ACTION_UPDATE
  ): Observable<{ results: UpdatePnDRoutesResp; successCount: number; errorCount: number }> {
    const errorCount: number = _size(results?.messages);
    const successCount: number = _size(results?.routes);
    const routes = results?.routes ?? [];
    const hasSomeModifiedRoute = _some(routes, (route: Route) => route.deliveryRouteDepartTime === '00:00');
    let releasedRoutes: Route[];
    let routesUpdated: string;
    let snackbar;
    if (_size(results.warnings) === 0) {
      if (hasSomeModifiedRoute) {
        releasedRoutes = _filter(routes, (route: Route) => route.deliveryRouteDepartTime !== '00:00');

        routesUpdated = _map(
          releasedRoutes,
          (route: Route) => `${(route?.routePrefix ?? '').toUpperCase()}-${(route?.routeSuffix ?? '').toUpperCase()}`
        ).join(', ');
      } else {
        routesUpdated = _map(
          routes,
          (route: Route) => `${(route?.routePrefix ?? '').toUpperCase()}-${(route?.routeSuffix ?? '').toUpperCase()}`
        ).join(', ');
      }

      const pluralResult = this.pluralPipe.transform(successCount, 'Route', 'Routes');

      if (errorCount === 0) {
        if (hasSomeModifiedRoute && _size(releasedRoutes)) {
          if (_size(releasedRoutes) > 1) {
            routesUpdated = this.beautifyMessage(routesUpdated);
            snackbar = this.notificationMessageService.openSnackBar(
              `Routes ${routesUpdated} have been successfully released. ${this.MESSAGE}`,
              NotificationMessageStatus.Success
            );
          } else if (_size(releasedRoutes) === 1) {
            snackbar = this.notificationMessageService.openSnackBar(
              `Route ${routesUpdated} has been successfully released. ${this.MESSAGE}`,
              NotificationMessageStatus.Success
            );
          } else {
            snackbar = this.notificationMessageService.openSnackBar(
              `${successCount} ${pluralResult} ${_capitalize(action)} Successfully. ${routesUpdated}`,
              NotificationMessageStatus.Success
            );
          }
        } else {
          snackbar = this.notificationMessageService.openSnackBar(
            `${successCount} ${pluralResult} ${_capitalize(action)} Successfully. ${routesUpdated}`,
            NotificationMessageStatus.Success
          );
        }
      } else {
        const description = results.messages.join('\n').replace(/ Id:.*?Error: |ExceptionMessage: /g, ' ');
        snackbar = this.notificationMessageService.openSnackBar(
          `${successCount} ${pluralResult} ${_capitalize(action)}. ${routesUpdated}`,
          NotificationMessageStatus.Warn,
          `The following routes were not ${action} because of the issues mentioned below:\n${description}`
        );
      }

      return snackbar.afterOpened().pipe(map(() => ({ results, successCount, errorCount })));
    }
    return of({ results, successCount, errorCount });
  }

  getRouteNames(): Observable<string[]> {
    return combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites),
    ]).pipe(
      take(1),
      map(([sicCd, planDate, sicZonesAndSatellites]) => {
        const path: ListPnDSuggestedRouteNamesPath = { sicCd: sicCd };
        const query: ListPnDSuggestedRouteNamesQuery = {
          planDate: moment(planDate).format('YYYY-MM-DD'),
          satelliteInd: true,
          zoneIndicatorCd: PndZoneUtils.getZoneIndicatorCd(sicZonesAndSatellites),
        };
        return { path, query };
      }),
      switchMap(({ path, query }) => {
        return this.cityOperationsService.listPnDSuggestedRouteNames(path, query).pipe(
          take(1),
          catchError(() => of({ data: new ListPnDSuggestedRouteNamesResp() })),
          map((response: ListPnDSuggestedRouteNamesResp) => {
            const suggestedRouteNames = response?.suggestedRouteNames ?? [];

            return suggestedRouteNames
              .filter((r) => RouteService.PLANNING_ROUTE_PREFIXES.indexOf(r?.geoArea?.geoAreaName) === -1)
              .map((suggestedRoute: SuggestedRouteName) => suggestedRoute.geoArea.geoAreaName);
          })
        );
      })
    );
  }
}
