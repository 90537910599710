import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { EventItem, AssignedStopIdentifier } from '../../../app/inbound-planning/shared';

export class ConfirmMergeDialogData {
  stops: EventItem<AssignedStopIdentifier>[];

  constructor(stops) {
    this.stops = stops;
  }
}
@Component({
  selector: 'pnd-confirm-merge',
  templateUrl: './confirm-merge.component.html',
  styleUrls: ['./confirm-merge.component.scss'],
})
export class ConfirmMergeComponent implements OnInit {
  title: string = 'Choose Profile';
  selectedStop: EventItem<AssignedStopIdentifier>;
  distinctStops: EventItem<AssignedStopIdentifier>[];

  constructor(
    public dialogRef: MatDialogRef<ConfirmMergeDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmMergeDialogData
  ) {}

  ngOnInit(): void {
    this.distinctStops = this.data?.stops?.filter((item, i, uniqueList) => {
      return uniqueList.indexOf(uniqueList.find((t) => t.id?.customerAcctIntId === item.id?.customerAcctIntId)) === i;
    });
    this.selectedStop = this.data?.stops[0];
  }

  changeSelection(value): void {
    this.selectedStop = value;
  }

  confirmAction(): void {
    this.dialogRef.close({ value: this.selectedStop });
  }

  cancelAction(): void {
    this.dialogRef.close({ value: undefined });
  }
}
