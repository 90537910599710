export enum UnassignedPickupsServiceFields {
  pickupRequestInstId = 'pickupRequestInstId',
  hostOriginTerminalSicCd = 'hostOriginTerminalSicCd',
  zip6 = 'zip6',
  pickupRequestSequenceNbr = 'pickupRequestSequenceNbr',
  palletsCount = 'palletsCount',
  loosePiecesCount = 'loosePiecesCount',
  weightLbs = 'weightLbs',
  guaranteedInd = 'guaranteedInd',
  hazardousMtInd = 'hazardousMtInd',
  freezableInd = 'freezableInd',
  statusCd = 'statusCd',
  destinationTerminalSicCd = 'destinationTerminalSicCd',
  remarks = 'remarks',
  updateById = 'updateById',
  proNbr = 'proNbr',
  cubeNbr = 'cubeNbr',
  motorMovesNbr = 'motorMovesNbr',
  bolInstId = 'bolInstId',
  sourceCd = 'sourceCd',
  pickupTypeCd = 'pickupTypeCd',
  shipper = 'shipper',
  pickupRequestInstIdHeader = 'header.pickupRequestInstId',
  sourceCdHeader = 'header.sourceCd',
  statusCdHeader = 'header.statusCd',
  pickupDateHeader = 'header.pickupDate',
  readyTime = 'header.readyTime',
  closeTime = 'header.closeTime',
  ttlPalletsCount = 'header.ttlPalletsCount',
  ttlLoosePiecesCount = 'header.ttlLoosePiecesCount',
  ttlWeightLbs = 'header.ttlWeightLbs',
  pickupTerminalSicCd = 'header.pickupTerminalSicCd',
  cadCallNbr = 'header.cadCallNbr',
  shipperName1 = 'header.shipperName1',
  shipperName2 = 'header.shipperName2',
  remarksHeader = 'header.remarks',
  pickupTypeCdHeader = 'header.pickupTypeCd',
  originTerminalSicCd = 'header.originTerminalSicCd',
  auditInfo = 'header.auditInfo',
  callNbr = 'header.callNbr',
  areaName = 'header.areaName',
  specialServiceSummary = 'specialServiceSummary',
  preassignedDriver = 'preassignedDriver',
  operationalRemarks = 'operationalRemarks',
  pickupInstructions = 'pickupInstructions',
  locationType = 'locationType',
  minimumAllowableTrailerAge = 'minimumAllowableTrailerAge',
  pickupDwellTime = 'pickupDwellTime',
  equipmentDwellTime = 'equipmentDwellTime',
  lineItemCount = 'lineItemCount',
  destinationZipCount = 'destinationZipCount',
  destinationSicCount = 'destinationSicCount',
  dispatchGroupId = 'dispatchGroupId',
  tripInstId = 'tripInstId',
  tenderId = 'tenderId',
  shipperPhoneCountryCd = 'header.shipperPhoneCountryCd',
  specialEquipmentCd = 'header.specialEquipmentCd',
  rschCnclReasonCd = 'header.rschCnclReasonCd',
  strandReasonCd = 'header.strandReasonCd',
  cubicFeetProfileMthdCd = 'cubicFeetProfileMthdCd',
  cubicFeetProfileTypeCd = 'cubicFeetProfileTypeCd',
  callDate = 'header.callDate',
  callSequenceNbr = 'header.callSequenceNbr',
}
